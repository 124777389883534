import store, { persistor } from "../../Redux/Store/store";
import * as actions from "../../Redux/Reducers/projectSlice";
import * as verificationActions from "../../Redux/Reducers/verificationSlice";
import * as projectAction from "../../Redux/Reducers/projectSlice";
import * as registerAction from "../../Redux/Reducers/registerSlice";
import * as dataAction from "../../Redux/Reducers/dataReducer";
import * as meetingActions from "../../Redux/Reducers/meetingSlice";
import $ from "jquery";
import { makePostRequest } from "../../Axios";
import { showErrorMessage } from "../verificationUtils";
import { setHour, setDate } from "../MeetingsUtils";
import moment from "moment";
import { requestMeeting, validateRegisterForm } from "../RegistetUtils";

export const stepsDictionary = {
	'building_selection' : 1,
	'floor_selection' : 2,
	'apartment_selection' : 3,
	'apartment_details' : 4,
	'approve_details' : 5,
	'contract_signature' : 5.5,
	'payment' : 6,
	'meeting_schedule' : 7,
	'finish' : 8,
	'graphic_view' : 9,
	'table_view' : 10,
	'3dview' : 11,
}

export const resetFlow = (pushHistory = true) => {
	try {
		const meetingSlug = store.getState().verification.slug;
		const isAgent = store.getState().meeting.isAgent;
		const projectID = store.getState().project.projectID;
		
		if(pushHistory) addURLHistory('building_selection', false);
		store.dispatch(projectAction.setStep(`1`));
		store.dispatch(projectAction.setStepsProgress("1"));
		store.dispatch(projectAction.setAppartmentBasicaData(""));
		setBuildingName("");
		setFloorName("");
		setAppartmentTitle("");
		setFeautresListPerm("");
		setSelectedAptImg("");

		store.dispatch(projectAction.setBuldingID(""));
		store.dispatch(projectAction.setFloorID(""));
		store.dispatch(projectAction.setAppartment_id(""));
		store.dispatch(projectAction.setAppartmentID(""));

		let currentProjectID = sessionStorage.getItem('projectID');
		if ((currentProjectID == '' || !currentProjectID) && projectID != '') {
			currentProjectID = projectID;
			sessionStorage.setItem('projectID', projectID);
		} 
		
		store.dispatch(projectAction.setProjectID(currentProjectID));

		if (meetingSlug) {
			setRemoteAction({ eventName: "stepChange", payload: 1, author: isAgent });
		}
		return;
	} catch (err) {
		console.log(err);
	}
}

export const setIsAppartmentAvailable = (value) => {
	store.dispatch(actions.setIsAppartmentAvailable(value));
};

export const setStepProgression = (value) => {
	actions.setStepsProgress(value);
};


export const getObjKey = (obj, value) => {
	return Object.keys(obj).find(key => obj[key] === value);
  }

export const setCurrentStep = (e, newStep_title = null, history = null) => {
	if (e == null && newStep_title == null) return;
	try {
		const meetingSlug = store.getState().verification.slug;
		const isAgent = store.getState().meeting.isAgent;
		const stepsProgress = store.getState().project.stepsProgress;

		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		let newStep_code;
		if (!newStep_title) {
			newStep_code = parseFloat(e.currentTarget.dataset.step);
			newStep_title = getObjKey(stepsDictionary, newStep_code);
		} else {
			newStep_code = stepsDictionary[`${newStep_title}`]
			if(!newStep_code) newStep_code = stepsDictionary[0]
		}
		
		const stepsButtons = document.querySelectorAll(".stepsButton");


		if (newStep_code <= 5) {
			setUserApprovedApt(false);
		}
		store.dispatch(actions.setStep(`${newStep_code}`));

		if (history) {
			if (newStep_code == 4) {
				const appartment_id = store.getState().project.appartmentID;
				addURLHistory(`${newStep_title}/${appartment_id}`, false)
			} else {
				addURLHistory(newStep_title, false)
			}
		}

		stepsButtons.forEach((el, idx) => {
			if (parseFloat(el.dataset.step) !== newStep_code) {
				el.classList.remove("current-link");
			} else {
				el.classList.add("current-link", "complate-link");
			}
		});

		if (newStep_code > 1 && stepsProgress == 1) {
			$($('#steps_menu__container').find('.steps_menu__link')[0]).addClass('current-link');
		}

		if (meetingSlug) {
			setRemoteAction({ eventName: "stepChange", payload: newStep_code, author: isAgent });
		}
	} catch (err) {
		console.log(err);
	}
};

export const setSuccessStep = (id) => {
	const elem = document.getElementById("steps_menu__container").children[0].children[id];
	let elem2 = document.getElementById("steps_menu__container").children[0].children[id + 1];
	if (!elem2) {
		elem2 = elem;
	}
	elem2.classList.add("current-link", "complate-link");

	const stepsButtons = document.querySelectorAll(".stepsButton");
	elem.classList.add("doneStep");
	stepsButtons.forEach((el) => {
		if (el.id !== elem2.id) {
			el.classList.remove("current-link");
		}
	});
};

export const handleBuildingSelect = async (e, src, rooms, currentLanguge, history) => {
	const meetingSlug = store.getState().verification.slug;
	const agentKey = store.getState().verification.agentKey;
	const id = e.target.id;
	
	if (meetingSlug) {
		updateMeetingFlow(id, 1, meetingSlug, agentKey).then((res) => {
			if (!res) {
				setFlowErrorObject({ errorCode: "code 500", errorMessage: currentLanguge.flowErrors.generalError_retry });
				return;
			} else if (res.data.statusCode == 713) { // In meeting - no permission to do enthing before agent joins the meeting
				setFlowErrorObject({
					errorCode: "code 404",
					errorTitle: currentLanguge.flowErrors.errorNoticTitle,
					errorMessage: currentLanguge.flowErrors.error713,
				});
			} else if (res.data.statusCode == 705) { // meeting is ended
				setFlowErrorObject({
					errorCode: "code 404",
					errorTitle: currentLanguge.flowErrors.errorNoticTitle,
					errorMessage: currentLanguge.flowErrors.error705,
					errorRedirectUrl: '/register',
					errorRedirectTitle: currentLanguge.flowErrors.reschedualBtn
				});
			} else if (!res.data.meeting) {
				setFlowErrorObject({
					errorCode: "code 404",
					errorTitle: currentLanguge.flowErrors.errorNoticTitle,
					errorMessage: currentLanguge.flowErrors.alreadyReservedApt,
				});
			} else if (res.data.statusCode == 200) {
				return;
			}
		});
	}

	if (!agentKey && !meetingSlug) {
		setSelectedFloorImg(src);
		store.dispatch(actions.setBuldingID(id));
		setSuccessStep(0);
		setBuildingName(e.target.dataset.builname);

		addURLHistory('floor_selection', false)

		persistor.flush();
		store.dispatch(actions.setStep("2"));
		store.dispatch(actions.setStepsProgress("2"));
	}
};

export const handleFloorSelect = async (e, name, src, rooms, currentLanguge, history) => {
	const meetingSlug = store.getState().verification.slug;
	const agentKey = store.getState().verification.agentKey;
	const id = e.target.id;

	if (agentKey) {
		updateMeetingFlow(id, 2, meetingSlug, agentKey).then((res) => {
			if (!res) {
				setFlowErrorObject({
					errorCode: "code 500",
					errorMessage: currentLanguge.flowErrors.generalError_reload
				});
				return;
			}
			if (!res.data.meeting) {
				setFlowErrorObject({
					errorCode: "code 404",
					errorMessage: currentLanguge.flowErrors.alreadyReservedApt,
				});
			}
			if (res.data.statusCode == 200) {
				// setSelectedFloorImg(src);
				return;
			}
		});
		return;
	}
	if (!agentKey && meetingSlug) {
		updateMeetingFlow(id, 2, meetingSlug, agentKey).then((res) => {
			if (!res) {
				setFlowErrorObject({
					errorCode: "code 500",
					errorMessage: currentLanguge.flowErrors.generalError_reload
				});
				return;
			}
			if (!res.data.meeting) {
				setFlowErrorObject({
					errorCode: "code 404",
					errorMessage: currentLanguge.flowErrors.alreadyReservedApt,
				});
			}
			if (res.data.statusCode == 200) {
				// setSelectedFloorImg(src);
				return;
			}
		});
		return;
	}
	if (!agentKey && !meetingSlug) {
		store.dispatch(actions.setFloorID(id));
		persistor.flush();

		addURLHistory('apartment_selection', false)
		store.dispatch(actions.setStepsProgress("3"));
		store.dispatch(actions.setStep("3"));


		setSelectedFloorImg(src);
		setFloorName(e.target.dataset.ftitle);
		setSuccessStep(1);

		return;
	}
};

export const handleAppartmentPreview = async (e, title, src, currentLanguge, history) => {
	try {
		e.preventDefault();
		e.stopPropagation();
		const aptslug = e.target.dataset.aptslug;
		const meetingSlug = store.getState().verification.slug;
		const appartment_id = e.target.dataset.aptid;
		const agentKey = store.getState().verification.agentKey;
		const id = e.target.id;


		setAppartment_id(appartment_id);
		store.dispatch(actions.setAppartmentID(id));
		
		addURLHistory(`apartment_details/${aptslug}`, false)
		store.dispatch(actions.setStepsProgress("4"));
		store.dispatch(actions.setStep("4"));

		persistor.flush();

		if (agentKey) {
			const req = await updateMeetingFlow(appartment_id, 3, meetingSlug, agentKey);
			if (!req) {
				setFlowErrorObject({
					errorCode: "code 500",
					errorMessage: currentLanguge.flowErrors.generalError_reload
				});
				return;
			}
			if (!req.data.meeting) {
				setFlowErrorObject({
					errorCode: "code" + req.data.statusCode,
					errorMessage: currentLanguge.flowErrors.generalError_reload
				});
			}
			if (req.data.statusCode == 200) {
				// setSelectedAptImg(src);
				setSuccessStep(2);
			}
			return;
		}
		if (!agentKey && meetingSlug) {
			const req = await updateMeetingFlow(appartment_id, 3, meetingSlug, agentKey);
			if (!req) {
				setFlowErrorObject({ errorCode: "code 500", errorMessage: currentLanguge.flowErrors.generalError_reload });
				return;
			}
			if (!req.data.meeting) {
				setFlowErrorObject({
					errorCode: "code 404",
					errorMessage: currentLanguge.flowErrors.alreadyReservedApt,
				});
			}

			if (req.data.statusCode == 200) {
				setSelectedAptImg(src);
				// setSuccessStep(2);
			}
			return;
		}
		if (!agentKey && !meetingSlug) {
			store.dispatch(actions.setAppartmentID(id));
			persistor.flush();

			addURLHistory(`apartment_details/${aptslug}`, false)
			store.dispatch(actions.setStepsProgress("4"));
			store.dispatch(actions.setStep("4"));
			setSelectedAptImg(src);
			setSuccessStep(2);
			setAppartmentTitle(title);

			return;
		}
	} catch (err) {
		console.log(err);
	}
};

export const handleTableAppartmentView = async (payload, currentLanguge, history = false) => {
	const { buildingID, floorID, slug, id, buildingTitle, floorTitle, aptTitle, imageDesktop } = payload;
	const agentKey = store.getState().verification.agentKey;
	const meetingSlug = store.getState().verification.slug;

	const asyncSetpCall = () => {
		store.dispatch(actions.setAppartmentID(slug));
		store.dispatch(actions.setStep("4"));
		store.dispatch(actions.setStepsProgress("4"));
		store.dispatch(actions.setBuldingID(buildingID));
		store.dispatch(actions.setFloorID(floorID));
		store.dispatch(actions.setAppartment_id(id));

		if(history) addURLHistory(`apartment_details/${slug}`, false)

		setBuildingName(buildingTitle);
		setFloorName(floorTitle);
		setAppartmentTitle(aptTitle);
		persistor.flush();
	};
	
	asyncSetpCall();
	setSuccessStep(0);
	setSuccessStep(1);
	setSuccessStep(2);
	setSelectedAptImg(imageDesktop);
	if (agentKey) {
		updateMeetingFlow(id, 3, meetingSlug, agentKey).then((res) => {
			if (!res) {
				setFlowErrorObject({
					errorCode: "code 500",
					errorMessage: currentLanguge.flowErrors.generalError_reload
				});
				return;
			}
			if (!res.data.meeting) {
				setFlowErrorObject({
					errorCode: "code 404",
					errorMessage: currentLanguge.flowErrors.alreadyReservedApt,
				});
			}
		});
		return;
	}
	if (!agentKey && meetingSlug) {
		updateMeetingFlow(id, 3, meetingSlug, agentKey).then((res) => {
			if (!res) {
				setFlowErrorObject({
					errorCode: "code 500",
					errorMessage: currentLanguge.flowErrors.generalError_reload
				});
				return;
			}
			if (!res.data.meeting) {
				setFlowErrorObject({
					errorCode: "code 404",
					errorMessage: currentLanguge.flowErrors.alreadyReservedApt,
				});
			}
			if (res.data.statusCode == 200) {
				// asyncSetpCall();
			}
		});
		return;
	}
};

export const handleAppartmentSelect = async (e, src, currentLanguge, history) => {
	try {
		const meetingSlug = store.getState().verification.slug;
		const isAgent = store.getState().meeting.isAgent;
		const previewMode = store.getState().project.previewMode;

		if (previewMode) {
			e.currentTarget.classList.add("appear");
			e.currentTarget.textContent = currentLanguge.general.previewMode;
			return;
		}

		if (meetingSlug) {
			setRemoteAction({ eventName: "stepChange", payload: 5, author: isAgent });
			setSuccessStep(3);
			
			addURLHistory('approve_details', false)

			store.dispatch(actions.setStep("5"));
			store.dispatch(actions.setStepsProgress("5"));
		}

		if (!meetingSlug) {
			addURLHistory('approve_details', false)
			
			store.dispatch(actions.setStep("5"));
			store.dispatch(actions.setStepsProgress("5"));
			return;
		}
	} catch (err) {
		console.log(err);
	}
};


export const setAppartmentBasicaData = (data) => {
	try {
		if (!data.rooms || !data.sqrMeter || !data.oldPrice || !data.newPrice) {
			//handle
			return;
		}

		store.dispatch(actions.setAppartmentBasicaData(data));
	} catch (err) {
		console.log(err);
	}
};

export const setBuildingName = (value) => {
	store.dispatch(actions.setBuldingName(value));
};
export const setFloorName = (value) => {
	store.dispatch(actions.setFloorName(value));
};

export const setAppartmentTitle = (value) => {
	store.dispatch(actions.setAppartmentTitle(value));
};

export const setAllAppartmentsData = (value) => {
	store.dispatch(actions.setAllAppartmentsData(value));
};

export const setAppartmentsDynamicData = (value) => {
	store.dispatch(actions.setDynamicAppartmentsData(value));
};

export const setProjectStats = (value) => {
	store.dispatch(actions.setProjectStats(value));
};

export const handleSignatureSubmit = async (e, isSigEmpty, signatureBase64, currentLanguge) => {
	const meetingSlug = store.getState().verification.slug;
	const requestSkipSign = store.getState().meeting.requestSkipSign;
	const requestSkipPay = store.getState().meeting.requestSkipPay;
	const isClientRegistered = store.getState().verification.isClientRegistered;
	const appartment_id = store.getState().project.appartmentRealID;

	const agentKey = store.getState().verification.agentKey;
	setLoadingModalContent(currentLanguge.contract_step.updatingSignature);

	e.preventDefault();
	e.stopPropagation();

	if (!isClientRegistered) {
		setLoadingModalContent(false);
		showErrorMessage("invalidSig", currentLanguge.flowErrors.registerRequired);
		return;
	}
	if (!requestSkipSign) {

		if (!isSigEmpty) {
			setLoadingModalContent(currentLanguge.contract_step.updatingSignature);
			const req = await updateMeetingFlow(signatureBase64, 5, meetingSlug, "", appartment_id);
			if (!req) {
				setLoadingModalContent(false);
				setFlowErrorObject({
					errorCode: "code 500",
					errorMessage: currentLanguge.flowErrors.generalError_reload
				});
				return;
			}
			if (!req.data.meeting) {
				setLoadingModalContent(false);
				setFlowErrorObject({
					errorCode: "code 404",
					errorMessage: currentLanguge.flowErrors.alreadyReservedApt
				});
				return;
			}
			if (req.data.statusCode == 200) {
				setSuccessStep(4);

				// check if skipPay is true
				if (requestSkipPay) {
					addURLHistory('meeting_schedule', false)
					store.dispatch(actions.setStep("7"));
					store.dispatch(actions.setStepsProgress("7"));
				} else {
					addURLHistory('payment', false)

					store.dispatch(actions.setStep("6"));
					store.dispatch(actions.setStepsProgress("6"));
				}
				
			}
			//display error
		}
		if (isSigEmpty) {
			showErrorMessage("invalidSig", currentLanguge.flowErrors.signatureRequired);
			setLoadingModalContent(false);
		}
		return;
	}
	if (requestSkipSign) {
		setLoadingModalContent(currentLanguge.contract_step.updatingSignature);
		const req = await updateMeetingFlow("SKIPPEDSIGN", 5, meetingSlug, agentKey, appartment_id);
		if (!req) {
			setLoadingModalContent(false);
			return;
		}
		if (req.data.statusCode == 200) {
			setSuccessStep(4);
			
			// check if skipPay is true
			if (requestSkipPay) {
				addURLHistory('meeting_schedule', false)
				
				store.dispatch(actions.setStep("7"));
				store.dispatch(actions.setStepsProgress("7"));
			} else {
				addURLHistory('payment', false);
				
				store.dispatch(actions.setStep("6"));
				store.dispatch(actions.setStepsProgress("6"));
			}
		}
	}
};

export const handlePaymentSubmit = async (data, currentLanguge, provider = 'tranzila') => {
	try {
		const meetingSlug = store.getState().verification.slug;
		const agentkey = store.getState().verification.agentKey;

		const requestSkipPay = store.getState().meeting.requestSkipPay;

		if (!requestSkipPay) {
			let wrapped;
			if (provider == 'tranzila') {
				wrapped = JSON.stringify(data.transaction_response);
			} else if (provider == 'stripe') {
				wrapped = JSON.stringify(data.paymentIntent);
			}
			setLoadingModalContent(currentLanguge.payment_step.updatingPayment);	
			
			updateMeetingFlow(wrapped, 6, meetingSlug, agentkey).then((res) => {
				if (!res) {
					return;
				}
				if (res.data.statusCode == 200) {
					addURLHistory('meeting_schedule', false);

					store.dispatch(actions.setStep("7"));
					store.dispatch(actions.setStepsProgress("7"));
					setSuccessStep(5);
					store.dispatch(projectAction.setIsPaid(true));
				}
			});
		}
		if (requestSkipPay) {
			setLoadingModalContent(currentLanguge.payment_step.updatingPayment);
			await updateMeetingFlow("SKIPPEDPAY", 6, meetingSlug).then((res) => {
				if (!res) {
					return;
				}
				if (res.data.statusCode == 200) {
					addURLHistory('meeting_schedule', false);

					store.dispatch(actions.setStep("7"));
					store.dispatch(actions.setStepsProgress("7"));
					setSuccessStep(5);
					store.dispatch(projectAction.setIsPaid(true));
				}
			});
		}
	} catch (err) {
		console.log(err);
	}
};

// Stripe
export const getPaymentIntent = async (amount, currentLanguge) => {

	const meetingSlug = store.getState().verification.slug;

	const data = new FormData();

	data.append("meetingslug", meetingSlug);
	data.append("amount", amount * 100);

	const req = await makePostRequest("/intent_stripe", data);
	if (!req) {
		setFlowErrorObject({
			errorCode: "code 500",
			errorMessage: currentLanguge.flowErrors.stripePaymentIntentError
		});
		return;
	}

	if (req.data.statusCode == 200 && req.data.message.client_secret) {
		return req.data.message.client_secret;
	}
}

export const handleRequestSignMeeting = (e) => {
	e.preventDefault();
	addURLHistory('meeting_schedule', false);

	store.dispatch(actions.setStep("7"));
	store.dispatch(actions.setStepsProgress("7"));
};

export const RenderIndicators = (list, buildingID, currentFloorID) => {
	try {
		if (list.length == 0) {
			list = store.getState().project.allAppartmentsData;
		}
		if (!list) {
			return;
		}
		for (let obj of list) {
			if (obj.building_id == buildingID) {
				for (let floor of obj.floors) {
					if (floor.floor_id == currentFloorID) {
						return floor.properties.map((el) => {
							if (el.status === 1) {
								return <span className="floor-stat__available"></span>;
							}
							if (el.status === 2) {
								return <span className="floor-stat__progress"></span>;
							}
							if (el.status === 3) {
								return <span className="floor-stat__reserved"></span>;
							}
							if (el.status === 4) {
								return <span className="floor-stat__reserved"></span>;
							} else {
								return <span className="floor-stat__reserved"></span>;
							}
						});
					}
				}
			}
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateMeetingFlow = async (value, step, meetingSlug, agentkey, propertyID, propertySlug, firstname, lastname, email, mobile, ID, clientType) => {
	if (!propertySlug) {
		const data = new FormData();
		data.append("meetingslug", meetingSlug);
		data.append("step", step);
		data.append("selectedvalue", value);
		data.append("agent_key", agentkey);

		let langCode = sessionStorage.getItem("langCode");
		data.append("client_language", langCode);
		if (propertyID) {
			data.append("propertyid", propertyID);
		}

		const updateFlow = await makePostRequest(`/meetingflow`, data).catch((err) => console.log(err));
		return updateFlow;
	}
};

// AgentLogic
export const changeMeetingAgentControl = async (agent_control) => {
	const meetingSlug = store.getState().verification.slug;
	const agentKey = store.getState().verification.agentKey;
	const data = new FormData();

	const getUrlParams = new URLSearchParams(window.location.search);
	const agentKeyParam = getUrlParams.get("agentkey");
	let ak = agentKey == '' || !agentKey ? agentKeyParam : agentKey;
	let agent_control_val = agent_control == true ? "1" : "0";

	data.append("meetingslug", meetingSlug);
	data.append("agent_key", ak);
	data.append("agent_control", agent_control_val);

	const req = await makePostRequest("/change_meeting_control", data);
	if (!req) {
		return;
	}
};

export const requestSignatureSkip = async () => {
	const meetingSlug = store.getState().verification.slug;
	const agentKey = store.getState().verification.agentKey;
	const data = new FormData();
	data.append("meetingslug", meetingSlug);
	data.append("agent_key", agentKey);

	const req = await makePostRequest("/request_skip_signature", data);
	if (!req) {
		return;
	}
};

export const requestPaymentSkip = async () => {
	const meetingSlug = store.getState().verification.slug;
	const agentKey = store.getState().verification.agentKey;
	const data = new FormData();
	data.append("meetingslug", meetingSlug);
	data.append("agent_key", agentKey);

	const req = await makePostRequest("/request_skip_payment", data);
	if (!req) {
		return;
	}
};

export const setScreenShare = (room, Twilio) => {
	let screenTrack;
	navigator.mediaDevices
		.getDisplayMedia()
		.then((stream) => {
			screenTrack = new Twilio.Video.LocalVideoTrack(stream.getTracks()[0]);
			room.localParticipant.publishTrack(screenTrack);
		})
		.catch(() => {
			alert("Could not share the screen.");
		});

	const stopScreenSharing = (room) => {
		room.localParticipant.unpublishTrack(screenTrack);
		screenTrack.stop();
		screenTrack = null;
	};
};

export const setEndOfReservationLink = (value) => {
	store.dispatch(actions.setEndOfReservationLink(value));
};

export const setAgentControlSwitch = (value) => {
	let item = store.getState().meeting.roomSpaceID;
	store.dispatch(verificationActions.setIsAgentControl(value));
};

export const setIsDataChange = (value) => {
	store.dispatch(actions.setIsDataChange(value));
};

export const setAppartment_id = (value) => {
	store.dispatch(actions.setAppartment_id(value));
};

export const handleSkipFollowup = async (currentLanguge) => {
	$("#skipBtn").prop("disabled", false);
	$("#skipBtn").prop("value", currentLanguge.general.fewMoreMoments);
	const meetingSlug = store.getState().verification.slug;
	const agentKey = store.getState().verification.agentKey;
	setLoadingModalContent("doingsomething....");
	let res = await updateMeetingFlow(0, 7, meetingSlug, agentKey);

	addURLHistory('finish', false);

	store.dispatch(actions.setStep("8"));
	store.dispatch(actions.setStepsProgress("8"));
	
	setSuccessStep(7);
	let elem = document.querySelector("#finishStep");
	elem.classList.add("doneStep");
};

/* @return A timezone offset in hours */
const getOffsetstring = (timeZone = 'UTC', date = new Date()) => {
	const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
	const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
	let offset = (tzDate.getTime() - utcDate.getTime()) / 3600000;
	
	let offsetStr = '';
	let offsetInt = parseInt(offset);
	let offsetEnd = offset % 1 == 0 ? '00' : '30';
	if (offset >= 10) {
		offsetStr = `+${offsetInt}${offsetEnd}`
	} else if (offset >= 0) {
		offsetStr = `+0${offsetInt}${offsetEnd}`
	} else if (offset <= -10) {
		offsetStr = `-${offsetInt}${offsetEnd}`
	} else if (offset < 0) {
		offsetStr = `-0${offsetInt}${offsetEnd}`
	}
	return offsetStr;
}

export const setFollowMeeting = async (e, currentLanguge) => {
	try {
		$("#scheduleBtn").prop("disabled", true);
		$("#scheduleBtn").prop("value", currentLanguge.general.fewMoreMoments);
		
		const meetingSlug = store.getState().verification.slug;
		const timeZone = store.getState().project.timezone;
		const date = store.getState().meeting.date;
		const time = store.getState().meeting.hour;
		const meetingReq = await requestMeeting(e, "self", currentLanguge);
		if (!meetingReq) {
			return;
		}
		setLoadingModalContent("doingsomething....");

		if (meetingReq.data.statusCode == 200) {
			let timeOffsetStr = getOffsetstring(timeZone);
			const meetingTime = `${date}T${time}${timeOffsetStr}`;
			const toUtchs = moment.utc(meetingTime).format("YYYY-MM-DD HH:mm");

			updateMeetingFlow(toUtchs, 7, meetingSlug, "").then((res) => {
				if (!res) {
					return;
				}
				if (res.data.statusCode == 200) {
					addURLHistory('finish', false);
					store.dispatch(actions.setStep("8"));
					store.dispatch(actions.setStepsProgress("8"));
					
					setSuccessStep(7);
					let elem = document.querySelector("#finishStep");
					elem.classList.add("doneStep");
					$("#scheduleBtn").prop("disabled", false);
					$("#scheduleBtn").prop("value", currentLanguge.followUpMeeting_step.stepCTA);
				}
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const setIsLoading = (value) => {
	store.dispatch(actions.setIsLoading(value));
};

export const setIsPaymentRequired = (value) => {
	store.dispatch(verificationActions.setIsPaymentRequired(value));
};

export const setIsSignatureRequired = (value) => {
	store.dispatch(verificationActions.setIsSignatureRequired(value));
};

export const convertIdToSlug = (id) => {
	let list = store.getState().project.allAppartmentsData;
	let slug = "";
	for (let item of list) {
		if (item.shortID === id) {
			slug = item.id;
			return slug;
		}
	}
	return slug;
};

export const getAptParents = (id) => {
	let list = store.getState().project.allAppartmentsData;
	let res = "";
	for (let item of list) {
		if (item.shortID === id) {
			res = { ...item };
		}
	}
	return res;
};

export const setNowMeeting = async (currentLanguge) => {
	try {
		let now = moment().format("YYYY-MM-DD hh:mm");
		let splitted = now.split(" ");
		setHour(splitted[1]);
		setDate(splitted[0]);
		const meetingReq = await requestMeeting(null, "self", currentLanguge);
		if (!meetingReq) {
			return;
		}

		if (meetingReq.data.statusCode == 200) {
		}
		return meetingReq;
	} catch (err) {
		console.log(err);
	}
};

export const setGetAgentStatus = (value) => {
	store.dispatch(meetingActions.setGetAgentStatus(value));
};

export const setSelectedFloorImg = (value) => {
	store.dispatch(actions.setSelectedFloorImg(value));
};

export const setSelectedAptImg = (value) => {
	store.dispatch(actions.setSelectedAptImg(value));
};

export const setSelectedAptMainImg = (value) => {
	store.dispatch(actions.setSelectedAptMainImg(value));
};

export const setLoadingIsDone = (value) => {
	store.dispatch(actions.setFinishLoading(value));
};

export const setIsEditingContract = (value) => {
	store.dispatch(actions.setIsEditingContract(value));
};
export const setThereIsNewContract = (value) => {
	store.dispatch(actions.setThereIsNewContract(value));
};

export const setIsMobile = (value) => {
	store.dispatch(actions.setIsMobile(value));
};
export const setInited3DView = (value) => {
	store.dispatch(actions.setInited3DView(value));
};
export const setOnBoardingViewed = (value) => {
	store.dispatch(actions.setOnBoardingViewed(value));
};

export const setShowVideoLoadingModal = (value) => {
	store.dispatch(actions.setShowVideoLoadingModal(value));
};

export const setVideoLoadingModalMessage = (value) => {
	store.dispatch(actions.setVideoLoadingModalMessage(value));
};
export const setShowFindAgentFormModel = (value) => {
	store.dispatch(actions.setShowFindAgentFormModel(value));
};
export const setIsChangingLang = (value) => {
	store.dispatch(actions.setIsChangingLang(value));
};

export const getNewContract = async (e, currentLanguge) => {
	try {
		e.preventDefault();
		e.stopPropagation();

		const slug = store.getState().verification.slug;
		const { buyerFirstName, buyerLastName, buyerID, buyerEmail, buyerMobileNumber } = store.getState().register;

		const thereIsNewContract = store.getState().project.thereIsNewContract;

		let isFormValid = validateRegisterForm(e, currentLanguge);
		if (isFormValid) {
			const formData = new FormData();
			formData.append("meetingslug", slug);
			formData.append("firstname", buyerFirstName);
			formData.append("lastname", buyerLastName);
			formData.append("email", buyerEmail);
			formData.append("mobile", buyerMobileNumber);
			formData.append("identity_id", buyerID);
			const req = await makePostRequest("/request_update_agreementdata", formData);

			if (!req) {
				return;
			}
		}
		setThereIsNewContract(!thereIsNewContract);
	} catch (err) {
		console.log(err);
	}
};

export const setStepShort = (step) => {
	if(step == "5.5") store.dispatch(actions.setStepsProgress(step));
	store.dispatch(actions.setStep(step));
};

export const setUserVerified = (value) => {
	store.dispatch(actions.setUserVerified(value));
};


export const setLoadingModalContent = (value) => {
	store.dispatch(actions.setLoadingModalContent(value));
};
export const resetForm = () => {
	store.dispatch(registerAction.resetForm());
};

export const setPusherUpdate = (value) => {
	store.dispatch(actions.setPusherUpdate(value));
};

export const setShowVideoModal = (value) => {
	store.dispatch(actions.setShowVideoModal(value));
};

export const setVideoModalMessage = (value) => {
	store.dispatch(actions.setVideoModalMessage(value));
};

export const setBuildings = (value) => {
	store.dispatch(dataAction.setBuildingLarge(value));
};

export const setFloorsBack = (value) => {
	store.dispatch(dataAction.setFloorLarge(value));
};
export const setApartmentsBack = (value) => {
	store.dispatch(dataAction.setApartmentsLarge(value));
};

export const setRoomsCount = (value) => {
	store.dispatch(actions.setRoomsCount(value));
};

export const addDelays = (className) => {
	let delays = [350, 450, 600, 750, 900, 1050, 1200, 1350, 1500];
	document.querySelectorAll(className).forEach((el, idx) => {
		setTimeout(() => {
			el.classList.add("showElement");
		}, delays[idx]);
	});
};

export const setLocalTimestamp = () => {
	localStorage.removeItem("userExit");
	let now = new Date().getTime();
	localStorage.setItem("userExit", now);
	return;
};

export const setProjectSettingsObject = (value) => {
	$("button").prop("disabled", true);
	store.dispatch(actions.setProjectSettings(value));
	$("button").prop("disabled", false);
};

export const setImages = (value) => {
	store.dispatch(dataAction.setImages(value));
};

export const toDataURL = (src, callback, outputFormat) => {
	var img = new Image();
	img.crossOrigin = "Anonymous";
	img.onload = function () {
		var canvas = document.createElement("CANVAS");
		var ctx = canvas.getContext("2d");
		var dataURL;
		canvas.height = this.naturalHeight;
		canvas.width = this.naturalWidth;
		ctx.drawImage(this, 0, 0);
		dataURL = canvas.toDataURL(outputFormat);
		callback(dataURL);
	};
	img.src = src;
	if (img.complete || img.complete === undefined) {
		img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
		img.src = src;
	}
};

export const sortRoomsArray = (rooms) => {
	let roomsInfo = rooms.replace(/\s+/g, "").split(",");
	let newArr = roomsInfo
		.sort(function (a, b) {
			return a - b;
		})
		.reverse()
		.join(",")
		.trim("");

	return newArr;
};

export const setPreviewMode = (value) => {
	store.dispatch(actions.setPreviewMode(value));
};

export const setVerificationMethod = (value) => {
	store.dispatch(verificationActions.setVerificationMethod(value));
};

export const initMeetingFlag = async () => {
	const meetingSlug = store.getState().verification.slug;
	let data = new FormData();
	data.append("meetingslug", meetingSlug);
	const req = await makePostRequest("/meetingstarted", data);
};

export const setApprovalNum = (value) => {
	store.dispatch(actions.setPaymentApprovalNum(value));
};

export const setExisitingMeetingData = (flowdata) => {
	try {
		let list = store.getState().project.allAppartmentsData;

		const remoteStepKeys = Object.keys(flowdata);
		const remoteStepValues = Object.values(flowdata);
		const stepsParsed = remoteStepKeys.map((el, idx) => {
			return { step: el, data: remoteStepValues[idx] };
		});
	} catch (err) {
		console.log(err);
	}
};

const idToSlug = (id) => {
	const dynamicAppartmentsData = store.getState().project.dynamicAppartmentsData;
	const buildingID = store.getState().project.buildingID;
	const floorID = store.getState().project.floorID;
	let slug = "";

	for (let item of dynamicAppartmentsData) {
		if (item.building_id == buildingID) {
			for (let floor of item.floors) {
				if (floor.floor_id == floorID) {
					for (let apt of floor.properties) {
						if (apt.property_id == id) {
							slug = apt.property_slug;
						}
					}
				}
			}
		}
	}
	return slug;
};

export const setShowNav = (value) => {
	store.dispatch(actions.setShowNav(value));
};

export const setShowFooterLinks = (value) => {
	store.dispatch(actions.setShowFooterLinks(value));
};

export const setIpAddress = (value) => {
	store.dispatch(registerAction.setIpAddress(value));
};
export const setCountry = (value) => {
	store.dispatch(registerAction.setCountry(value));
};


///Events Utils
export const disptachEvent = (eventObj) => {
	const isAgent = store.getState().meeting.isAgent;
	const meeting = store.getState().meeting;
	const isClient = store.getState().verification.isClient;
	const project = store.getState().project;

	let parsed = JSON.parse(eventObj);
	const { payload, eventName, author } = parsed;

	if (author === true) {
		if (!isAgent) {
			if (parsed.hasOwnProperty("sortType")) {
				var evt = new CustomEvent(eventName, { detail: `${payload} ${parsed.sortType}` });
				document.dispatchEvent(evt);
				return;
			}
			if (eventName == 'reqSyncFlow') {
				var evt = new CustomEvent(eventName, { detail: {project: project, meeting: meeting} });
				document.dispatchEvent(evt);
				return;
			}
			var evt = new CustomEvent(eventName, { detail: payload });
			document.dispatchEvent(evt);
		}
	}
	if (author === false) {
		if (isAgent) {
			if (parsed.hasOwnProperty("sortType")) {
				var evt = new CustomEvent(eventName, { detail: `${payload} ${parsed.sortType}` });
				document.dispatchEvent(evt);
				return;
			}
			if (eventName == 'reqSyncFlow') {
				return;
			}
			var evt = new CustomEvent(eventName, { detail: payload });
			document.dispatchEvent(evt);
		} else {
			var evt = new CustomEvent(eventName, { detail: payload });
			document.dispatchEvent(evt);
		}
	}
};

export const setRemoteAction = async (eventData, showError = true) => {
	try {
		const getUrlParams = new URLSearchParams(window.location.search);
		let json = JSON.stringify(eventData);
		let slug = store.getState().verification.slug;
		const currentLanguge = store.getState().project.currentLanguge;
		const meetingSlug = getUrlParams.get("meetingSlug");

		if ((!slug || slug == '') && meetingSlug != '') slug = meetingSlug; 
		
		let data = new FormData();
		data.append("meetingslug", slug);
		data.append("object_data", json);
		const stepReq = await makePostRequest(`/meeting_ui_event`, data);

		if (stepReq.data.statusCode == 711 && showError) {
			if (eventData.eventName == 'stepChange' && eventData.payload == 7) return;
			setFlowErrorObject({
				errorCode: stepReq.data.statusCode,
				errorMessage: currentLanguge.flowErrors.error711
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const removeEventListener = (name) => {
	document.removeEventListener(name, () => {});
};

export const getItem = (list, propName, id) => {
	for (let item of list) {
		if (item[propName] === id) {
			return item;
		}
	}
};

export const setBuildingNameAsync = (list, buildingID) => {
	if (list.length == 0) {
		list = store.getState().project.allAppartmentsData;
	}
	let item = getItem(list, "buildingID", buildingID);
	if (item) {
		setBuildingName(item.buildingNum);
	}
};

export const setFloorNameAsync = (list, floorID) => {
	if (list.length == 0) {
		list = store.getState().project.allAppartmentsData;
	}
	let item = getItem(list, "floorID", floorID);
	if (item) {
		setFloorName(item.floorNum);
	}
};

export const setAptNameAsync = (list, aptID) => {
	if (list.length == 0) {
		list = store.getState().project.allAppartmentsData;
	}
	var res = "";
	for (let item of list) {
		if (item.shortID == aptID) {
			res = item.aptNum;
		}
	}
	setAppartmentTitle(res);
};

export const setPropertyNamesAsync = (list, aptID) => {
	if (list.length == 0) {
		list = store.getState().project.allAppartmentsData;
	}
	var res = {};
	for (let item of list) {
		if (item.shortID == aptID) {
			res = { ...item };
		}
	}
	setBuildingName(res.buildingNum);
	setFloorName(res.floorNum);
	setAppartmentTitle(res.aptNum);
	persistor.flush();
};

export const sendMeetingEnd = async () => {
	const data = new FormData();
	const meetingSlug = store.getState().verification.slug;
	if (!meetingSlug || meetingSlug == '') return;
	data.append("meetingslug", meetingSlug);
	const req = await makePostRequest("/meetingfinished", data);
};

export const getPriceForClientType = (clientType) => {
	var priceProp = "";
	if (clientType == 1) {
		priceProp = "price1";
		return priceProp;
	}
	if (clientType == 2) {
		priceProp = "price2";
		return priceProp;
	}
	if (clientType == 3) {
		priceProp = "price3";
		return priceProp;
	}
	if (clientType == 4) {
		priceProp = "price4";
		return priceProp;
	} else {
		priceProp = "price1";
		return priceProp;
	}
};

export const setIsSelfReserve = (value) => {
	store.dispatch(meetingActions.setIsSelfReserve(value));
};

export const setColorsThemes = (colors_theme) => {
	// set color varibles in :root
	let mainColorRgb = hexToRgb(colors_theme.mainColor);
	document.documentElement.style.setProperty('--rf-main-color', colors_theme.mainColor);
	document.documentElement.style.setProperty('--rf-main-color-code', `${mainColorRgb.r}, ${mainColorRgb.g}, ${mainColorRgb.b}`);
	
	document.documentElement.style.setProperty('--rf-secondary-color', colors_theme.secondaryColor);
	// --rf-secondary-color-hsl-code
	document.documentElement.style.setProperty('--rf-dark-bg', colors_theme.darkBg);
	// --rf-dark-bg-code
	document.documentElement.style.setProperty('--rf-text-dark', colors_theme.textDark);
	document.documentElement.style.setProperty('--rf-text-light', colors_theme.textLight);
	document.documentElement.style.setProperty('--rf-text-darkest', colors_theme.textDarkest);
	
	store.dispatch(projectAction.setColorsThemes(colors_theme));
};


const hexToRgb = (hex) => {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
	  r: parseInt(result[1], 16),
	  g: parseInt(result[2], 16),
	  b: parseInt(result[3], 16)
	} : null;
  }

export const setMeetingSetSuccesful = (value) => {
	store.dispatch(meetingActions.setMeetingSetSuccesful(value));
};

export const setFlowErrorObject = (value) => {
	store.dispatch(projectAction.setFlowErrorObject(value));
};

export const setMobileNumberWithoutPrefix = (value) => {
	store.dispatch(registerAction.setMobileNumberWithoutPrefix(value));
};

export const setBuyerMobileNumberWithoutPrefix = (value) => {
	store.dispatch(registerAction.setBuyerMobileNumberWithoutPrefix(value));
};

export const setUserApprovedApt = (value) => {
	store.dispatch(projectAction.setUserApprovedApt(value));
};
export const setFeautresListPerm = (value) => {
	store.dispatch(projectAction.setFeautresList(value));
};
export const setShowApprovePopup = (value) => {
	store.dispatch(projectAction.setShowApprovePopup(value));
};

export const setAptRoomObj = (value) => {
	store.dispatch(projectAction.setAptRoomObj(value));
};
export const setTimeOffset = (value) => {
	store.dispatch(meetingActions.setTimeOffset(value));
};

export const setMettingTimezone = (value) => {
	store.dispatch(meetingActions.setTimezone(value));
};

export const addURLHistory = (url, onProjectBase = true) => {
	if (!url || url == '') return;

	if (onProjectBase) {
		window.stepHistory.push(`/${url}`);
	} else {
		let projectSlug = store.getState().project.projectID;
		let sessionProjectSlug = sessionStorage.getItem('projectID');
		if (!projectSlug && sessionProjectSlug != '') projectSlug = sessionProjectSlug;
		
		let searchString = window.location.search;
		if (searchString.includes('?showAgentForm=1') && searchString == '?showAgentForm=1') {
			searchString = searchString.replace('?showAgentForm=1','') 
		} else if (searchString.includes('?showAgentForm=1')){
			searchString = searchString.replace('showAgentForm=1','') 
		}
		window.stepHistory.push(`/${projectSlug}/${url}/${searchString}`);
	}
}

export const backURLHistory = (backURLHistory = '') => {
	if (backURLHistory != '') {
		window.stepHistory.replace(backURLHistory);	
	} else {
		window.stepHistory.goBack();
	}
}