import { useEffect } from "react";
import $ from "jquery";
import { useSelector } from "react-redux";
import { setSelectedCamera, setSelectedMic, setSelectedSpeaker } from "../../../Helpers/RegistetUtils";

export const getVideoDevices = (mediaDevices, selectID, setValue = null) => {
	try {

		let selectElem = $(`.${selectID}`);
		let initHTML = $(selectElem[0]).html();
		let count = 1;
		let vidRow = '<ul class="devices_list">';
		mediaDevices.forEach((mediaDevice) => {
			if (mediaDevice.kind === "videoinput") {
				let label;
				if (mediaDevice.label) {
					label = mediaDevice.label;
				} else {
					label = `Camera ${count}`;
					count++
				}
				vidRow += `
					<li class='align-self-center w-100 vid_devices_list'>
						<span class='videoListItem dropdown-item border-bottom' data-deviceid='${mediaDevice.deviceId}'>${label}</span>
					</li>
				`;
			}
			
		});
		vidRow += '</ul>'

		for (let i = 0; i < selectElem.length; i++){
			$(selectElem[i]).html(`${initHTML} ${vidRow}`);

			$(selectElem[i]).find('li').on('click', (e) => {
				e.preventDefault();
				try {
					window.room.emit("changeVideoInput", e.target.dataset.deviceid);

					$(`.${selectID}`).find('.activeVideoControllItem').removeClass('activeVideoControllItem');
					$(`[data-deviceid='${e.target.dataset.deviceid}']`).addClass('activeVideoControllItem');
					setSelectedCamera(e.target.dataset.deviceid);

				} catch (err) {
					console.log(err);
				}
			});

			if (setValue && setValue != '') {
				$(`[data-deviceid='${setValue}']`).addClass('activeVideoControllItem');
			} else {
				$($(selectElem[i]).find('li span')[0]).addClass('activeVideoControllItem');
			}
		}

	} catch (err) {
		console.log(err);
	}
};
export const getOutputDevices = (mediaDevices, selectID, setValue = null) => {
	try {

		let selectElem = $(`.${selectID}`);
		let initHTML = $(selectElem[0]).html();

		let count = 1;
		let vidRow = '<ul class="devices_list">';
		mediaDevices.forEach((mediaDevice) => {
			if (mediaDevice.kind === "audiooutput") {
				let label;
				if (mediaDevice.label) {
					label = mediaDevice.label;
				} else {
					label = `Camera ${count}`;
					count++
				}
				vidRow += `
					<li class='align-self-center w-100 vid_devices_list'>
						<span class='videoListItem dropdown-item border-bottom text-wrap' data-deviceid='${mediaDevice.deviceId}'>${label}</span>
					</li>
				`;
			}
			
		});
		vidRow += '</ul>'
		for (let i = 0; i < selectElem.length; i++){
			$(selectElem[i]).html(`${initHTML} ${vidRow}`);

			$(selectElem[i]).find('li').on('click', (e) => {
				e.preventDefault();
				try {
					window.room.emit("changeVideoInput", e.target.dataset.deviceid);

					$(`.${selectID}`).find('.activeVideoControllItem').removeClass('activeVideoControllItem');
					$(`.${selectID}`).find(`[data-deviceid='${e.target.dataset.deviceid}']`).addClass('activeVideoControllItem');
					
					let audioElem = document.querySelector("audio");
					audioElem.setSinkId(e.target.dataset.deviceid).then((res) => console.log(res))
					setSelectedSpeaker(e.target.dataset.deviceid);

				} catch (err) {
					console.log(err);
				}
			});

			if (setValue && setValue != '') {
				$(`[data-deviceid='${setValue}']`).addClass('activeVideoControllItem');
			} else {
				$($(selectElem[i]).find('li span')[0]).addClass('activeVideoControllItem');
			}
		}
	} catch (err) {
		console.log(err);
	}
};

export const getAudioDevices = (mediaDevices, selectID, setValue = null) => {
	try {

		let selectElem = $(`.${selectID}`);
		let initHTML = $(selectElem[0]).html();

		let count = 1;
		let vidRow = '<ul class="devices_list">';
		mediaDevices.forEach((mediaDevice) => {
			if (mediaDevice.kind === "audioinput") {
				let label;
				if (mediaDevice.label) {
					label = mediaDevice.label;
				} else {
					label = `Mic ${count}`;
					count++
				}
				vidRow += `
					<li class='align-self-center w-100 vid_devices_list'>
						<span class='videoListItem dropdown-item border-bottom text-wrap' data-deviceid='${mediaDevice.deviceId}'>${label}</span>
					</li>
				`;
			}
			
		});
		vidRow += '</ul>'

		for (let i = 0; i < selectElem.length; i++){
			$(selectElem[i]).html(`${initHTML} ${vidRow}`);

			$(selectElem[i]).find('li').on('click', (e) => {
				e.preventDefault();
				try {
					$(`.${selectID}`).find('.activeVideoControllItem').removeClass('activeVideoControllItem');
					$(`.${selectID}`).find(`[data-deviceid='${e.target.dataset.deviceid}']`).addClass('activeVideoControllItem');
					setSelectedMic(e.target.dataset.deviceid);

					window.room.emit("changeAudioInput", e.target.dataset.deviceid);
				} catch (err) {
					console.log(err);
				}
			});

			if (setValue && setValue != '') {
				$(`[data-deviceid='${setValue}']`).addClass('activeVideoControllItem');
			} else {
				$($(selectElem[i]).find('li span')[0]).addClass('activeVideoControllItem');
			}

		}
	} catch (err) {
		console.log(err);
	}
};


const VideoControllers = (props) => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);
	
	return (
		<div className="col-11 align-self-center d-flex flex-row justify-content-center p-0 border-top" aria-label="video-control">
			<div className="dropdown w-50 justify-content-center leftButtonBorder">
				<button
					className="btn btn-dark bg-dark dropdown-toggle w-100 rounded-0"
					type="button"
					id="dropdownMenuButton1"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					{currentLanguge.checkVideoStep.checkCamera}
				</button>
				<ul className="dropdown-menu" id="videoDropdownMenu" aria-labelledby="dropdownMenuButton1"></ul>
			</div>

			<div className="dropdown w-50 justify-content-center">
				<button
					className="btn btn-dark bg-dark dropdown-toggle w-100 rounded-0"
					type="button"
					id="dropdownMenuButton1"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					{currentLanguge.checkVideoStep.checkMic}
				</button>
				<div className="dropdown-menu" id="audioDropdownMenu" aria-labelledby="dropdownMenuButton1">
					<div className="col-12 border-bottom" id="outputAudioo" role="menu">
						<span className="f14 w700">{currentLanguge.checkVideoStep.speakerListTitle}</span>
					</div>
					<span className="f14 w700">{currentLanguge.checkVideoStep.micListTitle}</span>
				</div>
			</div>
		</div>
	);
};

export default VideoControllers;
