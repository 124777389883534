import { createLocalAudioTrack, createLocalVideoTrack, createLocalTracks } from "twilio-video";
import $ from "jquery";
import { setSelectedCamera, setSelectedMic, setSelectedSpeaker } from "../../../Helpers/RegistetUtils";

const changeVideoSource = (e) => {
	let deviceID = e.target.value;
	let videoParent = document.getElementById("local-cam-container");
	createLocalVideoTrack({ deviceId: { exact: deviceID } }).then((res) => {
		let currentVideo = videoParent.querySelector("video");
		if (currentVideo) {
			currentVideo.remove();
		}
		videoParent.style.opacity = 0;
		videoParent.appendChild(res.attach());

		$("#local-cam-container").fadeTo(400, 1);
	});
};
const changeAudioDevices = (e) => {
	let deviceID = e.target.value;
	let videoParent = document.getElementById("local-cam-container");
	createLocalAudioTrack({ deviceId: { exact: deviceID } }).then((res) => {
		let curerntAudio = videoParent.querySelector("audio");
		if (curerntAudio) {
			curerntAudio.remove();
		}
		videoParent.style.opacity = 0;
		videoParent.appendChild(res.attach());
		$("#local-cam-container").fadeTo(400, 1);
	});
};
export function getVideoDevices(mediaDevices, setValue = null) {
	const select = document.getElementById("videoCheckDropdownMenu");
	// select.innerHTML = "";
	const li = document.createElement("li");
	li.classList.add("align-self-center", "w-100");
	var value = "";
	select.appendChild(li);

	li.addEventListener("click", (e) => {
		e.preventDefault();
		e.stopPropagation();
		try {
			changeVideoSource(e);
			$(`#${e.target.id}`).siblings('.activeVideoControllItem').removeClass('activeVideoControllItem');
			$(`#${e.target.id}`).addClass('activeVideoControllItem');
			setSelectedCamera(e.target.id);
		} catch (err) {
			console.log(err);
		}
	});

	let count = 1;
	mediaDevices.forEach((mediaDevice) => {
		if (mediaDevice.kind === "videoinput") {
			const a = document.createElement("span");
			a.value = mediaDevice.deviceId;
			value = mediaDevice.deviceId;
			const label = mediaDevice.label || `Camera ${count}`;
			a.id = mediaDevice.deviceId;
			a.textContent = label;
			a.classList.add("inputSelecSpan");
			a.href = "#";
			li.appendChild(a);
			select.appendChild(li);
		}
	});
	if (setValue && setValue != '') {
		$(`#${setValue}`).addClass('activeVideoControllItem');
	} else {
		$($(select).find('li span')[0]).addClass('activeVideoControllItem');
		setSelectedCamera($($(select).find('li span')[0])[0].id);
	}
}

export function getAudioDevices(mediaDevices, setValue = null) {
	const select = document.getElementById("audioCheckDropdownMenu");
	// select.innerHTML = "";
	const li = document.createElement("li");
	li.classList.add("align-self-center", "w-100");
	var value = "";
	select.appendChild(li);

	li.addEventListener("click", (e) => {
		e.preventDefault();
		e.stopPropagation();
		try {
			changeAudioDevices(e);
			$(`#${e.target.id}`).siblings('.activeVideoControllItem').removeClass('activeVideoControllItem');
			$(`#${e.target.id}`).addClass('activeVideoControllItem');
			// TODO: set selected device
			setSelectedMic(e.target.id);
		} catch (err) {
			console.log(err);
		}
	});
	let count = 1;
	mediaDevices.forEach((mediaDevice) => {
		if (mediaDevice.kind === "audioinput") {
			const a = document.createElement("span");
			a.classList.add("v");
			a.id = mediaDevice.deviceId;
			value = mediaDevice.deviceId;
			const label = mediaDevice.label || `Audio ${count}`;
			a.id = mediaDevice.deviceId;
			a.textContent = label;
			a.classList.add("inputSelecSpan");
			a.href = "#";
			li.appendChild(a);
			select.appendChild(li);
		}
	});

	if (setValue && setValue != '') {
		$(`#${setValue}`).addClass('activeVideoControllItem');
	} else {
		$($(select).find('li span')[0]).addClass('activeVideoControllItem');
		setSelectedMic($($(select).find('li span')[0])[0].id);
	}
}

export function getAudioOutputDevice(mediaDevices, setValue = null) {
	const select = document.getElementById("outPutDeviceMenu");
	// select.innerHTML = "";
	const li = document.createElement("li");
	li.classList.add("align-self-center", "w-100");
	var value = "";
	select.appendChild(li);

	li.addEventListener("click", (e) => {
		e.preventDefault();
		e.stopPropagation();
		try {
			changeAudioDevices(e);
			$(`#${e.target.id}`).siblings('.activeVideoControllItem').removeClass('activeVideoControllItem');
			$(`#${e.target.id}`).addClass('activeVideoControllItem');
			// TODO: set selected device
			setSelectedSpeaker(e.target.id)
		} catch (err) {
			console.log(err);
		}
	});
	let count = 1;
	mediaDevices.forEach((mediaDevice) => {
		if (mediaDevice.kind === "audiooutput") {
			const a = document.createElement("span");
			a.classList.add("v");
			a.id = mediaDevice.deviceId;
			value = mediaDevice.deviceId;
			const label = mediaDevice.label || `Audio ${count}`;
			a.id = mediaDevice.deviceId;
			a.textContent = label;
			a.classList.add("inputSelecSpan");
			a.href = "#";
			li.appendChild(a);
			select.appendChild(li);
		}
	});

	if (setValue && setValue != '') {
		$(`#${setValue}`).addClass('activeVideoControllItem');
	} else {
		$($(select).find('li span')[0]).addClass('activeVideoControllItem');
		setSelectedMic($($(select).find('li span')[0])[0].id);
	}
}
export const createInitialTracks = (selectedCamera, selectedMic) => {
	let videoParent = document.getElementById("local-cam-container");
	
	if (selectedCamera != '' && selectedMic != '') {
		createLocalVideoTrack({ deviceId: { exact: selectedCamera } }).then((track) => {
			if (videoParent.querySelector("video")) {
				videoParent.querySelector("video").remove();
			}
			videoParent.appendChild(track.attach());
		});
		createLocalAudioTrack({ deviceId: { exact: selectedMic } }).then((track) => {
			if (videoParent.querySelector("audio")) {
				videoParent.querySelector("audio").remove();
			}
			videoParent.appendChild(track.attach());
		});
	} else {
		createLocalTracks().then((localTracks) => {
			if (videoParent.querySelector("video")) {
				videoParent.querySelector("video").remove();
			}
			localTracks.forEach(function (track) {
				videoParent.appendChild(track.attach());
			});
		});
	}
	
};
