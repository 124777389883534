import { useEffect } from "react";
import "./Finish.sass";
import { useSelector } from "react-redux";
import $ from "jquery";
import { setLoadingModalContent } from "../../../Helpers/AppartmentReserveUtils";
import Confetti from 'react-confetti'

const NextStepButton = () => {
	const endOfReservationLink = useSelector((state) => state.project.projectSettings.url_endof_reservation);
	const currentLanguge = useSelector((state) => state.project.currentLanguge);

	useEffect(() => { }, [currentLanguge]);


	if (!endOfReservationLink) {
		return null;
	}
	const handleClick = () => {
		localStorage.clear();
		sessionStorage.clear();
		return;
	};
	return (
		<div className="col-12 d-flex flex-row justify-content-center">
			<a type="button" href={endOfReservationLink} onClick={handleClick} className="btn rf-btn-primary col-6 align-self-center f16 fw-bold zindexon"
				 data-gt-category='finish' data-gt-action='click' data-gt-label='finish'>
				{currentLanguge.finish_step.nextStepButton}
			</a>
		</div>
	);
};

const Finish = ({ handleRequestSignMeeting }) => {
	const paymentApprovalNum = useSelector((state) => state.project.paymentApprovalNum);
	
	let firstName = useSelector((state) => state.register.firstName);
	let lastName = useSelector((state) => state.register.lastName);
	let email = useSelector((state) => state.register.email);
	let stringName = `${firstName} ${lastName}`;
	const selectedFloorImg = useSelector((state) => state.project.selectedAptImg);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	const basicData = useSelector((state) => state.project.appartmentBasicData);
	
	useEffect(() => { }, [currentLanguge]);

	useEffect(() => {
		window.onbeforeunload = null;
		setLoadingModalContent("");
		$('.mobile_schedualMeeting').removeClass('mobileView').hide();
		$('#project_stats_container').hide();
		$('#steps_menu__mobile_container').hide();
		$('#steps_menu__mobile_container').next('hr').hide();
		$('#apartment_view_change__mobile').hide();
	}, []); 

	return (
		<div className="row m-0 flashit apartment_reserve__stepContainer" id="apartment_reserve__step-8">
			{/* Step Content  */}
			<div className="col-xxl-7 col-md-12 px-3 py-4 position-relative zindexon">

				<div className="col-12 d-flex justify-content-center pfSuccessM  mt-5">
					<img src="/assets/img/icons/ico-checked.svg" className="mde-1" height="85" width="85" alt={currentLanguge.finish_step.text1}/>
				</div>
				<div className="col-xxl-8 col-xl-6 col-lg-8  d-flex justify-content-center flex-column m-auto ">
					<h1 className="pfLgFont align-self-center mb-1 text-center">{`${currentLanguge.finish_step.text1}`} <br/>{`${stringName}`}</h1>
					<h2 className="pfMdFont fs-2 align-self-center text-center">{`${currentLanguge.finish_step.text2} ${basicData.title} ${currentLanguge.finish_step.text3}`}</h2>
					{/* <p className="text-center pfItemFont">
						{currentLanguge.finish_step.description}
					</p> */}
					
					<p className={`text-center f18 fw-bold d-none`}>{currentLanguge.finish_step.approvalNumTitle} {paymentApprovalNum}</p>
					<p className={`mt-2 text-center f18 fw-bold ${email == '' ? 'd-none' : ''}`}>{currentLanguge.finish_step.infoSent} {email}</p>
				</div>
				<Confetti numberOfPieces='300' wind='1'/>
				{/* <canvas className="col-12 m-0 top-0 position-absolute" id="conffetiCanvas" style={{'zIndex':'-1' }}/> */}
				<NextStepButton />
			</div>
			{/* Step Graphics  */}
			<div className="col-xxl-5 d-xxl-block d-xl-none d-lg-none d-md-none desktopView bg-image">
				<img src={selectedFloorImg} alt={`${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`} />
			</div>
		</div>
	);
};

export default Finish;
