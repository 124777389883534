import "./ImagePlacholders.sass";

export const ThumbPlaceHolder = ({ height, width }) => {
	return (
		<div style={{ height: height, width: width }} className="col d-flex justify-content-center" id="thumbHolder">
			<div className="sideImageLG d-flex justify-content-center align-self-center">
				<div className="bounce1"></div>
				<div className="bounce2"></div>
				<div className="bounce3"></div>
			</div>
		</div>
	);
};

export const SideImagePlaceholder = () => {
	return (
		<div className="col d-flex justify-content-center" style={{ height: "900px", width: "540px" }}>
			<div className="sideImageLG d-flex justify-content-center align-self-center">
				<div className="bounce1"></div>
				<div className="bounce2"></div>
				<div className="bounce3"></div>
			</div>
		</div>
	);
};

export const AppartmentLoader = () => {
	return (
		<div className="row d-flex justify-content-center" style={{ height: "100px" }}>
			<div className="sideImageLG d-flex justify-content-center align-self-center">
				<div className="bounce1"></div>
				<div className="bounce2"></div>
				<div className="bounce3"></div>
			</div>
		</div>
	);
};

export const MainLoader = (props) => {
	return (
		<div className="row d-flex justify-content-center MainLoader" style={{ height: "100vh", width: "100vw" }}>
			<div className="sideImageLG d-flex justify-content-center align-self-center">
				<div className="bounce1"></div>
				<div className="bounce2"></div>
				<div className="bounce3"></div>
			</div>
		</div>
	);
};
