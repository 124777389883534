import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setShowCodeVerificationPopup} from "../../../Helpers/RegistetUtils";
import { setShowPopup } from "../../../Helpers/verificationUtils";
import { setUserApprovedApt, setRemoteAction } from "../../../Helpers/AppartmentReserveUtils";
import $ from "jquery";
import GTEvent from "../../../Helpers/GTEvent";

const CodeVerificationPopup = ({ verifyCode, onChangeCode, code, verificationMethod, resendCode }) => {
	const showthepopup = useSelector((state) => state.project.showCodeVerificationPopup);
	const maxLenghtCode = 6;
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const [show, setShow] = useState(false);

	function onlyNum(e, maxLength) {
		const re = /^[0-9\b]+$/;
		e.preventDefault();

		if (re.test(e.target.value) && e.target.value.length > 0 && e.target.value.length <= maxLength) {
			document.getElementById(e.target.id).value = e.target.value;
		} else if ((!re.test(e.target.value) && e.target.value.length > 0) || e.target.value.length > maxLength) {
			document.getElementById(e.target.id).value = e.target.value.slice(0, -1);
		}
	}

	useEffect(() => { }, [currentLanguge]);

	useEffect(() => {
		setShow(() => showthepopup);
		if (showthepopup == true) {
			$('#validationCustomMobile').focus()
		}
	}, [showthepopup]);


	const handleClose = () => {
		setShowCodeVerificationPopup(false);
		setShow(false);
		setUserApprovedApt(false);
		setShowPopup(false);
		setRemoteAction({ eventName: "setShowAgentModal", payload: false, author: false });
	}

	const resendCodeLocal = (e, method, elem) => {
		resendCode(e, verificationMethod, "invalidRegCode", currentLanguge);
		GTEvent(e)
	}
	return (
		<Modal show={show} centered >
			<Modal.Header className="align-self-center pfMdFont flex-column w-100">
				{currentLanguge.confirmValidationCode.codeVerificationPopupTitle}
			</Modal.Header>
			<div className="closepopupButton" onClick={()=>handleClose()}>
					<img src='/assets/img/icons/ico-close.svg' alt='' height="20" width="20"/>
				</div>
			<Modal.Body>
				<div className="row">
					<div className="col-12 d-flex flex-column justify-content-center">
						<div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-10 align-self-center d-flex justify-content-center">
							<div className="col-xxl-12 col-xl-10 col-lg-12 col-md-12 col-sm-12 mb-3 col-12 input-group-lg d-flex flex-column">
								<label htmlFor="validationCustomMobile" className="form-label align-self-center pfItemFontSm">
									{currentLanguge.confirmValidationCode.codeVerificationInputLable}
								</label>
								<div className="input-group input-group-md input-group-phone mb-2">
									<input
										type="number"
										className="form-control pfInput pfContainerShadow pfPlaceholder text-center"
										id="validationCustomMobile"
										placeholder={currentLanguge.confirmValidationCode.codeVerificationInputPlaceholder}
										maxLength={maxLenghtCode}
										minLength={maxLenghtCode}
										onInput={(e) => onlyNum(e, maxLenghtCode)}
										onChange={(e) => onChangeCode(e.currentTarget.value)}
									/>
								</div>
								<button
									onClick={(e) => verifyCode(e, verificationMethod, code)}
									className="btn rf-btn-primary pfSubmitBtnColor"
									id="email_login-tab"
									data-bs-toggle="tab"
									data-bs-target="#email_login-content"
									type="button"
									role="tab"
									aria-controls="email_login"
									aria-selected="true"
									data-gt-category='otp' data-gt-action='click' data-gt-label='verify code'
								>
									{currentLanguge.confirmValidationCode.codeVerificationPopupCTA}
								</button>
							</div>
						</div>
						<div className="col-12 d-flex mx-auto flex-column text-center">
							<a className="fw-bold fs-6 text-decoration-none rf-darkestText rf-no-hover" href="" onClick={(e) => resendCodeLocal(e, verificationMethod, "invalidRegCode", currentLanguge)}
								data-gt-category='otp' data-gt-action='click' data-gt-label='resend code'>
								{currentLanguge.confirmValidationCode.resandValidationCode}
							</a>
							<span className="resend_counter f15"></span>
						</div>
						<div className="text-center pfItemFontSm mb-2" style={{display: "block" }} id="invalidRegCode"></div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default CodeVerificationPopup;
