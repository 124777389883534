import ApartmentViewChange from "../ApartmentViewChange/ApartmentViewChange";
import LanguageSelector from "../../General/LanguageSelector/LanguageSelector";
import ProjectStats from "../../Stats/ProjectStats/ProjectStats";
import "./MainNav.sass";
import StepsMenu from "../StepsMenu/StepsMenu";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import $ from "jquery";
import { setGetAgentStatus, setShowFindAgentFormModel } from "../../../Helpers/AppartmentReserveUtils";

const MainNav = (props) => {
	const projectSettings = useSelector((state) => state.project.projectSettings);
	
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const showFindAgentFormModel = useSelector((state) => state.project.showFindAgentFormModel);
	const findingAgentState = useSelector((state) => state.meeting.getAgentStatus);
	const meetingStatus = useSelector((state) => state.meeting.meetingStatus);

	const showNav = useSelector((state) => state.project.showNav);
	const showFooterLinks = useSelector((state) => state.project.showFooterLinks);
	const [hideMobileAgentDropdown, setHideMobileAgentDropdown] = useState(false);
	const [hideStepsMenu, setHideStepsMenu] = useState(false);
	const [hideViewChange, setHideViewChange] = useState(false);
	const [hideStatsContainer, setHideStatsContainer] = useState(false);

	useEffect(() => {
		$('#main_nav__mobile').off('click').on('click', e => {
			if (e.target.localName == 'button' || e.target.localName == 'a') $('.navbar-toggler').trigger('click');
		})
		$('#main_nav__mobile .steps_menu__link').on('click', e => {
			if ($(e.currentTarget).hasClass('doneStep') || $(e.currentTarget).hasClass('current-link')) $('.navbar-toggler').trigger('click');
		})
	})

	useEffect(() => {
		if(projectSettings.project_icon != '') $('#favicon').attr('href', projectSettings.project_icon);
		if (projectSettings.project_title != '') $('#htmlTitle').html(projectSettings.project_title);

		const urlParams = new URLSearchParams(window.location.search);
		const infllow = urlParams.get('infllow')

		if (window.location.pathname.includes('/register') && (infllow != '1' || !infllow)) {
			setHideStepsMenu(true);
			setHideMobileAgentDropdown(true);
			setHideViewChange(true);
			$('#main_nav__mobile-toggler').addClass('d-none');
			setHideStatsContainer(true);
			$('.language_selector_desktop').show();
		} 
	}, [projectSettings])

	// Hide all menu
	useEffect(() => {
		if (!window.location.pathname.includes('/register')) {
			if (!showNav) {
				setHideStatsContainer(true)
				setHideStepsMenu(true);
				setHideMobileAgentDropdown(true);
				setHideViewChange(true);
				$('#main_nav__mobile-toggler').addClass('d-none');
			} else if (showNav) {
				setHideStatsContainer(false)
				setHideStepsMenu(false);
				setHideMobileAgentDropdown(false);
				setHideViewChange(false);
				$('#main_nav__mobile-toggler').removeClass('d-none');
			}
		}
		
	}, [showNav]);

	// Hide stats_container
	useEffect(() => {
		if (hideStatsContainer) {
			$(".stats_container").hide();
			$(".stats_container").next('.vr').hide();
		} else if (!hideStatsContainer) {
			$(".stats_container").show();
			$(".stats_container").next('.vr').show();
		}
	}, [hideStatsContainer]);


	// Hide steps menu
	useEffect(() => {
		if (hideStepsMenu) {
			$('#steps_menu__mobile_container').hide();
			$('#steps_menu__mobile_container').next('hr').hide();
			$('#steps_menu__col').hide();
		} else {
			$('#steps_menu__mobile_container').show();
			$('#steps_menu__mobile_container').next('hr').show();
			$('#steps_menu__col').show();
		}
	}, [hideStepsMenu])
	
	// Hide the mobile agent dropdown
	useEffect(() => {
		if (hideMobileAgentDropdown) {
			$('.mobileAgent__container').removeClass('mobileView');
		} else {
			$('.mobileAgent__container').addClass('mobileView');
		}
	}, [hideMobileAgentDropdown])

	// Hide view change btns
	useEffect(() => {
		if (hideViewChange) {
			$("#apartment_view_change__desktop").hide();
			$("#apartment_view_change__mobile").hide();
		} else {
			$("#apartment_view_change__desktop").show();
			$("#apartment_view_change__mobile").show();
		}
	}, [hideViewChange])
	
	const toggleFindAgentFormModel = () => {
		setShowFindAgentFormModel(showFindAgentFormModel ? false : true);
		setGetAgentStatus(showFindAgentFormModel ? false : "register");
	}
	return (
		<nav className="navbar sticky-top shadow-sm" id="main_nav">
			{/* Project Logo */}
			<div className="container-fluid ">
				<a className="navbar-brand" href={`/${projectSettings.project_slug}`} data-gt-category='navbar logo' data-gt-action='click' data-gt-label='navbar logo click'>
					<img src={projectSettings?.project_logo} alt={projectSettings?.project_title} loading="eager" className="appear" height="50"  />
				</a>

				<div className="d-flex ">
					<div className={`dropdown mobileAgent__container mobileView ${hideMobileAgentDropdown ? 'd-none': ''}`}>
						<button className="btn btn-secondary dropdown-toggle fw-bold" type="button" id="mobileAgent__dropdown" data-bs-toggle="dropdown" aria-expanded="false" data-gt-category="agent component" data-gt-action="click" data-gt-label="toggle mobile dropdown">
							<img src="/assets/img/icons/icons8-online-support-50.png" className="align-self-center" alt=""/>
						</button>
						<ul className="dropdown-menu" aria-labelledby="mobileAgent__dropdown">
							<li>
								<button id="findAgentMobile_trigger" onClick={toggleFindAgentFormModel} type="button"
									className={`btn f16 w-100 ${((findingAgentState == false || findingAgentState == 'register') && meetingStatus != 'start') ? '' : 'd-none'}`}
									data-gt-category="agent component" data-gt-action="click" data-gt-label="open lead form">{currentLanguge.findAgentComp.findAgentCTA}</button>
							</li>
							<li>
								<a className={`mobileView mobile_schedualMeeting btn f16 w-100 `} 
									href={`/${projectSettings.project_slug}/register/?infllow=1`}
									type="button"
									data-gt-category='agent component' data-gt-action='click' data-gt-label='schedule for later'>
									{currentLanguge.findAgentComp.findAgentReschedule}
								</a>
							</li>
						</ul>
					</div>

					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#main_nav__mobile"
						aria-controls="main_nav__mobile"
						id="main_nav__mobile-toggler"
						aria-expanded="false"
						aria-label="Toggle navigation"
						data-gt-category='mobile menu' data-gt-action='click' data-gt-label='toggle mobile menu'
					>
						<i className="bi bi-list"></i>
					</button>
				</div>
				
			</div>

			{/* Mobile Menu */}
			<div className="collapse navbar-collapse" id="main_nav__mobile">
				{/* Project stats */}
				<ProjectStats />

				<StepsMenu id="steps_menu__mobile_container" projectSettings={projectSettings} />

				<hr className="w-100 mx-auto" />
				
				{/* Change View */}
				<ApartmentViewChange id='apartment_view_change__mobile' />
				
				<hr className="w-100 mx-auto" />

				{/* language selector */}
				<LanguageSelector />
			</div>

			{/* Desktop Menu */}
			<div className="container justify-content-start flex-row-reverse" id="main_nav__desktop">
				{/* language selector */}
				<LanguageSelector />

				<div className="vr"></div>

				{/* Project stats */}
				<ProjectStats />

				<div className="vr"></div>

				{/* Change View */}
				<ApartmentViewChange id='apartment_view_change__desktop'/>
			</div>
		</nav>
	);
};

export default MainNav;
