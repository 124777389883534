import { createSlice } from "@reduxjs/toolkit";
import { he, en } from "./languageFile";
import $ from "jquery";
import GTPageView from "../../Helpers/GTPageView";

const initialState = {
	projectID: "",
	propertyID: "",
	currentStep: "1",
	stepsProgress: "1",
	timezone: "Asia/Jerusalem",
	userID: "",
	agentID: "",
	buildingID: "",
	floorID: "",
	appartmentID: "",
	appartmentRealID: "",
	appartmentBasicData: {},
	buildingName: "",
	floorName: "",
	appartmantTitle: "",
	showPopup: false,
	showCodeVerificationPopup: false,
	allAppartmentsData: [],
	dynamicAppartmentsData: [],
	projectStats: {
		pending: 0,
		available: 0,
		reserved: 0,
	},
	selectedFloorImg: "",
	paymentApprovalNum: "",
	isAppartmentAvailable: false,
	endOfReservationLink: "",
	userVerified: false,
	isDataChange: false,
	projectPublished: true,
	projectLocked: false,
	projectHidden: false,
	projectPublic: false,
	projectTranzilaAccount: "",
	projectThemeID: "",
	colorThemeID: "",
	projectDefaultTimezone: "",
	projectDefaultMetric: "",
	projectDefaultCurrency: "",
	projectDefaultLangauge: "",
	projectIcon: "",
	projectLogo: "",
	projectImageDesktop: "",
	projectImageMobile: "",
	projectSeoTitle: "",
	projectOgImage: "",
	tranzilaAccount: "",
	projectSeoDescription: "",
	projectContractPdfExample: "",
	projectReservePdfExample: "",
	projectTitle: "",
	projectText: "",
	projectLocation: "",
	projectAddress: "",
	projectDisclaimer: "",
	projectReservationPrice: "",
	projectSalesPhone: "",
	projectStartingPrice: "",
	meetingDuration: "",
	showTimerAfterMin: "",
	agreementText: "",
	feautresList: "",
	enableEditAgreement: "",
	projectFeatureList: {},
	colorsThemes: {
		background_color: "",
		primary_color: "",
		secondary_color: "",
		text_color: "",
		accent_color: "",
		buttons_bgcolor: "",
		buttons_textcolor: "",
	},
	crumbs: [],
	finishLoad: false,
	isEditingContract: false,
	thereIsNewContract: false,
	setIsProjectLanded: false,
	projectTransactionMode: "",
	isPaid: false,
	isLoading: false,
	isChangingLang: false,
	loadingModalContent: "",
	roomsCount: {},
	pusherUpdate: false,
	showVideoModal: false,
	videoModalMessage: "",
	roomsObj: [],
	projectSettings: {
		id: "",
		project_slug: "",
		project_published: "",
		project_locked: "",
		project_hidden: "",
		project_public: "",
		project_folder: "",
		colors_theme_id: "",
		project_default_timezone: "",
		project_default_metric: "",
		project_default_currency: "",
		project_default_language: "",
		project_icon: "",
		project_logo: "",
		project_additional_logos: [],
		project_gallery : [],
		project_image_desktop: "",
		project_image_mobile: "",
		project_seo_title: "",
		project_seo_description: "",
		project_og_image: "",
		project_contract_pdf: "",
		project_reservationsample_pdf: "",
		project_agreement_termspdf: "",
		hide_agent_panel: "",
		// tranzila
		tranzilla_onoff: "",
		project_tranzila_tranmode: "",
		project_tranzila_apikey: "",
		project_tranzila_account: "",
		// Stripe
		stripe_onoff: "",
		stripe_publishkey: "",
		project_title: "",
		project_text: "",
		project_location: "",
		project_address: "",
		project_desclaimer: "",
		project_reservation_price: "",
		project_sales_phone: "",
		project_starting_price: "",
		meeting_duration: "",
		show_timer_after_min: "",
		url_endof_reservation: "",
		agreement_text: "",
		featuresListJson: [],
		enable_edit_onagreement: "0",
		client_type1_name: "",
		client_type2_name: "",
		client_type3_name: "",
		client_type4_name: "",
		// 3d
		project_3dapikey: '',
	},
	meetingSettings: {},
	paymentData: {},
	previewMode: false,
	showNav: false,
	showFooterLinks: true,
	selectedAptImg: "",
	selectedAptMainImg: "",
	flowErrorObject: { errorCode: "", errorMessage: "" },
	userApprovedApt: false,
	showApprovePopup: false,
	aptRoomObj: {},
	currentLanguge: he,
	siteAuthorization: false,
	show3DView: false,
	inited3DView: false,
	isMobile: false,
	onBoardingViewed: false,
	showVideoLoadingModal: false,
	videoLoadingModalMessage: "מכינים בשבילך הכל...",
	showFindAgentFormModel: false,
	flowType: 'self',
	tokenIsSet: false
};


const stepsDictenery = {
	'1': 'Building Selection',
	'2': 'Floor Selection',
	'3': 'Apartment Selection List',
	'4': 'Apartment Details',
	'5': 'Details Approval',
	'5.5': 'Contract Signature',
	'6': 'Payment',
	'7': 'Follow Up Meeting',
	'8': 'Finish',
	'9': 'Apartment Selection Graphic',
	'10': 'Apartment Selection Table',
	'11': '3D View',
}

export const projectSlice = createSlice({
	name: "project",
	initialState,
	reducers: {
		reset: () => initialState,
		setProjectID: (state, action) => {
			let currentProjectID = sessionStorage.getItem('projectID');
			if (( currentProjectID == '' || !currentProjectID ) && action.payload != '') sessionStorage.setItem('projectID', action.payload);
			state.projectID = action.payload;
		},
		setVideoModalMessage: (state, action) => {
			state.videoModalMessage = action.payload;
		},
		setAptRoomObj: (state, action) => {
			state.aptRoomObj = action.payload;
		},
		setFlowErrorObject: (state, action) => {
			state.flowErrorObject = action.payload;
		},
		setUserApprovedApt: (state, action) => {
			state.userApprovedApt = action.payload;
		},
		setShowApprovePopup: (state, action) => {
			state.showApprovePopup = action.payload;
		},
		setShowNav: (state, action) => {
			state.showNav = action.payload;
		},
		setIsMobile: (state, action) => {
			state.isMobile = action.payload;
			state.inited3DView = false;
		},
		setOnBoardingViewed: (state, action) => {
			state.onBoardingViewed = action.payload;
		},
		setShowFooterLinks: (state, action) => {
			state.showFooterLinks = action.payload;
		},
		setSelectedAptImg: (state, action) => {
			state.selectedAptImg = action.payload;
		},
		setSelectedAptMainImg: (state, action) => {
			state.selectedAptMainImg = action.payload;
		},
		setPaymentApprovalNum: (state, action) => {
			state.paymentApprovalNum = action.payload;
		},
		setPaymentData: (state, action) => {
			state.paymentData = action.payload;
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setProjectSettings: (state, action) => {
			state.projectSettings = action.payload;
		},
		setPreviewMode: (state, action) => {
			state.previewMode = action.payload;
		},
		setShowVideoModal: (state, action) => {
			state.showVideoModal = action.payload;
		},
		setPusherUpdate: (state, action) => {
			state.pusherUpdate = action.payload;
		},
		setRoomsCount: (state, action) => {
			state.roomsObj = action.payload;
		},
		setMeetingSettings: (state, action) => {
			state.meetingSettings = action.payload;
		},
		setLoadingModalContent: (state, action) => {
			state.loadingModalContent = action.payload;
		},
		setIsChangingLang: (state, action) => {
			state.isChangingLang = action.payload;
		},
		setIsPaid: (state, action) => {
			state.isPaid = action.payload;
		},
		setUserVerified: (state, action) => {
			state.userVerified = action.payload;
		},
		setIsProjectLanded: (state, action) => {
			state.setIsProjectLanded = action.payload;
		},
		setTransactionMode: (state, action) => {
			state.projectTransactionMode = action.payload;
		},
		setProjectTranzilaAccount: (state, action) => {
			state.projectTranzilaAccount = action.payload;
		},
		setIsEditingContract: (state, action) => {
			state.isEditingContract = action.payload;
		},
		setTranzillaAccount: (state, action) => {
			state.tranzilaAccount = action.payload;
		},
		setThereIsNewContract: (state, action) => {
			state.thereIsNewContract = action.payload;
		},
		setProjectOgImage: (state, action) => {
			state.projectOgImage = action.payload;
		},
		setProjectContractPdfExample: (state, action) => {
			state.projectContractPdfExample = action.payload;
		},
		setFinishLoading: (state, action) => {
			state.finishLoad = action.payload;
		},
		incrementStep: (state) => {
			state.currentStep += 1;
		},
		setPropertyID: (state, action) => {
			state.propertyID = action.payload;
		},
		setUserID: (state, action) => {
			state.userID = action.payload;
		},
		setStep: (state, action) => {
			state.currentStep = action.payload;

			let stepTitle = stepsDictenery[`${parseFloat(action.payload)}`];
			let currentProjectID = sessionStorage.getItem('projectID');
			var pathArr = window.location.pathname.split( '/' ).filter((el)=> {return el != '';});
			if (pathArr.length >= 2 || (pathArr.length == 1 && pathArr[0] != currentProjectID)) GTPageView(`Step - ${stepTitle != '' ? stepTitle : action.payload}`)
			
			document.getElementById("top_ancher").scrollIntoView();
		},
		setBuldingID: (state, action) => {
			state.buildingID = action.payload;
		},
		setFloorID: (state, action) => {
			state.floorID = action.payload;
		},
		setAppartmentID: (state, action) => {
			state.appartmentID = action.payload;
		},
		setAppartmentBasicaData: (state, action) => {
			state.appartmentBasicData = action.payload;
		},
		setBuldingName: (state, action) => {
			state.buildingName = action.payload;
		},
		setAppartmentTitle: (state, action) => {
			state.appartmantTitle = action.payload;
		},
		setStepsProgress: (state, action) => {
			state.stepsProgress = action.payload;
		},
		setShowPopup: (state, action) => {
			state.showPopup = action.payload;
		},
		setShowVerificationPopup: (state, action) => {
			state.showCodeVerificationPopup = action.payload;
		},
		setAllAppartmentsData: (state, action) => {
			state.allAppartmentsData = action.payload;
		},
		setDynamicAppartmentsData: (state, action) => {
			state.dynamicAppartmentsData = action.payload;
		},
		setProjectStats: (state, action) => {
			state.projectStats = action.payload;
		},
		setTimeZone: (state, action) => {
			state.timezone = action.payload;
		},
		setIsAppartmentAvailable: (state, action) => {
			state.isAppartmentAvailable = action.payload;
		},
		setEndOfReservationLink: (state, action) => {
			state.endOfReservationLink = action.payload;
		},
		setIsDataChange: (state, action) => {
			state.isDataChange = action.payload;
		},
		setAppartment_id: (state, action) => {
			state.appartmentRealID = action.payload;
		},
		setFloorName: (state, action) => {
			state.floorName = action.payload;
		},
		setIsProjectPublised: (state, action) => {
			state.projectPublished = action.payload;
		},
		setIsProjectLocked: (state, action) => {
			state.projectLocked = action.payload;
		},
		setIsProjectHidden: (state, action) => {
			state.projectHidden = action.payload;
		},
		setProjectThemeID: (state, action) => {
			state.projectThemeID = action.payload;
		},
		setColorThemeID: (state, action) => {
			state.colorThemeID = action.payload;
		},
		setProjectDefaultTimezone: (state, action) => {
			state.projectDefaultTimezone = action.payload;
		},
		setProjectDefaultMetric: (state, action) => {
			state.projectDefaultMetric = action.payload;
		},
		setProjectDefaultCurrency: (state, action) => {
			state.projectDefaultCurrency = action.payload;
		},
		setProjectDefaultLangauge: (state, action) => {
			state.projectDefaultLangauge = action.payload;
			setCurrentLangauge(action.payload);
		},
		setProjectIcon: (state, action) => {
			state.projectIcon = action.payload;
		},
		setProjectLogoe: (state, action) => {
			state.projectLogo = action.payload;
		},
		setProjectImageDesktop: (state, action) => {
			state.projectImageDesktop = action.payload;
		},
		setProjectImageMobile: (state, action) => {
			state.projectImageMobile = action.payload;
		},
		setProjectSeoTitle: (state, action) => {
			state.projectSeoTitle = action.payload;
		},
		setProjectSeoDescription: (state, action) => {
			state.projectSeoDescription = action.payload;
		},
		setProjectReservePdfExample: (state, action) => {
			state.projectReservePdfExample = action.payload;
		},
		setProjectTitle: (state, action) => {
			state.projectTitle = action.payload;
		},
		setProjectText: (state, action) => {
			state.projectText = action.payload;
		},
		setProjectLocation: (state, action) => {
			state.projectLocation = action.payload;
		},
		setProjectAddress: (state, action) => {
			state.projectAddress = action.payload;
		},
		setProjectDisclaimer: (state, action) => {
			state.projectDisclaimer = action.payload;
		},
		setProjectReservationPrice: (state, action) => {
			state.projectReservationPrice = action.payload;
		},
		setProjectSalesPhone: (state, action) => {
			state.projectSalesPhone = action.payload;
		},
		setProjectStartingPrice: (state, action) => {
			state.projectStartingPrice = action.payload;
		},
		setMeetingDuration: (state, action) => {
			state.meetingDuration = action.payload;
		},

		setShowTimerAfterMin: (state, action) => {
			state.showTimerAfterMin = action.payload;
		},
		setFeautresList: (state, action) => {
			state.feautresList = action.payload;
		},
		setEnableEditAgreement: (state, action) => {
			state.enableEditAgreement = action.payload;
		},
		setColorsThemes: (state, action) => {
			state.colorsThemes = action.payload;
		},
		setSelectedFloorImg: (state, action) => {
			state.selectedFloorImg = action.payload;
		},
		addCrumbItem: (state, action) => {
			state.crumbs = action.payload;
		},
		removeCrumbItem: (state, action) => {
			state.crumbs.pop();
		},
		setIsProjectPublic: (state, action) => {
			state.projectPublic = action.payload;
		},
		setProjectAgreementText: (state, action) => {
			state.agreementText = action.payload;
		},
		setProjectFeauturesList: (state, action) => {
			state.projectFeatureList = action.payload;
		},
		setCurrentLangauge: (state, action) => {
			if (action.payload === "heb") {
				state.currentLanguge = he;
				sessionStorage.setItem('langCode', he.langAPICode);
                $('html').css('direction', he.direction).attr('dir', he.direction).attr('lang', he.language);

			} else if (action.payload === "eng") {
				state.currentLanguge = en;
				sessionStorage.setItem('langCode', en.langAPICode);
                $('html').css('direction', en.direction).attr('dir', en.direction).attr('lang', en.language);
			} else {
				state.currentLanguge = en;
				sessionStorage.setItem('langCode', en.langAPICode);
                $('html').css('direction', en.direction).attr('dir', en.direction).attr('lang', en.language);
			}
		},
		setSiteAuthorization: (state, action) => {
			state.siteAuthorization = action.payload;
		},
		setShow3DView: (state, action) => {
			state.show3DView = action.payload;
		},
		setInited3DView: (state, action) => {
			state.inited3DView = action.payload;
		},
		setShowVideoLoadingModal: (state, action) => {
			state.showVideoLoadingModal = action.payload;
		},
		setVideoLoadingModalMessage: (state, action) => {
			state.videoLoadingModalMessage = action.payload;
		},
		setShowFindAgentFormModel: (state, action) => {
			state.showFindAgentFormModel = action.payload;
		},
		setFlowType: (state, action) => {
			state.flowType = action.payload;
		},
		setTokenIsSet:(state, action) => {
			state.tokenIsSet = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	reset,
	setAppartmentTitle,
	removeCrumbItem,
	setShowVideoModal,
	addCrumbItem,
	setSelectedFloorImg,
	setProjectFeauturesList,
	setBuldingName,
	setProjectID,
	setUserApprovedApt,
	setPropertyID,
	setVideoModalMessage,
	setIsProjectPublic,
	setStep,
	setProjectAgreementText,
	setProjectTranzilaAccount,
	setProjectContractPdfExample,
	setUserID,
	setBuldingID,
	setShowNav,
	setShowFooterLinks,
	setFloorID,
	setAppartmentID,
	setMeetingSettings,
	setAppartmentBasicaData,
	incrementStep,
	setStepsProgress,
	setShowPopup,
	setAllAppartmentsData,
	setDynamicAppartmentsData,
	setPusherUpdate,
	setProjectStats,
	setTimeZone,
	setShowVerificationPopup,
	setIsAppartmentAvailable,
	setProjectSettings,
	setEndOfReservationLink,
	setIsDataChange,
	setAppartment_id,
	setIsProjectLanded,
	setFloorName,
	setIsProjectPublised,
	setIsProjectLocked,
	setIsProjectHidden,
	setPaymentData,
	setProjectThemeID,
	setIsLoading,
	setColorThemeID,
	setProjectDefaultTimezone,
	setColorsThemes,
	setEnableEditAgreement,
	setFeautresList,
	setMeetingDuration,
	setTransactionMode,
	setSelectedAptImg,
	setSelectedAptMainImg,
	setProjectAddress,
	setUserVerified,
	setProjectOgImage,
	setProjectDefaultCurrency,
	setProjectDefaultLangauge,
	setCurrentLangauge,
	setProjectDefaultMetric,
	setProjectDisclaimer,
	setProjectIcon,
	setPaymentApprovalNum,
	setProjectImageDesktop,
	setProjectImageMobile,
	setProjectLocation,
	setProjectLogoe,
	setProjectReservationPrice,
	setProjectReservePdfExample,
	setProjectSalesPhone,
	setProjectSeoDescription,
	setProjectSeoTitle,
	setProjectStartingPrice,
	setFlowErrorObject,
	setAptRoomObj,
	setProjectText,
	setTranzillaAccount,
	setPreviewMode,
	setProjectTitle,
	setIsPaid,
	setShowTimerAfterMin,
	setFinishLoading,
	setLoadingModalContent,
	setShowApprovePopup,
	setIsEditingContract,
	setThereIsNewContract,
	setRoomsCount,
	setSiteAuthorization,
	setShow3DView,
	setInited3DView,
	setIsMobile,
	setOnBoardingViewed,
	setIsChangingLang,
	setShowVideoLoadingModal,
	setVideoLoadingModalMessage,
	setShowFindAgentFormModel,
	setFlowType,
	setTokenIsSet
} = projectSlice.actions;

export default projectSlice.reducer;
