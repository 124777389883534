import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { handlePaymentSubmit, setApprovalNum } from "../../../Helpers/AppartmentReserveUtils";
import { showStaticMessage } from "../../../Helpers/RegistetUtils";
import { useSelector } from "react-redux";

const PaymentModal = ({ success }) => {
	const [isPaid, setIsPaid] = useState(false);

	const [response, setResponse] = useState("");
	const [show, setShow] = useState(false);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	useEffect(() => { }, [currentLanguge]);
	
	
	const handleClose = async () => {
		if (isPaid) {
			await handlePaymentSubmit(response, currentLanguge, 'stripe');
			setShow(false);
		} else {
			setShow(false);
		}
	};

	useEffect(async () => {
		if (success && !success.error) {
			setShow(true);
			setResponse(success);
			if (success.paymentIntent.status === "succeeded") {
				showStaticMessage("resSpan", `${currentLanguge.payment_step.paymentSuccessfulMsg}`, true);
				setApprovalNum(success.paymentIntent.id)
				setIsPaid(true);
				return;
			}
		}
	}, [success]);

	const Result = ({ success }) => {
		if (success == null || success == undefined || success.error) {
			return null;
		}
		if (success.paymentIntent.status === "succeeded") {
			return (
				<div className="">
					<span>{currentLanguge.payment_step.paymentSuccessfulTitle}</span>
				</div>
			);
		} else if (success.paymentIntent.status === "processing") {
			return (
				<div className="">
					<span>{currentLanguge.payment_step.paymenyProcessModalText}</span>
				</div>
			);
		} else if (success.paymentIntent.status === "requires_payment_method") {
			return (
				<div className="">
					<span>{currentLanguge.payment_step.paymentUnsuccessfulMsg}</span>
				</div>
			);
		} else {
			return <div className="">{currentLanguge.formsErrors.tryAgain}</div>;
		}
	};

	const ButtonResult = ({ success }) => {
		if (success == null || success == undefined) {
			return currentLanguge.payment_step.paymenyPopupClose;
		}
		if (success.paymentIntent.status === "succeeded") {
			return currentLanguge.payment_step.paymenyPopupContinu;
		} else {
			return currentLanguge.payment_step.paymenyPopupClose;
		}
	};
	return (
		<Modal centered show={show}>
			<Modal.Header>
				<Modal.Title className="align-self-center">{currentLanguge.payment_step.paymenyPopupTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex justify-content-center">
				<Result success={success} />
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-center">
				< button className = 'btn rf-btn-primary btn-sm align-self-center' onClick = {() => handleClose()}>
					<ButtonResult success={success} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PaymentModal;
