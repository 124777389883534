import { memo } from "react";
import { useSelector } from "react-redux";
import MeetingVideo from "../MeetingVideo";
import FindAgent from "../FindAgent/FindAgent";
import CheckVideoStep from "../../LoginPage/CheckVideoStep/CheckVideoStep";
import "./VideoComponent.sass";
import $ from "jquery";
import { useEffect } from "react";

const VideoComponent = memo(() => {
	const meetingStatus = useSelector((state) => state.meeting.meetingStatus);
	const isMobile = useSelector((state) => state.project.isMobile)
	
	useEffect(() => {
		let searchingAgentRunning = localStorage.getItem('searchingAgentRunning');
		let searchingAgentTime = localStorage.getItem('searchingAgentTime');
				
		if (!searchingAgentRunning || !searchingAgentTime) {
			localStorage.setItem('searchingAgentRunning', false);
			localStorage.setItem('searchingAgentTime', 0);
		}
	}, []);


	let MeetingVideoStep = () => {
		if (isMobile == true) {
			$('#meetingellm__toggle').removeClass('inMeeting');
			return <></>;
		}
		
		if (!meetingStatus) {
			$('#meetingellm__toggle').removeClass('inMeeting');
			return <FindAgent />;
		}
		if (meetingStatus === "waiting") {
			$('#meetingellm__toggle').removeClass('inMeeting');
			return <CheckVideoStep />;
		}
		if (meetingStatus === "start") {
			$('#meetingellm__toggle').addClass('inMeeting');
			return <MeetingVideo />;
		} else {
			$('#meetingellm__toggle').removeClass('inMeeting');
			return <FindAgent />;
		}
	};


	
	return (
		<div className={`p-0 rounded ${isMobile == true ? 'd-none' : ''}`} id="meetingellm">
			<div id='meetingellm__target'>
				<MeetingVideoStep />
			</div>

			
		</div>
	);
});

export default VideoComponent;
