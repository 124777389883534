
const TabsContent = props => {
    return (
        <div className={`tab-pane fade ${props.active === 'true' && 'show active'} ${typeof props.className !== 'undefined' ? props.className : '' }`} id={props.id} key={props.id} role="tabpanel" aria-labelledby={props.labelledby}>
            {props.children}
        </div>
    );
  }
  
  export default TabsContent;
  