import { useSelector } from 'react-redux';
import './Footer.sass';
import { useEffect } from "react";
import $ from "jquery";

const Footer = props => {
    const projectSettings = useSelector((state) => state.project.projectSettings)
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const onBoardingViewed = useSelector((state) => state.project.onBoardingViewed)
	const findingAgentState = useSelector((state) => state.meeting.getAgentStatus);
	const meetingStatus = useSelector((state) => state.meeting.meetingStatus);
    const isMobile = useSelector((state) => state.project.isMobile);
    
    const showFooterLinks = useSelector((state) => state.project.showFooterLinks);
    const step = useSelector((state) => state.project.currentStep);

    useEffect(() => { 
    }, [currentLanguge]);
    
    useEffect(() => {
		if (!showFooterLinks) {
            $(".footer").hide();
			return;
		}
		if (showFooterLinks) {
			$(".footer").show();
			return;
		}
    }, [showFooterLinks]);
    

     
    useEffect(() => {
        if (onBoardingViewed == true && isMobile && step != '8' && step != '7') {
             if (findingAgentState == 'searching' && meetingStatus != 'start') {
                $('.footer')[0].style.paddingBottom = '415px';
            } else if (findingAgentState == 'notFound' && meetingStatus != 'start') {
                $('.footer')[0].style.paddingBottom = '185px';
            } else if (meetingStatus == 'start') {
                $('.footer')[0].style.paddingBottom = '228px';
            }    
        } else {
            $('.footer')[0].style.paddingBottom = '0';
        }
    }, [findingAgentState, meetingStatus, onBoardingViewed, step]);
    
    const footer_project_logos = () => {
        if (projectSettings.project_additional_logos && projectSettings.project_additional_logos.length > 0) {
            return projectSettings.project_additional_logos.map((logo, idx) => {
                return (
                    <img src={logo.filepath} alt={logo.filetitle} className='mx-2' key={idx} />
                );
            })
        } else return <></>;
    }
  return (
    <footer className="footer">
        <div className="container-fluid  position-relative">
          
            <div className="row align-items-center footer_bottom_row" style={{padding: "0 4rem"}}>
                <div className="col-lg-6 col-12 footer_links">
                    <div className="list-group list-group-horizontal justify-content-center justify-content-lg-start">
                        <a href="#top_ancher" className="list-group-item" data-gt-category='footer link' data-gt-action='click' data-gt-label='home'>{currentLanguge.footer.homeLink}</a>
                        <a href={`${projectSettings.project_agreement_termspdf}`} target="_blank" className="list-group-item" data-gt-category='footer link' data-gt-action='click' data-gt-label='terms'>{currentLanguge.footer.termsLink}</a>
                    </div>
                </div>

                {footer_project_logos()}
            </div>              
        </div>
        <div className="footer_desclaimer text-light text-center fs-6 py-1">
                {projectSettings.project_desclaimer}
        </div>
    </footer>

  );
}

export default Footer;
