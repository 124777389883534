import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { handlePaymentSubmit, setApprovalNum } from "../../../Helpers/AppartmentReserveUtils";
import { showStaticMessage } from "../../../Helpers/RegistetUtils";
import { useSelector } from "react-redux";

const PaymentModal = ({ success }) => {
	const [isPaid, setIsPaid] = useState(false);

	const [response, setResponse] = useState("");
	const [show, setShow] = useState(false);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	useEffect(() => { }, [currentLanguge]);
	const handleClose = async () => {
		setShow(false);
		if (isPaid) {
			await handlePaymentSubmit(response, currentLanguge);
		}
	};

	useEffect(async () => {
		if (success) {
			setShow(true);
			setResponse(success);
			if (success.transaction_response.success) {
				showStaticMessage("resSpan", `${currentLanguge.payment_step.paymentSuccessfulMsg}`, true);
				
				setApprovalNum(success.transaction_response.auth_number)
				setIsPaid(true);

				return;
			}
		}
	}, [success]);


	const Result = ({ success }) => {
		if (success == null || success == undefined) {
			return null;
		}
		if (success.transaction_response.success === true) {
			return (
				<div className="">
					<span>{currentLanguge.payment_step.paymentSuccessfulTitle}</span>
				</div>
			);
		}
		if (success.transaction_response.success === false) {
			return <div className="">{success.transaction_response.error}</div>;
		}
	};

	const ButtonResult = ({ success }) => {
		if (success == null || success == undefined) {
			return currentLanguge.payment_step.paymenyPopupClose;
		}
		if (success.transaction_response.success === true) {
			return currentLanguge.payment_step.paymenyPopupContinu;
		}
		if (success.transaction_response.success === false) {
			return currentLanguge.payment_step.paymenyPopupClose;
		}
	};
	return (
		<Modal centered show={show}>
			<Modal.Header>
				<Modal.Title className="align-self-center">{currentLanguge.payment_step.paymenyPopupTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex justify-content-center">
				<Result success={success} />
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-center">
				< button className = 'btn rf-btn-primary btn-sm align-self-center' onClick = {() => handleClose()}>
					<ButtonResult success={success} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PaymentModal;
