import { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { ConvertCurrency } from "../../../Helpers/ConvertCurrency";

const Amount = () => {
	const projectReservationPrice = useSelector((state) => state.project.projectSettings.project_reservation_price);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const stripe_onoff = useSelector((state) => state.project.projectSettings.stripe_onoff);
	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != "undefined" ? ConvertCurrency(project_default_currency) : "";
	const [price, setPrice] = useState(projectReservationPrice);


	useEffect(() => {
		if (stripe_onoff == '1') {
			setPrice(projectReservationPrice);
		} else {
			setPrice(10);
		}
    }, [projectReservationPrice]);
	
	useEffect(() => { }, [currentLanguge]);

	const numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return (
		<Form.Group className="mb-0 d-flex flex-column input-group-lg payment_amount">
			<label className="mb-0 h-auto mds-2 paymentLabel" htmlFor="sum">{currentLanguge.general.amount}</label>
			<input className="form-control rounded w-100 paymentFieldName" id="sum" name="sum" readOnly disabled value={`${numberWithCommas(price)} ${currencySymbol}`} placeholder={`${numberWithCommas(price)} ${currencySymbol}`} />
		</Form.Group>
	);
};

export default Amount;
