import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeGetRequest } from "../../../Axios";
import { setProjectStats } from "../../../Helpers/AppartmentReserveUtils";
import ProjectStatItem from "./ProjectStatItem";
import "./ProjectStats.sass";
import $ from "jquery";

const ProjectStats = (props) => {
	const slug = useSelector((state) => state.project.projectID);
	const [data, setData] = useState([]);
	const [trigger, setTrigger] = useState([]);

	const [values, setvalues] = useState([]);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	useEffect(() => { }, [currentLanguge]);
	
	useEffect(() => {
		const abortController = new AbortController();

		try {
			const requestProjectStats = async () => {
				let sessionProjectSlug = sessionStorage.getItem('projectID');
				let projectSlug;
				if (!slug && sessionProjectSlug != '') {
					projectSlug = sessionProjectSlug;
				} else {
					projectSlug = slug;
				}
				if (projectSlug) {
					let langCode = sessionStorage.getItem("langCode");
					if (!langCode || langCode == '') {
						langCode = '';
					} else {
						langCode = '/' + langCode;
					}

					const req = await makeGetRequest("/project_availability", `${projectSlug}${langCode}`);
					if (!req) {
						return;
					}
					if (req.data) {
						setData(req.data);
						setProjectStats(req.data);
						let touch = document.createElement("span");
						touch.classList.add("d-none");
						$("#project_stats_container").append(touch);
					}
				}
			};
			requestProjectStats();
		} catch (err) {
			console.log(err);
		}

		return () => {
			abortController.abort(); // cancel pending fetch request on component unmount
		  }
	}, [trigger, slug, currentLanguge]);

	useEffect(() => {
		document.addEventListener("project_availability", () => {
			setTrigger((prev) => !prev);
		});
		return () => {
			document.removeEventListener("project_availability", () => {});
		};
	}, []);

	useEffect(() => {
		try {
			if (data.length > 0) {
				const count = data.map((el) => {
					return el.count;
				});
				setvalues([
					{ stat_color: "stat_circle__available", stat_number: count[0], stat_title: currentLanguge.projectStats.lableAvailable },
					{ stat_color: "stat_circle__progress", stat_number: count[1], stat_title: currentLanguge.projectStats.lableInProgress },
					{ stat_color: "stat_circle__reserved", stat_number: count[2] + count[3], stat_title: currentLanguge.projectStats.lableReserved },
				]);
			}
		} catch (err) {
			console.log(err);
		}
	}, [data, currentLanguge]);

	return (
		<div className="container flex-nowrap pds-lg-3 pds-4 py-3 stats_container" id="project_stats_container">
			<div className="row flex-nowrap">
				<div className="col text-nowrap px-lg-2 px-md-0 nav-section-title fw-bold mb-2 mb-lg-0">{currentLanguge.projectStats.title}</div>
				{values.map((stat, indx) => (
					<ProjectStatItem key={indx} indx={indx} stat_color={stat.stat_color} stat_number={stat.stat_number} stat_title={stat.stat_title} />
				))}
			</div>
		</div>
	);
};

export default ProjectStats;
