import { useSelector } from "react-redux";
import { useEffect } from "react";

const LateArrival = (props) => {
	
	const register = useSelector((state) => state.register);
	const agentFirstname = useSelector((state) => state.meeting.agentFirstname);
	const agentLastname = useSelector((state) => state.meeting.agentLastname);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
    const projectSettings = useSelector((state) => state.project.projectSettings)
	useEffect(() => { }, [currentLanguge]);

	const getUrlParams = new URLSearchParams(window.location.search);
	const agentKey = getUrlParams.get("agentkey");
	const isAgent = agentKey != '' && agentKey != null ? true : false;

	return (
		<div id="login-form__step4">
			{/* Step 4.1: Meeting is over. Proceed without agent, or reschedual meeting  */}
			<div className="row g-0 mt-5">
				<div className="col-12 text-center py-2 px-5 mt-4">
					<img src="/assets/img/icons/ico-info.svg" alt="" className="loginLayout_img" />
					<h1 className="fw-bold my-4 f36">
						{currentLanguge.loginLateArrival.textPart1} {`${isAgent ? `${register.firstName} ${register.lastName}` :  `${agentFirstname} ${agentLastname}`}`}, {currentLanguge.loginLateArrival.textPart2}
						<br />
					</h1>
					<p className={`fs-5 f18 ${isAgent ? 'd-none' : ''}`}>{currentLanguge.loginLateArrival.descriptionText1} <span className='fw-bolder'>{`${register.firstName} ${register.lastName}`}</span> {currentLanguge.loginLateArrival.descriptionText2}</p>
					<p className={`fs-5 f18 ${isAgent ? 'd-none' : ''}`}>{currentLanguge.loginLateArrival.descriptionText3}</p>
				</div>
			</div>

			{/* CTA  */}
			<div className={`row justify-content-center pt-4 ${isAgent ? 'd-none' : ''}`}>
				<div className="col-lg-6 col-md-6 col-10 text-center">
					<div className="d-grid gap-4">
						<a className="btn rf-btn-primary fw-bold f18 py-3 pfSubmitBtnColor" href={`/${projectSettings.project_slug}/register`}>
							{currentLanguge.loginLateArrival.reschedualBtn}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LateArrival;
