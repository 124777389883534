import FormSectionTitle from "./FormSectionTitle";
import "./DateSelectionFormFields.sass";
import { Days, setDate, setHour, sliceIntoChunks } from "../../../Helpers/MeetingsUtils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TimezoneSelect from "react-timezone-select";
import moment from "moment";
import { HoursAvailable } from "../../../Helpers/MeetingsUtils";
import { makeGetRequest } from "../../../Axios";
import { setTimeOffset, setMettingTimezone } from "../../../Helpers/AppartmentReserveUtils";
import $ from 'jquery';

const DateSelectionFormFields = (props) => {
	const [meetingDates, setMeetingDates] = useState([]);
	const [meetingDays, setMeetingDays] = useState([]);
	const [meetingHours, setMeetingHours] = useState([]);
	const [matchingHours, setMatchingHours] = useState(0);
	const [mappedDates, setMappedDates] = useState([]);
	const [counter, setCounter] = useState(0);
	const [isHoursAvailableLoaded, setIsHoursAvailableLoaded] = useState(false);

	const projectID = useSelector((state) => state.project.projectID);

	const [dataAvailable, setNoDataAvailable] = useState(true);
	const [selectedTimezone, setSelectedTimezone] = useState({ value: "Asia/Jerusalem", offset:-3 });

	const projectTimezone = useSelector((state) => state.project.timezone);
	const [_tempTimzone,setTempTimezone]=useState({})
	const [meetingsObject, setMeetingsObject] = useState([]);
	const token = sessionStorage.getItem("forestToken");

    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);
	
	useEffect(async () => {
		try {
			if (!token) return;
			let projectSlug = !projectID ? props.projectID : projectID;
			let sessionProjectSlug = sessionStorage.getItem('projectID');
			if (!projectSlug && sessionProjectSlug != '') projectSlug = sessionProjectSlug;

			const req = props.isFollowMeeting ? await makeGetRequest("/appointmentsavailable", projectSlug) : await makeGetRequest("/agentsavailable", projectSlug);
			if (req.data.statusCode) {
				setNoDataAvailable(false);
			}
			if (req.data.length < 1) {
				setNoDataAvailable(false);
			}

			if (req) {
				let values = Object.values(req.data);
				const keys = Object.keys(req.data);

				const meetingObject = keys.map((date, idx) => {
					return {
						date: date,
						times: values[idx],
					};
				});
				setMeetingsObject(meetingObject);
			}
		} catch (err) {
			console.log(err);
		}
	}, [selectedTimezone, token]);

	useEffect(() => {
		try {
			if (dataAvailable && selectedTimezone) {
				if (meetingsObject.length > 2) {
					let temp_meetingsObject = meetingsObject;
					const values = temp_meetingsObject.map((el) => {
						let accurate = el.times.map((time) => {
							let dateFormatet = moment.utc(`${el.date} ${time}`);
							let inTimeZone = dateFormatet.tz(selectedTimezone.value).format("YYYY-MM-DD HH:mm");
							let splitted = inTimeZone.split(" ");
							let timed = splitted[1];
							return timed;
						});
						el.times = accurate;
						return el;
					});
					setMappedDates(() => values);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, [meetingsObject ,selectedTimezone]);

	// //reset the selector *persist
	useEffect(() => {
		if (dataAvailable) {
			setHour("");
			setDate("");
		}
	}, []);

	useEffect(() => {
		setIsHoursAvailableLoaded(false);

		setTimeOffset(selectedTimezone.offset);
		setMettingTimezone(selectedTimezone.value);
	}, [selectedTimezone]);
	
	//split into weeks periods
	useEffect(() => {
		try {
			if (dataAvailable) {
				const slicedDays = sliceIntoChunks(mappedDates, 7);
				setMeetingDates(slicedDays);
			}
		} catch (err) {
			console.log(err);
		}
	}, [mappedDates]);

	useEffect(() => {
		try {
			if (dataAvailable) {
				//set days
				const availableDays = meetingDates.map((el) => {
					return el.map((obj) => {
						let parsed = obj.date.split("-");
						let month = parsed[1];
						let date = parsed[2];
						let weekday = new Date(obj.date).toLocaleString(currentLanguge.languageLocalCode, { weekday: "long" });
						let monthName = new Date(obj.date).toLocaleString(currentLanguge.languageLocalCode, { month: "short" });
						let monthAndDay = `${date} ${monthName}`;

						return { fulldate: obj.date, dayToDisplay: monthAndDay, time: obj.times, weekday: weekday.replace(`${currentLanguge.general.timeDay}`, "").trim(), monthName: monthName.trim() };
					});
				});
				setMeetingDays(availableDays);
			}
		} catch (err) {
			console.log(err);
		}
	}, [meetingDates, mappedDates, currentLanguge]);

	// set hours of the same day

	useEffect(() => {
		try {
			if (dataAvailable) {
				if (meetingDays[counter]) {
					setMeetingHours(() => meetingDays[counter][matchingHours]);
					let timeoutcounter = setTimeout(() => {
						setIsHoursAvailableLoaded(true);
					}, 1500);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, [meetingDays, matchingHours]);

	useEffect(() => {
		setSelectedTimezone({ value: projectTimezone, offset: getOffset(projectTimezone) })
	}, [projectTimezone]);



	const handleDecrement = () => {
		try {
			if (counter >= 1) {
				setCounter((prev) => prev - 1);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleIncrement = () => {
		try {
			if (counter < meetingDays.length - 1) {
				setCounter((prev) => prev + 1);
			}
		} catch (err) {
			console.log(err);
		}
	};

	
	/* @return A timezone offset in hours */
	const getOffset = (timeZone = 'UTC', date = new Date()) => {
		const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
		const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
		return (tzDate.getTime() - utcDate.getTime()) /3600000;
	}


	
	useEffect(() => {
		try {
			if (dataAvailable) {
				let next = document.querySelector("#next");
				let prev = document.querySelector("#prev");
				if (counter > 0) {
					prev.classList.remove("pfIcon");
					next.classList.remove("pfIcon");
				}
				if (counter === 0) {
					prev.classList.add("pfIcon");
				}
				if (counter === meetingDays.length - 1) {
					next.classList.add("pfIcon");
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, [counter]);

	if (!dataAvailable) {
		$('#scheduleBtn_container').hide();	
		return <div className="col-8 mx-auto d-flex flex-column justify-content-center">
			<h1 className="f26 align-self-center w600">{currentLanguge.formsErrors.notAvailableMeeting}</h1>
			<p className="f20 align-self-center w300">{currentLanguge.formsCTA.skipSchedualMeeting}</p>
		</div>;
	}

	return (
		<>
			{/*Meeting bar*/}
			<FormSectionTitle className="fw-bold align-self-center">{currentLanguge.formsFields.daySelectorTitle}</FormSectionTitle>
			<div id="invalidDate" className="invalidField" style={{marginTop: "-20px", marginBottom: "20px"}}></div>

			<div className="mb-3 radioButtons" id="radioButtonsContainer">
				<Days daysList={meetingDays[counter]} setMatchingHours={setMatchingHours} />
			</div>
			<div className="row mb-3 schedule_meeting__change_week">
				<div className="col-6">
					<a onClick={() => handleDecrement()} className="d-flex align-items-center justify-content-start pfIcon  rf-darkestText" alt="prev" id="prev">
						<i className="bi bi-arrow-right-short mde-2 direction_rotate"></i> {currentLanguge.formsFields.prevWeek}
					</a>
				</div>
				<div className="col-6">
					<a onClick={() => handleIncrement()} className="d-flex align-items-center justify-content-end rf-darkestText" alt="next" id="next">
						{currentLanguge.formsFields.nextWeek} <i className="bi bi-arrow-left-short mds-2 direction_rotate"></i>
					</a>
				</div>
			</div>

			<FormSectionTitle className="pfMdFont mt-4">{currentLanguge.formsFields.hourSelectorTitle}</FormSectionTitle>
			<p style={{ marginTop: '-20px' }}>{currentLanguge.formsFields.hourSelectorSubitle} {projectTimezone} ({getOffset(projectTimezone) >= 0 ? '+' : ''}{getOffset(projectTimezone)})</p>
			<div id="invalidTime" className="invalidField" style={{marginTop: "-15px", marginBottom: "20px"}}></div>
			
			<div className="row align-self-center g-3 mb-3 justify-content-start">
				<HoursAvailable meetingHours={meetingHours} isHoursAvailableLoaded={isHoursAvailableLoaded} setIsHoursAvailableLoaded={setIsHoursAvailableLoaded} />
			</div>

			<div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 text-end my-4 d-none">
				<h5 className="direction_text pb-2 pfMdFont">{currentLanguge.formsFields.timeZone}</h5>
				<TimezoneSelect value={selectedTimezone} onChange={(e) => setSelectedTimezone({ value: e.value,offset:e.offset })} />
			</div>
			<div className="row g-3 d-flex justify-content-center"></div>
		</>
	);
};

export default DateSelectionFormFields;
