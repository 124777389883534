import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setVerificationMethod , setUserApprovedApt, setLoadingModalContent, setShowApprovePopup, setRemoteAction} from "../../../Helpers/AppartmentReserveUtils";
import GTEvent from "../../../Helpers/GTEvent";
import { setShowPopup } from "../../../Helpers/verificationUtils";

const VerificationPopup = (props) => {
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const showPopup = useSelector((state) => state.project.showPopup);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);
	
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(() => showPopup);
		if (showPopup) setLoadingModalContent(false);
	}, [showPopup]);


	const handleClose = () => {
		setShow(false);
		setUserApprovedApt(false);
		setRemoteAction({ eventName: "setApprovePop", payload: false, author: isAgent });

		setShowApprovePopup(false);
		setRemoteAction({ eventName: "openApprovePop", payload: false, author: isAgent });

		setRemoteAction({ eventName: "setShowAgentModal", payload: false, author: false });
		setShowPopup(false);
	}
	const handleShow = () => setShow(true);

	const makeLocal = (e, method) => {
		props.make(e, method);
		GTEvent(e);
	}
	return (
		<Modal show={show}  centered >
			<Modal.Header className="align-self-center pfMdFont position-relative w-100  flex-column">
				{currentLanguge.confirmValidationCode.verificationPopupTitle1}<br />
				<p className="f18 mb-0 w400">{currentLanguge.confirmValidationCode.verificationPopupTitle2}</p>

				<a className="closepopupButton" onClick={() => handleClose()} data-gt-category='otp' data-gt-action='click' data-gt-label='close otp popup'>
					<img src='/assets/img/icons/ico-close.svg' alt='' height="20" width="20"/>
				</a>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-12 d-flex flex-column">
						{/* Login Tabs Triggers  */}
						<ul className="nav nav-tabs justify-content-center px-0" role="tablist">
							<li className="nav-item" role="presentation">
								<button
									onClick={() => props.setVerificationMethod("mobile")}
									className="nav-link active"
									id="mobile_login-tab"
									data-bs-toggle="tab"
									data-bs-target="#mobile_login-content"
									type="button"
									role="tab"
									aria-controls="mobile_login"
									aria-selected="true"
									data-gt-category='otp' data-gt-action='click' data-gt-label='set verification - mobile'
								>
									{currentLanguge.formsFields.mobilePhone}
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									onClick={() => props.setVerificationMethod("email")}
									className="nav-link"
									id="email_login-tab"
									data-bs-toggle="tab"
									data-bs-target="#email_login-content"
									type="button"
									role="tab"
									aria-controls="email_login"
									aria-selected="true"
									data-gt-category='otp' data-gt-action='click' data-gt-label='set verification - email'
								>
									{currentLanguge.formsFields.email}
								</button>
							</li>
						</ul>
						<div className="tab-content col-12 d-flex justify-content-center align-self-center">
							{/* mobile_login  */}
							
							<div
								className="tab-pane fade show active align-self-center col-xxl-10 col-xl-10 col-lg-8"
								id="mobile_login-content"
								role="tabpanel"
								aria-labelledby="mobile_login-tab"
								style={{width: '100%', maxWidth: '300px'}}
							>
								<form
									className="row mt-4 flex-column align-items-center  needs-validation"
									noValidate
									id="login_form__mobile"
									onSubmit={(e) => makeLocal(e, "phone")}
								>
									<div className="col-xxl-12 col-xl-10 col-lg-12 col-md-12 col-sm-12 mb-3 input-group-lg d-flex flex-column">
										<label htmlFor="validationCustomMobile" className="form-label align-self-center pfItemFontSm  d-none">
											{currentLanguge.formsFields.mobilePhone}
										</label>
										<div className="input-group input-group-md input-group-phone">
											<input
												type="text"
												className="form-control pfInput pfContainerShadow d-left alex2"
												id="validationCustomMobile"
												placeholder={currentLanguge.formsFields.mobilePhone}
												disabled
												value={props.phone}
											/>
										</div>
									</div>
									<div className="col-xxl-12 col-xl-10 col-lg-12 col-md-12 col-sm-12">
										<button className="btn rf-btn-primary fs-5 w-100 pfButton" type="submit"
											data-gt-category='otp' data-gt-action='click' data-gt-label='send verification code via mobile'>
											{currentLanguge.formsCTA.formSend}
										</button>
									</div>
									<div className="invalidField text-center" id="invalidPhonePopover"></div>
								</form>
							</div>

							{/* email_login  */}
							<div
								className="tab-pane fade align-self-center col-xxl-10 col-xl-10 col-lg-8"
								id="email_login-content"
								role="tabpanel"
								aria-labelledby="email_login-tab"
								style={{width: '100%', maxWidth: '300px'}}
							>
								<form
									className="row mt-4 flex-column align-items-center needs-validation"
									noValidate
									id="login_form__email"
									onSubmit={(e) => makeLocal(e, "email")}
								>
									<div className="col-xxl-12 col-xl-10 col-lg-12 col-md-12 col-sm-12 mb-3 input-group-lg d-flex flex-column">
										<label htmlFor="validationCustomEmail" className="form-label  align-self-center pfItemFontSm d-none">
											{currentLanguge.formsFields.email}
										</label>
										<div className="input-group input-group-md input-group-phone">
											<input
												type="email"
												className="form-control  pfInput pfContainerShadow d-left"
												placeholder={currentLanguge.formsFields.email}
												id="validationCustomEmail"
												required
												disabled
												value={props.email}
											/>
										</div>
									</div>
									<div className="col-xxl-12 col-xl-10 col-lg-12 col-md-12 col-sm-12">
										<button className="btn rf-btn-primary fs-6 w-100 pfButton" type="submit"
											data-gt-category='otp' data-gt-action='click' data-gt-label='send verification code via email'>
											{currentLanguge.formsCTA.formSend}
										</button>
									</div>
									<div className="invalidField text-center " id="invalidMailPopover"></div>

								</form>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default VerificationPopup;
