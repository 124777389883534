import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./MessageBar.sass";
import * as actions from "../../../Redux/Reducers/projectSlice";
import store from "../../../Redux/Store/store";

const MessageBar = (props) => {
	let buildingName = useSelector((state) => state.project.buildingName);
	let floorName = useSelector((state) => state.project.floorName);
	let appartmentTitle = useSelector((state) => state.project.appartmantTitle);
	let project = useSelector((state) => state.project);

	const currentStep = useSelector((state) => state.project.currentStep);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const buildings = useSelector((state) => state.data.buildings);


	const [localCrumbs, setCrumbs] = useState([""]);

	useEffect(() => {
		for (let building of buildings) {
			if (building.id == project.buildingID) {
				store.dispatch(actions.setBuldingName(building.building_title));
				break;
			}
		}
	}, [buildings]);
	
	useEffect(() => { }, [currentLanguge]);
	
	useEffect(() => {
		if (currentStep === "1") {
			setCrumbs([]);
			return;
		}
		if (currentStep === "2") {
			setCrumbs([`${buildingName}`]);
			return;
		}
		if (currentStep === "3") {
			setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`]);
			return;
		}
		if (currentStep === "4") {
			setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`, `${!isNaN(appartmentTitle) ? currentLanguge.messageBar.apartment : ''} ${appartmentTitle}`, `${currentLanguge.messageBar.reservation}`]);
			return;
		}
		if (currentStep === "5") {
			// setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`, `${!isNaN(appartmentTitle) ? currentLanguge.messageBar.apartment : ''} ${appartmentTitle}`, `${currentLanguge.messageBar.reservation}`,`${currentLanguge.messageBar.signature}`]);
			setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`, `${!isNaN(appartmentTitle) ? currentLanguge.messageBar.apartment : ''} ${appartmentTitle}`, `${currentLanguge.messageBar.reservation}`]);
			return;
		}
		if (currentStep === "6") {
			// setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`, `${!isNaN(appartmentTitle) ? currentLanguge.messageBar.apartment : ''} ${appartmentTitle}`, `${currentLanguge.messageBar.reservation}`, `${currentLanguge.messageBar.signature}`,`${currentLanguge.messageBar.payment}`]);
			setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`, `${!isNaN(appartmentTitle) ? currentLanguge.messageBar.apartment : ''} ${appartmentTitle}`, `${currentLanguge.messageBar.reservation}`]);
			return;
		}
		if (currentStep === "7") {
			// setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`, `${!isNaN(appartmentTitle) ? currentLanguge.messageBar.apartment : ''} ${appartmentTitle}`, `${currentLanguge.messageBar.reservation}`, `${currentLanguge.messageBar.signature}`, `${currentLanguge.messageBar.payment}`,`${currentLanguge.messageBar.meeting}`]);
			setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`, `${!isNaN(appartmentTitle) ? currentLanguge.messageBar.apartment : ''} ${appartmentTitle}`, `${currentLanguge.messageBar.reservation}`]);
			return;
		}
		if (currentStep === "8") {
			// setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`, `${!isNaN(appartmentTitle) ? currentLanguge.messageBar.apartment : ''} ${appartmentTitle}`, `${currentLanguge.messageBar.reservation}`, `${currentLanguge.messageBar.signature}`, `${currentLanguge.messageBar.payment}`, `${currentLanguge.messageBar.meeting}`, `${currentLanguge.messageBar.finish}`]);
			setCrumbs(() => [`${buildingName}`, `${currentLanguge.messageBar.floor} ${floorName}`, `${!isNaN(appartmentTitle) ? currentLanguge.messageBar.apartment : ''} ${appartmentTitle}`, `${currentLanguge.messageBar.reservation}`, `${currentLanguge.messageBar.finish}`]);
			return;
		}
	}, [currentStep,floorName,appartmentTitle,buildingName,appartmentTitle, currentLanguge]);


	const DisplayCrumbs = ({ list }) => {
		try {
			if (list.length < 1) {
				return <div />;
			}
			let content = list.map((el, idx) => {
				return (
					<li key={idx} className={`breadcrumb-item px-3 flashitOne `}>
						{el}
					</li>
				);
			});
			return content;
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div id="message_bar" className="d-flex flex-xl-row flex-md-column shadow">
			{/* Breadcrumbs  */}
			<nav aria-label="breadcrumb" id="apartment_reserve__breadcrumbs">
				<ol className="breadcrumb mb-0 h-100 flex-nowrap" id="crumbsContainer">
					<DisplayCrumbs list={localCrumbs} />
				</ol>
			</nav>

			{/* Messages  */}
			{/* <div className="d-flex align-items-center h-100 ps-5 pe-2" id="apartment_reserve__messages">
				<p className="m-0"></p>
			</div> */}
		</div>
	);
};

export default MessageBar;
