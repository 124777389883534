import "./HomeIcons.sass";

const HomeIconItem = (props) => {
	return (
		<div className={`col-lg-1 col-md-2 col-sm-3 col-6`} key={`homeIcon-${props.itemkey}`}  id={`homeIcon-${props.itemkey}`}>
			<div className="icon" >
				<img src={props.icon_img} alt="" />
			</div>
			<div className="pt-2">
				<p className="fs-6">{props.icon_title}</p>
				<p className="fs-6">{props.val}</p>
			</div>
		</div>
	);
};

export default HomeIconItem;
