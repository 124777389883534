import { useSelector } from "react-redux";
import { useEffect } from "react";
import $ from 'jquery'

const muteClick = (e) => {
	try {
		let remoteVideo = document.querySelector("#remoteVideo");
		let audio = remoteVideo.querySelector("audio");
		if (audio) {
			if (audio.muted) {
				audio.muted = false;
				if (e.target.id === "muteButton") {
					let img = e.target.children[0];
					e.target.src = "/assets/img/icons/icons8-audio-on.svg";
				}
				if (e.target.src) {
					e.target.src = "/assets/img/icons/icons8-audio-on.svg";
				}
				return;
			}
			if (!audio.muted) {
				audio.muted = true;
				if (e.target.id === "muteButton") {
					e.target.src = "/assets/img/icons/icons8-audio-off.svg";
				}
				if (e.target.src) {
					e.target.src = "/assets/img/icons/icons8-audio-off.svg";
				}
				return;
			}
		}
	} catch (err) {
		console.log(err);
	}
};
const micClick = (e) => {
	try {
		if (e.target.src) {
			let src = `${e.target.src}`;

			if (src.includes("mic-on")) {
				e.target.src = "/assets/img/icons/icons8-mic-off.svg";
				window.room.emit("mute");

				return;
			}
			if (src.includes("mic-off")) {
				e.target.src = "/assets/img/icons/icons8-mic-on.svg";
				window.room.emit("unmute");

				return;
			}
		}
	} catch (err) {
		console.log(err);
	}
};
const videoClick = (e) => {
	try {
		if (e.target.src) {
			let src = `${e.target.src}`;
			if (src.includes("video-on")) {
				e.target.src = "/assets/img/icons/icons8-video-off.svg";
				window.room.emit("stopVideo");

				return;
			}
			if (src.includes("video-off")) {
				e.target.src = "/assets/img/icons/icons8-video-on.svg";
				window.room.emit("resumeVideo");

				return;
			}
		}
	} catch (err) {
		console.log(err);
	}
};

function openFullscreen() {
	try {
		let elem = document.querySelector("#remoteVideo");
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		}
	} catch (err) {
		console.log(err);
	}
}

const minimizeVideo = () => {
	
	$('#minimizeVideo').toggleClass('targetMinimized')
	if ($('#minimizeVideo').hasClass('targetMinimized')) {
		$('#minimizeVideo').find('i').removeClass('bi-fullscreen-exit').addClass('bi-fullscreen');
		$('.footer')[0].style.paddingBottom = '60px';
	} else {
		$('#minimizeVideo').find('i').removeClass('bi-fullscreen').addClass('bi-fullscreen-exit');
		$('.footer')[0].style.paddingBottom = '228px';
	}
	$('#meetingVideoMobile__container .videoWrapper').toggleClass('minimized');
}

const emitShareScreen = () => {
	try {
		window.room.emit("share");
	} catch (err) {
		console.log(err);
	}
};
const VideoDisplayController = () => {
	const isAgent = useSelector((state) => state.meeting.isAgent);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);

	return (
		<div className="col-12 m-0 mt-1 px-2 d-flex flex-row justify-content-center bgTrans btp">
			{/* Full screen */}
			<button id="minimizeVideo" onClick={minimizeVideo}>
				<i className="bi bi-fullscreen-exit"></i>
			</button>
			

			<span className="d-flex justify-content-center pointer mx-4" >
				<img
					src="/assets/img/icons/icons8-full-screen.svg"
					className="mt-0 align-self-center "
					alt={currentLanguge.meetingVideoComp.alt_fullScreen}
					height="30"
					width="30"
					onClick={() => openFullscreen()}
				/>
			</span>

			
			{/* Mute */}
			{/* <span className="d-flex justify-content-center pointer align-self-center" onClick={(e) => muteClick(e)} style={{flex:"1"}}>
				<img src="/assets/img/icons/icons8-audio-on.svg" id="muteButton" className="mt-0 align-self-center " alt="" height="30" width="30" />
			</span> */}

			{/* Share screen */}
			<span className={`d-flex justify-content-center ${isAgent ? null : "d-none"} pointer mx-4`}>
				<img src="/assets/img/icons/icons8-share.svg" className="mt-0 align-self-center " alt={currentLanguge.meetingVideoComp.alt_shareScreen} height="30" width="30" onClick={() => emitShareScreen()} />
			</span>

			{/* Video source */}
			<div className="justify-content-center align-self-center d-flex mde-2 mx-4">
				<div className="btn-group dropup align-self-center">
					<div type="button" className="btn pointer p-0 d-flex align-items-center" onClick={(e) => videoClick(e)}>
						<img src="/assets/img/icons/icons8-video-on.svg" className="mde-2" alt={currentLanguge.meetingVideoComp.alt_videoSource} height="30" width="30" />
					</div>
					<div type="button" className="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
						<span className="visually-hidden">Toggle Dropright</span>
					</div>
					<ul className="dropdown-menu p-0 m-0 camDropdown" id="camDropdown"  style={{width:"300px"}}>
						<span className="f14 w600 text-center bg-secondary d-block text-light py-2">{currentLanguge.meetingVideoComp.cameraListTitle}</span>
					</ul>
				</div>
			</div>

			{/* Mic source */}
			<div className="justify-content-center align-self-center d-flex align-items-center mx-4">
				<div className="btn-group dropup align-self-center">
					<div type="button" className="btn pointer p-0" onClick={(e) => micClick(e)}>
						<img src="/assets/img/icons/icons8-mic-on.svg" alt={currentLanguge.meetingVideoComp.alt_soundSource} height="30" width="30" />
					</div>
					<div type="button" className="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
						<span className="visually-hidden">Toggle Dropright</span>
					</div>
					<div className="dropdown-menu col-12 p-0 m-0" id="micDropdown" style={{width:"300px", zIndex:"99999"}}>
						<div className="col-12 border-bottom ouptputDropdown" id="ouptputDropdown" role="menu">
							<span className="f14 w600 text-center bg-secondary d-block text-light py-2">{currentLanguge.meetingVideoComp.speakerListTitle}</span>
						</div>
						<div className="col-12 border-bottom micDropdown" id="micDropdown" role="menu">
							<span className="f14 w600 text-center bg-secondary d-block text-light py-2">{currentLanguge.meetingVideoComp.micListTitle}</span>
						</div>
						{/* <span className="f14 w600 text-center bg-secondary d-block text-light py-2">{currentLanguge.meetingVideoComp.micListTitle}</span> */}
					</div>
				</div>
			</div>
		</div>
	);
};
export default VideoDisplayController;
