import { handlePaymentSkip, handleSignatureSkip } from "../Utils";
import store from "../../../Redux/Store/store";
import { useEffect } from "react";
import $ from 'jquery';
import { useSelector } from "react-redux";

const SkipSigntBtn = ({ isAgent }) => {
	const requestSkipSign = store.getState().meeting.requestSkipSign;
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	useEffect(() => { }, [currentLanguge]);
	
	const onSigClick = (e) => {
		e.target.textContent = `${currentLanguge.meetingVideoComp.reqSent} `;
		handleSignatureSkip();
		return;

	};
	
	useEffect(() => {
		const requestSkipSign = store.getState().meeting.requestSkipSign;

		if (requestSkipSign) {
			$('#skipSigntBtn').html(`${currentLanguge.meetingVideoComp.reqApproved} `).prop('disabled', true).css('opacity','1');
		} 
	},[requestSkipSign])
	

    return (
        <button
            id="skipSigntBtn"
            className="btn rf-btnSecondery f16 w600"
            onClick={(e) => onSigClick(e)}
        >
            {currentLanguge.meetingVideoComp.skipSigntBtn}
        </button>
    );
};

export default SkipSigntBtn;
