import store from "../../../Redux/Store/store";
import DateSelectionFormFields from "../../General/UserDetailsForm/DateSelectionFormFields";
import { requestMeeting } from "../../../Helpers/RegistetUtils";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const ReschedualMeeting = (props) => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const { hoursIndex } = store.getState().meeting;
	const Callbacked = useCallback((e) => requestMeeting(e, "agent", currentLanguge))
	const meeting_duration = useSelector((state) => state.project.projectSettings.meeting_duration);
	useEffect(() => { }, [currentLanguge]);
	
	return (
		<div id="login-form__step5" className=" px-5 mt-5">
			{/* <div>Back</div> */}
			<h1 className="fw-bold">{currentLanguge.reschedualMeeting.title}</h1>
			<p className="my-1 mb-1 pfItemFont">
				<img src="/assets/img/icons/ico-meeting_duration.svg" className="pfIcon mde-1" height="22" width="22" alt={currentLanguge.reschedualMeeting.titleIcon1} /> {currentLanguge.reschedualMeeting.titleIcon1} {meeting_duration} {currentLanguge.general.timeMinutes}
			</p>
			<p className="my-1 pfItemFont">
				<img src="/assets/img/icons/ico-meeting_details.svg" className="pfIcon mde-1" height="22" width="22" alt={currentLanguge.reschedualMeeting.titleIcon2} />  {currentLanguge.reschedualMeeting.titleIcon2} 
			</p>

			{/* Registration Form  */}
			<form id="reschedualMeeting_form" className="my-5" onSubmit={Callbacked}>
				{/* Date Selection */}
				<DateSelectionFormFields hoursIndex={hoursIndex}  />

				<div className="row justify-content-center pt-4">
					<div className="col-lg-6 col-sm-12 text-center">
						<div className="d-grid">
							<input type="submit" value={currentLanguge.formsCTA.reschedualMeetingSubmitBtn} className="btn rf-btn-primary fw-bold f18 py-3 pfSubmitBtnColor" />
						</div>
					</div>
				</div>
				<div id="successReg" className="invalid-feedback text-center">
					{currentLanguge.reschedualMeeting.reschedualMeetingSuccessfulMsg}
				</div>
			</form>
		</div>
	);
};

export default ReschedualMeeting;
