import SectionTitle from '../../UI/Titels/SectionTitle';
import './HomeDescription.sass';

const HomeDescription = props => {
  if (props.projectSettings.project_about_title == '' && props.projectSettings.project_about_text) return null;
  return (
    <div className="row py-5  justify-content-center" id="project_description">
        <div className="col-lg-8 co-sm-12">
        <SectionTitle>{props.projectSettings.project_about_title}</SectionTitle>
            <p className="fs-5" >
                {props.projectSettings.project_about_text}
            </p>
        </div>
    </div>
  );
}

export default HomeDescription;
