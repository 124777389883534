import { setVerificationStep } from "../../../Helpers/verificationUtils";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const VerficationSuccessfull = (props) => {
	const onEnterClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setVerificationStep(6);
		return;
	};

    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);

	return (
		<div id="login-form__step3">
			{/* Step 3: Verfication Code Validated Successfully. Proceed In   */}
			<div className="row g-0 mt-5">
				<div className="col-12 text-center py-2 px-5 mt-5">
					<img src="/assets/img/icons/ico-checked.svg" className="col-2" alt="" width="25%" />
					<h1 className="fw-bold my-4">{currentLanguge.verficationSuccessful.title}</h1>
					<p className="fs-5 pfItemFont">
						{currentLanguge.verficationSuccessful.text}
					</p>
				</div>
			</div>

			{/* CTA  */}
			<div className="row justify-content-center pt-4">
				<div className="col-lg-6 col-md-6 col-10  text-center">
					<div className="d-grid">
						<button onClick={onEnterClick} className="btn rf-btn-primary fw-bold fs-6 py-3 pfSubmitBtnColor">
							{currentLanguge.verficationSuccessful.cta}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerficationSuccessfull;
