import store from "../../Redux/Store/store";
import * as actions from "../../Redux/Reducers/verificationSlice";
import * as meetingActions from "../../Redux/Reducers/meetingSlice";
import * as projectActions from "../../Redux/Reducers/projectSlice";
import * as registerActions from "../../Redux/Reducers/registerSlice";
import $ from "jquery";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { makePostRequest } from "../../Axios";
import { showStaticMessage } from "../RegistetUtils";
import {
	initMeetingFlag,
	setAgentControlSwitch,
	setProjectSettingsObject,
	setUserVerified,
} from "../AppartmentReserveUtils";

import { setClientType, setClientTypeName, setMeetingStatus } from "../MeetingsUtils";
import { setMeetingSettings } from "../ProjectLanding";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";


const timeBeforeMeeting = 10;

export const showErrorMessage = (id, message) => {
	try {
		const errorElem = document.getElementById(id);
		if (errorElem !== null || errorElem !== undefined) {
			errorElem.style.opacity = "0";
			errorElem.style.display = "block";
			errorElem.textContent = message;
			$("#" + id).fadeTo(400, 1);
		}
		setTimeout(() => {
			errorElem.textContent = "";
		}, 5000);
	} catch (err) {
		console.log(err);
	}
};

export const validatePhone = (phone) => {
	try {
		const numbersRegex = new RegExp("^[0-9]+$");
		const isPhoneInt = numbersRegex.test(phone);
		const phoneLength = `${phone}`.length > 6;
		if (!isPhoneInt || !phoneLength || !phone) {
			return false;
		}
		return true;
	} catch (err) {
		console.log(err);
	}
};

export const validateEmail = (email) => {
	try {
		const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
		const isEmailValid = emailRegex.test(email);
		if (!isEmailValid) {
			return false;
		}
		return true;
	} catch (err) {
		console.log(err);
	}
};
export const incrementStep = () => {
	store.dispatch(actions.incrementStep());
};
export const decrementStep = () => {
	store.dispatch(actions.decrementStep());
};

export const HandleVerificationInputs = () => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);
	
	const setEmail = (value) => {
		store.dispatch(actions.setEmail(value));
	};
	const setMobileNumber = (value) => {
		store.dispatch(actions.setMobileNumber(value));
	};
	const setAreaCode = (value) => {
		store.dispatch(actions.setAreaCode(value));
	};
	const setVerificationMethod = (value) => {
		store.dispatch(actions.setVerificationMethod(value));
	};

	const handlePhoneSubmit = async (e) => {
		try {
			//prevent submit behvaiour
			e.preventDefault();

			$(e.target).find('button[type=submit]').prop('disabled', true);

			//get the meeting slug from the url
			const { areaCode, mobileNumber, countryCode, slug } = store.getState().verification;

			const isPhoneValid = validatePhone(mobileNumber.substring(3));
			//Phone number does not meet the criteria

			if (!isPhoneValid) {
				showErrorMessage("invalidPhone", currentLanguge.formsErrors.invalidPhone);
				$(e.target).find('button[type=submit]').prop('disabled', false);

				return;
			}
			const numberWithPrefix = window.loginPage.getNumber();

			//object form of the data
			const data = new FormData();
			data.append("meetingslug", slug);
			store.dispatch(actions.setNumberWithPrefix(numberWithPrefix));
			data.append("mobile", numberWithPrefix);

			//request the pincode
			//numbers are sent with country code exp: 972*******
			const res = await requestCode("/userclient_mobilecheck", data);

			//number not found on our side
			if (res.data.statusCode !== 200) {
				showErrorMessage("invalidPhone", currentLanguge.confirmValidationCode.phoneNotFound);
				$(e.target).find('button[type=submit]').prop('disabled', false);

				return;
			}

			// //go to next step - verifying code
			setVerificationStep(2);
			setVerificationMethod("mobile")
			$(e.target).find('button[type=submit]').prop('disabled', false);

			return;
		} catch (err) {
			console.log(err);
			showErrorMessage("invalidPhone", currentLanguge.confirmValidationCode.tryAgain);
		}
	};

	const handleEmailSubmit = async (e) => {
		console.log('e',e);
		try {
			//prevent submit behvaiour
			e.preventDefault();
			$(e.target).find('button[type=submit]').prop('disabled', true);


			const email = store.getState().verification.email;
			const slug = store.getState().verification.slug;

			const isEmailValid = validateEmail(email);
			if (!isEmailValid) {
				showErrorMessage("invalidEmail", currentLanguge.formsErrors.invalidEmail);
				$(e.target).find('button[type=submit]').prop('disabled', false);

				return;
			}
			//object form of the data
			const data = new FormData();
			data.append("meetingslug", slug);
			data.append("email", email);

			//request the code via email
			const res = await requestCode("/userclient_emailcheck", data);
			//email does not found on our side
			if (res.data.statusCode !== 200) {
				showErrorMessage("invalidEmail",currentLanguge.confirmValidationCode.emailNotFound);
				$(e.target).find('button[type=submit]').prop('disabled', false);
				return;
			}
			//go to next step - verification
			setVerificationStep(2);
			setVerificationMethod("email")

			$(e.target).find('button[type=submit]').prop('disabled', false);
			return;
		} catch (err) {
			console.log(err);
		}
	};

	return {
		setVerificationMethod,
		setEmail,
		setMobileNumber,
		setAreaCode,
		handlePhoneSubmit,
		handleEmailSubmit,
	};
};

export const handleCodeInput = (e) => {
	let prevElem = e.target.parentElement.previousElementSibling;
	let nextElem = e.target.parentElement.nextElementSibling;
	let currentElem = e.target;
	if (prevElem && currentElem.value) {
		prevElem.children[0].focus();
	}
	if (!currentElem.value) {
		if (nextElem) {
			nextElem.children[0].focus();
		}
	}
};

export const handleExistingMeetingTime = async () => {
	// const meetingLength = store.getState().project.projectSettings.meeting_duration;
	const timeFormat = "YYYY-MM-DD HH:mm:ss";
	try {
		let langCode = sessionStorage.getItem("langCode");
		if (!langCode || langCode == '') {
			langCode = '';
		} else {
			langCode = '/' + langCode;
		}

		const slug = store.getState().verification.slug;
		const timezone = store.getState().project.timezone;
		setUserVerified(true);
		//get meeting data
		const res = await makePostRequest(`/meetingrooms/${slug}${langCode}`, null);
		if (!res) {
			return;
		}
		if (res.data.clientDetails) {
			setProjectID(res.data.project.project_slug);
			setMeetingStartAt(res.data.meeting.meeting_start_datetime);
			store.dispatch(registerActions.setFirstName(res.data.clientDetails.firstname));
			store.dispatch(registerActions.setLastName(res.data.clientDetails.lastname));
			store.dispatch(registerActions.setID(res.data.clientDetails.identity_id));
			store.dispatch(registerActions.setMobileNumber(res.data.clientDetails.mobile));
			setMeetingSettings(res.data.meeting, res.data.agentDetails);
			setProjectSettingsObject(res.data.project);
		}

		// const meetingUtc = "2022-02-01 10:00";
		const meetingUtc = res.data.meeting.meeting_start_datetime;
		let meetingLength = res.data.project.meeting_duration;
		const toUtchs = moment.utc(meetingUtc).tz(timezone).format(timeFormat)

		// const meetingStartTime = toUtchs.clone().tz(timezone);
		const meetingStartTime = moment(toUtchs);
		const meetingEndtTime = momentTimezone(meetingStartTime).add(meetingLength, "minutes");
		console.log("start", meetingStartTime.format(timeFormat));
		console.log("end", meetingEndtTime.format(timeFormat));
		// const now = moment().tz(timezone);
		const now = moment();


		setMeetingStartAt(meetingStartTime);

		if (now.isAfter(meetingStartTime)) {
			const difference = meetingEndtTime.diff(now);
			const diffMinutes = new Date(difference).getMinutes();
			// setTimeToMeeting(diffMinutes);

			initMeetingFlag().then((res) => {
				setContinueToApp(true);
				setMeetingStatus("start");
				window.location.reload();
			});
			return;
		}
		//early check for date match

		//early arrival
		if (now.isBefore(meetingStartTime)) {
			let duration = moment.duration(meetingStartTime.diff(now))

			const difference = meetingStartTime.diff(now);
			const diffMinutes = new Date(difference).getMinutes();
			// setTimeToMeeting(diffMinutes);
			let asMinuets = duration.asMinutes();

			//less then 10 min
			if (asMinuets <= timeBeforeMeeting) {
				// store.dispatch(meetingActions.setTimeToMeeting(asMinuets));
				setVerificationStep(6);
				setMeetingStatus("waiting");
				return;
			}
		}
		//late arrival
		if (now.isAfter(meetingEndtTime)) {
			setMeetingStatus(false);
			setMeetingStartAt(false);
			setVerificationStep(4);
			return;
		}
	} catch (err) {
		console.log(err);
	}
};

export const validateCode = async (e, method, currentLanguge) => {
	e.preventDefault();
	try {

		$(e.target).find('button[type=submit]').prop('disabled', true);

		const { slug } = store.getState().verification;

		const inputs = document.querySelectorAll("input");
		const codeHolder = [];
		inputs.forEach((es) => {
			codeHolder.push(es.value);
		});
		const newCode = codeHolder.reverse().join("");

		if (newCode.length < 6) {
			showErrorMessage("invalidCode", currentLanguge.confirmValidationCode.enter6dig);
			$(e.target).find('button[type=submit]').prop('disabled', false);
			return;
		}
		store.dispatch(actions.setCode(newCode));
		const data = new FormData();
		data.append("meetingslug", slug);
		data.append("code", newCode);
		const res = method === "email" ? await makePostRequest("/userclientmailcodecheck", data) : await makePostRequest("/userclientsmscodecheck", data);
		if (res.data.statusCode !== 200) {
			handleCodeIndicator();
			$(e.target).find('button[type=submit]').prop('disabled', false);
			return;
		}
		setIsClientRegistered(true);
		handleExistingMeetingTime();
		setUserVerified(true)
		// incrementStep();
	} catch (err) {
		console.log(err);
	}
};

export const handleSetToken = async () => {
	try {
		const token = sessionStorage.getItem("forestToken");
		if (!token) {
			const { apiEmail, apiPassword } = store.getState().verification;
			//stringify the data and set as object form
			const form = new FormData();
			form.append("email", apiEmail);
			form.append("password", apiPassword);
			await makePostRequest("/login", form);
			return true;
		}
	} catch (err) {
		//error handling
		console.log(err);
	}
};

export const setSlug = (slug) => {
	//get slug from url
	store.dispatch(actions.setSlug(slug));
};
export const setAgentKey = (key) => {
	//get slug from url
	store.dispatch(actions.setAgentKey(key));
};

export const setProjectID = (key) => {
	store.dispatch(projectActions.setProjectID(key));
};
export const setVerificationStep = (value) => {
	store.dispatch(actions.setVerificationStep(value));
};

export const BAL = ({ istoken }) => {
	const isLoaded = useSelector((state) => state.project.isLoading);

	useEffect(() => {
		const doCall = async () => {
			const getUrlParams = new URLSearchParams(window.location.search);
			const agentKey = getUrlParams.get("agentkey");
			const meetingSlug = getUrlParams.get("meetingSlug");

			let langCode = sessionStorage.getItem("langCode");
			if (!langCode || langCode == '') {
				langCode = '';
			} else {
				langCode = '/' + langCode;
			}

			if (!agentKey) {
				setIsClient(true);
				setAgentControlSwitch(false);
				console.log("IM CLEINT");
				store.dispatch(meetingActions.setIsAgent(false));
			}
			if (!agentKey && meetingSlug) {
				setSlug(meetingSlug);
				let data = new FormData();
				data.append("agent_key", "");
				const res = await makePostRequest(`/meetingrooms/${meetingSlug}${langCode}`, data);

				if (!isLoaded) {
					if (res) {
						setProjectID(res.data.project.project_slug);
						store.dispatch(registerActions.setFirstName(res.data.clientDetails.firstname));
						store.dispatch(registerActions.setLastName(res.data.clientDetails.lastname));
						store.dispatch(registerActions.setID(res.data.clientDetails.identity_id));
						store.dispatch(registerActions.setMobileNumber(res.data.clientDetails.mobile));
						// setMeetingStatus("start");
						setClientType(res.data.clientDetails.client_type);
						setClientTypeName(res.data.clientDetails.clientTypeName);
						setMeetingSettings(res.data.meeting, res.data.agentDetails);
						setProjectSettingsObject(res.data.project);
					}
				}
				return;
			}
			if (agentKey && meetingSlug) {
				console.log("IM AGENT");
				setAgentKey(agentKey);
				setSlug(meetingSlug);
				setIsClientRegistered(true);
				setIsAgent(true);
				let data = new FormData();
				data.append("agent_key", agentKey);
				const res = await makePostRequest(`/meetingrooms/${meetingSlug}${langCode}`, data);
				if (!isLoaded) {
					if (res) {
						store.dispatch(projectActions.setProjectDefaultLangauge(res.data.project.project_default_language));
						store.dispatch(projectActions.setCurrentLangauge(res.data.project.project_default_language));
						setProjectID(res.data.project.project_slug);
						setMeetingStartAt(res.data.meeting.meeting_start_datetime);
						store.dispatch(registerActions.setFirstName(res.data.clientDetails.firstname));
						store.dispatch(registerActions.setLastName(res.data.clientDetails.lastname));
						store.dispatch(registerActions.setID(res.data.clientDetails.identity_id));
						store.dispatch(registerActions.setMobileNumber(res.data.clientDetails.mobile));
						setClientType(res.data.clientDetails.client_type);
						setClientTypeName(res.data.clientDetails.clientTypeName);
						setMeetingSettings(res.data.meeting, res.data.agentDetails);
						setProjectSettingsObject(res.data.project);
						setMeetingStatus("start");
					}
				}
			}
		};
		if (istoken) {
			doCall();
		}
	}, [istoken]);
	return null;
};

const parseNumberToBool = (num) => {
	if (num === "0") {
		return false;
	}
	if (num === "1") {
		return true;
	}
};
export const requestCode = async (url, data) => {
	const req = await makePostRequest(url, data);
	return req;
};

export const handleEnterProject = () => {
	const slug = store.getState().verification.slug;
	if (!slug) {
		window.history.replaceState(null, "reserve", "/apartment_reserve");
		return;
	}
	if (slug) {
		window.history.replaceState(null, "login", "/login");
	}
};

export const resendCode = async (e, method, currentLanguge) => {
	try {
		e.preventDefault();
		e.stopPropagation();
		//clear inputs
		const inputs = document.querySelectorAll("input");
		inputs.forEach((input) => {
			input.value = "";
		});
		//set data
		const slug = store.getState().verification.slug;
		const data = new FormData();
		data.append("meetingslug", slug);

		if (method === "email") {
			const email = store.getState().verification.email;
			data.append("email", email);
			await requestCode("/userclient_emailcheck", data);
		}

		if (method === "phone") {
			const mobile = store.getState().verification.numberWithPrefix;
			data.append("mobile", mobile);
			await requestCode("/userclient_mobilecheck", data);
		}


		showStaticMessage("smsSent", currentLanguge.confirmValidationCode.validationCodeSent, true);
		return;
	} catch (err) {
		console.log(err);
	}
};

export const handleCodeIndicator = () => {
	const codeIndicator = document.querySelector("#codeIndicator");
	const hasClass = codeIndicator.classList.contains("d-none");
	codeIndicator.style.opacity = 0;

	if (!hasClass) {
		codeIndicator.classList.add("d-none");
	}
	if (hasClass) {
		codeIndicator.classList.remove("d-none");
		$("#codeIndicator").fadeTo(400, 1);
	}
};

export const setIsClientRegistered = (value) => {
	store.dispatch(actions.setIsClientRegistered(value));
};

export const setShowPopup = (value) => {
	store.dispatch(projectActions.setShowPopup(value));
};

export const setWorkingToken = (val) => {
	store.dispatch(actions.setWorkingToken(val));
};

export const setIsAgent = (value) => {
	store.dispatch(actions.setIsAgent(value));
};
export const setIsClient = (value) => {
	store.dispatch(actions.setIsClient(value));
};
export const setMeetingStartAt = (value) => {
	store.dispatch(actions.setMeetingStartAt(value));
};

export const setRoomSpaceID = (value) => {
	store.dispatch(actions.setRoomSpaceID(value));
};
export const setReservationPrice = (value) => {
	store.dispatch(actions.setTranzillaAccouont(value));
};
export const setTranzillaTrandmode = (value) => {
	store.dispatch(actions.setTranzillaTrandmode(value));
};
export const setTranzillaAccouont = (value) => {
	store.dispatch(actions.setTranzillaAccouont(value));
};
export const setTranzillaApiKey = (value) => {
	store.dispatch(actions.setTranzillaApiKey(value));
};

export const setShowTimerAfter = (value) => {
	store.dispatch(actions.setShowTimerAfter(value));
};

export const setContinueToApp = (value) => {
	store.dispatch(actions.setContinueToApp(value));
};
