import { useCallback, useEffect } from "react";
import MeetingTimerBar from "../MeetingComponents/MeetingTimerBar/MeetingTimerBar";
import { useSelector } from "react-redux";
import "./MeetingVideo.sass";
import VideoLoadingModal from "./VideoLoadingModal/VideoLoadingModal";
import UserInfo from "./UserInfo";
import ControlBar from "./ControlBar/ControlBar";
import AgentFlowControls from "./AgentControls/AgentFlowControls";
import AgentControlToggler from "./AgentControls/AgentControlToggler";
import NotesList from "./NotesList/NotesList";

const MeetingVideo = (props) => {
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const agentControl = useSelector((state) => state.meeting.agentControl);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	 
	useEffect(() => { }, [currentLanguge]);

	const OneNotes = useCallback(() => <NotesList />, []);
	const OneControl = useCallback(() => <ControlBar/>, []);
	const TimesBar = useCallback(() => <MeetingTimerBar/>, []);

	return (
		<div className="d-flex flex-column" id="meetingvideocontainer">
			<div id="agentControlBanner" className={`text-center p-1 px-2 ${!agentControl ? 'd-none' : !isAgent ? '' : 'd-none'}`}>{currentLanguge.meetingVideoComp.underAgentControl}</div>
			<div className={`col-12 align-self-center position-relative videoCom ${!agentControl ? '' : !isAgent ? 'agent-control' : ''}`}>
				<div className="col videoWrapper position-relative">
					<div
						style={{ height: "100px", zIndex: "102" }}
						className="col-4 bg-dark position-absolute end-0 bottom-0 me-2 mb-2 puff-in-center"
						id="localVideo"
					></div>
					<div className="d-flex flex-column justify-content-center d-flex flex-row" style={{ height: "280px" }} id="remoteVideo">
						<VideoLoadingModal/>
					</div>
				</div>
				<OneControl />
			</div>
			<hr style={{opacity: '.05'}} className={`${!isAgent ? 'd-none' : ''}`}/>
			<div className="d-flex align-items-center" style={{order: !isAgent ? '' : '-1'}}>
				<UserInfo />
				<TimesBar />
			</div>

			<AgentControlToggler/>
			<hr style={{ opacity: '.05' }} className={`${!isAgent ? 'd-none' : ''}`} />
			<AgentFlowControls />
			<hr style={{opacity: '.05'}}  className={`mb-0 ${!isAgent ? 'd-none' : ''}`}/>
			<OneNotes />
		</div>
	);
};

export default MeetingVideo;
