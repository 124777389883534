import { useEffect } from "react";
import "./validator.sass";
import { setMobileNumberWithoutPrefix, setBuyerMobileNumberWithoutPrefix } from '../../../Helpers/AppartmentReserveUtils/index';
import store from "../../../Redux/Store/store";

const PhoneValidator = ({ onChangeMethod, valueSelector, className, id, propName, placeHolderFunc }) => {

	function getIp(callback) {
		fetch("https://ipinfo.io/json?token=567893b3937a56", { headers: { Accept: "application/json" } })
			.then((resp) => resp.json())
			.catch(() => {
				return {
					country: "us",
				};
			})
			.then((resp) => callback(resp.country));
	}
	
	useEffect(() => {
		const phoneInputField = document.getElementById(id);
		window[propName] = window.intlTelInput(phoneInputField, {
			initialCountry: "auto",
			geoIpLookup: getIp,
			utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
		});
		if (valueSelector != '' && !!valueSelector) {
			window[propName].setNumber(valueSelector);
		}
	}, [id]);

	function onlyNum(e, maxLength) {
		const re = /^[0-9+\b]+$/;
		e.preventDefault();

		if (re.test(e.target.value) && e.target.value.length > 0 && e.target.value.length <= maxLength) {
			document.getElementById(e.target.id).value = e.target.value;

			if (e.target.id.includes("_buyer")) {
				setBuyerMobileNumberWithoutPrefix(e.target.value);
			} else {
				setMobileNumberWithoutPrefix(e.target.value);

				const isDifferentBuyer = store.getState().register.isDifferentBuyer;
				if (!isDifferentBuyer) {
					setBuyerMobileNumberWithoutPrefix(e.target.value);
				}
			}

			placeHolderFunc(e.target.value)
			onChangeMethod(window[propName].getNumber())
		
		} else if((!re.test(e.target.value) && e.target.value.length > 0) || e.target.value.length >= maxLength ){
			document.getElementById(e.target.id).value = e.target.value.slice(0, -1);
		}
	}

	return (
		<input
			style={{
				width: "100%",
			}}
			onInput={(e) => onlyNum(e, 10)}
			className={`form-control f18 ${className} validationCustomMobile`}
			id={id}
			type="tel"
			key={id}
		/>
	);
};

export default PhoneValidator;


const AgentFormPhone = ({ onChangeMethod, valueSelector, className, id, propName, placeHolderFunc }) => {
	function getIp(callback) {
		fetch("https://ipinfo.io/json?token=567893b3937a56", { headers: { Accept: "application/json" } })
			.then((resp) => resp.json())
			.catch(() => {
				return {
					country: "us",
				};
			})
			.then((resp) => callback(resp.country));
	}
	
	useEffect(() => {
		const phoneInputField = document.getElementById(id);
		window[propName] = window.intlTelInput(phoneInputField, {
			initialCountry: "auto",
			geoIpLookup: getIp,
			utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
		});
		if (valueSelector != '' && !!valueSelector) {
			window[propName].setNumber(valueSelector);
		}
	}, [id]);

	function onlyNum(e, maxLength) {
		const re = /^[0-9+\b]+$/;
		e.preventDefault();

		if (re.test(e.target.value) && e.target.value.length > 0 && e.target.value.length <= maxLength) {
			document.getElementById(e.target.id).value = e.target.value;
			placeHolderFunc(e.target.value)
			onChangeMethod(window[propName].getNumber())
		
		} else if((!re.test(e.target.value) && e.target.value.length > 0) || e.target.value.length >= maxLength ){
			document.getElementById(e.target.id).value = e.target.value.slice(0, -1);
			
		}
	}

	return (
		<input
			style={{
				width: "100%",
			}}
			onInput={(e) => onlyNum(e, 10)}
			className={`form-control f18 ${className} validationCustomMobile`}
			id={id}
			type="tel"
			key={id}
		/>
	);
};
export {AgentFormPhone}