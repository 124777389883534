import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PhoneValidator from "../../General/PhoneValidator/PhoneValidator";
import "./InitialLoginForm.sass";

const InitialLoginForm = (props) => {
	const register = useSelector((state) => state.register);
	const mobileWithoutPrefix = useSelector((state) => state.register.mobileNumberWithoutPrefix);
	const [mobileNumbers, setMobileNumbers] = useState(mobileWithoutPrefix);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	useEffect(() => { }, [currentLanguge]);

	return (
		<div id="login-form__step1">
			{/* Personal welcome message  */}
			<div className="row g-0" id="loginFormContent-step1">
				<div className="col-12 text-center py-2 px-5 mt-5 d-flex flex-column">
					<img src="/assets/img/icons/ico-phone.svg" alt="" className="col-2 align-self-center" height="93" width="127" />
					<h1 className="f36 fw-bold mt-4 align-self-center">
						{`${currentLanguge.login.initialLoginGreeting} ${register.firstName} ${register.lastName} `} <br />
						{currentLanguge.login.initialLoginGreetingText1}
						<br />
					</h1>
					<p className="f18">{currentLanguge.login.initialLoginGreetingText2}</p>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					{/* Login Tabs Triggers  */}
					<ul className="nav nav-tabs justify-content-center" role="tablist">
						<li className="nav-item" role="presentation">
							<button
								onClick={() => props.setVerificationMethod("mobile")}
								className="nav-link pb-1 f16 active"
								id="mobile_login-tab"
								data-bs-toggle="tab"
								data-bs-target="#mobile_login-content"
								type="button"
								role="tab"
								aria-controls="mobile_login"
								aria-selected="true"
							>
								{currentLanguge.formsFields.mobilePhone}
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								onClick={() => props.setVerificationMethod("email")}
								className="nav-link pb-1 f16"
								id="email_login-tab"
								data-bs-toggle="tab"
								data-bs-target="#email_login-content"
								type="button"
								role="tab"
								aria-controls="email_login"
								aria-selected="true"
							>
								{currentLanguge.formsFields.email}
							</button>
						</li>
					</ul>

					{/* Login Tabs Content  */}
					<div className="tab-content px-5">
						{/* mobile_login  */}
						<div className="tab-pane fade show active" id="mobile_login-content" role="tabpanel" aria-labelledby="mobile_login-tab">
							<form
								className="row mt-4 flex-column align-items-center  needs-validation"
								noValidate
								id="login_form__mobile"
								onSubmit={(e) => props.handlePhoneSubmit(e, props.slug)}
							>
								<div className="col-xxl-8 col-xl-10 col-lg-12 col-md-8 col-sm-12 mb-2 input-group-lg d-flex flex-column justify-content-center">
									<label htmlFor="validationCustomMobile" className="form-label align-self-start d-none">
										{currentLanguge.formsFields.mobilePhone}
									</label>
									<PhoneValidator
										id="loginPhone"
										onChangeMethod={props.setMobileNumber}
										valueSelector={mobileNumbers}
										className="align-self-center mds-0"
										propName="loginPage" 
										placeHolderFunc={setMobileNumbers}
									/>

									<div id="invalidPhone" style={{ display: "block", height: "10px" }} className="invalid-feedback m-0 align-self-center text-center"></div>
								</div>
								<div className="col-xxl-8 col-xl-10 col-lg-12 col-md-8 col-sm-12 input-group-lg">
									<button className="col-col-xxl-12 btn rf-btn-primary w-100 fw-bold fs-5 pfSubmitBtnColor" type="submit">
										{currentLanguge.formsCTA.formSend}
									</button>
								</div>
							</form>
						</div>

						{/* email_login  */}
						<div className="tab-pane fade" id="email_login-content" role="tabpanel" aria-labelledby="email_login-tab">
							<form
								className="row mt-4 flex-column align-items-center needs-validation"
								noValidate
								id="login_form__email"
								onSubmit={(e) => props.handleEmailSubmit(e, props.slug)}
							>
								<div className="col-xxl-8 col-xl-10 col-lg-12 col-md-8 col-sm-12 mb-3 input-group-lg">
									<label htmlFor="validationCustomEmail" className="form-label  d-none">
										{currentLanguge.formsFields.email}
									</label>
									<input
										type="email"
										className="form-control pfPlaceholder pfItemFontp"
										placeholder={currentLanguge.formsFields.email}
										id="validationCustomEmail"
										required
										onChange={(e) => props.setEmail(e.currentTarget.value)}
										value={props.verificationSelector.email}
									/>
									<div id="invalidEmail" className="invalid-feedback">
										{currentLanguge.formsErrors.invalidEmail}
									</div>
								</div>
								<div className="col-xxl-8 col-xl-10 col-lg-12 col-md-8 col-sm-12 input-group-lg">
									<button className="col-col-xxl-12 btn rf-btn-primary w-100 fw-bold fs-5 pfSubmitBtnColor" type="submit">
										{currentLanguge.formsCTA.formSend}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InitialLoginForm;
