import "./StepsMenu.sass";
import {  setCurrentStep, stepsDictionary } from "../../../Helpers/AppartmentReserveUtils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import GTEvent from "../../../Helpers/GTEvent";
import * as projectActions from "../../../Redux/Reducers/projectSlice";
import store from "../../../Redux/Store/store";

const StepsMenu = ( props ) => {
	const stepsProgress = useSelector((state) => state.project.stepsProgress);
	const userApprovedApt = useSelector((state) => state.project.userApprovedApt);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
    const show3DView = useSelector((state) => state.project.show3DView)

	useEffect(() => { }, [currentLanguge]);
	
	useEffect(() => {
		if (stepsProgress) {
			const list = document.querySelectorAll(".steps_menu__link");
			list.forEach((el, idx) => {
				if (el.dataset.step) {
					if (stepsProgress <= 1) {
						el.classList.remove("doneStep");
						el.classList.remove("complate-link");
						return;
					}
					if (parseFloat(el.dataset.step) <= stepsProgress) {
						el.classList.add("doneStep");
						el.classList.add("complate-link");
					}
				}
			});

			$('#steps_menu__container').find('.steps_menu__link.current-link').removeClass('current-link');
			$($('#steps_menu__container').find('.steps_menu__link')[stepsProgress - 1]).addClass('current-link');
		}
	}, [stepsProgress]);


	const onHistoryChange = (e) => {
		const getUrlParams = new URLSearchParams(window.location.search);
		const agentKey = getUrlParams.get("agentkey");

		var pathArr = window.location.pathname.split( '/' ).filter((el)=> {return el != '';});
		let newStep_title = pathArr[1];

		if (!newStep_title || (agentKey && !props.isAgentSynced)) return;
		if (stepsDictionary[`${newStep_title}`] != '') {
			if (parseFloat(stepsProgress) > 5) {
				window.stepHistory.goForward();
				$('.steps_menu__link').css('pointer-events', 'none');
				$('.navViewChange').css('pointer-events', 'none');
			} else {
				if (newStep_title == '3dview') {
					setCurrentStep(null, '3dview', true);
					store.dispatch(projectActions.setShow3DView(true));
				} else {
					store.dispatch(projectActions.setShow3DView(false));
					setCurrentStep(null, newStep_title, null);
				}
			}
		}
	}

	useEffect(() => {
		window.onpopstate = e => {
			onHistoryChange(e)
		}
	}, []);

	useEffect(() => {
		if (userApprovedApt && stepsProgress >= 5) {
			$('.steps_menu__link').css('pointer-events', 'none');
			$('.navViewChange').css('pointer-events', 'none');
		} else {
			$('.steps_menu__link').css('pointer-events', 'unset');
			$('.navViewChange').css('pointer-events', 'unset');
		}
	}, [userApprovedApt, stepsProgress]);
	

	const hide3DView = () => {
		if (show3DView === true){
			store.dispatch(projectActions.setShow3DView(false));
			$('.view3DViewButton').removeClass('activeView');
		}
	}

	const localClick = (e) => {
		if (!$(e.target).hasClass('complate-link') && !$(e.target).hasClass('doneStep') && !$(e.target).hasClass('current-link')) {
			e.preventDefault();
			e.stopPropagation();
			return;
		} 

		hide3DView();
		setCurrentStep(e, null, true);
		GTEvent(e);

		if ((window.location.pathname.includes('/register') || (window.location.pathname.includes(`/${props.projectSettings.project_slug}`) && !window.location.pathname.includes(`/${props.projectSettings.project_slug}/`)))) {
			window.location.href = `/${props.projectSettings.project_slug}/${e.target.dataset.steptitle}`
		}
	}

	return (
		// Steps Icon (Icon status: Inactive, Active, Done)
		<div id={props.id}>
			<div className="list-group">
				<a
					id="buildingStep"
					className="list-group-item list-group-item-action pointer d-flex flex-lg-column flex-md-row stepsButton align-items-center text-center py-3 px-0 steps_menu__link complate-link current-link display-linebreak "
					data-step="1"
					data-steptitle="building_selection"
					onClick={localClick}
					data-gt-category='steps menu' data-gt-action='click' data-gt-label='step 1'
				>
					<img src="/assets/img/icons/side menu/ico-building.svg" className="mb-lg-1 mb-0 mx-3" alt={currentLanguge.stepsMenu.step1} width="30" height="30" />
					{currentLanguge.stepsMenu.step1}
				</a>
				<a
					id="floorStep"
					className="list-group-item list-group-item-action pointer d-flex flex-lg-column flex-md-row stepsButton align-items-center text-center py-3 px-0 steps_menu__link display-linebreak "
					data-step="2"
					data-steptitle="floor_selection"
					onClick={localClick}
					data-gt-category='steps menu' data-gt-action='click' data-gt-label='step 2'
				>
					<img src="/assets/img/icons/side menu/ico-floors.svg" className="mb-lg-1 mb-0 mx-3" alt={currentLanguge.stepsMenu.step2} width="30" height="30" />
					{currentLanguge.stepsMenu.step2}
				</a>
				<a
					id="previewStep"
					className="list-group-item list-group-item-action pointer d-flex flex-lg-column flex-md-row stepsButton align-items-center text-center py-3 px-0 steps_menu__link display-linebreak "
					data-step="3"
					data-steptitle="apartment_selection"
					onClick={localClick}
					data-gt-category='steps menu' data-gt-action='click' data-gt-label='step 3'
				>
					<img src="/assets/img/icons/side menu/ico-bullseye.svg" className="mb-lg-1 mb-0 mx-3" alt={currentLanguge.stepsMenu.step3} width="30" height="30" />
					{currentLanguge.stepsMenu.step3}
				</a>
				<a
					id="aptStep"
					className="list-group-item list-group-item-action pointer d-flex flex-lg-column flex-md-row stepsButton align-items-center text-center py-3 px-0 steps_menu__link display-linebreak "
					data-step="4"
					data-steptitle="apartment_details"
					onClick={localClick}
					data-gt-category='steps menu' data-gt-action='click' data-gt-label='step 4'
				>
					<img src="/assets/img/icons/side menu/ico-key.svg" className="mb-lg-1 mb-0 mx-3" alt={currentLanguge.stepsMenu.step4} width="30" height="30" />
					{currentLanguge.stepsMenu.step4}
				</a>
				<a
					id="signStep"
					className="list-group-item list-group-item-action pointer d-flex flex-lg-column flex-md-row stepsButton align-items-center text-center py-3 px-0 steps_menu__link display-linebreak "
					data-step="5"
					data-steptitle="approve_details"
					onClick={localClick}
					data-gt-category='steps menu' data-gt-action='click' data-gt-label='step 5'
				>
					<img src="/assets/img/icons/side menu/ico-signature.svg" className="mb-lg-1 mb-0 mx-3" alt={currentLanguge.stepsMenu.step5} width="30" height="30" />
					{currentLanguge.stepsMenu.step5}
				</a>
				<a
					id="paygStep"
					className="list-group-item list-group-item-action pointer d-flex flex-lg-column flex-md-row stepsButton align-items-center text-center py-3 px-0 steps_menu__link display-linebreak "
					data-step="6"
					data-steptitle="payment"
					onClick={localClick}
					data-gt-category='steps menu' data-gt-action='click' data-gt-label='step 6'
				>
					<img src="/assets/img/icons/side menu/ico-payment.svg" className="mb-lg-1 mb-0 mx-3" alt={currentLanguge.stepsMenu.step6} width="30" height="30" />
					{currentLanguge.stepsMenu.step6}
				</a>
				<a
					id="contractStep"
					className="list-group-item list-group-item-action pointer d-flex flex-lg-column flex-md-row stepsButton align-items-center text-center py-3 px-0 steps_menu__link display-linebreak "
					data-step="7"
					data-steptitle="meeting_schedule"
					onClick={localClick}
					data-gt-category='steps menu' data-gt-action='click' data-gt-label='step 7'
				>
					<img src="/assets/img/icons/side menu/ico-contract.svg" className="mb-lg-1 mb-0 mx-3" alt={currentLanguge.stepsMenu.step7} width="30" height="30" />
					{currentLanguge.stepsMenu.step7}
				</a>
				<a
					id="finishStep"
					className="list-group-item list-group-item-action pointer d-flex flex-lg-column flex-md-row stepsButton align-items-center text-center py-3 px-0 steps_menu__link display-linebreak "
					data-step="8"
					data-steptitle="finish"
					onClick={localClick}
					data-gt-category='steps menu' data-gt-action='click' data-gt-label='step 8'
				>
					<img src="/assets/img/icons/side menu/ico-hand-shake.svg" className="mb-lg-1 mb-0 mx-3" alt={currentLanguge.stepsMenu.step8} width="30" height="30" />
					{currentLanguge.stepsMenu.step8}
				</a>
			</div>
		</div>
	);
};

export default StepsMenu;
