import axios from "axios";
// import { setToken } from "../Redux/Reducers/verificationSlice";
import * as projectAction from "../Redux/Reducers/projectSlice";
import store, { persistor } from "../Redux/Store/store";

export const makePostRequest = async (url, data) => {
	const token = sessionStorage.getItem("forestToken");
	const postConfig = {
		baseURL: "https://staging.admin.realforest.com/frontapi",
		headers: {
			Accept: "application/json; charset=UTF-8",
			"Content-type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		},
	};
	// const request = await axios(postConfig)
	const request = await axios.post(url, data, postConfig).catch((err) => {
		//error handlerc
		console.log(err);
	});

	//handle login request
	if (!request) {
		return;
	}
	if (request.data.access_token && url == '/login' && request.status === 200) {
		sessionStorage.setItem("forestToken", request.data.access_token);
		store.dispatch(projectAction.setTokenIsSet(true));

		return request;
	} else {
		return request;
	}

};

export const makeGeneralPostRequest = async (baseURL, url, data) => {
	const postConfig = {
		baseURL: baseURL,
		headers: {
			Accept: "application/json; charset=UTF-8",
			"Content-type": "multipart/form-data"
		},
	};
	// const request = await axios(postConfig)
	const request = await axios.post(url, data, postConfig).catch((err) => {
		//error handlerc
		console.log(err);
	});

	//handle login request
	if (!request) {
		return;
	}

	return request;
};


export const makeGetRequest = async (url, slug) => {
	const token = sessionStorage.getItem("forestToken");

	const getConfig = {
		baseURL: `https://staging.admin.realforest.com/frontapi`,
		headers: {
			Accept: "application/json; charset=UTF-8",
			Authorization: `Bearer ${token}`,
		},
	};
	const request = await axios.get(`${url}/${slug}`, getConfig).catch((err) => {
		//error handler
		console.log(err);
	});
	return request;
};
export const makeGeneralGetRequest = async (baseURL, url) => {
	const getConfig = {
		baseURL: baseURL,
		headers: {
			Accept: "application/json; charset=UTF-8"
		},
	};
	const request = await axios.get(url, getConfig).catch((err) => {
		//error handler
		console.log(err);
	});
	return request;
};