import { handleTableAppartmentView } from "../../../../Helpers/AppartmentReserveUtils";
import { AppartmentStatus } from "../../ApartmentSelectionGraphic/Components";
import { ConvertCurrency } from "../../../../Helpers/ConvertCurrency";
import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";

const MobileAptComponent = ({ el }) => {
	
	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != 'undefined' ? ConvertCurrency(project_default_currency) : '';
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const defaultMetric = useSelector((state) => state.project.projectSettings.project_default_metric)
	const dynamicAppartmentsData = useSelector((state) => state.project.dynamicAppartmentsData);

	useEffect(() => { }, [currentLanguge]);

	const callbackedTask = useCallback(() =>
		handleTableAppartmentView({
			buildingID: el.buildingID,
			floorID: el.floorID,
			slug: el.id,
			id: el.shortID,
			buildingTitle: el.buildingNum,
			floorTitle: el.floorNum,
			aptTitle: el.aptNum,
			imageDesktop: el.desktopImage
		}, currentLanguge)
	);

	const MakeButon = () => {
		try {
			for (let obj of dynamicAppartmentsData) {
				if (obj.building_id == el.buildingID) {
					for (let floor of obj.floors) {
						if (floor.floor_id == el.floorID) {
							return floor.properties.map((pr) => {
								if (pr.property_slug === el.id) {
									if (!pr.status) {
										return (<></>);
									}
									if (pr.status === 1) {
										return (
											<button
												key={el.id}
												id={el.id}
												type="button"
												onClick={callbackedTask}
												className={`btn text-nowrap fw-bold fs-6 py-2 m-auto align-self-center col-12 tblAllowedBtn`}
												data-gt-category='table view' data-gt-action='click' data-gt-label='select apartment'
												data-apartment="<?=1200+$i?>"
											>
												{currentLanguge.apartmentSelectionTable.watchApt}
											</button>
										);
									}
									if (pr.status === 2) {
										return (
											<button
												key={el.id}
												id={el.id}
												onClick={callbackedTask}
												className={`btn text-nowrap fw-bold fs-6 py-2 m-auto align-self-center col-12 tblDisbaledBtn`}
												data-apartment="<?=1200+$i?>"
												disabled
											>
												{currentLanguge.apartmentStats.progressApt}
											</button>
										);
									}
									if (pr.status === 3) {
										return (
											<button
												key={el.id}
												id={el.id}
												onClick={callbackedTask}
												className={`btn text-nowrap fw-bold fs-6 py-2 m-auto align-self-center col-12 tblDisbaledBtn`}
												data-apartment="<?=1200+$i?>"
												disabled
											>
												{currentLanguge.apartmentStats.notAvailableApt}
											</button>
										);
									}
									if (pr.status === 4) {
										return (
											<button
												key={el.id}
												id={el.id}
												onClick={callbackedTask}
												className={`btn text-nowrap fw-bold fs-6 py-2 m-auto align-self-center col-12 tblDisbaledBtn`}
												data-apartment="<?=1200+$i?>"
												disabled
											>
												{currentLanguge.apartmentStats.notAvailableApt}
											</button>
										);
									}
								}
							});
						}
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
	};


	const CollapsedDetails = ({className, id}) => {
		return (
			<div className={`my-2 ${className}`} id={id}>

				<div className="collapsedDetails ">
					<div className="collapsedDetails__item row">
						<div className="col-6 fw-bold" style={{maxWidth: '160px'}}>{currentLanguge.apartmentSelectionTable.headerBuilding}</div>
						<div className="col-6">{el.buildingNum}</div>
					</div>
					<div className="collapsedDetails__item row">
						<div className="col-6 fw-bold" style={{maxWidth: '160px'}}>{currentLanguge.apartmentSelectionTable.headerFloor}</div>
						<div className="col-6">{el.floorNum}</div>
					</div>
					<div className="collapsedDetails__item row">
						<div className="col-6 fw-bold" style={{maxWidth: '160px'}}>{currentLanguge.apartmentSelectionTable.headerModel}</div>
						<div className="col-6">{el.model}</div>
					</div>

					<div className="collapsedDetails__item row">
						<div className="col-6 fw-bold" style={{maxWidth: '160px'}}>{currentLanguge.apartmentSelectionTable.headerBalconyTerrace}</div>
						<div className="col-6">{el.balconyTerrace} {currentLanguge.general.metric[defaultMetric]}</div>
					</div>
					<div className="collapsedDetails__item row">
						<div className="col-6 fw-bold" style={{maxWidth: '160px'}}>{currentLanguge.apartmentSelectionTable.headerGradenTerrace}</div>
						<div className="col-6">{el.gardenTerrace} {currentLanguge.general.metric[defaultMetric]}</div>
					</div>
					<div className="collapsedDetails__item row">
						<div className="col-6 fw-bold" style={{maxWidth: '160px'}}>{currentLanguge.apartmentSelectionTable.headerServiceTerrace}</div>
						<div className="col-6">{el.serviceTerrace} {currentLanguge.general.metric[defaultMetric]}</div>
					</div>
				</div>
			</div>
		)
	}

	try {
		let priceStringed = (() => parseInt(el.price).toLocaleString())();
		let oldPriceStringed = (() => parseInt(el.priceHever).toLocaleString())();
		return (
			<div className="col-12 d-flex flex-row justify-content-center p-0">
				<div className="col-auto flex-shrink-1 py-3">
					<a className="btn rf-btn-primary mobileTableCollapesed_trigger collapsed px-0 pde-1 pt-1"
						data-bs-toggle="collapse"
						data-bs-target={`#mobileTableCollapesed_${el.id}`}
						role="button" aria-expanded="false"
						aria-controls={`mobileTableCollapesed_${el.id}`}><i className="bi bi-caret-right-fill"></i>
					</a>

				</div>
				<div className="flex-grow-1 p-3 pds-0 position-relative">
					<a className="btn mobileTableCollapesed_trigger__overlay collapsed px-0 pde-1"
						data-bs-toggle="collapse"
						data-bs-target={`#mobileTableCollapesed_${el.id}`}
						role="button" aria-expanded="false"
						aria-controls={`mobileTableCollapesed_${el.id}`}>
					</a>
					<div className="col d-flex flex-row">
						<span className="f26 w700">{!isNaN(el.aptNum) ? currentLanguge.appartmentShort.apartment : ''} {el.aptNum}</span>
						<AppartmentStatus id={el.id} floorID={el.floorID} buildingID={el.buildingID} />
					</div>
					<div className="col d-flex flex-column mt-2 ">
						<div className="info_container d-flex flex-row flex-wrap">
							<div className="col-auto mde-4 info-item fw-bold pds-0 f20">
								<img src="/assets/img/icons/ico-rooms.svg" className="mde-2 pfIcon" alt={currentLanguge.appartmentShort.rooms} height="24" width="25" />
								{`${el.roomsNum} ${currentLanguge.appartmentShort.rooms}`}
							</div>
							<div className="col-auto mde-4 info-item fw-bold pds-0 f20">
								<img src="/assets/img/icons/ico-measuring-tape.svg" className="mde-2 pfIcon" alt={currentLanguge.general.metric[defaultMetric]} height="24" width="25" />
								{`${el.appartmentTerrace} ${currentLanguge.general.metric[defaultMetric]}`}
							</div>
							<div className="col-auto mde-4 info-item fw-bold pds-0 f20">
								<img src="/assets/img/icons/ico-sign.svg" className="mde-2 pfIcon" alt="" height="24" width="25" />
								{el.direction}
							</div>
						</div>
						<div className="col-auto mde-2 info-item fw-bold pds-0 f20 mt-2 d-flex flex-row flex-wrap">
							<img src="/assets/img/icons/ico-price.svg" className="mde-1 pfIcon" alt="" height="24" width="25" />
							<span className="old_price mde-4"> {`${currencySymbol}${oldPriceStringed}`}</span>
							<span className="mde-2">{`${currencySymbol}${priceStringed}`}</span>
						</div>
						<div className="collapse col-auto mde-4 info-item fw-bold pds-0 f20 mt-3 health" id={`mobileTableCollapse${el.id}`}>
							<ul className="card card-body d-flex flex-column p-0">
								<span className="col-5 d-flex justify-content-between">
									<span className=" f18 w700 align-self-start">{currentLanguge.appartmentShort.building}</span>
									<span className="f18 w500">{el.buildingNum}</span>
								</span>
								<span className="col-5 d-flex justify-content-between">
									<span className=" f18 w700 align-self-start">{currentLanguge.appartmentShort.floor}</span>
									<span className="f18 w500">{el.floorNum}</span>
								</span>
								<span className="col-5 d-flex justify-content-between">
									<span className=" f18 w700 align-self-start">{currentLanguge.appartmentShort.model}</span>
									<span className="f18 w500">{el.model}</span>
								</span>
								<span className="col-5 d-flex justify-content-between">
									<span className=" f18 w700 align-self-start">{currentLanguge.appartmentShort.balconyTerrace}</span>
									<span className="f18 w500">{el.balconyTerrace}{currentLanguge.general.metric[defaultMetric]}</span>
								</span>
							</ul>
						</div>

						<CollapsedDetails id={`mobileTableCollapesed_${el.id}`} className="col-12 collapse" />
						
						<MakeButon arr={el} />
					</div>
					<hr className="w-100 m-0 p-0 mt-3"></hr>
				</div>
			</div>
		);
	} catch (err) {
		console.log(err);
	}
};


export default MobileAptComponent;
