import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { makeGetRequest } from "../../../../Axios";

const Square = ({ status, idx }) => {
	if (status === "1") {
		return <span key={idx} className="floor-stat__available"></span>;
	}
	if (status === "2") {
		return <span key={idx} className="floor-stat__progress"></span>;
	}
	if (status === "3") {
		return <span key={idx} className="floor-stat__reserved"></span>;
	}
	if (status === "4") {
		return <span key={idx} className="floor-stat__reserved"></span>;
	}
};
class Square2 extends React.Component {
	constructor(props, status, idx) {
		super(props);
		this.status = props.status;
		this.idx = props.idx;
	}
	shouldComponentUpdate(nextProps) {
		if (nextProps.status !== this.status) {
			return true;
		} else {
			return false;
		}
	}
	render() {
		if (this.status === "1") {
			return <span key={this.idx} className="floor-stat__available"></span>;
		}
		if (this.status === "2") {
			return <span key={this.idx} className="floor-stat__progress"></span>;
		}
		if (this.status === "3") {
			return <span key={this.idx} className="floor-stat__reserved"></span>;
		}
		if (this.status === "4") {
			return <span key={this.idx} className="floor-stat__reserved"></span>;
		}
	}
}
const FloorIndicators = memo(({ buildingID, floorID }) => {
	const dynamicAppartmentsData = useSelector((state) => state.project.dynamicAppartmentsData);
	const [trigger, setTrigger] = useState(false);

	const [data, setData] = useState([]);
	// const [getNewData, setNewData] = useState(false);

	useEffect(async () => {
		try {
			let res = [];
			for (let obj of dynamicAppartmentsData) {
				if (obj.building_id == buildingID) {
					for (let floor of obj.floors) {
						if (floor.floor_id == floorID) {
							floor.properties.map((el) => {
								if (el.status === 1) {
									res.push("1");
									// return <span className="floor-stat__available"></span>;
								}
								if (el.status === 2) {
									res.push("2");

									// return <span className="floor-stat__progress"></span>;
								}
								if (el.status === 3) {
									res.push("3");

									// return <span className="floor-stat__reserved"></span>;
								}
								if (el.status === 4) {
									res.push("4");
									// return <span className="floor-stat__reserved"></span>;
								}
							});
						}
					}
				}
			}
			setData(res);
		} catch (err) {
			console.log(err);
		}
	}, []);

	
	let Indicatores = memo(({ data }) => {
		if (!data) {
			return null;
		}

		return (
			<div className="d-flex align-items-center" id="floorIndicators">
				{data.map((el, idx) => (
					// <Square status={el} key={idx} />
					<Square2 status={el} key={idx} />
				))}
			</div>
		);
	}, (prev, next) => {
		return true;
	});

	return <Indicatores data={data} />;
});

export default memo(FloorIndicators, (prev, next) => {
	return true;
});
