import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const ProcessModal = ({ isCharging, data }) => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const meetingSlug = useSelector((state) => state.verification.slug);

	const [show, setShow] = useState(false);
	const [msg, setMsg] = useState(false);
	
	const stripe_onoff = useSelector((state) => state.project.projectSettings.stripe_onoff);
	const tranzilla_onoff = useSelector((state) => state.project.projectSettings.tranzilla_onoff);

	useEffect(() => { }, [currentLanguge]);
	useEffect(() => {
		if (isCharging) {
			setShow(true);
		}
		if (!isCharging) {
			setShow(false);
		}
	}, [isCharging, data]);

	useEffect(() => {
		const getUrlParams = new URLSearchParams(window.location.search);
		const agentKey = getUrlParams.get("agentkey");
		const isAgent = agentKey != '' && agentKey != null ? true : false;
		if (meetingSlug && isAgent && stripe_onoff == '1') {
			if (data.error) {
				if (data.error.type === "card_error" || data.error.type === "validation_error"|| data.error.type === "invalid_request_error") {
				  setMsg(data.error.message);
				} else {
				  setMsg(currentLanguge.formsErrors.generalPaymentError);
				}
			} else if (data.paymentIntent) {
				if (data.paymentIntent.status == 'succeeded') {
					setMsg(currentLanguge.payment_step.paymentSuccessfulTitle);
				} else if (data.paymentIntent.status == 'processing') {
					setMsg(currentLanguge.payment_step.paymenyProcessModalText);
				} else if (data.paymentIntent.status == 'requires_payment_method') {
					setMsg(currentLanguge.payment_step.paymentUnsuccessfulMsg);
				} else {
					setMsg(currentLanguge.formsErrors.tryAgain);
				} 
			} else {
				setMsg(currentLanguge.payment_step.paymenyProcessModalText);
			}
		} else if (meetingSlug && isAgent && tranzilla_onoff == '1') {
			if (data.error) {
				data.error.messages.map((message) => {
					if (message.code === "credit_card_number_invalid") {
						setMsg(message.message);
					}
					if (message.code === "expiry_invalid") {
						setMsg(message.message);
					}
					if (message.code === "cvv_invalid") {
						setMsg(message.message);
					}
					if (message.code === "card_holder_id_number_invalid") {
						setMsg(message.message);
					}
				});
			} else if (data.response) {
				if (data.response.transaction_response.success === true) {
					setMsg(currentLanguge.payment_step.paymentSuccessfulTitle);
				} else if (data.response.transaction_response.success === false) {
					setMsg(data.response.transaction_response.error);
				} else {
					setMsg(currentLanguge.formsErrors.tryAgain);
				} 
			} else {
				setMsg(currentLanguge.payment_step.paymenyProcessModalText);
			}
		} else {
			setMsg(currentLanguge.payment_step.paymenyProcessModalText);
		}
	}, [data]);

	const agentCloseBtn = () => {
		const getUrlParams = new URLSearchParams(window.location.search);
		const agentKey = getUrlParams.get("agentkey");
		const isAgent = agentKey != '' && agentKey != null ? true : false;

		if (meetingSlug && isAgent) {
			return (
				<button className='btn rf-btn-primary btn-sm align-self-center' onClick={() => setShow(false)}>
					{currentLanguge.payment_step.paymenyPopupClose}
				</button>
			);
		} 
		return;
	}

	return (
		<Modal centered show={show}>
			<Modal.Header>
				<Modal.Title className="align-self-center">{currentLanguge.payment_step.paymenyProcessModalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex justify-content-center">
				<span id="ressSpan">{msg}</span>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-center">
				{agentCloseBtn()}
			</Modal.Footer>
		</Modal>
	);
};

export default ProcessModal;
