import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./ApartmentSelection3D.sass";
import $ from "jquery";
import { setInited3DView } from "../../../Helpers/AppartmentReserveUtils";

const ApartmentSelection3D = (props) => {
    const show3DView = useSelector((state) => state.project.show3DView);
    const inited3DView = useSelector((state) => state.project.inited3DView);

    const isMobile = useSelector((state) => state.project.isMobile);

    const currentLanguge = useSelector((state) => state.project.currentLanguge);
	const project_3dapikey = useSelector((state) => state.project.projectSettings.project_3dapikey)
    

    
	const hide_3dView_desktop = () => {
        $('.view3DViewButton').removeClass('activeView');
        $('#apartment_selection__3d').addClass('show3DView_hidden');
        $('.apartment_reserve__stepContainer').show();
    }
    
    const hide_3dView_mobile = () => {
        $('.view3DViewButton').removeClass('activeView');
        $('._3desm__app').fadeOut();
	}

	const show_3dView_mobile = () => {
        $('.view3DViewButton').addClass('activeView');
        $('._3desm__app').fadeIn();
        $('._3desm__mobile-placeholder__button').trigger('click');
	}

    
	const show_3dView_desktop = () => {
        $('.view3DViewButton').addClass('activeView');
        $('#apartment_selection__3d').removeClass('show3DView_hidden');
        $('._3desm__mobile-placeholder__button').trigger('click');
        $('.apartment_reserve__stepContainer').hide();
	}
	// const setHistoryAfter3D = () => {
	// 	if (window.stepHistory.length <= 2) {
	// 		setCurrentStep(null, "building_selection", true);
	// 	} else {
	// 		backURLHistory(pre3DviewURL);
	// 		let newStep_title = getObjKey(stepsDictionary, parseFloat(pre3DviewStep));
	// 		if(newStep_title && newStep_title != '') setCurrentStep(null, newStep_title, true);
	// 	}
	// }


    useEffect(() => { }, [currentLanguge]);
    
    useEffect(() => { 
        if (!project_3dapikey || project_3dapikey == '') return;

        if (show3DView === true && !isMobile) {
            show_3dView_desktop();
        } else if (show3DView === false && !isMobile) {
            hide_3dView_desktop();
        } else if (show3DView === true && isMobile) {
            show_3dView_mobile();
        } else if (show3DView === false && isMobile) {
            hide_3dView_mobile();
        }
    }, [show3DView, project_3dapikey]);

    useEffect(() => { 
        if (!project_3dapikey || project_3dapikey == '') return;
        
        let settings;
        if (show3DView === true && !isMobile) {
            settings = {
                showPrice: true,
                defaultFlatListCards: true,
                defaultHideFiltersStateTrue: true,
                hide3DEstateLogo: true,
                disableShareButton: true,
                hideFlatsCounter: true
            };
            if (currentLanguge.language == 'heb') settings.forceLang = 'he';
        } else if (show3DView === true && isMobile) {
            settings = {
                showPrice: true,
                defaultFlatListCards: true,
                defaultHideFiltersStateTrue: true,
                hide3DEstateLogo: true,
                disableShareButton: true,
                hideFlatsCounter: true,
                autoOpenOnMobile: true
            };
            if (currentLanguge.language == 'heb') settings.forceLang = 'he';
        }


        if (show3DView === true && !inited3DView) {
            $('#root3dEstate').empty();
            $('#topLevelElement_3destate_smart_makieta').remove();
            window.SM.init(project_3dapikey, 'root3dEstate', {}, settings)
            setInited3DView(true);
        }
    }, [show3DView, isMobile, project_3dapikey, currentLanguge]);

	
	return (
		<div className="show3DView_hidden" id="apartment_selection__3d">
			{/* Step Content  */}
			<div className="step_content px-3 py-4">
                <div id="root3dEstate"></div>
			</div>
		</div>
	);
};

export default ApartmentSelection3D;
