import { makeGetRequest } from "../../Axios";
import { persistor, store } from "../../Redux/Store/store";
import * as actions from "../../Redux/Reducers/projectSlice";
import moment from "moment";
import {
	setAgentEmail,
	setAgentLasttname,
	setAgentFirstname,
	setRoomSpaceID,
	setRequestSkipSign,
	setRequestSkipPay,
	setAgentID,
	setUserId,
	setIsAgent,
} from "../../Helpers/MeetingsUtils";
import { setMeetingStartAt, setProjectID, setSlug } from "../verificationUtils";
import {
	setColorsThemes,
	setIsLoading,
	setProjectSettingsObject,
	setLoadingModalContent,
	setIsChangingLang
} from "../AppartmentReserveUtils";


export const setProjectSettings = (projectObj) => {
	let parseNumber = (num) => {
		if (num === "0") {
			return false;
		}
		if (num === "1") {
			return true;
		}
	};

	let project_default_language = projectObj.project_default_language;
	let project_contract_pdf = projectObj.project_contract_pdf;
	let project_reservationsample_pdf = projectObj.project_reservationsample_pdf;
	let project_tranzila_tranmode = projectObj.project_tranzila_tranmode;
	let project_tranzila_account = projectObj.project_tranzila_account;
	let project_reservation_price = projectObj.project_reservation_price;
	let project_sales_phone = projectObj.project_sales_phone;
	let project_starting_price = projectObj.project_starting_price;
	let meeting_duration = projectObj.meeting_duration;
	let show_timer_after_min = projectObj.show_timer_after_min;
	let url_endof_reservation = projectObj.url_endof_reservation;
	let enable_edit_onagreement = parseNumber(projectObj.enable_edit_onagreement);
	let agreement_text = projectObj.project_agreement_termspdf;
	let featuresListJson = projectObj.featuresListJson;
	
	store.dispatch(actions.setProjectContractPdfExample(project_contract_pdf));
	store.dispatch(actions.setProjectReservePdfExample(project_reservationsample_pdf));
	store.dispatch(actions.setProjectReservationPrice(project_reservation_price));
	store.dispatch(actions.setProjectSalesPhone(project_sales_phone));
	store.dispatch(actions.setProjectStartingPrice(project_starting_price));
	store.dispatch(actions.setMeetingDuration(meeting_duration));
	store.dispatch(actions.setProjectAgreementText(agreement_text));

	store.dispatch(actions.setEndOfReservationLink(url_endof_reservation));

	store.dispatch(actions.setShowTimerAfterMin(show_timer_after_min));
	store.dispatch(actions.setProjectFeauturesList(featuresListJson));
	store.dispatch(actions.setEnableEditAgreement(enable_edit_onagreement));
	store.dispatch(actions.setIsProjectLanded(true));
	store.dispatch(actions.setProjectTranzilaAccount(project_tranzila_account));
	store.dispatch(actions.setTransactionMode(project_tranzila_tranmode));
	store.dispatch(actions.setCurrentLangauge(project_default_language));
};

export const setMeetingSettings = (meetingObj, agentObj, clientDetails) => {
	let parseNumber = (num) => {
		if (num === "0") {
			return false;
		}
		if (num === "1") {
			return true;
		}
	};
	setRoomSpaceID(meetingObj.roomspaceid);
	setSlug(meetingObj.roomspaceid);
	setRequestSkipPay(parseNumber(meetingObj.request_of_skip_pay == 0 ? false : true));
	setRequestSkipSign(parseNumber(meetingObj.request_of_skip_sign == 0 ? false : true));
	setUserId(meetingObj.userclient_id);
	setIsAgent(meetingObj.isAgent);
	const timeFormat = "YYYY-MM-DD HH:mm:ss";
	let timezone = store.getState().project.timezone;
	let meetingTimeParsed = moment.utc(meetingObj.meeting_start_datetime).tz(timezone).format(timeFormat);
	setMeetingStartAt(meetingTimeParsed);
	setProjectID(meetingObj.project_slug);

	if (agentObj) {
		setAgentEmail(agentObj.email);
		setAgentLasttname(agentObj.first_name);
		setAgentFirstname(agentObj.last_name);
		setAgentID(meetingObj.salesagent_id);
	}
};

const ProjectLandingInit = async(slug, fromRegister = false) => {
	try {
		console.log("Loading Project Data...");

		if (!slug) {
			console.log("error in project ProjectLandingInit");
			return;	
		} 

		let langCode = sessionStorage.getItem("langCode");
		if (!langCode || langCode == '') {
			langCode = '';
		} else {
			langCode = '/' + langCode;
		}

		setProjectID(slug);
		const projectReq = await makeGetRequest("/projectlanding", `${slug}${langCode}`);
 
		if (!projectReq) {
			console.log("error in project req");
			return;
		}

		let projectObj = projectReq.data.projectinfo;

		if(langCode == '') setLanguage(projectObj.project_default_language);

		setProjectSettingsObject(projectObj);
		persistor.flush();

		const colors_theme = {
			mainColor: '#1562E3',
			secondaryColor: '#1aa0d5',
			darkBg: '#081d43',
			textDark: '#081d43',
			textLight: '#fff',
			textDarkest: '#000'
		}
		setColorsThemes(colors_theme);

		if (projectObj.skip_landing_page == 1 && !fromRegister && getWithExpiry('siteAuthorization_expiry')) {
			window.location.href = `/${slug}/building_selection`;
		}

		return projectReq;
	} catch (err) {
		console.log(err);
	}
};

export default ProjectLandingInit;


export const projectLandingShort = async (slug) => {
	try {
		console.log("Loading Project Data...");

		if (!slug) {
			console.log("error in project projectLandingShort");
			return;	
		} 

		setProjectID(slug);
		const projectReq = await makeGetRequest("/projectlanding", slug);
		if (!projectReq) {
			console.log("error in project req");
			return;
		}

		let projectObj = projectReq.data.projectinfo;
		setProjectSettingsObject(projectObj);
		setIsLoading(true);

		return false;
	} catch (err) {
		console.log(err);
	}
};


export const setLanguage = async (value) => {
	try {
		
		store.dispatch(actions.setCurrentLangauge(value));
		const slug = store.getState().project.projectID;
		if (!slug || !value) {
			console.log("error in project setLanguage");
			return;	
		} 

		const projectReq = await makeGetRequest("/projectlanding", `${slug}/${value}`);	
		if (!projectReq) {
			console.log("error in project req");
			return;
		}

		let projectObj = projectReq.data.projectinfo;

		setProjectSettingsObject(projectObj);
		persistor.flush();
		setLoadingModalContent(false);
		setIsChangingLang(false);
	} catch (err) {
		console.log(err);
	}
	

};

export const getWithExpiry =(key) => {
	const itemStr = localStorage.getItem(key)

	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}

	const item = JSON.parse(itemStr)
	const now = new Date()

	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}
	