import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./MeetingTimerBar.sass";
import moment from "moment";
import $ from "jquery";
import ToastMessage from "../../General/ToastMessage/ToastMessage"
import { sendMeetingEnd } from "../../../Helpers/AppartmentReserveUtils";
import { setMeetingStatus } from "../../../Helpers/MeetingsUtils";

const MeetingTimerBar = memo((props) => {
	const meetingStart = useSelector((state) => state.verification.meetingStartAt);
	const showTimerAfter = useSelector((state) => state.project.projectSettings.show_timer_after_min);
	const [walla, setwalla] = useState(0);
	const [time, settime] = useState(0);
	const [isIntervalSet, setIsIntervalSet] = useState(false);
	const projectSettings = useSelector((state) => state.project.projectSettings);
	const meetingStatus = useSelector((state) => state.meeting.meetingStatus);
	const currentLanguge = useSelector((state) => state.project.currentLanguge);
	
	var interval;
	let toastViewed = false;

	useEffect(() => { }, [currentLanguge]);

	useEffect(() => {
		try {
			const meeting_duration = parseInt(projectSettings.meeting_duration);
			// const meeting_duration = 5;
			const timeFormat = "YYYY-MM-DD HH:mm:ss";

			let momentTimeObj = moment(meetingStart);
			const meetingStartTime = momentTimeObj;
			const meetingEndtTime = meetingStartTime.clone().add(meeting_duration, "minutes");
			let endDateFormatted = meetingEndtTime.format(timeFormat);
			let endTime = moment(endDateFormatted);

			const nowTime = new Date().getTime();
			let now = moment(nowTime);
			let thediff = endTime.diff(now);
			let difference = moment(thediff).get("minutes");

			let timeInSeconds = difference * 60;
			settime(timeInSeconds);
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		if (!meetingStatus) {
			return;
		}
		try {
			if (isIntervalSet) {
				return;
			}
			setwalla((prev) => prev + 1);

			if (time > 0) {
				let hereTime = time;
				setIsIntervalSet(true);
				interval = setInterval(() => {
					const meeting_duration = parseInt(projectSettings.meeting_duration);
					const endMeetingAlertTime = parseInt(projectSettings.meeting_duration) - 10;
					
					let minutesSec = hereTime--;
					settime(minutesSec);

					let cutted = minutesSec / 60;
					let rounded = Math.round(cutted);

					// show timer after showTimerAfter
					if (meeting_duration - rounded > showTimerAfter) {
						let elem = document.getElementById("meetingTimerBar");
						if (elem !== undefined || elem !== null) {
							if ($("#meetingTimerBar").hasClass("d-none")) {
								$("#meetingTimerBar").removeClass("d-none");
							}
						}
					}
					if (meeting_duration - rounded == endMeetingAlertTime && !toastViewed) {
						var toastLiveExample = document.getElementById('liveToast');
						var toast = new window.bootstrap.Toast(toastLiveExample);
						toast.show();
						toastViewed = true;
					}

					// display timer
					let hourVal = parseInt(minutesSec / 3600);
					let minutesVal = parseInt((minutesSec - hourVal * 3600) / 60);
					let secVal = parseInt(minutesSec - hourVal * 3600 - minutesVal * 60);

					let hourText = hourVal <= 0 ? '00' : hourVal < 10 ? `0${hourVal}` : hourVal;
					let minutesText = minutesVal <= 0 ? '00' : minutesVal < 10 ? `0${minutesVal}` : minutesVal;
					let secText = secVal <= 0 ? '00' : secVal < 10 ? `0${secVal}` : secVal;
					$('#timebarContent').html(`${hourText}:${minutesText}:${secText}`)


					if (meeting_duration - rounded <= 0) {
						onEndCounter();
						clearInterval(interval);
					}
				}, 1000);
			}
		} catch (err) {
			console.log(err);
		}
		return () => {
			settime(0);
		};
	}, [time]);

	const onEndCounter = () => {
		const meeting_duration = parseInt(projectSettings.meeting_duration);
		if (meeting_duration > 0) {
			const meeting_duration = parseInt(projectSettings.meeting_duration);
			const meetingUtc = meetingStart;
			const timeFormat = "YYYY-MM-DD HH:mm:ss";
			const meetingStartTime = moment(meetingUtc);
			const meetingEndtTime = meetingStartTime.clone().add(meeting_duration, "minutes");
			let endDateFormatted = meetingEndtTime.format(timeFormat);

			const nowTime = new Date().getTime();
			let now = moment(nowTime);

			if (now.isAfter(endDateFormatted)) {
				sendMeetingEnd().then((res) => {
					setMeetingStatus(false);
					const localVideo = document.querySelectorAll('#localVideo video');
					let tracks = localVideo.srcObject.getTracks();

					tracks.forEach((track) => {
						track.stop();
					  });
					
					localVideo.srcObject = null;
				});
			}
		}
	};

	return (
		<>
			<div className="d-flex flex-column d-none" id="meetingTimerBar">
				<div className={`d-flex flex-column align-items-center justify-content-center`} role="alert" style={{ height: "50px" }} id="timeSetBar">
					<p className="p-0 m-0"  style={{opacity: '0.7'}}>{currentLanguge.findAgentComp.timeLeft} </p>
					<span className="align-self-center pfMeetingBarFont f20" id="timebarContent">
					</span>
				</div>
			</div>
			<ToastMessage title={currentLanguge.findAgentComp.toastTitle} msg={currentLanguge.findAgentComp.toastMsg} />
		</>
	);
});

export default MeetingTimerBar;
