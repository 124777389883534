const Button = props => {
  if (props.dataGtCategory && props.dataGtAction && props.dataGtLabel) {
    return (
      <button
        id={props.id}  
        className={`btn ${props.className}`}
        type={props.type}
        onClick={props.clickCTA}
        data-gt-category={props.dataGtCategory} data-gt-action={props.dataGtAction} data-gt-label={props.dataGtLabel} 
        alt={props.alt}>{props.children}</button>
    )
  }
  return (
    <button
        id={props.id}  
        className={`btn ${props.className}`}
        type={props.type}
        onClick={props.clickCTA}
        alt={props.alt}>{props.children}</button>
  );
}

export default Button;
