import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./VideoLoadingModal.sass";

const VideoLoadingModal = () => {
    const showVideoLoadingModal = useSelector((state) => state.project.showVideoLoadingModal)
    const videoLoadingModalMessage = useSelector((state) => state.project.videoLoadingModalMessage)

	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(() => showVideoLoadingModal);
	}, [showVideoLoadingModal, videoLoadingModalMessage]);

	return (
		<div id="loadingVideoModal" className={`d-flex mx-auto flex-column justify-content-center position-absolute zindexon ${show ? "" : "d-none"}`}>
			<div className="col d-flex align-self-center flex-column justify-content-center position-relative">
				<div className="align-self-center text-white w500">{videoLoadingModalMessage}</div>
				<div className={`lds-default align-self-center ${showVideoLoadingModal == 'alright' ? 'd-none' : ''}`}>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default VideoLoadingModal;
