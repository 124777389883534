import { memo, useEffect } from "react";
import FloorIndicators from "./FloorIndicators";
import { useSelector } from "react-redux";
import { ConvertCurrency } from "../../../Helpers/ConvertCurrency";
import GTEvent from "../../../Helpers/GTEvent";

const Floor = memo(({title,id,rooms,price,handleFloorSelect,onFloorClick,idx,floorImg,buildingID}) => {
	useEffect(() => {}, [floorImg]);
	let priceStringed = (() => parseInt(price).toLocaleString())();
	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != 'undefined' ? ConvertCurrency(project_default_currency) : '';
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	useEffect(() => { }, [currentLanguge]);

	const roomsString = (rooms) => {
		if (rooms != '') {
			let trimmed = rooms.split(",").map((el) => el.trim()).sort((a, b) => a - b);
			return [...new Set(trimmed)].join(',');
		} else return rooms;
	}
	
	const onFloorClickLocal = (e) => {
		onFloorClick(e);
		GTEvent(e)
	}

	const handleFloorSelectLocal = (e, title, floorImg, rooms, currentLanguge) => {
		handleFloorSelect(e, title, floorImg, rooms, currentLanguge)
		GTEvent(e)
	}

	return (
		<div className="list-group-item d-flex floors_list__item info_container mb-1 pfFloorBborder position-relative flashit appear px-2" data-floor={idx} id={id}>
			<div className="w-100 h-100 position-absolute top-0 elemIndex" onClick={onFloorClickLocal} data-flid={"a" + id}
				data-gt-category='floor selection' data-gt-action='click' data-gt-label='preview floor'></div>

			<div className="d-flex flex-nowrap">
				<h2 className=" m-0 f24 fw-bold pfTextColor" style={{width: '26px'}}>
					{title}
				</h2>
				<div className="col-auto costumeFloorMargin floor-stats align-items-baseline mx-2" style={{ paddingTop: "6px"}}>
					{/* {children} */}
					<FloorIndicators buildingID={buildingID} floorID={[id]} />
				</div>
			</div>

			<div className="d-flex flex-wrap">
				<div className="col-auto costumeFloorMargin h-50 align-self-center info-item  pds-0 floorInfoFont">
					<img src="/assets/img/icons/ico-rooms.svg" className="mde-2 pfIcon" alt={currentLanguge.floorSelection_step.rooms} height="23" width="24" />
					{`${roomsString(rooms)} ${currentLanguge.floorSelection_step.rooms}`}
				</div>
				<div className="col-auto h-auto align-self-center costumeFloorMargin info-item pds-0 floorInfoFont">
					<img src="/assets/img/icons/ico-price.svg" className="mde-2 pfIcon" alt={currentLanguge.general.priceStartFrom} height="23" width="24" />
					{` ${currentLanguge.general.priceStartFrom} ${currencySymbol}${priceStringed}`}
				</div>
			</div>
			
			<button
				id={id}
				data-ftitle={title}
				type="button"
				data-gt-category='floor selection' data-gt-action='click' data-gt-label='select floor'
				className="floors_list__item-cta btn rf-btn-primary mds-auto pfButton m-0 pfSubmitBtnColor disappear floorButtonFont elemIndex"
				onClick={(e) => handleFloorSelectLocal(e, title, floorImg, rooms, currentLanguge)}
			>
				{currentLanguge.floorSelection_step.stepCTA}
			</button>
		</div>
	);
});

export default Floor;
