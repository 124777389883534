import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setRemoteAction } from "../../../Helpers/AppartmentReserveUtils";
import "./roomFilter.sass";
import { makeGetRequest } from "../../../Axios";
import $ from "jquery";

const RoomsFilter = ({ costumeOnChange, buildingID, classes }) => {
	const roomsObj = useSelector((state) => state.project.roomsObj);
	const step = useSelector((state) => state.verification.currentStep);
	const meetingSlug = useSelector((state) => state.verification.slug);
	const isAgent = useSelector((state) => state.meeting.isAgent);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);

	const [localRooms, setLocalRooms] = useState([]);
	const filterEventName = "filterFChange";

	useEffect(() => {
		
		roomsObj.map((el) => {
			if (el.id === buildingID) {
				let splitted = el.rooms.split(",");
				let trimmed = splitted.map((el) => el.trim());
				trimmed.sort(function (a, b) {
					return a - b;
				});
				setLocalRooms(trimmed);
			}
		});
	}, [step]);

	const handleChange = (e) => {
		e.preventDefault();
		if (meetingSlug) {
			setRemoteAction({ eventName: filterEventName, payload: e.target.value, author: isAgent });
		}
		costumeOnChange(e.target.value);
		$('.roomSelectTitle').html(e.target.innerHTML)
	};


	return (
		<div className={`dropdown mt-3 mb-3 floor_filters col-12 jusify-content-center ${classes ? classes : ''}`}>
			<button
				className="dropdown-toggle pfContainerShadow f18 w500 py-2 col-12 rounded"
				aria-label="form-select-md example"
				role="button"
				id="roomFilter"
				type="button"
				data-bs-toggle="dropdown"
				aria-expanded="false"
				data-gt-category='floor rooms filter' data-gt-action='click' data-gt-label='toggle dropdown'
			>
				<img src="/assets/img/icons/ico-rooms.svg" className="pfIcon mde-2 mds-2" height="23" width="25" alt={currentLanguge.apartmentSelectionTable.headerRooms} />
				<span className="mde-1 roomSelectTitle" id="roomSelectTitle">
					{currentLanguge.formsFields.roomsFilter_all}
				</span>
			</button>
			<ul className="dropdown-menu m-0 p-0 col-12 mde-1" aria-labelledby="roomFilter">
				<li className="dropdown-item fw-bold" onClick={handleChange} key="" value=""
					data-gt-category='floor rooms filter' data-gt-action='click' data-gt-label='set filter all'>
					{currentLanguge.formsFields.roomsFilter_all}
				</li>
				{localRooms.map((el, idx) => {
					return (
						<li className="dropdown-item" onClick={handleChange} key={el} value={el}
							data-gt-category='floor rooms filter' data-gt-action='click' data-gt-label={`set filter ${el}`}>
							{`${el} ${currentLanguge.formsFields.roomsFilter_suffix}`}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default RoomsFilter;

const AptRoomFilter = ({ costumeOnChange, floorID, classes }) => {
	//get rooms of current floor

	const meetingSlug = useSelector((state) => state.verification.slug);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const buildingID = useSelector((state) => state.project.buildingID);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);
	
	const [localRooms, setLocalRooms] = useState([]);
	const filterEventName = "filterFChange";

	useEffect(() => {
		const requestFloors = async () => {
			try {
				const req = await makeGetRequest("/buildingfloors", buildingID);
				if (req) {
					if (req.data.message) {
						let roomsObj = req.data.message.map((el) => {
							return {
								id: el.id,
								rooms: el.roomsinfo,
							};
						});
						roomsObj.map((el) => {
							if (el.id === floorID) {
								// let splitted = el.rooms.split(",");
								const splitted = Array.from(new Set(el.rooms.split(",")))
								let trimmed = splitted.map((el) => el.trim());
								trimmed.sort(function (a, b) {
									return a - b;
								});
								setLocalRooms(trimmed);
							}
						});
					}
				}
			} catch (err) {
				console.log(err);
			}
		};

		if(buildingID && floorID) requestFloors();

		
	}, [floorID, buildingID]);

	const handleChange = (e) => {
		e.preventDefault();
		if (meetingSlug) {
			setRemoteAction({ eventName: filterEventName, payload: e.target.value, author: isAgent });
		}
		costumeOnChange(e.target.value);
		$('.roomSelectTitle').html(e.target.innerHTML)
	};

	return (
		<div className={`dropdown mt-3 mb-3 floor_filters col-12 jusify-content-center ${classes ? classes : ''}`}>
			<button
				className="dropdown-toggle pfContainerShadow f18 w500 py-2 col-12 rounded"
				aria-label="form-select-md example"
				role="button"
				id="roomFilter"
				type="button"
				data-gt-category='apartment rooms filter' data-gt-action='click' data-gt-label='toggle dropdown'
				data-bs-toggle="dropdown"
				aria-expanded="false"
			>
				<img src="/assets/img/icons/ico-rooms.svg" className="pfIcon mde-2 mds-2" height="23" width="25" alt={currentLanguge.apartmentSelectionTable.headerRooms} />
				<span className="mde-1 roomSelectTitle" id="roomSelectTitle">
					{currentLanguge.formsFields.roomsFilter_all}
				</span>
			</button>
			<ul className="dropdown-menu m-0 p-0 col-12 mde-1" aria-labelledby="roomFilter">
				<li className="dropdown-item fw-bold" onClick={handleChange} key="" value=""
					data-gt-category='floor rooms filter' data-gt-action='click' data-gt-label='set filter all'>
					{currentLanguge.formsFields.roomsFilter_all}
				</li>
				{[...new Set(localRooms)].map((el, idx) => {
					return (
						<li className="dropdown-item" onClick={handleChange} key={el} value={el}
							data-gt-category='floor rooms filter' data-gt-action='click' data-gt-label={`set filter ${el}`}>
							{`${el} ${currentLanguge.formsFields.roomsFilter_suffix}`}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export { AptRoomFilter };
