import React, { Component } from "react";
import GoogleMap from "./GoogleMap";
import MapMarker from "./MapMarker";
import "./HomeMap.sass";
import store from "../../../Redux/Store/store";

class HomeMap extends Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.projectLocation = store.getState().project.projectSettings.project_location;

		let cor1 = '', cor2 = '';
		if (this.projectLocation && this.projectLocation != '') {
			let splitted = this.projectLocation.split(",");
			cor1 = parseFloat(splitted[0]);
			cor2 = parseFloat(splitted[1]);	
		}

		this.state = {
			places: [],
			projectLocation: [cor1, cor2],
		};
	}
	componentDidMount() {

		fetch("places.json")
			.then((response) => response.json())
			.then((data) => {
				data.results.forEach((result) => {
					result.show = true; // eslint-disable-line no-param-reassign
				});
				this.setState({ places: data.results });
			});
	}

	shouldComponentUpdate(nextProps) {
		if (true) {
			return true;
		} else {
			return false;
		}
	}
	// onChildClick callback can take two arguments: key and childProps
	onChildClickCallback = (key) => {
		this.setState((state) => {
			const index = state.places.findIndex((e) => e.id === key);
			state.places[index].show = !state.places[index].show; // eslint-disable-line no-param-reassign
			return { places: state.places };
		});
	};

	render() {
		const { places } = this.state;

		this.projectLocation = this.props.projectSettings.project_location;

		if (!this.projectLocation || this.projectLocation == '') return ('');

		let splitted = this.projectLocation.split(",");
		let cor1 = parseFloat(splitted[0]);
		let cor2 = parseFloat(splitted[1]);
		
		this.state.projectLocation = [cor1, cor2];

		if (isNaN(this.state.projectLocation[0]) || isNaN(this.state.projectLocation[1])) return null;
		
		return (
			<GoogleMap
				defaultZoom={10}
				defaultCenter={this.state.projectLocation}
				bootstrapURLKeys={{ key: "AIzaSyAGecYBtwfXbyJ_7rpU0vaXcWhqi7O2Yts" }}
				onChildClick={this.onChildClickCallback}
				style={{direction: 'rtl'}}
			>
				{places.map((place) => (
					<MapMarker
						key={place.id}
						lat={this.state.projectLocation[0]}
						lng={this.state.projectLocation[1]}
						show={place.show}
						place={place}
						logo={this.props.projectSettings.project_logo}
					/>
				))}
			</GoogleMap>
		);
	}
}
export default HomeMap;
