import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { handleTableAppartmentView } from "../../../../Helpers/AppartmentReserveUtils";
import { ConvertCurrency } from "../../../../Helpers/ConvertCurrency";

const AppartmentLine = ({
	aptNum,
	buildingNum,
	floorNum,
	roomsNum,
	model,
	appartmentTerrace,
	balconyTerrace,
	gardenTerrace,
	serviceTerrace,
	price,
	priceHever,
	direction,
	id,
	idx,
	handleAppartmentPreview,
	floorID,
	buildingID,
	shortID,
	price1,
	price2,
	price3,
	price4,
	clientType,
	imageDesktop
}) => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const callbackedTask = useCallback(() =>
		handleTableAppartmentView({
			buildingID: buildingID,
			floorID: floorID,
			slug: id,
			id: shortID,
			buildingTitle: buildingNum,
			floorTitle: floorNum,
			aptTitle: aptNum,
			imageDesktop:imageDesktop
		}, currentLanguge)
	); 
	useEffect(() => { }, [currentLanguge]);
	const dynamicAppartmentsData = useSelector((state) => state.project.dynamicAppartmentsData);
	const isDataChange = useSelector((state) => state.project.isDataChange);

	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != "undefined" ? ConvertCurrency(project_default_currency) : "";

	let arr = {};
	const MakeButon = ({ arr }) => {
		try {
			for (let obj of dynamicAppartmentsData) {
				if (obj.building_id == buildingID) {
					for (let floor of obj.floors) {
						if (floor.floor_id == floorID) {
							return floor.properties.map((el) => {
								if (el.property_slug === id) {
									if (!el.status) {
										return (
											<td key={`${id + id + id}`} className="stats_container justify-content-center m-0 ">
												<span className="stat_circle stat_circle__reserved mt-3"></span>
											</td>
										);
									}
									if (el.status === 1) {
										return (
											<button
												key={id}
												id={id}
												type="button"
												onClick={callbackedTask}
												className={`btn text-nowrap fw-bold fs-6 py-2 m-auto align-self-center col-12 tblAllowedBtn`}
												data-gt-category='table view' data-gt-action='click' data-gt-label='select apartment'
												data-apartment="<?=1200+$i?>"
											>
												{currentLanguge.apartmentSelectionTable.watchApt}
											</button>
										);
									}
									if (el.status === 2) {
										return (
											<button
												key={id}
												id={id}
												onClick={callbackedTask}
												className={`btn text-nowrap fw-bold fs-6 py-2 m-auto align-self-center col-12 tblDisbaledBtn`}
												data-apartment="<?=1200+$i?>"
												disabled
											>
												{currentLanguge.apartmentStats.progressApt}
											</button>
										);
									}
									if (el.status === 3) {
										return (
											<button
												key={id}
												id={id}
												onClick={callbackedTask}
												className={`btn text-nowrap fw-bold fs-6 py-2 m-auto align-self-center col-12 tblDisbaledBtn`}
												data-apartment="<?=1200+$i?>"
												disabled
											>
												{currentLanguge.apartmentStats.notAvailableApt}
											</button>
										);
									}
									if (el.status === 4) {
										return (
											<button
												key={id}
												id={id}
												onClick={callbackedTask}
												className={`btn text-nowrap fw-bold fs-6 py-2 m-auto align-self-center col-12 tblDisbaledBtn`}
												data-apartment="<?=1200+$i?>"
												disabled
											>
												{currentLanguge.apartmentStats.notAvailableApt}
											</button>
										);
									}
								}
							});
						}
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	const SpecialPriceTab = ({ clientType, price, currencySymbol, price1, priceHever }) => {
		if (!clientType) {
			return (
				<td className="m-0 p-0 justify-content-center specialPriceColumn px-1" align="center" id="newPriceTab">
					<div className="mt-3 pt-1">
						{currencySymbol}
						{parseInt(price).toLocaleString()}
					</div>
				</td>
			);
		}
		if (clientType == 1) {
			return (
				<td className="m-0 p-0 justify-content-center specialPriceColumn px-1" align="center" id="newPriceTab">
					<div className="mt-3 pt-1">
						{currencySymbol}
						{parseInt(price).toLocaleString()}
					</div>
				</td>
			);
		}
		if (clientType > 1) {
			return (
				<>
					<td className="m-0 p-0 justify-content-center specialPriceColumn px-1" align="center" id="newPriceTab">
						<div className="mt-3 pt-1 old_price">
							{currencySymbol}
							{parseInt(price4).toLocaleString()}
						</div>
					</td>
					<td className="m-0 p-0 justify-content-center specialPriceColumn px-1" align="center" id="newPriceTab">
						<div className="mt-3 pt-1">
							{currencySymbol}
							{parseInt(priceHever).toLocaleString()}
						</div>
					</td>
				</>
			);
		}
	};

	const findStatus = useCallback(
		(id, floorID, buildingID) => {
			try {
				if (!dynamicAppartmentsData) {
					return;
				}
				for (let obj of dynamicAppartmentsData) {
					if (obj.building_id == buildingID) {
						for (let floor of obj.floors) {
							if (floor.floor_id == floorID) {
								return floor.properties.map((el) => {
									if (el.property_slug === id) {
										arr = { ...el };
										if (!el.status) {
											return (
												<td key={`${id + id + id}`} className="stats_container justify-content-center m-0 ">
													<span className="stat_circle stat_circle__reserved mt-3"></span>
												</td>
											);
										}
										if (el.status === 1) {
											return (
												<td key={`${id + id + id}`} className="stats_container justify-content-center m-0">
													<span className="stat_circle stat_circle__available align-self-center mt-3 pt-2"></span>
												</td>
											);
										}
										if (el.status === 2) {
											return (
												<td key={`${id + id + id}`} className="stats_container justify-content-center m-0">
													<span className="stat_circle stat_circle__progress mt-3"></span>
												</td>
											);
										}
										if (el.status === 3) {
											return (
												<td key={`${id + id + id}`} className="stats_container justify-content-center m-0">
													<span className="stat_circle stat_circle__reserved mt-3"></span>
												</td>
											);
										}
										if (el.status === 4) {
											return (
												<td key={`${id + id + id}`} className="stats_container justify-content-center m-0">
													<span className="stat_circle stat_circle__reserved mt-3"></span>
												</td>
											);
										}
									}
								});
							}
						}
					}
				}
			} catch (err) {
				console.log(err);
			}
		},
		[dynamicAppartmentsData, isDataChange]
	);

	return (
		<tr key={`${id + id}`} style={{ textAlign: "center" }}>
			{findStatus(id, floorID, buildingID)}
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{aptNum}</div>
			</td>
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{buildingNum}</div>
			</td>
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{floorNum}</div>
			</td>
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{roomsNum}</div>
			</td>
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{model}</div>
			</td>
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{currentLanguge.directions[direction.toLowerCase()]}</div>
			</td>
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{appartmentTerrace}</div>
			</td>
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{balconyTerrace}</div>
			</td>
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{gardenTerrace}</div>
			</td>
			<td className="m-0 p-0 justify-content-center px-1" align="center">
				<div className="mt-3 pt-1">{serviceTerrace}</div>
			</td>
			{/* old_price */}
			<SpecialPriceTab clientType={clientType} currencySymbol={currencySymbol} price={price} price1={price1} priceHever={priceHever} />

			<td>
				<MakeButon arr={arr} />
			</td>
		</tr>
	);
};

export default AppartmentLine;
