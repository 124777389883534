
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import $ from 'jquery'
import MobileAptComponent from "./MobileAptComponent";
import MobileTableFilters from "./MobileTableFilters";


const MobileTable = () => {
    const allAppartmentsData = useSelector((state) => state.project.allAppartmentsData);
	const [mainDisplay, setMainDisplay] = useState(allAppartmentsData);
	
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)

	
	const [aptNumFilter, setAptNumFilter] = useState('');
	const [buildingfilter, setBuildingFilter] = useState('');
	const [floorfilter, setFloorFilter] = useState('');
	const [roomsFilter, setRoomsFilter] = useState('');
	const [modelFilter, setModelFilter] = useState('');
	const [directionFilter, setDirectionFilter] = useState('');
	const [showAvailable, setShowAvailable] = useState(false);

	useEffect(() => { }, [currentLanguge]);
	
	useEffect(() => {
		if (allAppartmentsData.length > 0 && mainDisplay.length == 0 && !isDataLoaded) {
			setIsDataLoaded(true)
			setMainDisplay(allAppartmentsData);
			$('#mobileTable_loader').hide().removeClass('d-flex');
			$('#mobileTable').show();
		} else if (mainDisplay.length > 0 && !isDataLoaded) {
			setIsDataLoaded(true);
			$('#mobileTable_loader').hide().removeClass('d-flex');
			$('#mobileTable').show();
		}
	 }, [mainDisplay, allAppartmentsData]);

	
	
	 useEffect(() => {
		 $(".mobileTableCollapesed_trigger").off('click').on('click', (e) => {
			$(`${e.target.dataset.bsTarget}`).addClass("show");
		});
		 
		return () => {
			$(".mobileTableCollapesed_trigger").off('click')
		};
	}, []);
	

	// Filter Data
	useEffect(() => {
		let filteredResults = [...allAppartmentsData];
		if (aptNumFilter != '') {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.aptNum === aptNumFilter || apt.aptNum.includes(`${aptNumFilter}`)) {
					return apt;
				}
			});
		}
		if (buildingfilter != '') {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.buildingNum === buildingfilter) {
					return apt;
				}
			});
		}
		if (floorfilter != '') {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.floorNum === floorfilter) {
					return apt;
				}
			});
		}
		if (roomsFilter > 0) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.roomsNum.includes(`${roomsFilter}`)) {
					return apt;
				}
			});
		}
		if (modelFilter !== 0) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.model.includes(modelFilter)) {
					return apt;
				}
			});
		}
		if (directionFilter !== 0 || directionFilter !== '') {
			filteredResults = filteredResults.filter((apt) => {
				return currentLanguge.directions[apt.direction.toLowerCase()] ? currentLanguge.directions[apt.direction.toLowerCase()].includes(directionFilter) : apt;
			});
		}

		if (showAvailable == true) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.property_status == "1") {
					return apt;
				}
			});
		}
		setMainDisplay(filteredResults);
	}, [roomsFilter, aptNumFilter, floorfilter, buildingfilter, directionFilter, modelFilter, showAvailable]);

	// Reset Filters
	const resetFilters = () => {
		let payload = [...allAppartmentsData];
		setMainDisplay(payload);
		setAptNumFilter('');
		setBuildingFilter('');
		setFloorFilter('');
		setRoomsFilter('');
		setModelFilter('');
		setDirectionFilter('');
		setShowAvailable(false);
	};

	return (
		<div className="row m-0 p-0 d-none" id="mobileTable">
			<MobileTableFilters 
				resetFilters={resetFilters}
				setAptNumFilter={setAptNumFilter}
				setBuildingFilter={setBuildingFilter}
				setFloorFilter={setFloorFilter}
				setRoomsFilter={setRoomsFilter}
				setModelFilter={setModelFilter}
				setDirectionFilter={setDirectionFilter}
				setShowAvailable={setShowAvailable} />
			
			<hr className="w-100 m-0 p-0" />
			{mainDisplay.map((el, idx) => {
					return (
						<MobileAptComponent
							key={el.id}
							el={el}
						/>
					);
			})}

			{/* table loader */}
			<div className="sideImageLG d-flex justify-content-center align-self-center w-100 my-4" style={{width: '100% !important'}} id="mobileTable_loader">
				<div className="bounce1"></div>
				<div className="bounce2"></div>
				<div className="bounce3"></div>
			</div>
		</div>
	);
};

export default MobileTable;
