import './AboutPage.sass'

const AboutPage = props => {
    return (
        <div className="container-fluid" id="page_about">
            About
        </div>
    );
  }
  
  export default AboutPage;
  