import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AsyncThumbImageSingle } from "../../ApartmentReserveSteps/BuildingsSelection/image";
import { ThumbPlaceHolder } from "../../Layout/ImagePlacholders/ImagePlacholders";
import { ConvertCurrency } from "../../../Helpers/ConvertCurrency";
import { getPriceForClientType } from "../../../Helpers/AppartmentReserveUtils";
import ApartmentAdditionalDetail from "../../ApartmentReserveSteps/ApartmentDetails/ApartmentAdditionalDetail";
import "./AppartmentShort.sass"

const AptPrice = ({ clientType, price, currencySymbol, specialPrice,firstPrice, alt }) => {
	if (!clientType) {
		return (
			<div className="col-auto mde-2 info-item fw-bold pds-0 f20 d-flex flex-row flex-wrap">
				<img src="/assets/img/icons/ico-price.svg" className="mde-1 pfIcon" alt={alt} height="24" width="25" />
				<span className="mde-4"> {`${currencySymbol}${price}`}</span>
			</div>
		);
	}
	if (!clientType) {
		return (
			<div className="col-auto mde-2 info-item fw-bold pds-0 f20 d-flex flex-row flex-wrap">
				<img src="/assets/img/icons/ico-price.svg" className="mde-1 pfIcon" alt={alt} height="24" width="25" />
				<span className="mde-4"> {`${currencySymbol}${price}`}</span>
			</div>
		);
	}
	if (clientType == 1) {
		return (
			<div className="col-auto mde-2 info-item fw-bold pds-0 f20 d-flex flex-row flex-wrap">
				<img src="/assets/img/icons/ico-price.svg" className="mde-1 pfIcon" alt={alt} height="24" width="25" />
				<span className="mde-4"> {`${currencySymbol}${price}`}</span>
			</div>
		);
	}
	if (clientType > 1) {
		return (
			<div className="col-auto mde-2 info-item fw-bold pds-0 f20 d-flex flex-row flex-wrap">
				<img src="/assets/img/icons/ico-price.svg" className="mde-1 pfIcon" alt={alt} height="24" width="25" />
				<span className="old_price mde-4"> {`${currencySymbol}${firstPrice}`}</span>
				<span className="mde-2">{`${currencySymbol}${specialPrice}`}</span>
			</div>
		);
	}
};

const AppartmentShort = ({id, hideImage, className, verticalView = false}) => {
	const basicData = useSelector((state) => state.project.appartmentBasicData);
	const clientType = useSelector((state) => state.meeting.clientType);

	var priceProp = getPriceForClientType(clientType);

	let specialPrice = (() => parseInt(basicData[priceProp]).toLocaleString())();
	let old = (() => parseInt(basicData.price1).toLocaleString())();

	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != "undefined" ? ConvertCurrency(project_default_currency) : "";
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const defaultMetric = useSelector((state) => state.project.projectSettings.project_default_metric);

	const feautresList = useSelector((state) => state.project.feautresList);
	const aptMainImg = useSelector((state) => state.project.selectedAptMainImg);
	useEffect(() => { }, [currentLanguge]);


	useEffect(() => {
		let src = '';
		if (!aptMainImg || aptMainImg == '') {
			src = basicData.imageThumb;
		} else {
			src = aptMainImg;
		}
		AsyncThumbImageSingle(src, id, ["rounded"], `${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`);
	}, [basicData]);


	const Icons = () => {
		if (feautresList) {
			let values = Object.values(feautresList);

			if (!values) {
				return null;
			}
			
			let content = values.map((el, idx) => {
				let splitted = el.icon.split("/");
				return <ApartmentAdditionalDetail title={el.val} subTitle={el.sub} imgSrc={`/assets/img/icons/${splitted[3]}`}  id={idx} />;
			});
			return content;
		}
		return null;
	};

	let addedClasses = '';
	if (!className || className == '') addedClasses = 'd-flex';

	if (className == 'desktopView' && verticalView == true) {
		return (
			<div className={`apartment_list__item mb-4 m-0 slide-in-left py-2 mt-1 ${className} verticalView`} key={id}>
				
				<div className="mb-3 d-flex flex-column appartmentShort_content">
					<div className="apartment_titles mb-2">
						<h2 className="fw-bold f26">{`${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`}</h2>
					</div>
					<div className="info_container d-flex flex-row flex-wrap">
						{basicData.rooms &&
							<div className="col-auto mde-4 info-item fw-bold pds-0 f20">
								<img src="/assets/img/icons/ico-rooms.svg" className="mde-2 pfIcon" alt={currentLanguge.appartmentShort.rooms} height="24" width="25" />
								{`${basicData.rooms} ${currentLanguge.appartmentShort.rooms}`}
							</div>
						}
						{basicData.sqrMeter &&
							<div className="col-auto mde-4 info-item fw-bold pds-0 f20">
								<img src="/assets/img/icons/ico-measuring-tape.svg" className="mde-2 pfIcon" alt={currentLanguge.general.metric[defaultMetric]} height="24" width="25" />
								{`${basicData.sqrMeter} ${currentLanguge.general.metric[defaultMetric]}`}
							</div>
						}
						{basicData.direction &&
							<div className={`col-auto mde-4 info-item fw-bold pds-0 f20 ${basicData.direction && currentLanguge.directions[basicData.direction.toLowerCase()] ? 'd-flex' : 'd-none'}`}>
								<img src="/assets/img/icons/ico-sign.svg" className="mde-2 pfIcon" alt={basicData.direction ? currentLanguge.directions[basicData.direction.toLowerCase()] : ''} height="24" width="25" />
								{basicData.direction ? currentLanguge.directions[basicData.direction.toLowerCase()] : ''}
							</div>
						}
					</div>
					<AptPrice clientType={clientType} currencySymbol={currencySymbol} specialPrice={specialPrice} price={old} firstPrice={basicData.price1} alt={currentLanguge.apartmentSelectionTable.price} />
					
				</div>

				<div className={`col-auto mde-3 appartmentShort_image ${hideImage == 'true' ? 'd-none' : ''}`} id={id}>
					<ThumbPlaceHolder height="140px" width="200px" />
				</div>

				<div className="apartment-additional-details">
					<Icons/>
				</div>
			</div>
		);
	} else {

		return (
			<div className={`apartment_list__item mb-4 m-0 slide-in-left py-2 mt-1 ${className}`} key={id}>
				<div className={`col-auto mde-3 appartmentShort_image ${hideImage == 'true' ? 'd-none' : ''}`} id={id}>
					<ThumbPlaceHolder height="140px" width="200px" />
				</div>
				<div className="mb-3 d-flex flex-column appartmentShort_content">
					<div className="apartment_titles mb-2">
						<h2 className="fw-bold f26">{`${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`}</h2>
					</div>
					<div className="info_container d-flex flex-row flex-wrap">
						{basicData.rooms &&
							<div className="col-auto mde-4 info-item fw-bold pds-0 f20">
								<img src="/assets/img/icons/ico-rooms.svg" className="mde-2 pfIcon" alt={currentLanguge.appartmentShort.rooms} height="24" width="25" />
								{`${basicData.rooms} ${currentLanguge.appartmentShort.rooms}`}
							</div>
						}
						{basicData.sqrMeter &&
							<div className="col-auto mde-4 info-item fw-bold pds-0 f20">
								<img src="/assets/img/icons/ico-measuring-tape.svg" className="mde-2 pfIcon" alt={currentLanguge.general.metric[defaultMetric]} height="24" width="25" />
								{`${basicData.sqrMeter} ${currentLanguge.general.metric[defaultMetric]}`}
							</div>
						}
						{basicData.direction &&
							<div className={`col-auto mde-4 info-item fw-bold pds-0 f20 ${basicData.direction && currentLanguge.directions[basicData.direction.toLowerCase()] ? 'd-flex' : 'd-none'}`}>
								<img src="/assets/img/icons/ico-sign.svg" className="mde-2 pfIcon" alt={basicData.direction ? currentLanguge.directions[basicData.direction.toLowerCase()] : ''} height="24" width="25" />
								{basicData.direction ? currentLanguge.directions[basicData.direction.toLowerCase()] : ''}
							</div>
						}
					</div>
					<AptPrice clientType={clientType} currencySymbol={currencySymbol} specialPrice={specialPrice} price={old} firstPrice={basicData.price1} alt={currentLanguge.apartmentSelectionTable.price} />
				</div>
				
			</div>
		);
	}
};

export default AppartmentShort;
