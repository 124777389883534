import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../../Redux/Store/store";

 export const AppartmentStatus = ({ id, floorID, buildingID, setFlag }) => {
	const allAppartments = useSelector((state) => state.project.dynamicAppartmentsData);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	const [status, setStatus] = useState(0);
	useEffect(() => { }, [currentLanguge]);
	
	 useEffect(async () => {
		try {
			if (!allAppartments) {
				return;
			}
			for (let obj of allAppartments) {
				if (obj.building_id == buildingID) {
					for (let floor of obj.floors) {
						if (floor.floor_id == floorID) {
							return floor.properties.map((el) => {
								if (el.property_slug === id) {
									setStatus(el.status);
									setFlag(el);
									return;
								}
								
							});
						}
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, [id, allAppartments]);


	if (status === 1) {
		return (
			<span className="apartment_status apartment_status__available sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.availableApt}
			</span>
		);
	}
	if (status === 2) {
		return (
			<span className="apartment_status apartment_status__progress sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.progressApt}
			</span>
		);
	}
	if (status === 3) {
		return (
			<span className="apartment_status apartment_status__reserved  sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.notAvailableApt}
			</span>
		);
	}
	if (status === 4) {
		return (
			<span className="apartment_status apartment_status__reserved sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.notAvailableApt}
			</span>
		);
	} else {
		return (
			<span className="apartment_status apartment_status__reserved sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.notAvailableApt}
			</span>
		);
	}
};

export default AppartmentStatus;

const findAppartmentData = async (list, id) => {
	try {
		let match = {};
		for (const apt of list) {
			if (apt.id === id) {
				return Object.assign(match, apt);
			}
		}
		return new Promise((resolve, reject) => {
			resolve(match);
		});
	} catch (err) {
		console.log(err);
	}
};

export const getAppartmentStatus = async (list, slug) => {
	try {
		const dynamicData = store.getState().project.dynamicAppartmentsData;
		let apt = {};
		const currentApt = await findAppartmentData(list, slug);
		if (currentApt) {
			let match = {};
			for (const apt of dynamicData) {
				if (apt.slug === slug) {
					return Object.assign(match, { ...apt, ...currentApt });
				}
			}
			return new Promise((resolve, reject) => {
				resolve(match);
			});
		}
	} catch (err) {
		console.log(err);
	}
};
