import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	firstName: "",
	lastName: "",
	ID: "",
	email: "",
	mobileNumber: "",
	mobileNumberWithoutPrefix: "",
	areaCode: "050",
	termsAgreed: false,
	isRegisterd: false,
	timezone: "",
	isClientVerified: false,
	codeRegisterStep: 0,
	ipAddress: "",
	country: "",
	registerSuccess: false,
	editClientDetails: false,
	buyerFirstName: "",
	buyerLastName: "",
	buyerEmail: "",
	buyerID: "",
	buyerMobileNumber: "",
	buyerMobileNumberWithoutPrefix: "",
	buyerAreaCode: "050",
	isDifferentBuyer: false,
	selectedMic: '',
	selectedCamera: '',
	selectedSpeaker: '',

};

export const registerSlice = createSlice({
	name: "register",
	initialState,
	reducers: {
		reset: () => initialState,
		setFirstName: (state, action) => {
			state.firstName = action.payload;
		},
		setMobileNumberWithoutPrefix: (state, action) => {
			state.mobileNumberWithoutPrefix = action.payload;
		},

		setIpAddress: (state, action) => {
			state.ipAddress = action.payload;
		},
		setCountry: (state, action) => {
			state.country = action.payload;
		},
		setRegisterSuccess: (state, action) => {
			state.registerSuccess = action.payload;
		},
		setLastName: (state, action) => {
			state.lastName = action.payload;
		},
		setID: (state, action) => {
			state.ID = action.payload.toLowerCase();
		},
		setEmail: (state, action) => {
			state.email = action.payload.toLowerCase();
		},
		setMobileNumber: (state, action) => {
			state.mobileNumber = action.payload;
		},
		setAreaCode: (state, action) => {
			state.areaCode = action.payload;
		},
		setTerms: (state, action) => {
			state.termsAgreed = action.payload;
		},
		setTimezone: (state, action) => {
			state.timezone = action.payload;
		},
		setCodeRegisterStep: (state, action) => {
			state.codeRegisterStep = action.payload;
		},
		setEditClientDetails: (state, action) => {
			state.editClientDetails = action.payload;
		},
		resetForm: (state) => {
			state.buyerFirstName = "";
			state.buyerLastName = "";
			state.buyerID = "";
			state.buyerEmail = "";
			state.buyerMobileNumber = "";
			state.lastName = "";
			state.mobileNumber = "";
			state.email = "";
			state.ID = "";
			state.termsAgreed = false;
			state.editClientDetails = false;
		},
		setIsClientVerified: (state, action) => {
			state.isClientVerified = action.payload;
		},
		
		setBuyerFirstName: (state, action) => {
			state.buyerFirstName = action.payload;
		},
		setBuyerLastName: (state, action) => {
			state.buyerLastName = action.payload;
		},
		setBuyerID: (state, action) => {
			state.buyerID = action.payload.toLowerCase();
		},
		setBuyerEmail: (state, action) => {
			state.buyerEmail = action.payload.toLowerCase();
		},
		setBuyerMobileNumber: (state, action) => {
			state.buyerMobileNumber = action.payload;
		},
		setBuyerMobileNumberWithoutPrefix: (state, action) => {
			state.buyerMobileNumberWithoutPrefix = action.payload;
		},
		setBuyerAreaCode: (state, action) => {
			state.buyerAreaCode = action.payload;
		},
		setIsDifferentBuyer: (state, action) => {
			state.isDifferentBuyer = action.payload;
		},

		setSelectedMic: (state, action) => {
			state.selectedMic = action.payload;
		},
		setSelectedCamera: (state, action) => {
			state.selectedCamera = action.payload;
		},
		setSelectedSpeaker: (state, action) => {
			state.selectedSpeaker = action.payload;
		},
	},
});


// Action creators are generated for each case reducer function
export const {
	reset,
	setEmail,
	setAreaCode,
	setMobileNumber,
	setFirstName,
	setLastName,
	setID,
	setCountry,
	setTerms,
	resetForm,
	setTimezone,
	setIpAddress,
	setRegisterSuccess,
	setMobileNumberWithoutPrefix,
	setIsClientVerified,
	setCodeRegisterStep,
	setEditClientDetails,

	setBuyerFirstName,
	setBuyerLastName,
	setBuyerID,
	setBuyerEmail,
	setBuyerMobileNumber,
	setBuyerMobileNumberWithoutPrefix,
	setBuyerAreaCode,
	setIsDifferentBuyer,
	setSelectedMic,
	setSelectedCamera,
	setSelectedSpeaker
} = registerSlice.actions;

export default registerSlice.reducer;
