import "./Donut.sass";
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { useSelector } from "react-redux";
import $ from 'jquery';

const getOrCreateTooltip = (chart) => {
	let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
	if (!tooltipEl) {
	  tooltipEl = document.createElement('div');
	  tooltipEl.classList.add('donutTooltipEl');
  
	  const table = document.createElement('table');
	  table.style.margin = '0px';
  
	  tooltipEl.appendChild(table);
	  chart.canvas.parentNode.appendChild(tooltipEl);
	}
  
	return tooltipEl;
  };
  
const externalTooltipHandler = (context) => {
	// Tooltip Element
	const {chart, tooltip} = context;
	const tooltipEl = getOrCreateTooltip(chart);
  
	// Hide if no tooltip
	if (tooltip.opacity === 0) {
	  tooltipEl.style.opacity = 0;
	  return;
	}
  
	// Set Text
	if (tooltip.body) {
	  const titleLines = tooltip.title || [];
	  const bodyLines = tooltip.body.map(b => b.lines);
  
	  const tableHead = document.createElement('thead');
  
	  titleLines.forEach(title => {
		const tr = document.createElement('tr');
		tr.style.borderWidth = 0;
  
		const th = document.createElement('th');
		th.style.borderWidth = 0;
		const text = document.createTextNode(title);
  
		th.appendChild(text);
		tr.appendChild(th);
		tableHead.appendChild(tr);
	  });
  
	  const tableBody = document.createElement('tbody');
	  bodyLines.forEach((body, i) => {
		const colors = tooltip.labelColors[i];
  
		const span = document.createElement('span');
		span.style.background = colors.backgroundColor;
		span.style.borderColor = colors.borderColor;
		span.style.borderWidth = '2px';
		span.style.marginBottom = '-1px';

		
		if ($('html').attr('dir') == 'rtl') {
			span.style.marginLeft = '5px';	
		} else {
			span.style.marginRight = '5px';
		}
		
		span.style.height = '10px';
		span.style.width = '10px';
		span.style.display = 'inline-block';
  
		const tr = document.createElement('tr');
		tr.style.backgroundColor = 'inherit';
		tr.style.borderWidth = 0;
  
		const td = document.createElement('td');
		td.style.borderWidth = 0;
		td.style.fontSize = '14px';
		td.style.fontWeight = '400';
		td.style.whiteSpace = 'nowrap';
  
		const text = document.createTextNode(body);
  
		td.appendChild(span);
		td.appendChild(text);
		tr.appendChild(td);
		tableBody.appendChild(tr);
	  });
  
	  const tableRoot = tooltipEl.querySelector('table');
  
	  // Remove old children
	  while (tableRoot.firstChild) {
		tableRoot.firstChild.remove();
	  }
  
	  // Add new children
	  tableRoot.appendChild(tableHead);
	  tableRoot.appendChild(tableBody);
	}
  
	const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
	// Display, position, and set styles for font
	tooltipEl.style.opacity = 1;
	tooltipEl.style.left = positionX + tooltip.caretX + 'px';
	tooltipEl.style.top = positionY + tooltip.caretY + 'px';
	tooltipEl.style.font = tooltip.options.bodyFont.string;
	tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

const Donut = (props) => {
	let projectStats = useSelector((state) => state.project.projectStats);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	useEffect(() => { }, [currentLanguge]);
	useEffect(() => {
		if (!projectStats.statusCode) {
			const count = projectStats.map((el) => {
				return el.count;
			});
	
			let chartSize = !props.size || props.size == '' ? "70" : props.size;
			
			$('#myChart').remove();
			$('#myChartDiv').append(`<canvas id="myChart" className="w-100 h-100 align-self-center" style="direction:ltr" height="${chartSize}" width="${chartSize}"></canvas>`);
	
			var ctx = document.getElementById("myChart");
			
			let statColor_available = getComputedStyle(document.documentElement).getPropertyValue('--rf-stat-available') || "#7dab00";
			let statColor_progress = getComputedStyle(document.documentElement).getPropertyValue('--rf-stat-progress') || "#fbb03b";
			let statColor_reserved = getComputedStyle(document.documentElement).getPropertyValue('--rf-stat-reserved') || "#fd3a00";
			let statColor_white = getComputedStyle(document.documentElement).getPropertyValue('--rf-white') || "#fff";

			var myChart = new Chart(ctx, {
				type: "doughnut",
				
				data: {
					labels: [
						currentLanguge.donutStats.lableAvailable,
						currentLanguge.donutStats.lableInProgress,
						currentLanguge.donutStats.lableReserved
					  ],
					datasets: [
						{
							data: [count[0], count[1], count[2] + count[3]],
							backgroundColor: [statColor_available, statColor_progress, statColor_reserved],
							borderColor: statColor_white,
							borderWidth: 1,
						},
					],
				},
				options: {
					//cutoutPercentage: 40,
					responsive: false,
					locale: currentLanguge.languageLocalCode,
					plugins: {
						legend: {
							display: false
						},
						tooltip: {
							enabled: false,
							position: 'nearest',
							external: externalTooltipHandler
						  }
					}
					
				},
			});
			return () => {
				myChart.destroy();	
			  };	
		}
		
	}, [currentLanguge, projectStats]);
	
	return (
		<div className={`col-auto m-0 p-0 donutCostume align-self-center d-flex justify-content-center ${props.className}`} id="myChartDiv">
			<canvas id="myChart" className="w-100 h-100 align-self-center" style={{ direction: 'ltr' }}></canvas>
		</div>
	);
};

export default Donut;
