export const createSideImg = (url, bodyID, id = '', alt = '') => {
	try {
		let imageInstance = new Image();
		imageInstance.id = `${bodyID}${id}`;
		imageInstance.key = url;
		imageInstance.src = url;
		imageInstance.src = url;
		imageInstance.alt = alt;
		return imageInstance;
	} catch (err) {
		console.log(err);
	}
};

export const AsyncThumbImage = (src, parentID, idx, classLists, alt='') => {
	try {
		const image = new Image();
		image.key = src;
		image.src = src;
		image.alt = alt;
		image.height = "153";
		image.width = "110";
		image.classList.add("appear");

		classLists.forEach((el) => {
			image.classList.add(el);
		});
		let parent = document.getElementById(parentID + idx);
		image.onload = function () {
			try {
				parent.replaceChild(image, parent.firstElementChild);
			} catch (err) {
				console.log(err);
			}
		};
	} catch (err) {
		console.log(err);
	}
};
export const setImageAsync = (propName, parentID) => {
	try {
		if (window.hasOwnProperty(propName)) {
			let image = window[propName];
			let imgParent = document.getElementById(parentID);
			let newImg = new Image();
			newImg.key = image.src;
			newImg.src = image.src;
			newImg.alt = image.alt;
			newImg.classList.add("appear");

			newImg.onload = function () {
				imgParent.replaceChild(newImg, imgParent.firstElementChild);
			};
		}
	} catch (err) {
		console.log(err);
	}
};
export const handleLargeImagesDisplay = (list, propName, bodyID, idProp, firstImageProp, imgParentId, imagesPropName, withInital, altPropName = '', altPrefix = '') => {
	try {
		const data = list.map((el) => {
			let src = el[propName];
			let id = el[idProp];
			let alt = altPropName == '' ? '' : altPrefix + ' ' + el[altPropName];

			return { src: src, id: id, alt: alt };
		});

		let res = data.map((el) => {
			let img = createSideImg(el.src, el.id,  '', el.alt);
			return { img: img, id: el.id, alt: el.alt };
		});
		window[imagesPropName] = res;
		if (withInital === true) {
			window[firstImageProp] = createSideImg(data[0].src, bodyID, data[0].id, data[0].alt);
			setImageAsync(firstImageProp, imgParentId);
		}
	} catch (err) {
		console.log(err);
	}
};

export const handleExistingImage = (src, parentID, alt ='') => {
	try {
		let imgParent = document.getElementById(parentID);
		let newImg = new Image();
		newImg.key = src;
		newImg.src = src;
		newImg.alt = alt;
		newImg.classList.add("appear");
		newImg.onload = function () {
			try {
				imgParent.replaceChild(newImg, imgParent.firstElementChild);
			} catch (err) {
				console.log(err);
			}
		};
	} catch (err) {
		console.log(err);
	}
};
export const AsyncThumbImageSingle = (src, parentID,classLists, alt = '') => {
	try {
		const image = new Image();
		image.key = src;
		image.src = src;
		image.alt = alt;
		image.heireplaceChildght = "140";
		image.width = "200";
		image.classList.add("appear");

		classLists.forEach((el) => {
			image.classList.add(el);
		});
		let parent = document.getElementById(parentID);
		image.onload = function () {
			try {
				parent.replaceChild(image, parent.firstElementChild);
			} catch (err) {
				console.log(err);
			}
		};
	} catch (err) {
		console.log(err);
	}
};
export const handleExistingThumbImage = (src, parentID,classLists) => {
	try {
		let imgParent = document.getElementById(parentID);
		let newImg = new Image();
		newImg.key = src;
		newImg.src = src;
		newImg.classList.add("appear");
		classLists.forEach((el) => {
			newImg.classList.add(el);
		});
		newImg.onload = function () {
			try {
				imgParent.replaceChild(newImg, imgParent.firstElementChild);
			} catch (err) {
				console.log(err);
			}
		};
	} catch (err) {
		console.log(err);
	}
};