import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makePostRequest } from "../../../Axios";
import "./NotesList.sass";
import $ from 'jquery'
const NotesList = () => {
	const [note, setNote] = useState("");
	const [notesList, setNoteslist] = useState([]);
	const meetingslug = useSelector((state) => state.verification.slug);
	const agentKey = useSelector((state) => state.verification.agentKey);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const timezone = useSelector((state) => state.project.timezone);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	useEffect(() => { }, [currentLanguge, notesList]);
	
	useEffect(() => {
		try {
			let asyncCall = async () => {
				let data = new FormData();
				data.append("meetingslug", meetingslug);
				data.append("agent_key", agentKey);
				// data.append("note_text", note);

				const req = await makePostRequest("/addget_meeting_notes", data);
				if (req) {
					if (req.data.message === "OK") {
						let notes = mapNotes(req.data.notes);
						var revMyArr = [].concat(notes).reverse();
						setNoteslist(revMyArr);
					}
				}
			};
			asyncCall();
		} catch (err) {
			console.log(err);
		}
	}, []);

	const mapNotes = (notes) => {
		return notes.map((el) => {
			return {
				noteID: el.id,
				noteDate: (() => moment(el.created_at).tz(timezone).format("MMM, DD YYYY hh:mm"))(),
				content: el.note_text,
				user: el.user,
			};
		})
	}

	const addNote = async (e) => {
		try {
			let note = $('#noteInput').val();

			if (note.length < 2) {
				$(e.target).prop('disabled', false);
				$('#add_note_form__error').fadeIn('fast').delay(2500).fadeOut('fast');
				return; //handle
			}
			let data = new FormData();
			data.append("meetingslug", meetingslug);
			data.append("agent_key", agentKey);
			data.append("note_text", note);

			const req = await makePostRequest("/addget_meeting_notes", data);

			if (!req) {
				$(e.target).prop('disabled', false);
				$('#add_note_form__error').fadeIn('fast').delay(2500).fadeOut('fast');
				return;
			}
			if (req.data.message === "OK") {
				let notes = mapNotes(req.data.notes);
				setNoteslist(notes);
				document.querySelector("#noteInput").value = '';
				$(e.target).prop('disabled', false);

			}
		} catch (err) {
			console.log(err);
		}
	};

	const AddNoteForm = () => {
		return <div className="d-flex flex-column">
			<div id="add_note_form" className="mb-2">
				<input className="form-control border-0" id='noteInput' placeholder="Add Note" type="text"/>
				<button className="btn fw-bold" onClick={(e) => addNote(e)}>
					<i className="bi bi-play-fill"></i>
				</button>
			</div>
   			<p className="err_msg" id="add_note_form__error">{currentLanguge.meetingVideoComp.notesError}</p>
		</div>

	}

	const NotesListContent = () => {
		if (notesList.length > 0) {
			return <div className="col-12 d-flex flex-column h-auto m-0 align-self-center" id="notesContent">
				{notesList.map((el, idx) => (
					<div className="noteList_note" key={idx}>
						<p className="p-0 m-0">{el.content}</p>
						<span className="f12 d-left">{el.noteDate}</span>
					</div>
				))}
			</div>
		} else {
			return <></>;
		}
	}

	
	if (!isAgent) {
		return <div />;
	}

	return (
		<div className="accordion" id="notesAccordion">
			<div className="accordion-item">
				<h2 className="w-100 p-0 m-0 w700 mde-2" id="headingOne">
					<button className="accordion-button px-0 w700 collapsed" id="notesList_trigger" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
						{currentLanguge.meetingVideoComp.notesListTitle}
					</button>
				</h2>
				
				<div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#notesAccordion">
					<div className="accordion-body p-0 pt-4">
						<AddNoteForm />
						
						<NotesListContent/>
					</div>
				</div>
			</div>
		</div>

	);
};

export default NotesList;
