import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./MobileTable.sass";
import Autocomplete from "../../../UI/AutoComplate/Autocomplete";
import RadioSelect from "../../../UI/RadioSelect/RadioSelect";
import $ from 'jquery'

const MobileTableFilters = (props) => {
	const [show, setShow] = useState(false);

	const [unique_aptNum, setUnique_aptNum] = useState([]);
	const [unique_buildingNum, setUnique_buildingNum] = useState([]);
	const [unique_floorNum, setUnique_floorNum] = useState([]);
	const [unique_roomsNum, setUnique_roomsNum] = useState([]);
	const [unique_model, setUnique_model] = useState([]);
	const [unique_direction, setUnique_direction] = useState([]);
	
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
    const allAppartmentsData = useSelector((state) => state.project.allAppartmentsData);
	
	const [aptNumFilter, setAptNumFilter] = useState('');
	const [buildingfilter, setBuildingFilter] = useState('');
	const [floorfilter, setFloorFilter] = useState('');
	const [roomsFilter, setRoomsFilter] = useState('');
	const [modelFilter, setModelFilter] = useState('');
	const [directionFilter, setDirectionFilter] = useState('');
	const [showAvailable, setShowAvailable] = useState(false);

	useEffect(() => { 
		props.setAptNumFilter(aptNumFilter)
	}, [aptNumFilter]);
	useEffect(() => { 
		props.setBuildingFilter(buildingfilter)
	}, [buildingfilter]);
	useEffect(() => { 
		props.setFloorFilter(floorfilter)
	}, [floorfilter]);
	useEffect(() => { 
		props.setRoomsFilter(roomsFilter)
	}, [roomsFilter]);
	useEffect(() => { 
		props.setModelFilter(modelFilter)
	}, [modelFilter]);
	useEffect(() => { 
		props.setDirectionFilter(directionFilter)
	}, [directionFilter]);
	useEffect(() => { 
		props.setShowAvailable(showAvailable)
	}, [showAvailable]);

	// set filters optional values
	useEffect(() => { 
		if (allAppartmentsData.length > 0) {
			setUnique_aptNum([...new Set(allAppartmentsData.map(item => item.aptNum)),'']);
			setUnique_buildingNum([...new Set(allAppartmentsData.map(item => item.buildingNum)),'']);
			setUnique_floorNum([...new Set(allAppartmentsData.map(item => item.floorNum)),'']);
			setUnique_roomsNum([...new Set(allAppartmentsData.map(item => item.roomsNum))]);
			setUnique_model([...new Set(allAppartmentsData.map(item => item.model))]);
			setUnique_direction([...new Set(allAppartmentsData.map(item => item.direction))]);	
		}
	}, [allAppartmentsData]);
	
	
	useEffect(() => { }, [currentLanguge]);

	const toggleSettingModel = () => {
		setShow(show == true ? false : true);
	}


	const submitFilters = () => {
		let inputValue_aptNum = $('#mobileTable__filter__apartment').val();
		let inputValue_buildingNum = $('#mobileTable__filter__building').val();
		let inputValue_floorNum = $('#mobileTable__filter__floor').val();
		let inputValue_roomsNum = $('input[name=mobileTable__filter__bedrooms]:checked').length > 0 ? $('input[name=mobileTable__filter__bedrooms]:checked').val() : ''
		let inputValue_model = $('input[name=mobileTable__filter__model]:checked').length > 0 ? $('input[name=mobileTable__filter__model]:checked').val() : ''
		let inputValue_direction = $('input[name=mobileTable__filter__direction]:checked').length > 0 ? $('input[name=mobileTable__filter__direction]:checked').val() : ''
		
		if (inputValue_aptNum || inputValue_aptNum == '') setAptNumFilter(inputValue_aptNum);
		if (inputValue_buildingNum || inputValue_buildingNum == '') setBuildingFilter(inputValue_buildingNum);
		if (inputValue_floorNum || inputValue_floorNum == '') setFloorFilter(inputValue_floorNum);
		if (inputValue_roomsNum != '') setRoomsFilter(inputValue_roomsNum == 'any' ? '' : inputValue_roomsNum);
		if (inputValue_model != '') setModelFilter(inputValue_model == 'any' ? '' : inputValue_model);
		if (inputValue_direction != '') setDirectionFilter(inputValue_direction == 'any' ? '' : inputValue_direction);

		toggleSettingModel();
	}


	const setFilter_showAvailable = (checked) => {
		setShowAvailable(checked);
	}

	const resetFilters = () => {
		setAptNumFilter('');
		setBuildingFilter('');
		setFloorFilter('');
		setRoomsFilter('');
		setModelFilter('');
		setDirectionFilter('');
		setShowAvailable(false);

		props.resetFilters();
		toggleSettingModel();
	}


	return (
		<>
			<div className="d-flex p-0 align-items-center">
				<button
					className="btn btn-outline-dark my-2 d-flex justify-content-start w-auto"
					id="mobileTableFilters__modelTrigger"
					data-gt-category='table view' data-gt-action='click' data-gt-label='show mobile filters'
					onClick={toggleSettingModel}><i className="bi bi-sliders mde-2"></i> {currentLanguge.apartmentSelectionTable.mobile_filtersModel_trigger}
				</button>

				{/* show only available units */}
				<div className="form-check mds-2">
					<input
						className="form-check-input"
						type="checkbox"
						value={showAvailable}
						checked={showAvailable}
						id="mobileTable__filter__showAvailable"
						onChange={(e) => setFilter_showAvailable(e.target.checked)}
					/>
					<label className="form-check-label pfItemFont" htmlFor="mobileTable__filter__showAvailable">
						{currentLanguge.apartmentSelectionTable.filter__show_available}
					</label>
				</div>
			</div>
			

			<Modal show={show}  centered >
				<Modal.Header>
					<a className="mobileTableFilters__modelClose" onClick={toggleSettingModel} data-gt-category='table view' data-gt-action='click' data-gt-label='close mobile filters'>
						<img src='/assets/img/icons/ico-close.svg' alt='' height="20" width="20"/>
					</a>
					<h5 className="align-self-center m-0">{currentLanguge.apartmentSelectionTable.mobile_filtersModel_title}</h5>
				</Modal.Header>

				<Modal.Body className="d-flex flex-column">
					{/* Apartment Number */}
					<div className="mobileTable__filter_container">
						<h6 className="fw-bold">{currentLanguge.apartmentSelectionTable.headerApartment}</h6>
						<Autocomplete
							value={aptNumFilter}
							id='mobileTable__filter__apartment'
							placeholder={currentLanguge.apartmentSelectionTable.headerApartment}
							suggestions={unique_aptNum}
							noResultsMsg={currentLanguge.formsErrors.noOptionsFoound} />
					</div>
					<hr />
					
					{/* Building Name */}
					<div className="mobileTable__filter_container">
						<h6 className="fw-bold">{currentLanguge.apartmentSelectionTable.headerBuilding}</h6>
						<Autocomplete
							value={buildingfilter}
							id='mobileTable__filter__building'
							placeholder={currentLanguge.apartmentSelectionTable.headerBuilding}
							suggestions={unique_buildingNum} 
							noResultsMsg={currentLanguge.formsErrors.noOptionsFoound}/>
					</div>
					<hr />

					{/* Floor Number */}
					<div className="mobileTable__filter_container">
						<h6 className="fw-bold">{currentLanguge.apartmentSelectionTable.headerFloor}</h6>
						<Autocomplete
							value={floorfilter}
							id='mobileTable__filter__floor'
							placeholder={currentLanguge.apartmentSelectionTable.headerFloor}
							suggestions={unique_floorNum} 
							noResultsMsg={currentLanguge.formsErrors.noOptionsFoound}/>
					</div>
					<hr />

					{/* Bedrooms */}
					<div className="mobileTable__filter_container">
						<h6 className="fw-bold">{currentLanguge.apartmentSelectionTable.headerRooms}</h6>
						<RadioSelect id='mobileTable__filter__bedrooms' options={unique_roomsNum} selected={roomsFilter } />
					</div>
					<hr />

					{/* Model */}
					<div className="mobileTable__filter_container">
						<h6 className="fw-bold">{currentLanguge.apartmentSelectionTable.headerModel}</h6>
						<RadioSelect id='mobileTable__filter__model' options={unique_model} selected={modelFilter } />
					</div>
					<hr />

					{/* Direction */}
					<div className="mobileTable__filter_container">
						<h6 className="fw-bold">{currentLanguge.apartmentSelectionTable.headerDirection}</h6>
						<RadioSelect id='mobileTable__filter__direction' options={unique_direction} selected={ directionFilter} />
					</div>

				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-between">
					<button className='btn btn-outline-dark align-self-center'
						onClick={resetFilters} type="button"
						data-gt-category='table view' data-gt-action='click' data-gt-label='clear all filters'>
						<i className="bi bi-eraser-fill mde-2"></i> {currentLanguge.apartmentSelectionTable.clearFilters}
					</button>
					<button className='btn rf-btn-primary align-self-center'
						onClick={submitFilters} type="button"
						data-gt-category='table view' data-gt-action='click' data-gt-label='set filters'>
						 {currentLanguge.apartmentSelectionTable.mobile_filtersModel_submit}
					</button>
					
				</Modal.Footer>
			</Modal>
		</>
    )
};


export default MobileTableFilters;
