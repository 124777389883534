import VideoDisplayController from "../VideoDisplayController";
import "./ControlBar.sass";

const ControlBar = () => {
	return (
		<div className="row p-0 m-0">
			
			<div className="col-12 videoMenu p-0 borderRb" id="videoMenuControls__container">
				<div className="collapse m-0 show" id="videoMenuCollapse">
					<div className="card card-body p-0 m-0 col-12 w-100 d-flex flex-column justify-content-center">
						<VideoDisplayController />
					</div>
				</div>
			</div>
		</div>
	);
};
export default ControlBar;
