import PropTypes from 'prop-types';
import InfoWindow from './InfoWindow';


// Marker component
const MapMarker = ({ show, place,logo }) => {
  const markerStyle = {
    width: '26px',
    cursor: 'pointer',
    zIndex: 10,
    objectFit: 'cover',
    objectPosition: 'center center'
  };

  return (
    <div id='tttttt'>
      <div>
        <img style={markerStyle} src="/assets/img/pushpin.png" alt="" />
      </div>
      <InfoWindow place={place}>
        <div className="d-flex justify-content-center">
          <img src={logo} alt="" className='align-self-center' style={{ maxHeight: '60px' }} />
        </div>
      </InfoWindow>
    </div>
  );
};



MapMarker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};


export default MapMarker;
