import { useSelector } from "react-redux";
import SkipPayBtn from "./SkipPayBtn";
import SkipSigntBtn from "./SkipSigntBtn";
import "./AgentFlowControls.sass";

const AgentFlowControls = () => {
	const isAgent = useSelector((state) => state.meeting.isAgent);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	if (isAgent) {
		return (
			<div className={`agentActionRow`}>
				<p className="p-0 m-0 w700 mde-2">{currentLanguge.meetingVideoComp.sectionTitle_actions}</p>
				<SkipSigntBtn/>
				<SkipPayBtn/>
			</div>
		);
	} else {
		return <div />;
	}
};

export default AgentFlowControls;
