import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const AppartmentStatus = ({ id, floorID, buildingID, setFlag }) => {
	const allAppartments = useSelector((state) => state.project.dynamicAppartmentsData);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)

	const [status, setStatus] = useState(0);
	useEffect(() => { }, [currentLanguge]);
	useEffect(async () => {
		try {
			for (let obj of allAppartments) {
				if (obj.building_id == buildingID) {
					for (let floor of obj.floors) {
						if (floor.floor_id == floorID) {
							return floor.properties.map((el) => {
								if (el.property_slug === id) {
									setStatus(el.status);
									return;
								}
							});
						}
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
	});

	if (status === 1) {
		return (
			<span className="apartment_status apartment_status__available sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.availableApt}
			</span>
		);
	}
	if (status === 2) {
		return (
			<span className="apartment_status apartment_status__progress sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.progressApt}
			</span>
		);
	}
	if (status === 3) {
		return (
			<span className="apartment_status apartment_status__reserved  sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.notAvailableApt}
			</span>
		);
	}
	if (status === 4) {
		return (
			<span className="apartment_status apartment_status__reserved sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.notAvailableApt}
			</span>
		);
	} else {
		return (
			<span className="apartment_status apartment_status__reserved sm_aptStatus mds-3 f15">
				<span></span>{currentLanguge.apartmentStats.notAvailableApt}
			</span>
		);
	}
};

const Appartment = ({ status, id, slug, idx, showdata }) => {
	if (status === 1) {
		return (
			<span
				id={id}
				tabIndex={idx}
				type="button"
				data-gt-category='graphic view' data-gt-action='click' data-gt-label='preview apartment'
				data-bs-toggle="popover"
				className="floor-stat__available lilpopvers"
				onClick={showdata}
			></span>
		);
	} else if (status === 2) {
		return (
			<span
				tabIndex={idx}
				id={id}
				type="button"
				data-bs-toggle="popover"
				className="floor-stat__progress lilpopvers"
				onClick={showdata}
			></span>
		);
	} else if (status === 3) {
		return (
			<span
				tabIndex={idx}
				id={id}
				type="button"
				data-bs-toggle="popover"
				className="floor-stat__reserved lilpopvers"
				onClick={showdata}
			></span>
		);
	} else if (status === 4) {
		return (
			<span
				tabIndex={idx}
				id={id}
				type="button"
				data-bs-toggle="popover"
				className="floor-stat__reserved lilpopvers"
				onClick={showdata}
			></span>
		);
	} else {
		return (
			<span
				tabIndex={idx}
				id={id}
				type="button"
				// data-bs-container="body"
				data-bs-toggle="popover"
				className="floor-stat__reserved lilpopvers"
				onClick={showdata}
			></span>
		);
	}
};

// let Maybe = useCallback(()=><Appartment as="btn" status={el.status} id={el.property_id} slug={el.property_slug} idx={idx} showdata={showdata} />,[])
const Floors = ({ floor, floorID, buildingID, idx, showdata }) => {
	return (
		<div className="list-group-item d-flex apartment_list__item">
			<h4 className="floor_title fw-bold">{floor.floor_title}</h4>
			<div className="mde-2 floor-stats">
				{floor.properties.map((el) => {
					return <Appartment as="btn" status={el.status} id={el.property_id} slug={el.property_slug} idx={idx} showdata={showdata} key={el.property_slug}/>;
				})}
			</div>
		</div>
	);
};

const Building = ({ list, showdata }) => {
	if (!list) {
		return (
			<div className="col-12 d-flex justify-content-center align-self-center m-auto">
				<div className="spinner-grow top-50" role="status">
				</div>
			</div>
		);
	}
	
	return list.map((building, idx) => {
		return (
			<div key={idx} className="col building_slide" id={`building_slide__${idx}`}>
				<h3 className="building_title fw-bold">{`${building.building_title}`}</h3>
				{/* Apartments List  */}
				<div className="list-group apartments_list">
					{building.floors.sort((a,b) => parseInt(a.floor_title) > parseInt(b.floor_title) ? 1 : -1).reverse().map((floor) => (
						<Floors key={floor.floor_id} floor={floor} floorID={floor.floor_id} buildingID={building.building_id} idx={idx} showdata={showdata} />
					))}
				</div>
			</div>
		);
	});
};

export default Building;
