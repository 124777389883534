import React, { useState, useEffect, memo } from "react";
import { ConvertCurrency } from "../../../Helpers/ConvertCurrency";
import AppartmentStatus from "../../Stats/AppartmentStatus/AppartmentStatus";
import { AsyncThumbImage } from "../BuildingsSelection/image";
import { ThumbPlaceHolder } from "../../Layout/ImagePlacholders/ImagePlacholders";
import { useSelector } from "react-redux";
import GTEvent from "../../../Helpers/GTEvent";

const MakeLabel = memo(({ status, title, imageDesktop, id, rooms, aptID, handleAppartmentSelect, lang }) => {
	if (!status) {
		return null;
	}
	let addClass;
	let text;

	if (status === 1) {
		addClass = "rf-btn-primary";
		text = lang.apartmentSelectionList.watchApt;
		return (
			<button
				key={`${id + id}`}
				id={id}
				className={`apartment_list__item-cta btn ${addClass} fw-bold f18 mds-auto pfAptButton disappear col-2 `}
				data-building="1"
				type="button"
				data-gt-category='apartment selection' data-gt-action='click' data-gt-label='select apartment'
				onClick={(e) => handleAppartmentSelect(e, title, imageDesktop, rooms)}
				data-aptid={aptID}
				data-aptslug={id}
			>
				{text}
			</button>
		);
	}
	if (status === 2) {
		addClass = "btn-secondary";
		text = lang.apartmentStats.progressApt;
		return (
			<button
				key={`${id + id}`}
				id={id}
				className={`apartment_list__item-cta btn ${addClass} fw-bold f18 mds-auto pfAptButton disappear col-2`}
				data-building="1"
				data-aptid={aptID}
				data-aptslug={id}
				disabled
			>
				{text}
			</button>
		);
	}
	if (status === 3) {
		addClass = "btn-secondary";
		text = lang.apartmentStats.soldApt;
		return (
			<button
				key={`${id + id}`}
				id={id}
				className={`apartment_list__item-cta btn ${addClass} fw-bold f18 mds-auto pfAptButton disappear col-2`}
				data-building="1"
				data-aptid={aptID}
				data-aptslug={id}
				disabled
			>
				{text}
			</button>
		);
	}
	if (status === 4) {
		addClass = "btn-secondary";
		text = lang.apartmentStats.soldApt;
		return (
			<button
				key={`${id + id}`}
				id={id}
				className={`apartment_list__item-cta btn ${addClass} fw-bold f18 mds-auto pfAptButton disappear col-2`}
				data-building="1"
				data-aptid={aptID}
				data-aptslug={id}
				disabled
			>
				{text}
			</button>
		);
	}
});

const AptPrice = ({ clientType, price, currencySymbol, firstPrice, lastPrice, alt }) => {
	if (!clientType) {
		return (
			<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0 f18">
				<img src="/assets/img/icons/ico-price.svg" className="mde-2 pfIcon" alt={alt} height="23" width="24" />
				<span className="ms-0 mde-4">
					{currencySymbol}
					{price}
				</span>
				{/* {`${currencySymbol}${firstPrice}`} */}
			</div>
		);
	}
	if (!clientType) {
		return (
			<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0 f18">
				<img src="/assets/img/icons/ico-price.svg" className="mde-2 pfIcon" alt={alt} height="23" width="24" />
				<span className="ms-0 mde-4">
					{currencySymbol}
					{price}
				</span>
				{/* {`${currencySymbol}${newP}`} */}
			</div>
		);
	}
	if (clientType == 1) {
		return (
			<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0 f18">
				<img src="/assets/img/icons/ico-price.svg" className="mde-2 pfIcon" alt={alt} height="23" width="24" />
				<span className="ms-0 mde-4">
					{currencySymbol}
					{price}
				</span>
				{/* {`${currencySymbol}${newP}`} */}
			</div>
		);
	}
	if (clientType > 1) {
		return (
			<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0 f18">
				<img src="/assets/img/icons/ico-price.svg" className="mde-2 pfIcon" alt={alt} height="23" width="24" />
				<span className="ms-0 mde-4 old_price">
					{currencySymbol}
					{lastPrice}
				</span>
				{`${currencySymbol}${price}`}
			</div>
		);
	}
};

const Appartment = ({
	rooms,
	sqrMeter,
	clientTypePrice,
	firstPrice,
	direction,
	id,
	title,
	description,
	thumbImg,
	handleAppartmentSelect,
	buildingID,
	floorID,
	aptID,
	locationImg,
	handleAptClick,
	imageDesktop,
	idx,
	clientType,
	lastPrice,
	history
}) => {
	const [isAvailable, setIsAvailable] = useState("");
	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != "undefined" ? ConvertCurrency(project_default_currency) : "";
	const defaultMetric = useSelector((state) => state.project.projectSettings.project_default_metric)
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);
	let price = (() => parseInt(clientTypePrice).toLocaleString())();
	let lastPriceNew = (() => parseInt(lastPrice).toLocaleString())();

	useEffect(() => {}, [locationImg]);
	useEffect(() => {
		AsyncThumbImage(thumbImg, "aptSideThumb", `${idx}_desktop`, ["apartment_tumb", "pfThumb"], `${!isNaN(title) ? currentLanguge.appartmentShort.apartment : ''} ${title}`);
		AsyncThumbImage(thumbImg, "aptSideThumb", `${idx}_mobile`, ["apartment_tumb", "pfThumb"], `${!isNaN(title) ? currentLanguge.appartmentShort.apartment : ''} ${title}`);
	});

	const handleAptClickLocal = (e, locationImg, title) => {
		handleAptClick(e, locationImg, title);
		// GTEvent(e);
	}

	const handleAppartmentSelectLocal = (e, title, imageDesktop, rooms) => {
		handleAppartmentSelect(e, title, imageDesktop, rooms);
		GTEvent(e);
	}

	return (
		<div data-appartid={aptID} key={id}>
			<div
				className="list-group-item d-flex apartment_list__item position-relative p-3 p-0 justify-content-between appear flex-wrap"
				data-apartment="1"
				id={id}
			>
				<div className="w-100 h-100 position-absolute top-0 elemIndex" data-locationimg={locationImg}
					data-gt-category='apartment selection' data-gt-action='click' data-gt-label='preview apartment'
					onClick={(e) => handleAptClickLocal(e, locationImg, `${!isNaN(title) ? currentLanguge.appartmentShort.apartment : ''} ${title}`)} data-aptlig={"a" + id} />
			
				<div className="desktopView flex-wrap">
					{/* Thumbnail */}
					<div className="col-auto d-flex mds-1 pde-2 " id={`aptSideThumb${idx}_desktop`}>
						<ThumbPlaceHolder height="120px" width="150px" />
					</div>

					<div className="col">
						{/* Title + Status */}
						<div className="apartment_titles mb-1 flex-wrap" style={{ width: "64% !important" }}>
							<h2 className="fw-bold f24 mde-2 mb-2">{`${!isNaN(title) ? currentLanguge.appartmentShort.apartment : ''} ${title}`}</h2>
							<AppartmentStatus id={id} floorID={floorID} buildingID={buildingID} setFlag={setIsAvailable} />
						</div>

						{/* Icons */}
						<div className="info_container d-flex flex-wrap mt-2">
							<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0 f18 mb-2">
								<img src="/assets/img/icons/ico-rooms.svg" className="mde-2 pfIcon" alt={currentLanguge.appartmentShort.rooms} height="23" width="24" />
								{`${rooms} ${currentLanguge.appartmentShort.rooms}`}
							</div>
							{sqrMeter &&
								<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0  mb-2 f18">
									<img src="/assets/img/icons/ico-measuring-tape.svg" className="mde-2 pfIcon" alt={currentLanguge.general.metric[defaultMetric]} height="23" width="24" />
									{`${sqrMeter} ${currentLanguge.general.metric[defaultMetric]}`}
								</div>
							}
							{currentLanguge.directions[direction.toLowerCase()] &&
								<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0  mb-2 f18">
									<img src="/assets/img/icons/ico-sign.svg" className="mde-2 pfIcon" alt={currentLanguge.directions[direction.toLowerCase()]} height="23" width="24" />
									{currentLanguge.directions[direction.toLowerCase()]}
								</div>
							}
						</div>

						{/* Price */}
						<AptPrice
							clientType={clientType}
							price={price}
							currencySymbol={currencySymbol}
							key={clientTypePrice}
							firstPrice={firstPrice}
							lastPrice={lastPriceNew}
							alt={currentLanguge.apartmentSelectionTable.price}
						/>
					</div>

					{/* Description */}
					<div className="w-100 mt-2">
						<p className="apartment_description mt- f18">{description}</p>
					</div>

					{/* CTA */}
					<MakeLabel status={isAvailable.status} title={title} imageDesktop={imageDesktop} id={id} rooms={rooms} aptID={aptID} handleAppartmentSelect={handleAppartmentSelectLocal} history={history} lang={currentLanguge}/>
				</div>

				<div className="mobileView">
					{/* Title + Status */}
					<div className="apartment_titles mb-1 flex-wrap my-2" style={{ width: "100%" }}>
						<h2 className="fw-bold f24 mde-2 mb-2">{`${!isNaN(title) ? currentLanguge.appartmentShort.apartment : ''} ${title}`}</h2>
						<AppartmentStatus id={id} floorID={floorID} buildingID={buildingID} setFlag={setIsAvailable} />
					</div>
					
					{/* Price */}
					<AptPrice
						clientType={clientType}
						price={price}
						currencySymbol={currencySymbol}
						key={clientTypePrice}
						firstPrice={firstPrice}
						lastPrice={lastPriceNew}
						alt={currentLanguge.apartmentSelectionTable.price}
					/>

					{/* Icons */}
					<div className="info_container d-flex flex-wrap mt-2">
						<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0 f18 mb-2">
							<img src="/assets/img/icons/ico-rooms.svg" className="mde-2 pfIcon" alt={currentLanguge.appartmentShort.rooms} height="23" width="24" />
							{`${rooms} ${currentLanguge.appartmentShort.rooms}`}
						</div>
						{sqrMeter &&
							<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0  mb-2 f18">
								<img src="/assets/img/icons/ico-measuring-tape.svg" className="mde-2 pfIcon" alt={currentLanguge.general.metric[defaultMetric]} height="23" width="24" />
								{`${sqrMeter} ${currentLanguge.general.metric[defaultMetric]}`}
							</div>
						}
						{currentLanguge.directions[direction.toLowerCase()] &&
							<div className="col-auto mde-2 info-item pfItemFont fw-bolder pds-0  mb-2 f18">
								<img src="/assets/img/icons/ico-sign.svg" className="mde-2 pfIcon" alt={currentLanguge.directions[direction.toLowerCase()]} height="23" width="24" />
								{currentLanguge.directions[direction.toLowerCase()]}
							</div>
						}
					</div>

					{/* Thumbnail */}
					<div className="w-100 d-flex mds-1 pde-2 my-3 justify-content-center" id={`aptSideThumb${idx}_mobile`}>
						<ThumbPlaceHolder height="120px" width="150px" />
					</div>

					{/* Description */}
					<div className="w-100 mt-2">
						<p className="apartment_description mt- f18">{description}</p>
					</div>

					{/* CTA */}
					<MakeLabel status={isAvailable.status} title={title} imageDesktop={imageDesktop} id={id} rooms={rooms} aptID={aptID} handleAppartmentSelect={handleAppartmentSelectLocal} history={history} lang={currentLanguge}/>
				</div>
				
			</div>
			<hr className="w-90 m-0 pfLine" />
		</div>
	);
};

export default Appartment;