import pusher from "../../Pusher";
import ApartmentDetails from "../../components/ApartmentReserveSteps/ApartmentDetails/ApartmentDetails";
import ApartmentSelectionGraphic from "../../components/ApartmentReserveSteps/ApartmentSelectionGraphic/ApartmentSelectionGraphic";
import ApartmentSelectionList from "../../components/ApartmentReserveSteps/ApartmentSelectionList/ApartmentSelectionList";
import ApartmentSelectionTable from "../../components/ApartmentReserveSteps/ApartmentSelectionTable/ApartmentSelectionTable";
import BuildingsSelection from "../../components/ApartmentReserveSteps/BuildingsSelection/BuildingsSelection";
import FloorSelection from "../../components/ApartmentReserveSteps/FloorSelection/FloorSelection";
import DetailsApproval from "../../components/ApartmentReserveSteps/DetailsApproval/DetailsApproval";
import Payment from "../../components/ApartmentReserveSteps/Payment/Payment";
import FollowUpMeeting from "../../components/ApartmentReserveSteps/FollowUpMeeting/FollowUpMeeting";
import Finish from "../../components/ApartmentReserveSteps/Finish/Finish";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleNewClient } from "../RegistetUtils";
import {
	handleBuildingSelect,
	handleFloorSelect,
	handleAppartmentPreview,
	handleAppartmentSelect,
	handleSignatureSubmit,
	handlePaymentSubmit,
	handleRequestSignMeeting,
	setAllAppartmentsData,
	setAppartmentsDynamicData,
	setIsLoading,
	getPriceForClientType,
} from "./index";
import $ from 'jquery'
import { makeGetRequest } from "../../Axios";
import pusherSubscribe from "./pusherReg";
import ContractSide from "../../components/ApartmentReserveSteps/ContractSignature/ContractSignature";
import store from "../../Redux/Store/store";
import StripePayment from "../../components/ApartmentReserveSteps/StripePayment/StripePayment";

const addAllAppartmentsData = async (setAllAppartmentsData, projectID) => {
	if (!projectID) {
		return;
	}
	const clientType = store.getState().meeting.clientType;

	
	let langCode = sessionStorage.getItem("langCode");
	if (!langCode || langCode == '') {
		langCode = '';
	} else {
		langCode = '/' + langCode;
	}

	const req = await makeGetRequest("/propertieslist", `${projectID}${langCode}`).catch((err) => console.log(err));
	if (!req) {
		return;
	}
	var priceProp = getPriceForClientType(clientType);

	if (req.data.message) {
		const appartmentsObject = req.data.message.map((el, idx) => {
			const appartmentData = {
				aptNum: el.p_property_title,
				buildingNum: el.building_title,
				floorNum: el.floor_title,
				roomsNum: el.pv_number_of_rooms,
				model: el.pv_property_model_name,
				appartmentTerrace: el.pv_total_sqr_area,
				balconyTerrace: el.pv_terrace_sqr_area,
				gardenTerrace: el.pv_garden_sqr_area,
				serviceTerrace: el.pv_service_place_sqr_area,
				price: el.price1,
				price2: el.price2,
				price3: el.price3,
				price4: el.price4,
				priceHever: el[priceProp],
				direction: el.p_property_direction,
				id: el.p_property_slug,
				buildingID: el.p_building_id,
				floorID: el.p_floor_id,
				idx: idx,
				shortID: el.property_id,
				desktopImage: el.pv_apartment_image_desktop,
				mobileImage:el.pv_apartment_image_mobile,
				property_status: el.p_property_status_id
			};
			return appartmentData;
		}).sort((a,b)=> (a.buildingNum.localeCompare(b.buildingNum) || parseInt(a.floor_title) > parseInt(b.floor_title) || a.aptNum.localeCompare(b.aptNum))  ? 1 : -1 );;

		setAllAppartmentsData(appartmentsObject);

		if ($('#apartment_reserve__step-3__graphic').length > 0) {
			$('#apartment_reserve__step-3__graphic').find('#graphicArea').fadeIn();
			$('#graphic_loader').hide().removeClass('d-flex');
		}
	}
	return req;
};

const setAptProjectStatus = async (setAppartmentsDynamicData, projectID) => {
	if (!projectID) {
		return;
	}

	let langCode = sessionStorage.getItem("langCode");
	if (!langCode || langCode == '') {
		langCode = '';
	} else {
		langCode = '/' + langCode;
	}

	const req = await makeGetRequest(`/projectstatus`, `${projectID}${langCode}`).catch((err) => console.log(err));
	if (!req) {
		return;
	}
	const buildingsInfo = req.data[0];
	const buildings = buildingsInfo.buildings;
	setAppartmentsDynamicData(buildings);
	return req;
};

const DisplayStep = (props) => {
	const projectSlice = useSelector((state) => state.project);
	const registerSlice = useSelector((state) => state.register);
	const step = useSelector((state) => state.project.currentStep);
	const projectID = useSelector((state) => state.project.projectID);
	const meetingslug = useSelector((state) => state.verification.slug);
	const isClient = useSelector((state) => state.verification.isClient);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const agentKey = useSelector((state) => state.verification.agentKey);
	const allAppartmentsData = useSelector((state) => state.project.allAppartmentsData);
	const dynamicAppartmentsData = useSelector((state) => state.project.dynamicAppartmentsData);
	const buildingID = projectSlice.buildingID;
	const floorID = projectSlice.floorID;
	const appartmentID = projectSlice.appartmentID;
	const appartment_id = projectSlice.appartment_id;

	const appartmentBasicData = projectSlice.appartmentBasicData;
	const [pusherUpdate, setPusherUpdate] = useState(false);
	const [currentRemoteStep, setCurrentRemoteStep] = useState(0);
	const [trigger, setTrigger] = useState(0);
	const isSelfReserve = useSelector((state) => state.meeting.isSelfReserve);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const stripe_onoff = useSelector((state) => state.project.projectSettings.stripe_onoff);
	const tranzilla_onoff = useSelector((state) => state.project.projectSettings.tranzilla_onoff);

	useEffect(() => {}, [currentLanguge]);

	useEffect(() => {}, [currentRemoteStep]);

	useEffect(async () => {
		if (projectID) {
			await setAptProjectStatus(setAppartmentsDynamicData, projectID, pusherUpdate);
		}
	}, [trigger, projectID]);

	useEffect(async () => {
		try {
			$("#navViewChange").hide()

			if (projectID) {
				let res = await addAllAppartmentsData(setAllAppartmentsData, projectID);

				if (!res) {
					return;
				}
				$("#navViewChange").show()
				setIsLoading(true);
			}
		} catch (err) {
			console.log(err);
		}
	}, [projectID]);

	useEffect(() => {
		pusherSubscribe(pusher, meetingslug, allAppartmentsData, isClient, isAgent, agentKey,buildingID,floorID,appartment_id, isSelfReserve, currentLanguge);
	}, [meetingslug]);

	useEffect(() => {
		try {
			var channel = pusher.subscribe(`projectstatus-${projectID}`);
			let updateEvt = new CustomEvent("project_availability");
			console.log("conntected to main pusher");
			channel.bind("statuschange", function (data) {
				document.dispatchEvent(updateEvt);
				setTrigger((prev) => !prev);
			});
		} catch (err) {
			console.log(err);
		}
	}, []);

	if (step === "1") {
		return <BuildingsSelection handleBuildingSelect={handleBuildingSelect} {...props} />;
	}
	if (step === "2") {
		return <FloorSelection dynamicAppartmentsData={dynamicAppartmentsData} buildingID={buildingID} handleFloorSelect={handleFloorSelect} {...props} />;
	}
	if (step === "3") {
		return (
			<ApartmentSelectionList
				dynamicAppartmentsData={dynamicAppartmentsData}
				floorID={floorID}
				buildingID={buildingID}
				handleAppartmentSelect={handleAppartmentPreview}
				appartmentID={appartmentID}
				{...props}
			/>
		);
	}
	if (step === "4") {
		return (
			<ApartmentDetails appartmentID={appartmentID} buildingID={buildingID} floorID={floorID} handleAppartmentSelect={handleAppartmentSelect} {...props} />
		);
	}
	if (step === "5") {
		return (
			<DetailsApproval
				formSelector={registerSlice}
				appartmentData={appartmentBasicData}
				handleSubmit={handleNewClient}
				handleSignatureSubmit={handleSignatureSubmit}
				{...props}
			/>
		);
	}
	if (step === "5.5") {
		return <ContractSide handleSignatureSubmit={handleSignatureSubmit} isAgent={isAgent} meetingSlug={meetingslug} />;
	}
	
	if (step === "6" && tranzilla_onoff == '1') {
		return <Payment {...props} handlePaymentSubmit={handlePaymentSubmit} />;
		// return <StripePayment {...props} />;
	} else if (step === "6" && stripe_onoff == '1') {
		return <StripePayment {...props} />;
	}

	if (step === "8") {
		return <Finish handleRequestSignMeeting={handleRequestSignMeeting} {...props} />;
	}
	if (step === "7") {
		return <FollowUpMeeting {...props} />;
	}
	if (step === "9") {
		return <ApartmentSelectionGraphic {...props} />;
	}
	if (step === "10") {
		return <ApartmentSelectionTable {...props} projectSlice={dynamicAppartmentsData} />;
	} else {
		return <BuildingsSelection handleBuildingSelect={handleBuildingSelect} {...props} />;
	}
};

export default DisplayStep;
