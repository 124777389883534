import "./ToastMessage.sass";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import $ from "jquery";

const ToastMessage = (props) => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
    useEffect(() => {
        // Set directions to html elements
        let langCode = sessionStorage.getItem("langCode");
        if (langCode && langCode != '') {
            if (currentLanguge.direction == 'ltr') {
                $('#toast_container').css({'right': 0, 'left':'unset'});
                $('.toast-close').css({'right': '10px', 'left':'unset'});
            } else {
                $('#toast_container').css({'right': 'unset', 'left':0});
                $('.toast-close').css({'right': 'unset', 'left': '10px'});
            }
        }
    }, [currentLanguge]);

    return (
        <div className="position-fixed bottom-0 p-3" id='toast_container'>
            <div id="liveToast" className="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" data-bs-delay="20000">
                <div className="toast-header">
                    <strong className="mx-auto f18">{props.title}</strong>
                    <button type="button" className="btn-close toast-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div className="toast-body text-center f16">
                    {props.msg}
                </div>
            </div>
        </div>
	);
};

export default ToastMessage;
