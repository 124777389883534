
import $ from 'jquery';


export const sortByProperty = (propName, direction, type) => (a, b) => {
	if (direction === "up") {
		if (type === "str") {
			if (a[propName] > b[propName]) {
				return -1;
			} else if (a[propName] < b[propName]) {
				return 1;
			} else {
				return 0;
			}
		} else if (type === "int") {
			if (parseInt(a[propName]) > parseInt(b[propName])) {
				return -1;
			} else if (parseInt(a[propName]) < parseInt(b[propName])) {
				return 1;
			} else {
				return 0;
			}
		}
	} else if (direction === "down") {
		if (type === "str") {
			if (a[propName] < b[propName]) {
				return -1;
			} else if (a[propName] > b[propName]) {
				return 1;
			} else {
				return 0;
			}
		} else if (type === "int") {
			if (parseInt(a[propName]) < parseInt(b[propName])) {
				return -1;
			} else if (parseInt(a[propName]) > parseInt(b[propName])) {
				return 1;
			} else {
				return 0;
			}
		}
	}
};

export const sortAptNumV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("aptNum", direction, "int"));
	setMainDisplay(filteredResults);
	highlightSortCTA('aptNum', direction);
	if (meetingSlug) {
		setRemoteAction({ sortType: "aptNumSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortbuildingV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("buildingNum", direction, "str"));
	highlightSortCTA('buildingNum', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "buildingNumSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortFloorNumV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("floorNum", direction, "int"));
	highlightSortCTA('floorNum', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "floorNumSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortRoomsV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("roomsNum", direction, "int"));
	highlightSortCTA('roomsNum', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "roomsNumSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortSizeV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("appartmentTerrace", direction, "int"));
	highlightSortCTA('appartmentTerrace', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "appartmentTerraceSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortTerraceV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("balconyTerrace", direction, "int"));
	highlightSortCTA('balconyTerrace', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "balconyTerraceSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortGardenTerraceV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("gardenTerrace", direction, "int"));
	highlightSortCTA('gardenTerrace', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "gardenTerraceSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortServiceTerraceV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("serviceTerrace", direction, "int"));
	highlightSortCTA('serviceTerrace', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "serviceTerraceSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortPriceV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("price", direction, "int"));
	highlightSortCTA('price', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "priceSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortSpecialPriceV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("priceHever", direction, "int"));
	highlightSortCTA('priceHever', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "priceHeverSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortModelV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("model", direction, "str"));
	highlightSortCTA('model', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "modelSort", payload: direction, eventName: "sort", author: isAgent });
	}
};

export const sortDirectionV2 = (setMainDisplay, direction, mainDisplay, meetingSlug, setRemoteAction, isAgent) => {
	let filteredResults = [...mainDisplay];
	filteredResults.sort(sortByProperty("direction", direction, "str"));
	highlightSortCTA('direction', direction);
	setMainDisplay(filteredResults);
	if (meetingSlug) {
		setRemoteAction({ sortType: "directionSort", payload: direction, eventName: "sort", author: isAgent });
	}
};


const highlightSortCTA = (selector, direction) => {
	$('.sortHighlight').removeClass('sortHighlight')
	$(`span[data-sortaction="${selector}"]`).find(`.fa-sort-${direction}`).addClass('sortHighlight')
}

export const sortAptNum = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];
	filteredResults.sort(sortByProperty("aptNum", direction, "int"));
	setMainDisplay(filteredResults);
};

export const sortbuilding = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];

	filteredResults.sort(sortByProperty("buildingNum", direction, "int"));
	setMainDisplay(filteredResults);
};

export const sortFloorNum = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];

	filteredResults.sort(sortByProperty("floorNum", direction, "int"));
	setMainDisplay(filteredResults);
};

export const sortRooms = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];

	filteredResults.sort(sortByProperty("roomsNum", direction, "int"));
	setMainDisplay(filteredResults);
};

export const sortSize = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];
	filteredResults.sort(sortByProperty("appartmentTerrace", direction, "int"));
	setMainDisplay(filteredResults);
};

export const sortTerrace = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];

	filteredResults.sort(sortByProperty("balconyTerrace", direction, "int"));
	setMainDisplay(filteredResults);
};

export const sortPrice = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];
	filteredResults.sort(sortByProperty("price", direction, "int"));
	setMainDisplay(filteredResults);
};

export const sortSpecialPrice = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];

	filteredResults.sort(sortByProperty("priceHever", direction, "int"));
	setMainDisplay(filteredResults);
};

export const sortModel = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];

	filteredResults.sort(sortByProperty("model", direction, "str"));
	setMainDisplay(filteredResults);
};

export const sortDirection = (setMainDisplay, direction, content) => {
	let filteredResults = [...content];

	filteredResults.sort(sortByProperty("direction", direction, "str"));
	setMainDisplay(filteredResults);
};

