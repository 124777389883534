import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./VideoComponentMobile.sass";
import MeetingVideo from "../MeetingVideo";

const MeetingVideoMobile = memo(() => {
	const [show, setShow] = useState(false);
	const meetingStatus = useSelector((state) => state.meeting.meetingStatus);
	
	// seraching agent + timer
	useEffect(() => {
		if (meetingStatus == 'start') {
			setShow(true)
		} else {
			setShow(false)
		}
	}, [meetingStatus]);


	if (meetingStatus == 'start') {
		return (
			<div id="meetingVideoMobile__container" className="pt-2">
				<MeetingVideo />
			</div>
		)
	}

	return (
		<></>
	);
});

export default MeetingVideoMobile;
