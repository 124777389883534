import StepTitle from "../../UI/Titels/StepTitle";
import "./ApartmentSelectionGraphic.sass";
import { useState, useEffect } from "react";
import { store } from "../../../Redux/Store/store";
import "./popover.sass";
import Building from "./Components";
import { handleTableAppartmentView, setRemoteAction } from "../../../Helpers/AppartmentReserveUtils";
import { sliceIntoChunks } from "../../../Helpers/MeetingsUtils";
import { useSelector } from "react-redux";
import jQuery from "jquery";
import { ConvertCurrency } from "../../../Helpers/ConvertCurrency";
import $ from "jquery"; 
import GTEvent from "../../../Helpers/GTEvent";

const ApartmentSelectionGraphic = (props) => {
	const dynamicAppartmentsData = useSelector((state) => state.project.dynamicAppartmentsData);
	const allAppartmentsData = useSelector((state) => state.project.allAppartmentsData);
	const meetingSlug = store.getState().verification.slug;
	const isAgent = useSelector((state) => state.meeting.isAgent);

	const [list, setList] = useState([dynamicAppartmentsData]);
	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != "undefined" ? ConvertCurrency(project_default_currency) : "";
	const clientType = useSelector((state) => state.meeting.clientType);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const defaultMetric = useSelector((state) => state.project.projectSettings.project_default_metric)
	
	const [buildings, setBuildings] = useState([]);
	const [buildingsToShow, setBuildingsToShow] = useState([]);
	
	const [currentStep, setCurrentStep] = useState(0);
	const [maxSteps, setMaxSteps] = useState(0);
	const [slidesToShow, setSlidesToShow] = useState(0);
	const [sliderInitDone, setSliderInitDone] = useState(false);
	
	useEffect(() => { }, [currentLanguge]);


	// Popover functions
	const getAptStatusElement = (status) => {
		let elemClass = '';
		let elemText = '';
		if (status == 1) {
			elemClass = 'apartment_status__available';
			elemText = `${currentLanguge.apartmentStats.availableApt}`;
		} else if (status == 2) {
			elemClass = 'apartment_status__progress';
			elemText = `${currentLanguge.apartmentStats.progressApt}`;
		} else if (status == 3 || status == 4) {
			elemClass = 'apartment_status__reserved';
			elemText = `${currentLanguge.apartmentStats.reservedApt}`;
		} 

		if (elemClass != '' && elemText != '') {
			return `<span class='${elemClass} apartment_status sm_aptStatus mds-3 f12'>
				${elemText}
				<div class="p-2"></div>
				<span></span>
			</span>`;
		} else {
			return '';
		}
	}

	const getAptPricesElement = (price, priceHever, currencySymbol) => {
		if (clientType > 1) {
			return `<div class="old_price">${parseInt(price).toLocaleString()}${currencySymbol}</div>
				<div class="pds-3 f16">${currencySymbol}${priceHever.toLocaleString()}</div>`;
		} else {
			return `<div class="old_price"></div>
				<div class="pds-3 f16">${currencySymbol}${parseInt(price).toLocaleString()}</div>`;
		}
	}

	const getPopupFooter = (res) => {
		if (res.status == 1) {
			return `<div class="popover-footer m-0 p-0 row">
				<div class="col-12 d-flex flex-column justify-content-center m-0 p-0 text-center">
					<a class="btn rf-btn-primary btn-lg col-12 align-self-center pfButton text-center w600" 
						id="graphicSelectionCTA" 
						data-gt-category='graphic view' data-gt-action='click' data-gt-label='select apartment'
						data-buildingid="${res.props.buildingID}" 
						data-floorid="${res.props.floorID}" 
						data-slug="${res.props.id}" 
						data-shortid="${res.props.shortID}" 
						data-buildingtitle="${res.props.buildingNum}" 
						data-floortitle="${res.props.floorNum}" 
						data-apttitle="${res.props.aptNum}">${currentLanguge.apartmentSelectionGraphic.watchApt}</a>
				</div>
			</div>`;
		} else {
			return '';
		}
	}
	
	let buildPopup = (id) => {
		const parent = document.createElement("div");
		getElementAsync(id, dynamicAppartmentsData, allAppartmentsData).then((res) => {
			let directionIconHtml = res.props.direction && currentLanguge.directions[res.props.direction.toLowerCase()] ? `<div class="col-auto mde-2 info-item fw-bold pds-0">
				<img src="/assets/img/icons/ico-price.svg" class="mde-2" style="height: 25px; width: 25px;" alt="${currentLanguge.directions[res.props.direction.toLowerCase()]}">
				<span class="f16">${currentLanguge.directions[res.props.direction.toLowerCase()]}</span>
			</div>` : ``;

			let popoverContent = `
				<div class='popover-header popoverHead align-self-start d-flex justify-content-start m-0 p-0'>
					<a class='p-${id}'></a>
					<div class='apartment_titles d-flex justify-content-center'>
						<h5 class='fw-bold smHeader'>${!isNaN(res.props.aptNum) ? currentLanguge.appartmentShort.apartment : ''} ${res.props.aptNum}</h5>
						${getAptStatusElement(res.status)}
					</div>
				</div>
				<div class="popover-body m-0 p-0 mt-2">
					<div class="col-auto d-flex flex-row mb-2">
						<div class="col-auto mde-2 info-item fw-bold pds-0">
							<img src="/assets/img/icons/ico-rooms.svg" class="mde-2" style="height: 25px; width: 25px;" alt="${res.props.roomsNum} ${currentLanguge.appartmentShort.rooms}">
							<span class="f16">${res.props.roomsNum} ${currentLanguge.appartmentShort.rooms}</span>
						</div>

						<div class="col-auto mde-2 info-item fw-bold pds-0">
							<img src="/assets/img/icons/ico-sign.svg" class="mde-2" style="height: 25px; width: 25px;" alt="${res.props.appartmentTerrace} ${currentLanguge.general.metric[defaultMetric]}">
							<span class="f16">${res.props.appartmentTerrace} ${currentLanguge.general.metric[defaultMetric]}</span>
						</div>

						${directionIconHtml}
					</div>

					<div class="col-auto d-flex mb-2">
						<div class="col-auto d-flex flex-row mde-2 info-item fs-6 fw-bold pds-0">
							<img src="/assets/img/icons/ico-price.svg" style="height: 25px; width: 25px;" alt=${currentLanguge.apartmentSelectionTable.price}>
							${getAptPricesElement(res.props.price, res.props.priceHever, res.currencySymbol)}
						</div>
					</div>
				</div>
				${getPopupFooter(res)}
			`;
			parent.innerHTML = popoverContent;
		});
		return parent;
	};

	useEffect(() => {
		if (allAppartmentsData.length > 0 && dynamicAppartmentsData.length > 0 && sliderInitDone == true) {
			$('#apartment_reserve__step-3__graphic').find('#graphicArea').fadeIn();
			$('#graphic_loader').hide().removeClass('d-flex');
		}
	}, [sliderInitDone])


	useEffect(() => {
		document.addEventListener("project_availability", () => {
			setList(dynamicAppartmentsData);
		});

		return () => {
			document.removeEventListener("project_availability", () => {});
		};
	}, []);

	const handleIncrement = () => {
		if (currentStep < maxSteps - 1) {
			setCurrentStep((prev) => prev + 1);
		}
	};

	const handleDecrement = () => {
		if (currentStep > 0) {
			setCurrentStep((prev) => prev - 1);
		}
	};

	useEffect(async () => {
		if (dynamicAppartmentsData.length > 0) {
			const res = sliceIntoChunks(dynamicAppartmentsData, 3);
			const content = [...res[0]];
			const dataaa = content.map((el) => {
				let newFloors = [].concat(el.floors).reverse();
				return {
					building_id: el.building_id,
					building_title: el.building_title,
					floors: newFloors,
				};
			});
			setBuildings([dataaa]);	
		}
		
	}, [list, dynamicAppartmentsData]);


	const returnAsync = async (value) => {
		return new Promise((resolve) => {
			resolve(value);
		});
	};

	const getElementAsync = async (id, list, list2) => {
		let data = {
			status: "",
		};
		for (let item of list) {
			for (let floor of item.floors) {
				for (let apt of floor.properties) {
					if (apt.property_id == id) {
						data.status = apt.status;
					}
				}
			}
		}
		for (let obj of list2) {
			if (obj.shortID == id) {
				data = { props: obj, ...data };
			}
		}
		data.currencySymbol = currencySymbol;

		return returnAsync(data);
	};

	useEffect(() => {
		let j7 = jQuery.noConflict();

		j7("body").on("mousedown", "#graphicSelectionCTA", function (e) {
			const { buildingid, floorid, slug, shortid, buildingtitle, floortitle, apttitle } = e.target.dataset;

			let payload = {
				buildingID: buildingid,
				floorID: floorid,
				slug: slug,
				id: shortid,
				buildingTitle: buildingtitle,
				floorTitle: floortitle,
				aptTitle: apttitle,
			};

			handleTableAppartmentView(payload, currentLanguge);
			$(".popover").remove();
			GTEvent(e);
		});

		document.addEventListener("openPopup", (e) => {
			$(".popover").remove();
			e.stopPropagation();
			let elem = document.getElementById(e.detail);
			var bs = window.bootstrap.Popover.getOrCreateInstance($(`span#${e.detail}.floor-stat__available`)[0]);
			bs.show();
		});

		

		return () => {
			document.removeEventListener("openPopup", () => {});
			j7("body").off("mousedown", ".popover", () => {});
			$(".popover").remove();
		};
	}, []);

	useEffect(() => {
		var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
		var popoverListAddEvent = popoverTriggerList.map(function (popoverTriggerEl) {
			return new window.bootstrap.Popover(popoverTriggerEl, {
				trigger: "focus",
				container: "#graphicArea",
				html: true,
				customClass: `popoverContainer popoverContainer${popoverTriggerEl.id}`,
				placement: "top",
				animation: true,

				content: function () {
					return details_in_popup(popoverTriggerEl.id);
				},
			});
		});

		$('[data-bs-toggle="popover"]').on('click', function (e) {
			$('.popoverContainer').not(`.popoverContainer${e.target.id}`).remove();
		});
	});


	// Graphic slider - window resize and init
	useEffect(() => {
		if (buildings.length == 0) return;
		initSlider();
		window.addEventListener('resize', initSlider);
		return () => {
			window.removeEventListener('resize', initSlider);
		};
	}, [buildings]);
	
	// setting current slides data
	useEffect(() => {
		if (buildings.length == 0) return;
		$('#building_slider').fadeOut();
		setTimeout(() => {
			setBuildingsToShow(buildings[0].slice(currentStep, currentStep + (slidesToShow)));
		}, 500)
		
		$('#building_slider').fadeIn();
	}, [maxSteps, currentStep, slidesToShow])
	
	// Init slider
	const initSlider = () => {
		if (buildings.length == 0 || buildings[0].length == 0) return;
		let slider = $('#building_slider')[0];
		let sw = slider.offsetWidth; // slider width
		let sc = buildings[0].length; // slides total count
		let sts = sw >= 1000 ? 3 : sw >= 600 ? 2 : 1; // slides to show: desktop (1200 and above): 3, tablet (800 - 1200): 2, mobile (less then 800): 1
		setMaxSteps(1 + sc - sts);
		setSlidesToShow(sts);

		setSliderInitDone(true);
	}

	function details_in_popup(id) {
		const pop = buildPopup(id);

		setTimeout(() => {
			try {
				let elem = document.getElementById("targetdiv");
				elem.innerHTML = pop.innerHTML;
				if (pop) {
					$(".innerpopoverloader").remove();
				}
			} catch (err) {
				console.log(err);
			}
		}, 500);

		return `<div id='targetdiv' class="popoverloader m-auto">
			<div class="innerpopoverloader d-flex justify-content-center">
			<div class="thumbAnim  d-flex justify-content-center align-self-center">
				<div class="bounce1"></div>
				<div class="bounce2"></div>
				<div class="bounce3"></div>
			</div>
			</div>
		</div>`;
	}

	const showdata = (e) => {
		if (meetingSlug) {
			setRemoteAction({ payload: e.target.id, eventName: "openPopup", author: isAgent });
		}
	};


	return (
		<div className="row m-0 apartment_reserve__stepContainer" id="apartment_reserve__step-3__graphic">
			{/* Step Content  */}
			<div className="col px-3 py-4" id="graphicArea">
				<div className="row">
					<div className="col-auto">
						<StepTitle className="fw-bold pfHeaderShadow ">{currentLanguge.apartmentSelectionGraphic.selectApt}</StepTitle>
					</div>
					<div className="col-auto mds-auto d-flex align-items-center">
						<a onClick={() => handleDecrement()} className={`rf-arrow mde-2 direction_rotate ${currentStep > 0 ? '' : 'rf-arrow-disabled'}`} alt="prev" 
							data-gt-category='graphic view' data-gt-action='click' data-gt-label='previous building'>
							<i className="bi bi-arrow-right-short"></i>
						</a>

						<a onClick={() => handleIncrement()} className={`rf-arrow direction_rotate ${currentStep < maxSteps - 1 ? '' : 'rf-arrow-disabled'}`} alt="next" 
							data-gt-category='graphic view' data-gt-action='click' data-gt-label='next building'>
							<i className="bi bi-arrow-left-short"></i>
						</a>
					</div>
				</div>

				{/* Building Slider  */}
				<div className="row mt-3" id="building_slider">
					<Building list={buildingsToShow} showdata={showdata} />
				</div>
			</div>
			{/* graphic loader */}
			<div className="sideImageLG d-flex justify-content-center align-self-center w-100 my-4" id="graphic_loader">
				<div className="bounce1"></div>
				<div className="bounce2"></div>
				<div className="bounce3"></div>
			</div>
		</div>
	);
};

export default ApartmentSelectionGraphic;
