
const PageContentWrapper = props => {
    return (
        <div className="container-fluid" id={props.id}>
            <div className="row">
                {props.children}
            </div>
      </div>
    );
  }
  
  export default PageContentWrapper;
  