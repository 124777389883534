import { memo } from "react";
import { getPriceForClientType } from "../../../Helpers/AppartmentReserveUtils";
import store from "../../../Redux/Store/store";
import Appartment from "./Appartment";

const Appartments = ({ list, roomsLocal, handleAppartmentSelect, buildingID, floorID, handleAptClick }) => {
	const clientType = store.getState().meeting.clientType;

	var priceProp = getPriceForClientType(clientType);

	if (!list) {
		return null;
	}
	return list.map((el, idx) => {
		roomsLocal.push(el.pv_number_of_rooms);
		return (
			<Appartment
				direction={el.p_property_direction}
				aptID={el.property_id}
				id={el.p_property_slug}
				firstPrice={el.price1}
				lastPrice={el.price4}
				clientTypePrice={el[priceProp]}
				rooms={el.pv_number_of_rooms}
				sqrMeter={el.pv_total_sqr_area}
				key={idx}
				title={el.p_property_title}
				status={el.p_property_status_id}
				description={el.property_model_description}
				thumbImg={el.pv_apartment_image_mobile}
				handleAppartmentSelect={handleAppartmentSelect}
				buildingID={buildingID}
				floorID={floorID}
				locationImg={el.p_property_image_onfloor_plan}
				handleAptClick={handleAptClick}
				imageDesktop={el.pv_apartment_image_desktop}
				idx={idx}
				clientType={clientType}
			/>
		);
	});
};

const AppartmentsView = ({ list, roomsLocal, handleAppartmentSelect, buildingID, floorID, handleAptClick }) => {
	if (!list || !roomsLocal) {
		return null;
	}
	return (
		<div className="list-group apartments_list" id="aptScroller" key={roomsLocal}>
			<Appartments
				list={list}
				roomsLocal={roomsLocal}
				handleAppartmentSelect={handleAppartmentSelect}
				buildingID={buildingID}
				floorID={floorID}
				handleAptClick={handleAptClick}
			/>
		</div>
	);
};

export default memo(AppartmentsView);
