import Building from "./Building";
import { useMemo } from "react";
import { sortRoomsArray } from "../../../Helpers/AppartmentReserveUtils";

const BuildingsView = ({ list, handleBuildingSelect, onContainerClick,isAgent,meetingSlug }) => {
	let res = useMemo(() => {
		try {
			if (list.length < 1) {
				return (
					<div className="col-12 d-flex justify-content-center align-self-center m-auto">
						<div className="spinner-grow top-50" role="status">
						</div>
					</div>
				);
			}

			return list.map((el, idx) => {
				let sortedRooms = sortRoomsArray(el.roomsinfo);
				return (
					<Building
						floors={el.floorscount}
						buildingName={el.building_title}
						rooms={sortedRooms}
						key={idx}
						startingPrice={el.pricefrom}
						buildingID={el.id}
						thumbImg={el.first_picture}
						handleBuildingSelect={handleBuildingSelect}
						onContainerClick={onContainerClick}
						idx={idx}
						isAgent={isAgent}
						meetingSlug={meetingSlug}
					/>
				);
			});
		} catch (err) {
			console.log(err);
			return (
				<div className="col-12 d-flex justify-content-center align-self-center m-auto">
					<div className="spinner-grow top-50 " role="status">
					</div>
				</div>
			);
		}
	}, [list]);
	return res;
};

export default BuildingsView;
