import { useSelector } from "react-redux";
import { useEffect } from "react";
import { handleSetMeetingAgentControl } from "../Utils";
import $ from "jquery";
import store from "../../../Redux/Store/store";

const AgentControlToggler = () => {

    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const agentControl = useSelector((state) => state.meeting.agentControl);
	const meetingStatus = useSelector((state) => state.meeting.meetingStatus);
	const meetingslug = useSelector((state) => state.verification.slug);

	// set Btns Style based on agent control
	useEffect(() => {
		if (agentControl == true) {
			$('#agentControlBtn').addClass('pfActiveControl').prop('disabled', true);
			$('#clientControlBtn').removeClass('pfActiveControl').prop('disabled', false);
		} else {
			$('#clientControlBtn').addClass('pfActiveControl').prop('disabled', true);
			$('#agentControlBtn').removeClass('pfActiveControl').prop('disabled', false);
		}
	},[agentControl]);
	
	// Lock screen control
	useEffect(() => {
		if (meetingStatus === "start") {
			if (meetingslug) {
				if (agentControl) {
					if (!isAgent) {
						lockScreen("agentControl");
					}
					if (isAgent) {
						lockScreen("clientControl");
					}
				}
				if (!agentControl) {
					if (!isAgent) {
						lockScreen("clientControl");
					}
					if (isAgent) {
						lockScreen("agentControl");
					}
				}
			}
		} else {
			return () => {};
		}
	},[meetingStatus, agentControl, isAgent]);

	useEffect(() => { }, [currentLanguge]);

	const lockScreen = (state) => {
		let elem = document.getElementById("appContainer");
		let navViewChange = document.querySelectorAll(".navViewChange");
		const userApprovedApt = store.getState().project.userApprovedApt;
	
		if (state === "clientControl") {
			elem.style.pointerEvents = "auto";
			if(!userApprovedApt) navViewChange.forEach((el) => (el.style.pointerEvents = "auto"));
		}
		if (state === "agentControl") {
			elem.style.pointerEvents = "none";
			navViewChange.forEach((el) => (el.style.pointerEvents = "none"));
		}
	};

	return (
		<div className={`agentActionRow ${isAgent ? '' : 'd-none'}`}>
			<p className="p-0 m-0 w700 mde-2">{currentLanguge.meetingVideoComp.sectionTitle_controls}</p>

			<button
				className="btn rf-btnPrimery f16 w600 controlButtons"
				onClick={(e) => (isAgent ? handleSetMeetingAgentControl(false) : null)}
				id="clientControlBtn"
			>
				{currentLanguge.meetingVideoComp.clientControlBtn}
			</button>
			<button
				className="btn rf-btnPrimery f16 w600 mds-1 controlButtons pfActiveControl "
				onClick={(e) => (isAgent ? handleSetMeetingAgentControl(true) : null)}
				id="agentControlBtn"
			>
				{currentLanguge.meetingVideoComp.agentControlBtn}
			</button>
		</div>
	);
};
    
export default AgentControlToggler;