import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const GeneralModal = (props) => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge);
	const [show, setShow] = useState(false);
	const agentControl = useSelector((state) => state.meeting.agentControl);
	const step = useSelector((state) => state.project.currentStep);

	const getUrlParams = new URLSearchParams(window.location.search);
	const agentKey = getUrlParams.get("agentkey");
	const isAgent = agentKey != '' && agentKey != null ? true : false;
	
	useEffect(() => { }, [currentLanguge]);

	useEffect(() => {
		if (((isAgent && !agentControl) || (!isAgent && agentControl)) && step == "5") {
			setShow(props.showAgentModal);	
		} else if (step != "5") {
			setShow(false);	
		}
    }, [props, step]);

	return (
		<Modal show={show}  centered >
			<Modal.Header className="align-self-center pfMdFont position-relative w-100  flex-column">
                <p>{isAgent ? currentLanguge.detailsApproval_step.agentWaitingModalTitle : currentLanguge.detailsApproval_step.agentWaitingModalTitle_customer}</p>
			</Modal.Header>
		</Modal>
	);
};

export default GeneralModal;
