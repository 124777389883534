import $ from 'jquery';
import { setRemoteAction } from '../../../Helpers/AppartmentReserveUtils';
let imgParentId = "buildingImgCont";
let imagesPropName = "buildingImages";
let currentContainerID = "";

export const onRemoteContainerClick = (e) => {
	try {
		e.stopPropagation();
		let selector = e.detail;
		let elem = document.querySelector(`[data-blid=${selector}]`);
		let parent = elem.parentElement;
		let id = parent.id;
		
		if (parent.classList.contains("buildings_list__item")) {
			// change image
			let imgParent = document.getElementById(imgParentId);
			let copy = [...window[imagesPropName]];
			let currentImage = copy.filter((el) => el.id === id);
			currentImage[0].img.style.opacity = 0;
			imgParent.replaceChild(currentImage[0].img, imgParent.firstElementChild);
			setTimeout(() => {
				$("#buildingImgCont").children("img").fadeTo(400, 1);
			}, 0);


			// select container and show btn
			let button = parent.querySelector("button");
			if (!button) {
				return;
			}
			if (button) {
				button.classList.remove("disappear");
				parent.classList.add("blueBorder");
				parent.classList.remove("notSelectedGrey");
				currentContainerID = button.id;
			}

			// remove other selected
			let elems = document.querySelectorAll(".buildings_list__item");
			elems.forEach((el) => {
				if (el.id !== id) {
					el.classList.remove("blueBorder");
					el.querySelector("button").classList.add("disappear");
					el.classList.add("notSelectedGrey");
				}
			});

			// scroll to element
			$('html, body').animate({
				scrollTop: parseInt($(`#${id}`).offset().top - 100)
			}, 200);
		}
	} catch (err) {
		console.log(err);
	}
};

//on continer click
export const onContainerClick = (e, meetingSlug, isAgent) => {

	e.stopPropagation();
	let parent = e.target.parentElement;
	let id = parent.id;
	let target = e.target;

	if (parent.classList.contains("buildings_list__item") && !target.classList.contains('buildings_list__item-cta')) {
		// change image
		let imgParent = document.getElementById(imgParentId);
		let copy = [...window[imagesPropName]];
		let currentImage = copy.filter((el) => el.id === id);
		currentImage[0].img.style.opacity = 0;
		imgParent.replaceChild(currentImage[0].img, imgParent.firstElementChild);
		setTimeout(() => {
			$("#buildingImgCont").children("img").fadeTo(400, 1);
		}, 0);

		// select container and show btn
		let button = parent.querySelectorAll("button");
		if (!button) {
			return;
		}
		if (button) {
			button.forEach(btn => {
				btn.classList.remove("disappear");
				parent.classList.add("blueBorder");
				parent.classList.remove("notSelectedGrey");
				currentContainerID = btn.id;
			})
			
		}

		// remove other selected
		let elems = document.querySelectorAll(".buildings_list__item");
		elems.forEach((el) => {
			if (el.id !== id) {
				el.classList.remove("blueBorder");
				$(el).find("button").addClass('disappear');
				el.classList.add("notSelectedGrey");
			}
		});

		// scroll to element
		// $('html, body').animate({
		// 	scrollTop: parseInt($(`#${id}`).offset().top - 100)
		// }, 200);
		// dispach remote event
		if (meetingSlug) {
			setRemoteAction({ payload: e.target.dataset.blid, eventName: "buildingClick", author: isAgent });
		}
	}
};
