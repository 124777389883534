import StepTitle from "../../UI/Titels/StepTitle";
import "./ApartmentSelectionTable.sass";
import { useSelector } from "react-redux";

import MobileTable from './MobileTable/MobileTable'
import DesktopTable from "./DesktopTable/DesktopTable";

const ApartmentSelectionTable = (props) => {
	
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	return (
		<>
			<div className="row m-0 overflow-hidden apartment_reserve__stepContainer" id="apartment_reserve__step-3__table">
				{/* Step Content  */}
				<div className="col-12 px-3 py-4">
					<div className="row">
						<div className="col-xl-8">
							<StepTitle className="fw-bold pfHeaderShadow ">{currentLanguge.apartmentSelectionTable.stepTitle}</StepTitle>
						</div>
					</div>
					
					<DesktopTable/>
					<MobileTable/>
						
				</div>
			</div>
		</>
	);
};

export default ApartmentSelectionTable;
