import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./Payment.sass";
import $ from "jquery";
import AppartmentShort from "../../General/AppartmentShort/AppartmentShort";
import Amount from "./Amount";
import { useSelector } from "react-redux";
import { showErrorMessage } from "../../../Helpers/verificationUtils";
import { ConvertCurrency } from "../../../Helpers/ConvertCurrency";
import { NumberWithCommas } from "../../../Helpers/NumberFormat";
import PaymentModal from "./PaymentModal";
import ProcessModal from "./ProcessModal";
import { setLoadingModalContent } from "../../../Helpers/AppartmentReserveUtils";
import { handleExistingImage } from "../BuildingsSelection/image";
import { setRemoteAction } from "../../../Helpers/AppartmentReserveUtils";

const Payment = (props) => {
	const [tranzilaAccount, setTranzilaAccount] = useState("");
	const [isCharging, setIsCharging] = useState(false);

	const [result, setResult] = useState("");
	const [resultForAgent, setResultForAgent] = useState("");

	const projectReservationPrice = useSelector((state) => state.project.projectSettings.project_reservation_price);

	const projectTranzilaAccount = useSelector((state) => state.project.projectSettings.project_tranzila_account);
	const project_sales_phone = useSelector((state) => state.project.projectSettings.project_sales_phone);

	const projectTransactionMode = useSelector((state) => state.project.projectSettings.project_tranzila_tranmode);
	const selectedFloorImg = useSelector((state) => state.project.selectedAptImg);

	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != "undefined" ? ConvertCurrency(project_default_currency) : "";
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	const meetingSlug = useSelector((state) => state.verification.slug);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const basicData = useSelector((state) => state.project.appartmentBasicData);

	useEffect(() => { }, [currentLanguge]);
	useEffect(() => {
		if (window.hasOwnProperty("$")) {
			window.$("#paymentImg").sticky({ topSpacing: 90, bottomSpacing: 140, wrapperClassName: "" });
		}
	}, []);

	const Phone = () => {
		if (project_sales_phone.includes('*') && currentLanguge.direction == 'rtl') {
			let newProject_sales_phone = project_sales_phone.replace('*', '');
			return newProject_sales_phone + '*';
		} else {
			return project_sales_phone;
		}
	};

	useEffect(() => {
		setLoadingModalContent("");
		handleExistingImage(selectedFloorImg, "paymentImg", `${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`);
		let fields = window.TzlaHostedFields.create({
			sandbox: false,
			fields: {
				credit_card_number: {
					selector: "#ccnum",
					placeholder: currentLanguge.payment_step.credit_card_number_placeholder,
					tabindex: 1,
				},
				expiry: {
					selector: "#myexp",
					placeholder: currentLanguge.payment_step.expiry_placeholder,
					version: "1",
					tabindex: 2,
				},
				cvv: {
					selector: "#mycvv",
					placeholder: currentLanguge.payment_step.cvv_placeholder,
					tabindex: 3,
				},
				card_holder_id_number: {
					selector: "#myid",
					placeholder: currentLanguge.payment_step.card_holder_id_number_placeholder,
					tabindex: 5,
				},
			},
			styles: {
				input: {
					height: "100%",
					"align-self": "center",
					width: "100%",
					"font-size": "16px",
					"border-radius": "5px",
					"text-align": "center",
				},
				"input:focus::placeholder": {
					opacity: "0",
				},
				"input::placeholder": {
					"text-align": "center",
					"font-size": "16px",
				},
				height: "fit-content",
				width: "fit-content",
				body: {
					display: "flex",
					"justify-content": "center",
				},
				label: {
					display: "none",
				},
			},
		});
		window.fields = fields;
	}, []);
	useEffect(() => {
		setTranzilaAccount(projectTranzilaAccount);
	}, [projectTranzilaAccount]);

	useEffect(() => {
		document.addEventListener("paymentInProgress_start", (e) => {
      const getUrlParams = new URLSearchParams(window.location.search);
      const agentKey = getUrlParams.get("agentkey");
      const isAgent = agentKey != '' && agentKey != null ? true : false;
      if (meetingSlug && isAgent) {
        setIsCharging(true);
        setResultForAgent(e.detail)
      }
		});

    document.addEventListener("paymentInProgress_end", (e) => {
      const getUrlParams = new URLSearchParams(window.location.search);
      const agentKey = getUrlParams.get("agentkey");
      const isAgent = agentKey != '' && agentKey != null ? true : false;
      if (meetingSlug && isAgent) {
        setResultForAgent(e.detail)
        setTimeout(() => {
          setIsCharging(false);
        },8000)
      }
    });
    
		return () => {
			document.removeEventListener("paymentInProgress_start", () => {});
			document.removeEventListener("paymentInProgress_end", () => {});
		};
	}, []);

	const handleResponse = (messages) => {
		messages.map((message) => {
			if (message.code === "credit_card_number_invalid") {
				showErrorMessage("invalidCard", message.message);
			}
			if (message.code === "expiry_invalid") {
				showErrorMessage("invalidExpiru", message.message);
			}
			if (message.code === "cvv_invalid") {
				showErrorMessage("invalidCVC", message.message);
			}
			if (message.code === "card_holder_id_number_invalid") {
				showErrorMessage("invalidID", message.message);
			}
		});
	};

	const charge = () => {
		window.fields.charge(
			{
				terminal_name: tranzilaAccount, // todo change it to correct terminal name
				credit_card_number: $("#ccnum").val(),
				amount: $("#sum").val(),
				expiry: $("#myexp").val(),
				cvv: $("mycvv").val(),
				card_holder_id_number: $("#myid").val(),
				tran_mode: projectTransactionMode,
			},
			function (err, response) {
				console.log("err: " + JSON.stringify(err));
				console.log("response:" + JSON.stringify(response));
				let name = document.querySelector("#fullName");

				if (name.value < 5) {
					showErrorMessage("invalidName", currentLanguge.payment_step.invalidName);
				}
				if (err) {
					handleErrors(err);
					handleResponse(err.messages);
					setIsCharging(false);
					/* TODO:
					*  Send via pusher - paymentInProgress_end + result
					*/
					if (meetingSlug) {
						setRemoteAction({ eventName: "paymentInProgress_end", payload: {error: err}, author: isAgent });
					}
				}
				if (response) {
					setResult(response);
					setIsCharging(false);
					/* TODO:
					*  Send via pusher - paymentInProgress_end + result
					*/
					if (meetingSlug) {
						setRemoteAction({ eventName: "paymentInProgress_end", payload:  {response: response}, author: isAgent });
					}
				}
			}
		);
	};

	
	const fakeSubmit = async (e) => {
		e.preventDefault();

		setIsCharging(true);
		/* TODO:
		*  Send via pusher - paymentInProgress_start
		*/
		if (meetingSlug) {
			setRemoteAction({ eventName: "paymentInProgress_start", payload: true, author: isAgent });
		}
		charge();
	};

	function handleErrors(error) {
		error.messages.forEach(function (message) {
			$("#errors_for_" + message.param).text(message.message);
		});
	}
	const handleChargeState = (value) => {
		setIsCharging(value);
	};
	return (
		<div className="row m-0 apartment_reserve__stepContainer" id="apartment_reserve__step-6">
			<div className="stepCon">
				<h1 className="fw-bold mt-4 f48">{currentLanguge.payment_step.stepTitle}</h1>
				<span className="f20 w500 mb-3">
					{currentLanguge.payment_step.descriptionText1} <b>{`${currencySymbol}${NumberWithCommas(projectReservationPrice)}`}</b>. <br />
					{currentLanguge.payment_step.descriptionText2}
					<br />
					<span>
						{" "}
						{currentLanguge.payment_step.helpText} <span style={{ direction: "rtl" }}>{Phone()}</span>
					</span>
				</span>
				{/* Apartment Details again for review before signing  */}
				<AppartmentShort id={"paymentModal"} />
				<div className="col-xxl-12 d-flex flex-column justify-content-center flashit">
					<Form className="col-xxl-6 col-xl-8 col-lg-8 col-md-10 align-self-center" onSubmit={(e) => fakeSubmit(e)} id="paymentForm">
						<PaymentModal success={result} />
						<ProcessModal isCharging={isCharging}  data={resultForAgent} />
						<Form.Group className="d-flex flex-column input-group">
							<label className="mb-0 h-auto mds-2 paymentLabel">{currentLanguge.payment_step.ccnum_lable}</label>
							<div className="form-control pfShadow rounded w-100  paymentFieldName" style={{ height: "50px" }} id="ccnum" name="ccnum" />
						</Form.Group>
						<div className="invalidField" id="invalidCard"></div>
						<Form.Group className="d-flex flex-column input-group">
							<label className="mb-0 h-auto mds-2 paymentLabel">{currentLanguge.payment_step.myexp_lable}</label>
							<div className="form-control pfShadow rounded w-100 paymentFieldName" style={{ height: "50px" }} id="myexp" name="myexp" />
						</Form.Group>
						<div className="invalidField" id="invalidExpiru"></div>

						<Form.Group className="d-flex flex-column input-group">
							<label className="mb-0 h-auto mds-2 paymentLabel">{currentLanguge.payment_step.mycvv_lable}</label>
							<div className="form-control pfShadow rounded w-100 paymentFieldName" style={{ height: "50px" }} id="mycvv" name="mycvv" />
						</Form.Group>
						<div className="invalidField" id="invalidCVC"></div>

						<h2 className="pfMdFont mt-4">{currentLanguge.payment_step.customerDetailsTitle}</h2>
						<Form.Group className="d-flex flex-column input-group-lg">
							<Form.Label className="mb-0 h-auto mds-2 paymentLabel">{currentLanguge.payment_step.fullName_lable}</Form.Label>
							<Form.Control
								tabIndex={4}
								className="form-control pfShadow rounded w-100 paymentFieldName"
								style={{ height: "50px" }}
								id="fullName"
								name="fullName"
								placeholder={currentLanguge.payment_step.fullName_placeholder}
							/>
						</Form.Group>
						<div className="invalidField" id="invalidName"></div>

						<Form.Group className="d-flex flex-column input-group-lg">
							<label className="mb-0 h-auto mds-2 paymentLabel">{currentLanguge.payment_step.myid_lable}</label>
							<div className="form-control pfShadow rounded w-100 paymentFieldName" style={{ height: "50px" }} id="myid" name="myid" />
						</Form.Group>
						<div className="invalidField" id="invalidID"></div>

						<Amount />
						<Button variant="primary col-12 pfInput pfShadow mt-2 f18 pfSubmitBtnColor" type="submit" id="paymentSubmitSelect">
							{currentLanguge.payment_step.stepCTA}
						</Button>
						<div className="invalidField" id="paymentError" />
					</Form>
					<div className="col-12 d-flex flex-column">
						<div className="d-flex flex-row justify-content-between align-self-center align-items-center flex-wrap mobileTextCenter">
							<span className="pfItemFontSm mb-3">{currentLanguge.payment_step.paymentFooterText}</span>
							<div className="d-flex mobileContentCenter mobileFuulWidth mb-3">
								<span className="col-auto mds-1">
									<img src="/assets/img/icons/ico-amex.png" alt="amex"/>
								</span>
								<span className="col-auto mds-1">
									<img src="/assets/img/icons/ico-master.png" alt="master"/>
								</span>
								<span className="col-auto mds-1">
									<img src="/assets/img/icons/ico-visa.png" alt="visa"/>
								</span>
							</div>
						</div>
						<div className="col d-flex flex-row align-self-center pb-5 flex-wrap mobileContentCenter">
							<span className="col-auto mds-1">
								<img src="/assets/img/icons/ico-auth.png" alt="auth"/>
							</span>
							<span className="col-auto mds-1">
								<img src="/assets/img/icons/ico-digital.png" alt="digital"/>
							</span>
							<span className="col-auto mds-1">
								<img src="/assets/img/icons/ico-secure.png" alt="secure"/>
							</span>
							<span className="col-auto mds-1">
								<img src="/assets/img/icons/ico-pci.png" alt="pci"/>
							</span>
						</div>
					</div>
				</div>
			</div>
			{/* Step Graphics  */}
			<div className="bg-image rounded" id="paymentImg">
				<div className="col-12 d-flex justify-content-center align-self-center m-auto position-relative">
					<img src={selectedFloorImg} alt={`${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`} />
				</div>
			</div>
		</div>
	);
};

export default Payment;
