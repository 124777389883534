import PropTypes from 'prop-types';


// InfoWindow component
const InfoWindow = (props) => {
  const infoWindowStyle = {
    position: 'relative',
    bottom: 180,
    left: 147,
    width: 320,
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  return (
    <div className='markerrr' style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>
        {props.children}
      </div>
    </div>
  );
};


InfoWindow.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};


export default InfoWindow;
