import Video from "twilio-video";
import {
	requestPaymentSkip,
	requestSignatureSkip,
	changeMeetingAgentControl,
	setShowVideoModal,
	setVideoModalMessage,
} from "../../../Helpers/AppartmentReserveUtils";
import $ from 'jquery';

export const setVideoLoadingData = (trigger, message) => {
	setShowVideoModal(trigger);
	setVideoModalMessage(message);
	return;
};

export let clearLocalTracks = () => {
	const localVideo = document.getElementById("localVideo");
	if (localVideo.querySelector("video")) {
		localVideo.innerHTML = " ";
	}
};

export const detachTracks = (track) => {
	var detachedElements = track.detach();
	detachedElements.forEach(function (el) {
		el.remove();
	});
};

export const resetHolders = (track) => {
	const remoteVideo = document.getElementById("remoteVideo");
	let videoelem = remoteVideo.querySelector("video");
	let audiolem = remoteVideo.querySelector("audio");
	if (videoelem) {
		if (track.kind === "video") {
			videoelem.remove();
		}
	}
	if (audiolem) {
		if (track.kind === "audio") {
			audiolem.remove();
		}
	}
};

export function updateVideoDevice(value) {
	let localVideo = document.getElementById("localVideo");
	const localParticipant = window.room.localParticipant;
	Video.createLocalTracks({ audio: false, video: { deviceId: { exact: value } } }).then(function (localVideoTrack) {
		localParticipant.tracks.forEach((publication) => {
			if (publication.track) {
				if (publication.track.kind === "video") {
					localParticipant.unpublishTrack(publication.track);
				}
			}
		});
		let trackToPublish = localVideoTrack.find((track) => track.kind === "video");
		localParticipant.publishTrack(trackToPublish);
		if (localVideo.querySelector("video")) {
			localVideo.querySelector("video").remove();
		}
		localVideo.appendChild(trackToPublish.attach());
	});
}

export async function updateAudioDevice(value) {
	const localParticipant = window.room.localParticipant;
	const localAudioTrack = await Video.createLocalTracks({ audio: { deviceId: { exact: value } }, video: false });
	localParticipant.tracks.forEach((publication) => {
		if (publication.track) {
			let track = publication.track;
			if (track.kind === "audio") {
				localParticipant.unpublishTrack(track);
			}
		}
	});
	let trackToPublish = localAudioTrack.find((track) => track.kind === "audio");
	localParticipant.publishTrack(trackToPublish);
}

// AgentLogic
export const freezAgentControls = () => {
	$('#agentControlBtn').prop('disabled', true);
	$('#clientControlBtn').prop('disabled', true);
}
// AgentLogic
export const unfreezAgentControls = () => {
	$('#agentControlBtn').prop('disabled', false);
	$('#clientControlBtn').prop('disabled', false);
}

// AgentLogic
export const handleSetMeetingAgentControl = (agent_control) => {
	freezAgentControls();
	changeMeetingAgentControl(agent_control)
};


export const handleSignatureSkip = () => {
	requestSignatureSkip();
};

export const handlePaymentSkip = () => {
	requestPaymentSkip();
};

export const setScreenShareInRoom = (room, localTrack) => {
	navigator.mediaDevices
		.getDisplayMedia()
		.then((stream) => {
			room.localParticipant.unpublishTrack(localTrack);

			let screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0]);

			room.localParticipant.publishTrack(screenTrack);

			screenTrack.on("stopped", (track) => {
				room.localParticipant.unpublishTrack(screenTrack);
				track.stop();
				room.emit("getSource");
				let detach = track.detach();
				detach.forEach((el) => {
					el.remove();
				});
			});
		})
		.catch(() => {
			alert("Could not share the screen.");
		});
};
