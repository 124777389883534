import { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./Signature.sass";

const Signature = (props) => {
	let sigRef = useRef();
	const [signed, setSigned] = useState([]);

	useEffect(() => {
		try {
			if (signed.length > 0) {
				props.setIsSigEmpty(false);
				props.setSignatureBase64(sigRef.current.toDataURL(sigRef));
			}
		} catch (err) {
			console.log(err);
		}
	}, [signed]);

	useEffect(() => {
		let elem = document.querySelector("#cleanSig");

		elem.addEventListener("click", () => {
			sigRef.current.clear();
			setSigned(false);
			props.setIsSigEmpty(true);
		});
	}, []);
	return (
		<SignatureCanvas
			throttle={0}
			onEnd={(e) => setSigned(()=>[Math.random,"refresh"])}
			ref={sigRef}
			backgroundColor="rgb(231,231,231)" 
			penColor="black"
			canvasProps={{ className: "mb-2 py-3 pfSignature align-self-center w-100", 'data-gt-category':'digital signature', 'data-gt-action' : 'click' ,'data-gt-label' : 'signing' }}

		/>
	);
};

export default Signature;
