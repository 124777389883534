import "./ApartmentDetails.sass";

import ApartmentAdditionalDetail from "./ApartmentAdditionalDetail";
import TabsContent from "../../UI/Tabs/TabsContent";
import { useCallback, useEffect, useState } from "react";
import { makeGetRequest } from "../../../Axios";
import {
	setAppartmentBasicaData,
	setFeautresListPerm,
	setLoadingModalContent,
	setSelectedAptImg,
	setSelectedAptMainImg,
} from "../../../Helpers/AppartmentReserveUtils";
import { useSelector } from "react-redux";
import { AppartmentInfo } from "./AppartmentInfo";
import $ from "jquery";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { setRemoteAction } from "../../../Helpers/AppartmentReserveUtils";
import ReactPlayer from 'react-player';

const ApartmentDetails = (props) => {
	const [appartmentData, setAppartmentData] = useState({ isLoaded: false });
	const [isAvailable, setIsAvailable] = useState(false);
	const [icons, setIcons] = useState([]);
	const [gallery, setGallery] = useState([]);
	const [galleryVideo, setGalleryVideo] = useState("");
	const appartmentID = useSelector((state) => state.project.appartmentID);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const meetingSlug = useSelector((state) => state.verification.slug);
	const agentControl = useSelector((state) => state.meeting.agentControl);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const defaultMetric = useSelector((state) => state.project.projectSettings.project_default_metric)

	const buildingName = useSelector((state) => state.project.buildingName);
	const [aptImageSrc, setAptImageSrc] = useState("");
	const [featuresList, setFeaturesList] = useState([]);

	const [tourSrc, setTourSrc] = useState("https://tours.3destate.pl/interjet-demo/a2.01.3d");
	const [model360, setModel360] = useState("https://360.3destate.pl/interjet-demo/a2.01?hideDarkModeButton=true");
	const [additional_files, setAdditional_files] = useState([]);
	const project_3dapikey = useSelector((state) => state.project.projectSettings.project_3dapikey)


	const [previewGallery_slides, setPreviewGallery_slides] = useState([
		{ imgSrc: "", imgAlt: "" },
		{ imgSrc: "", imgAlt: "" },
		{ imgSrc: "", imgAlt: "" },
	]);

	const FileIcons = {
		xlsx: "/assets/img/icons/icons8-xls.svg",
		xls: "/assets/img/icons/icons8-xls.svg",
		pdf: "/assets/img/icons/icons8-pdf.svg",
		doc: "/assets/img/icons/icons8-doc.svg",
		docx: "/assets/img/icons/icons8-doc.svg",
		other: "/assets/img/icons/icons8-file.svg",
	};

	const onTabClick = (id) => {
		setRemoteAction({ eventName: "tabClick", payload: id, author: isAgent });
	};

	useEffect(() => { }, [currentLanguge]);
	
	useEffect(async () => {
		try {
			$('#apartmentDetails_content').hide();
			$('#apartmentDetails_loader').show();

			let langCode = sessionStorage.getItem("langCode");
			if (!langCode || langCode == '') {
				langCode = '';
			} else {
				langCode = '/' + langCode;
			}


			if (!appartmentData.isLoaded) {
				if (appartmentID) {
					const req = await makeGetRequest("/properties_short", `${appartmentID}${langCode}`).catch((err) => console.log(err));
					if (!req) {
						return;
					}
					setLoadingModalContent("");
					if (req) {
						let tempFeaturesList = [];
						const {
							property_direction,
							property_status_id,
							property_image_onfloor_plan,
							property_image_mobile,
							price1,
							price2,
							price3,
							price4,
							id,
							property_title,
							property_slug,
							building_id,
							floor_id,
						} = req.data.propertydata;

						const {
							number_of_rooms,
							total_sqr_area,
							garden_sqr_area,
							service_place_sqr_area,
							terrace_sqr_area,
							specifications,
							apartment_thumbnail,
							gallery,
							apartment_video,
							apartment_image_desktop,
							apartment_image_mobile,
							apartment_main_image,
							apartment_plan_image,
							apartment_plan,
							additional_files,
							property_model_description,
						} = req.data.modeldata;

						const appartmentBasicData = {
							rooms: number_of_rooms,
							sqrMeter: total_sqr_area,
							gardenSize: garden_sqr_area,
							balconySize: terrace_sqr_area,
							serviceSize: service_place_sqr_area,
							oldPrice: price1,
							newPrice: price2,
							title: property_title,
							direction: property_direction,
							status: property_status_id,
							locationImage: property_image_onfloor_plan,
							id: property_slug,
							buildingID: building_id,
							floorID: floor_id,
							buildingName: buildingName,
							imageThumb: apartment_thumbnail,
							apartment_image_desktop: apartment_image_desktop,
							price1,
							price2,
							price3,
							price4,
							apartment_plan_image: apartment_plan_image,
							apartment_plan: apartment_plan,
							additional_files: additional_files,
							property_model_description: property_model_description
						};

						setAdditional_files(additional_files);
						setAppartmentBasicaData(appartmentBasicData);
						setAppartmentData(appartmentBasicData);

						if (apartment_main_image == '' || !apartment_main_image) {
							if (property_image_mobile == '') {
								setAptImageSrc(apartment_image_mobile);
								setSelectedAptMainImg(apartment_image_mobile)
							} else {
								setAptImageSrc(property_image_mobile);
								setSelectedAptMainImg(property_image_mobile)
							}
						} else {
							setAptImageSrc(apartment_main_image);
							setSelectedAptMainImg(apartment_main_image)
						}

						if (property_image_mobile == '') {
							setSelectedAptImg(apartment_image_mobile);
						} else {
							setSelectedAptImg(property_image_mobile);
						}
					

						const sqrMeterDisplay = `${appartmentBasicData.sqrMeter} ${currentLanguge.general.metric[defaultMetric]}`;
						const aptTerraceObj = { val: currentLanguge.apartmentDetails_step.aptTerrace, sub: sqrMeterDisplay, icon: "/assets/feat-icons/ico-measuring-tape.svg" };
						tempFeaturesList.push(aptTerraceObj);
						
						if (appartmentBasicData.balconySize && appartmentBasicData.balconySize > 1) {
							const sqrBalconyDisplay = `${appartmentBasicData.balconySize} ${currentLanguge.general.metric[defaultMetric]}`;
							const balconyTerraceObj = { val: currentLanguge.apartmentDetails_step.balconyTerrace, sub: sqrBalconyDisplay, icon: "/assets/feat-icons/ico-rooms.svg" };
							tempFeaturesList.push(balconyTerraceObj);
						}

						if (appartmentBasicData.gardenSize && appartmentBasicData.gardenSize > 1) {
							const sqrGradenDisplay = `${appartmentBasicData.gardenSize} ${currentLanguge.general.metric[defaultMetric]}`;
							const gradenTerraceObj = { val: currentLanguge.apartmentDetails_step.gradenTerrace, sub: sqrGradenDisplay, icon: "/assets/feat-icons/ico-garden.svg" };
							tempFeaturesList.push(gradenTerraceObj);
						}

						if (appartmentBasicData.serviceSize && appartmentBasicData.serviceSize > 1) {
							const sqrSerivesDisplay = `${appartmentBasicData.serviceSize} ${currentLanguge.general.metric[defaultMetric]}`;
							const serviceTerraceObj = { val: currentLanguge.apartmentDetails_step.serviceTerrace, sub: sqrSerivesDisplay, icon: "/assets/feat-icons/ico-rooms.svg" };
							tempFeaturesList.push(serviceTerraceObj);
						}

						
						const specificationsArr = specifications ? Object.values(specifications) : [];

						setGallery(gallery);
						setGalleryVideo(getGalleryVideoFullURL(apartment_video));
						setFeaturesList(tempFeaturesList.concat(specificationsArr));
						tempFeaturesList.shift();
						setFeautresListPerm(tempFeaturesList.concat(specificationsArr));
					}
					$('#apartmentDetails_loader').hide();
					$('#apartmentDetails_content').show();
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, [appartmentID, currentLanguge]);

	useEffect(() => {
		document.addEventListener("tabClick", (e) => {
			//handle showing the tabs content here

			// remove active tab
			$('#apartment-details-tabs').find('.nav-link.active').removeClass('active').removeClass('false');
			$('#apartment-details-tabs__content').find('.tab-pane.show.active').removeClass('show').removeClass('active');

			// set active tab
			$('#apartment-details-tabs').find(`#${e.detail}`).addClass('active').addClass('false');
			$('#apartment-details-tabs__content').find(`[aria-labelledby=${e.detail}]`).addClass('active').addClass('show');
		});

		
		let controlNext = document.querySelector(".control-next");
		let controlPrev = document.querySelector(".control-prev");


		$('#apartment-details-tabs .nav-link').on('click', (e) => {
			if (e.currentTarget.id == 'gallery-tab') {
				$('.thumbs .thumb').off('click').on('click', (e) => {
					let selectedTumb = $('.thumbs .thumb').index($(e.currentTarget));

					if (meetingSlug) {
						if ((isAgent && agentControl) || (!isAgent && !agentControl)) {
							setRemoteAction({ eventName: "imageChange", payload: selectedTumb, author: isAgent });
						}
					}
					playGalleryVideo(selectedTumb);

				})
			}
		})

		controlPrev.addEventListener("click", (e) => {
			let totalTumbs = $('.thumbs .thumb').length;
			let selectedTumb = $('.thumbs .thumb').index($('.thumbs .thumb.selected'));
			let newselectedTumb = selectedTumb == 0 ? 0 : selectedTumb - 1;

			if (meetingSlug) {
				if ((isAgent && agentControl) || (!isAgent && !agentControl)) {
					setRemoteAction({ eventName: "imageChange", payload: newselectedTumb, author: isAgent });
				}
			}

			
			playGalleryVideo(newselectedTumb);
		});

		controlNext.addEventListener("click", (e) => {
			let totalTumbs = $('.thumbs .thumb').length;
			let selectedTumb = $('.thumbs .thumb').index($('.thumbs .thumb.selected'));
			let newselectedTumb = selectedTumb == totalTumbs - 1 ? selectedTumb : selectedTumb + 1;

			if (meetingSlug) {
				if ((isAgent && agentControl) || (!isAgent && !agentControl)) {
					setRemoteAction({ eventName: "imageChange", payload: newselectedTumb, author: isAgent });
				}
			}

			playGalleryVideo(newselectedTumb);
			
		});

		document.addEventListener("imageChange", (e) => {
			$($('.thumbs .thumb')[e.detail]).trigger('click');
			// playGalleryVideo(e.detail);
		});
		
		return;
	}, [agentControl]);


	const getGalleryVideoFullURL = (vidURL) => {
		if (vidURL == '') return false;
		let vidID = '';
		if(vidURL.includes('https://www.youtube.com/watch?v=')) vidID = vidURL.substr('https://www.youtube.com/watch?v='.length , vidURL.length)
		if(vidURL.includes('https://youtu.be/')) vidID = vidURL.substr('https://youtu.be/'.length, vidURL.length)
		if (vidURL.includes('https://www.youtube.com/embed/')) vidID = vidURL.substr('https://www.youtube.com/embed/'.length , vidURL.length)
		
		return `http://www.youtube.com/embed/${vidID}`;
	}

	const playGalleryVideo = (slide) => {
		if ($($('.slide')[slide]).find('iframe').length > 0) {
			$($('.slide')[slide]).find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
		} else {
			if($('.slide').find('iframe')[0]) $('.slide').find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
		}
		
	}


	useEffect(() => {
		try {
			if (gallery.length > 1) {
				// let size = gallery.length;
				let mappedPreview = gallery.map((el) => {
					return { imgSrc: el.filepath, imgAlt: "" };
				});

				setPreviewGallery_slides(mappedPreview);
			}

		} catch (err) {
			console.log(err);
		}
	}, [gallery]);

	const tabsTriggers = {
		id: "apartment-details-tabs",
		items: [
			{
				id: "general-tab",
				target: "#general",
				controls: "general",
				title: currentLanguge.apartmentDetails_step.tabsTrigger_general,
				active: true,
				visible: true,
			},
			{
				id: "gallery-tab",
				target: "#gallery",
				controls: "gallery",
				title: currentLanguge.apartmentDetails_step.tabsTrigger_gallery,
				active: false,
				visible: true,
			},
			{
				id: "tour3D-tab",
				target: "#tour3D",
				controls: "tour3D",
				title: currentLanguge.apartmentDetails_step.tabsTrigger_tour3D,
				active: false,
				visible: project_3dapikey && project_3dapikey != '' ? true : false,
			},
			{
				id: "plans-tab",
				target: "#plans",
				controls: "plans",
				title: currentLanguge.apartmentDetails_step.tabsTrigger_plans,
				active: false,
				visible: !appartmentData.apartment_plan || appartmentData.apartment_plan == "" ? false : true,
			},
			{
				id: "downloads-tab",
				target: "#downloads",
				controls: "downloads",
				title: currentLanguge.apartmentDetails_step.tabsTrigger_downloads,
				active: false,
				visible: additional_files.length > 0 ? true : false,
			},
		],
	};

	const Icons = useCallback(() => {
		try {
			if (featuresList) {
				let values = Object.values(featuresList);

				if (!values) {
					return null;
				}
				
				let content = values.map((el, idx) => {
					let splitted = el.icon.split("/");
					return <ApartmentAdditionalDetail title={el.val} subTitle={el.sub} imgSrc={`/assets/img/icons/${splitted[3]}`}  id={idx} key={idx} />;
				});
				return content;
			}
			return null;
		} catch (err) {
			console.log(err);
		}
	}, [featuresList]);

	const refreshGallery = (id) => {
		if (meetingSlug) {
			onTabClick(id);
		}
	};

	const all = () => {
		let mapped = previewGallery_slides.map((el) => {
			return (
				<img src={el.imgSrc} alt={currentLanguge.apartmentDetails_step.alt_gallery} id={el.imgSrc} key={el.imgSrc} />
			);
		})
		let vidSlide = galleryVideoSlides();
		if (vidSlide != null) mapped.push(vidSlide);
		return mapped
	};

	const galleryVideoSlides = () => {
		if (galleryVideo == "") return;
		return <ReactPlayer controls={false} width="100%" url={galleryVideo} className='react_player' key="galleryVideoSlide"/>;
	}

	const downloadFiles = additional_files.map((el, idx) => {
		var fileExt = el.filepath.split(".").pop();
		let src = "";

		if (fileExt in FileIcons) {
			src = FileIcons[fileExt];
		} else {
			src = FileIcons["other"];
		}
		let fileTitle = el.filetitle.replace(`.${fileExt}`, "");

		return (
			<div key={fileTitle}>
				<a href={el.filepath}  target="_blank" className="fileDownloadLink fw-bold f20 mb-2 mde-3" alt={el.filetitle}
					data-gt-category='apartment details' data-gt-action='click' data-gt-label='download apartment file'>
					<img className="mde-2 mb-2" src={src} alt="" />
					<span className="f18">{fileTitle}</span>
				</a>
			</div>
		);
	});

	const getVideoThumbSrc = (videoId) => `https://img.youtube.com/vi/${videoId}/0.jpg`;

	const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length -1, url.length);
	
	const customRenderThumb = (children) => {
		let tumbs = [];
		if (children.length > 0) {
			tumbs = children;	
			return tumbs.map((item) => {
				if (item.type && item.type == 'img') {
					return <img src={item.props.src} alt={currentLanguge.apartmentDetails_step.alt_galleryThumb} key={item.key} />;	
				} else if (item.type) {
					const videoId = getVideoId(item.props.url);
					return <img src={getVideoThumbSrc(videoId)} alt={currentLanguge.apartmentDetails_step.alt_videoThumb}  key={item.key} />;
				}
			});
		} else {
			return null;
		}
	}

	const generalTabGraphics = (project3dapikey) => {
		if (!project3dapikey || project3dapikey == '') {
			return <img src={aptImageSrc} className="aptMainImg" alt={currentLanguge.apartmentDetails_step.apartment + " " + appartmentData.title} />;
		} else {
			return <iframe className="iframe iframe-3destate-widget" allowFullScreen={true} src={model360} style={{ width: '100%', height: '500px', border: '0' }}></iframe>;
		}
	}

	const print3DTour = (project3dapikey) => {
		if (!project3dapikey || project3dapikey == '') {
			return;
		} else {
			return <TabsContent active="false" id="tour3D" labelledby="tour3D-tab" key={"tour3Dtabs"}>
				<iframe className="iframe iframe-3destate-widget" allowFullScreen={true} src={tourSrc} style={{width:'100%', height: '500px', border: '0'}}></iframe>
			</TabsContent>;
		}
	}

	
        
	return (
		<div className="row p-0 m-0 flashit apartment_reserve__stepContainer" id="apartment_reserve__step-4">
			<div id="apartmentDetails_loader" className="col justify-content-center" style={{ height: "900px", width: "540px", display: 'flex' }}>
				<div className="sideImageLG d-flex justify-content-center align-self-center">
					<div className="bounce1"></div>
					<div className="bounce2"></div>
					<div className="bounce3"></div>
				</div>
			</div>
			{/* Step Content  */}
			<div className="col px-3 py-4 position-relative" id="apartmentDetails_content">
				<AppartmentInfo setIsAvailable={setIsAvailable} />
				
				<button
					className={`btn rf-btn-primary w700 f18 py-2 px-4 ${isAvailable.status == 1 ? '' : 'd-none'}`}
					id="confirm_apartment_selection"
					data-apartment="1"
					type="button"
					data-gt-category='apartment details' data-gt-action='click' data-gt-label='select apartment'
					disabled={isAvailable.status == 1 ? false : true}
					onClick={(e) => props.handleAppartmentSelect(e, aptImageSrc, currentLanguge)}
				>
					{currentLanguge.apartmentDetails_step.stepCTA}
				</button>


				
				{/* Apartment Details Tabs  */}
				{/* Tabs triggers  */}
				<div id="apartment-details-tabs__trigger_container">
					<ul className="nav nav-tabs f18 px-0" id={tabsTriggers.id} role="tablist" key={tabsTriggers.id}>
						{tabsTriggers.items.map((item, idx) => {
							return (
								<li className={`nav-item ${item.visible ? "" : "d-none"}`} role="presentation" key={idx}>
									<button
										className={`nav-link text-nowrap ${item.active && "active"}`}
										id={item.id}
										onClick={() => refreshGallery(item.id)}
										data-bs-toggle="tab"
										data-bs-target={item.target}
										type="button"
										role="tab"
										data-gt-category='apartment details' data-gt-action='click' data-gt-label={`tab ${item.controls}`}
										aria-controls={item.controls}
										aria-selected="true"
									>
										{item.title}
									</button>
								</li>
							);
						})}
					</ul>
				</div>

				{/* Tabs content  */}
				<div className="tab-content" id="apartment-details-tabs__content">
					{/* General Details and description  */}
					<TabsContent active="true" id="general" labelledby="general-tab" key={"generaltabs"}>
						<div className="row">
							{/* Description and details  */}
							<div className="col-xl-5 col-sm-12 py-4 pfTextColor d-flex flex-column">
								<h3 className="previewHeadersFont fw-bold">{currentLanguge.apartmentDetails_step.aptDescriptionTitle}</h3>
								<p>
									{appartmentData.property_model_description}
								</p>

								{/* Additional Details  */}
								<h4 className="previewHeadersFont fw-bold mb-3">{currentLanguge.apartmentDetails_step.aptAdditionalDetailsTitle}</h4>
								<div className="apartment-additional-details">
									<Icons list={icons} />
								</div>
								<button
									className={`btn rf-btn-primary w700 f18 py-2 px-4 mde-auto mt-4 ${isAvailable.status == 1 ? '' : 'd-none'}`}
									id="confirm_apartment_selection2"
									data-apartment="1"
									type="button"
									data-gt-category='apartment details' data-gt-action='click' data-gt-label='select apartment'
									disabled={isAvailable.status == 1 ? false : true}
									onClick={(e) => props.handleAppartmentSelect(e, aptImageSrc, currentLanguge)}
								>
									{currentLanguge.apartmentDetails_step.stepCTA}
								</button>
							</div>

							{/* Gallery 1  */}
							<div className="col-xl-7 col-sm-12 p-2 pb-4 text-xl-start text-md-center">
								{generalTabGraphics(project_3dapikey)}
								
							</div>
						</div>
					</TabsContent>

					{/* 3D Tour */}
					{print3DTour(project_3dapikey)}

					{/* Gallery  */}
					<TabsContent className="pb-2" active="false" id="gallery" labelledby="gallery-tab" key={"gallerytabs"}>
						<Carousel
							// useKeyboardArrows={true}
							animationHandler="slide"
							autoPlay={false}
							swipeable={true}
							emulateTouch={true}
							showThumbs={true}
							renderThumbs={customRenderThumb}
							dynamicHeight={false}
							showIndicators={false}
							showStatus={false}
						>
							{all()}
							{/* {galleryVideoSlides()} */}

						</Carousel>
					</TabsContent>

					{/* Plans */}
					<TabsContent active="false" id="plans" labelledby="plans-tab" key={"planstabs"}>
						<div id="aptPlans" className="pt-4">
							<a href={appartmentData.apartment_plan} download target="_blank" className="fw-bold f20 mb-2"
									data-gt-category='apartment details' data-gt-action='click' data-gt-label='download apartment plans'>
								<img className="mde-2 " src="/assets/img/icons/ico-file-download.svg" alt="Download plans" />
								{currentLanguge.apartmentDetails_step.aptDownloadPlansLink}
							</a>
							{appartmentData.apartment_plan_image != '' && <img className={`aptPlansImage ${appartmentData.apartment_plan_image == '' ? 'd-none' : ''}`} src={appartmentData.apartment_plan_image} alt="Apartment Plans" />}
						</div>
					</TabsContent>

					{/* downloads */}
					<TabsContent active="false" id="downloads" labelledby="downloads-tab" key={"downloadstabs"}>
						<div className="mt-4 d-flex">{downloadFiles}</div>
					</TabsContent>
				</div>
			</div>
		</div>
	);
};

export default ApartmentDetails;
