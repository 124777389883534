import { memo, useEffect } from "react";
import { handleTableAppartmentView } from "../../../../Helpers/AppartmentReserveUtils";
import AppartmentLine from "./AppartmentLine";

const DesktopTableBody = ({ list,clientType,clientTypeName }) => {
	useEffect(() => {
		if (clientType) {
			let specialPriceColumn = document.querySelectorAll(".specialPriceColumn");
			specialPriceColumn.forEach((el) => {
				el.classList.remove("d-none")
			})
		}
	}, [])
	
	try {
		if (!list) {
			return (
				<div className="col-12 d-flex justify-content-center align-self-center m-auto position-relative">
					<div className="spinner-grow top-50 position-absolute" role="status">
						{/* <span className="sr-only">Loading...</span> */}
					</div>
				</div>
			);
		}
		return list.map((el, idx) => {
			let roomsInt = parseInt(el.roomsNum);
			let appartmentTerrace = parseInt(el.appartmentTerrace);
			// let roomsInt = parseInt(el.roomsNum)
			return (
				<AppartmentLine
					appartmentTerrace={appartmentTerrace}
					aptNum={el.aptNum}
					balconyTerrace={el.balconyTerrace}
					gardenTerrace={el.gardenTerrace}
					serviceTerrace={el.serviceTerrace}
					buildingNum={el.buildingNum}
					direction={el.direction}
					model={el.model}
					key={el.id}
					floorNum={el.floorNum}
					price={el.price}
					priceHever={el.priceHever}
					roomsNum={roomsInt}
					id={el.id}
					idx={idx}
					handleAppartmentPreview={handleTableAppartmentView}
					buildingID={el.buildingID}
					floorID={el.floorID}
					shortID={el.shortID}
					price1={el.price1}
					price2={el.price2}
					price3={el.price3}
					price4={el.price4}
					clientType={clientType}
					imageDesktop={el.desktopImage}
					// imageDesktop={el.mobileImage}


				/>
			);
		});
	} catch (err) {
		console.log(err);
	}
};

export default memo(DesktopTableBody);
