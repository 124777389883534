import './LanguageSelector.sass';
import { useSelector } from "react-redux";
import {setLanguage} from '../../../Helpers/ProjectLanding/index'
import { useEffect } from "react";
import $ from "jquery";
import {
    setBuildings,
    setLoadingModalContent,
    setIsChangingLang
} from "../../../Helpers/AppartmentReserveUtils";
import { makeGetRequest } from "../../../Axios";

const LanguageSelector = props => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge);
	const projectID = useSelector((state) => state.project.projectID);

    useEffect(() => {
        // Set directions to html elements
        let langCode = sessionStorage.getItem("langCode");
        if (langCode && langCode != '') {
            if (currentLanguge.direction == 'ltr') {
                $('html').css('direction', 'ltr').attr('dir', 'ltr').attr('lang', 'eng');
            } else {
                $('html').css('direction', 'rtl').attr('dir', 'rtl').attr('lang', 'heb');
            }
            sessionStorage.setItem('langCode', currentLanguge.langAPICode);
        }
    }, [currentLanguge]);


    
	useEffect(() => {
		try {
			if (projectID) {
				const requestBuildings = async () => {
					
					let langCode = sessionStorage.getItem("langCode");
					if (!langCode || langCode == '') {
						langCode = '';
					} else {
						langCode = '/' + langCode;
					}

					const req = await makeGetRequest("/projectbuildings",  `${projectID}${langCode}`).catch((err) => console.log(err));
					if (!req || req.data.statusCode == '404') {
						return;
					}
					if (req) {
						if (req.data.message) {
                            const mapped = req.data.message.map((el, idx) => el);
							setBuildings(mapped);
						}
					}
				};
				requestBuildings();
			}
		} catch (err) {
			console.log(err);
		}
	}, [currentLanguge]);

    const changeLang = (lang) => {
        setLoadingModalContent(true);
        setIsChangingLang(true);
        
        setTimeout(() => {
            setLanguage(lang);
        }, 400)
    }

    return (
        <>
            <div className="dropdown language_selector_desktop">
                <button className=" btn btn-secondary dropdown-toggle fw-bold" type="button" id="language_selector__dropdown" data-bs-toggle="dropdown" aria-expanded="false" data-gt-category='change language' data-gt-action='click' data-gt-label='toggle dropdown'>
                    <img src='/assets/img/icons/ico-globe.svg' height='20' className='svgImg' alt={currentLanguge.general.languageSelectorTitle}/> {currentLanguge.general.languageSelectorTitle}
                </button>
                <ul className="dropdown-menu" aria-labelledby="language_selector__dropdown">
                    <li><a onClick={(e) => changeLang('heb')} className={`dropdown-item ${currentLanguge.language == 'heb' ? 'active' : ''}`} data-gt-category='change language' data-gt-action='click' data-gt-label='change language to hebrew'>עברית</a></li>
                    <li><a onClick={(e) => changeLang('eng')} className={`dropdown-item ${currentLanguge.language == 'eng' ? 'active' : ''}`} data-gt-category='change language' data-gt-action='click' data-gt-label='change language to english'>English</a></li>
                </ul>
            </div>

            <div className="container my-0 mb-5 mds-0 language_selector_mobile">
                <div className="row gy-md-2 flex-column justify-content-md-start">
                    <div className="col text-nowrap fw-bold px-lg-2 px-md-0 nav-section-title col d-lg-none">
                        {currentLanguge.general.languageSelectorTitle}  
                    </div>
                    <ul className="list-group">
                        <li className={`list-group-item ${currentLanguge.language == 'heb' ? 'active' : ''}`} data-gt-category='change language' data-gt-action='click' data-gt-label='change language to hebrew'><a onClick={(e) => changeLang('heb')}>עברית</a></li>
                        <li className={`list-group-item ${currentLanguge.language == 'eng' ? 'active' : ''}`} data-gt-category='change language' data-gt-action='click' data-gt-label='change language to english'><a onClick={(e) => changeLang('eng')}>English</a></li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default LanguageSelector;
