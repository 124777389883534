import { useEffect, useState } from "react";
import { makeGetRequest } from "../../../Axios";
import StepTitle from "../../UI/Titels/StepTitle";
import { useSelector } from "react-redux";
import "./ApartmentSelectionList.sass";
import "../DetailsApproval/DetailsApproval.sass";
import "./ApartmentSelectionList.sass";
import $ from "jquery";
import Donut from "../../Stats/Donut/Donut";
import {
	handleAppartmentPreview,
	setApartmentsBack,
	setLoadingModalContent,
	setRemoteAction,
} from "../../../Helpers/AppartmentReserveUtils";
import { AptRoomFilter } from "../../General/RoomsFilter/RoomsFilter";
import { handleExistingImage, handleLargeImagesDisplay } from "../BuildingsSelection/image";
import { SideImagePlaceholder } from "../../Layout/ImagePlacholders/ImagePlacholders";
import AppartmentsView from "./Appartments";

const ApartmentSelectionList = (props) => {
	const [roomsFilter, setRoomsFilter] = useState("0");
	const [priceFilter, setPriceFilter] = useState("0");
	const [mainAppartments, setMainAppartments] = useState([]);
	const floorID = useSelector((state) => state.project.floorID);
	const buildingID = useSelector((state) => state.project.buildingID);
	const selectedFloorImg = useSelector((state) => state.project.selectedFloorImg);
	const selectedAptImg = useSelector((state) => state.project.selectedAptImg);
	const token = sessionStorage.getItem("forestToken");

	let buildingName = useSelector((state) => state.project.buildingName);
	const [appartmentsData, setApprtmentData] = useState([]);
	const [roomsLocal, setRoomsLocal] = useState([]);
	const [requestOk, setRequestOk] = useState(false);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const meetingSlug = useSelector((state) => state.verification.slug);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	useEffect(() => { }, [currentLanguge]);
	
	let imgParentId = "aptSideImg";
	let imagesPropName = "aptImages";
	let firstImageProp = "aptMain";
	
	useEffect(async () => {
		try {
			if (!floorID || floorID == '') return;
			setLoadingModalContent("");
			// if (appartmentsData.length == 0) {
				
				let langCode = sessionStorage.getItem("langCode");
				if (!langCode || langCode == '') {
					langCode = '';
				} else {
					langCode = '/' + langCode;
				}
				const req = await makeGetRequest("/floorapartments",  `${floorID}${langCode}`);
				if (!req) {
					return;
				}
				if (req.data.message) {
					setApprtmentData(() => req.data.message);
					setRequestOk(true);
					setApartmentsBack(req.data.message);
				}
			// }
		} catch (err) {
			console.log(err);
		}
	}, [currentLanguge, token, floorID]);

	useEffect(() => {
		if (requestOk) {
			handleLargeImagesDisplay(appartmentsData, "pv_apartment_image_desktop", "aptSideImg", "property_id", firstImageProp, imgParentId, imagesPropName, false, 'p_property_title', currentLanguge.apartmentSelectionTable.headerApartment);
			if (!selectedFloorImg) {
				handleExistingImage(selectedAptImg, imgParentId);
			}
			handleExistingImage(selectedFloorImg, imgParentId);
		}
	}, [requestOk]);

	useEffect(() => {
		document.addEventListener("filterFChange", (e) => {
			setRoomsFilter(e.detail);
		});
		return () => {
			document.removeEventListener("filterFChange", () => {});
		};
	}, []);

	useEffect(() => {
		document.addEventListener("aptClick", (e) => {
			handleRemoteAptClick(e);
		});

		return () => {
			document.removeEventListener("aptClick", () => {});
		};
	}, []);

	useEffect(() => {
		setMainAppartments(appartmentsData);
	}, [appartmentsData]);

	useEffect(() => {
		if (window.hasOwnProperty("$")) {
			window.$("#aptSideImg").sticky({ topSpacing: 90, bottomSpacing: 140, wrapperClassName: "" });
		}
	}, []);

	useEffect(() => {
		let filteredResults = appartmentsData;

		if (roomsFilter > 0) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.pv_number_of_rooms.length == 1) {
					if (parseInt(apt.pv_number_of_rooms) == parseInt(roomsFilter)) {
						return apt;
					}
				}
				const parsed = apt.pv_number_of_rooms.split(",");
				let trimmed = parsed.map((el) => el.trim());
				if (trimmed.includes(`${roomsFilter}`)) {
					return apt;
				}
			});
		}

		setMainAppartments(filteredResults);
	}, [roomsFilter, priceFilter]);

	const replaceLocationImg = (src, alt='') => {
		try {
			const image = new Image();
			image.src = src;
			image.alt = alt;
			image.classList.add("appear");
			let parent = document.getElementById("directionImg");
			parent.replaceChild(image, parent.firstChild);
		} catch (err) {
			console.log(err);
		}
	};

	const handleRemoteAptClick = (e) => {
		try {
			let selector = e.detail;
			let elem = document.querySelector(`[data-aptlig=${selector}]`);
			let src = elem.dataset.locationimg;
			let parent = elem.parentElement;
			let id = parent.id;
			let button = parent.querySelector("button");
			let aptid = button.dataset.aptid;

			if (button) {
				button.classList.remove("disappear");
			}

			replaceLocationImg(src);
			let imgParent = document.getElementById(imgParentId);
			let copy = [...window[imagesPropName]];

			let currentImage = copy.filter((el) => el.id === aptid);
			currentImage[0].img.style.opacity = 0;

			imgParent.replaceChild(currentImage[0].img, imgParent.firstElementChild);
			setTimeout(() => {
				$("#aptSideImg").children("img").fadeTo(400, 1);
			}, 0);
			if(!src || src == '') $('#directionImg').hide();

			parent.classList.add("blueBorder");
			parent.classList.remove("notSelectedGrey");
			let elems = document.querySelectorAll(".apartment_list__item");
			elems.forEach((el) => {
				if (el.id !== id) {
					el.classList.remove("blueBorder");
					if (el.querySelector("button")) {
						el.querySelector("button").classList.add("disappear");
					}

					el.classList.add("notSelectedGrey");
				}
			});

			// scroll to element
			$('html, body').animate({
				scrollTop: parseInt($(`#${id}`).offset().top - 100)
			}, 200);
		} catch (err) {
			console.log(err);
		}
	};

	const handleAptClick = (e, src, alt) => {
		try {
			let elem = e.target;
			let parent = e.target.parentElement;
			let id = parent.id;
			let button = parent.querySelectorAll("button");
			let aptid = button[0].dataset.aptid;
			let target = e.target;

			if (button.length > 0) {
				if (button[0]) button[0].classList.remove("disappear");
				if (button[1]) button[1].classList.remove("disappear");
			}

			if (target.classList.contains('buildings_list__item-cta')) return;

			replaceLocationImg(src, alt);
			let imgParent = document.getElementById(imgParentId);
			let copy = [...window[imagesPropName]];

			let currentImage = copy.filter((el) => el.id === aptid);
			currentImage[0].img.style.opacity = 0;

			imgParent.replaceChild(currentImage[0].img, imgParent.firstElementChild);
			setTimeout(() => {
				$("#aptSideImg").children("img").fadeTo(400, 1);
			}, 0);

			if(!src || src == '') $('#directionImg').hide();

			parent.classList.add("blueBorder");
			parent.classList.remove("notSelectedGrey");
			let elems = document.querySelectorAll(".apartment_list__item");
			elems.forEach((el) => {
				if (el.id !== id) {
					el.classList.remove("blueBorder");

					let otherButtons = el.querySelectorAll("button");
					if (otherButtons.length > 0) {
						if (otherButtons[0]) otherButtons[0].classList.add("disappear");
						if (otherButtons[1]) otherButtons[1].classList.add("disappear");
					}

					el.classList.add("notSelectedGrey");
				}
			});

			// scroll to element
			// $('html, body').animate({
			// 	scrollTop: parseInt($(`#${id}`).offset().top - 100)
			// }, 200);

			if (meetingSlug) {
				setRemoteAction({ payload: e.target.dataset.aptlig, eventName: "aptClick", author: isAgent});
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className="d-flex m-0 apartment_reserve__stepContainer" id="apartment_reserve__step-3__list">
			{/* Step Content  */}
			<div className="step_content px-3 py-4">
				<div className="row">
					<div className="col-12 col-lg-auto flashit">
						<StepTitle className="pfHeaderShadow py-2 mb-3 fw-bold">{currentLanguge.apartmentSelectionList.stepTitle}</StepTitle>
						<AptRoomFilter classes='desktopView' floorID={floorID} key={"hey"} costumeOnChange={setRoomsFilter} />
					</div>
					<div className="col ms-auto p-2 flex-row justify-content-end mde-2 desktopView">
						<div className="col-auto p-0 m-0 d-flex flex-column justify-content-start">
							<Donut />
							<h2 className="align-self-center fs-5 fw-bold" style={{direction:'ltr'}}>{buildingName}</h2>
						</div>
					</div>
					<div className="col-12 mobileView">
						<AptRoomFilter floorID={floorID} key={"hey"} costumeOnChange={setRoomsFilter} />
					</div>
				</div>
				<hr className="w-90 m-0 pfLine" />

				{/* Apartments List  */}
				<AppartmentsView
					list={mainAppartments}
					roomsLocal={roomsLocal}
					handleAppartmentSelect={handleAppartmentPreview}
					buildingID={buildingID}
					floorID={floorID}
					handleAptClick={handleAptClick}
					key="listofapts"
				/>
			</div>

			{/* Step Graphics  */}
			<div className="bg-image step_side_img" id="aptSideImg">
				<SideImagePlaceholder />
				<div className="col-4" id="directionImg">
					<img src="" alt="" className="d-none" />
				</div>
			</div>
		</div>
	);
};

export default ApartmentSelectionList;
