import { setShowNav, setShowFooterLinks } from "../../Helpers/AppartmentReserveUtils";
import "./SiteAuthorizationPage.sass";
import store from "../../Redux/Store/store";
import $ from 'jquery';
import * as projectActions from "../../Redux/Reducers/projectSlice";
import { useEffect } from "react";

const SiteAuthorizationPage = (props) => {
	useEffect(() => {
		setShowNav(false);
		setShowFooterLinks(false);
	},[])

	const un = 'realyForest';
	const pass = 'rf1agn2!';

	

	const getWithExpiry =(key) => {
		const itemStr = localStorage.getItem(key)

		// if the item doesn't exist, return null
		if (!itemStr) {
			return null
		}

		const item = JSON.parse(itemStr)
		const now = new Date()

		// compare the expiry time of the item with the current time
		if (now.getTime() > item.expiry) {
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.removeItem(key)
			return null
		}
		return item.value
	}

	const setWithExpiry = (key, value) => {
		const now = new Date()
		let ttl = 7200000; // 1 day
		// let ttl = 30000; // 30s

		const item = {
			value: value,
			expiry: now.getTime() + ttl,
		}
		localStorage.setItem(key, JSON.stringify(item))
	}


	const handleSiteAuthorization = (e) => {
		e.stopPropagation();
		e.preventDefault();

		$('#authorization_submit').prop('disabled', true);

		let typed_username = e.target[0].value;
		let typed_pass = e.target[1].value;

		if (typed_username === un && typed_pass === pass) {
			// All ok - save to session 
			store.dispatch(projectActions.setSiteAuthorization(true));
			setWithExpiry('siteAuthorization_expiry', true);

			const hideErrorTimeout = setTimeout(() => {
				window.location.reload();
			}, 500);
			
		} else {
			$('#authorization_error').fadeIn();
			const hideErrorTimeout = setTimeout(() => {
				$('#authorization_error').fadeOut();
			}, 2000);
			$('#authorization_submit').prop('disabled', false);

			store.dispatch(projectActions.setSiteAuthorization(false));
			localStorage.removeItem('siteAuthorization_expiry');
		}
	};

	if (getWithExpiry('siteAuthorization_expiry') == true) {
		store.dispatch(projectActions.setSiteAuthorization(true));
		const hideErrorTimeout = setTimeout(() => {
			window.location.reload();
		}, 500);
	}

	return (
		<div className="container-fluid" id="page_SiteAuthorizationPage">
			<form style={{ direction: 'ltr' }} onSubmit={(e) => handleSiteAuthorization(e)}>
				<h3 className="text-center mb-4 ">Site Authorization</h3>
				{/* Email input */}
				<div className="form-outline mb-4">
					<label className="form-label" htmlFor="authorization_username">Username</label>
					<input type="text" id="authorization_username" className="form-control" />
				</div>

				{/* Password input */}
				<div className="form-outline mb-4">
					<label className="form-label" htmlFor="authorization_pass">Password</label>
					<input type="password" id="authorization_pass" className="form-control" />
				</div>

				{/* Submit button */}
				<button type="submit" id="authorization_submit" className="btn btn-primary btn-block mb-4 w-100">Sign in</button>
				<p style={{display: 'none', color:'red'}} className="text-center" id='authorization_error'>Invalid credentials</p>

			</form>
		</div>
	);
};

export default SiteAuthorizationPage;