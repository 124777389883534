
const RadioSelect = (props) => {

    const RenderOptions = (options, id) => {
        return <>{
            options.sort(function (a, b) {
                return b -a;
            })
            .reverse().map((option, idx) => {
                return (<div className="m-1" key={`${id}__${idx}`}>
                    <input type="radio" className="btn-check" name={id} id={`${id}__${idx}`} value={option} autoComplete="off" defaultChecked={ props.selected == option ? true : false} />
                    <label className="btn btn-outline-dark" htmlFor={`${id}__${idx}`}>{option}</label>
                </div>);
        })}</>
    }

	return (
        <div className="d-flex flex-wrap">
            <div className="m-1" key={`${props.id}__any`}>
                <input type="radio" className="btn-check" name={props.id} id={`${props.id}__any`} value='any' autoComplete="off" defaultChecked={ props.selected == '' ? true : false} />
                <label className="btn btn-outline-dark" htmlFor={`${props.id}__any`}>Any</label>
            </div>
            {RenderOptions(props.options, props.id)}
        </div>
    )
};


export default RadioSelect;
