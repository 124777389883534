import './Page404.sass'
import { useEffect, useState } from "react";
import { setShowNav, setShowFooterLinks } from "../../Helpers/AppartmentReserveUtils";
import GTPageView from '../../Helpers/GTPageView';
import * as meetingActions from "../../Redux/Reducers/meetingSlice";
import * as registerActions from "../../Redux/Reducers/registerSlice";
import * as verificationActions from "../../Redux/Reducers/verificationSlice";
import * as projectActions from "../../Redux/Reducers/projectSlice";
import store from "../../Redux/Store/store";
import $ from 'jquery';
const Page404 = props => {
    
    useEffect(() => {
        if (window.location.href === (window.location.origin + '/')) window.location.href = 'https://realforest.com/';

        store.dispatch(projectActions.reset());
		store.dispatch(verificationActions.reset());
		store.dispatch(meetingActions.reset());
        store.dispatch(registerActions.reset());

		setShowNav(false);
        setShowFooterLinks(false);
        $('.language_selector_desktop').hide();
        $('.language_selector_mobile').hide();
        $('.language_selector_mobile').next('.vr').hide();

        GTPageView('404 page')
	},[])

    return (
        <div className="container bg-white my-3 p-5 w-98 shadow" id="page_404" style={{minHeight:"calc(100vh - 206px)"}}>
            <div className="row pt-5 justify-content-center">
                <div className="col text-center">
                    <img src="/assets/img/real_forest_400x400.jpg" alt="404 Page"/>
                    <h1 className="fw-bold">404</h1>
                    <p className="fs-2 m-0 fw-bold">אירעה שגיאה</p>
                    <p className="fs-3 m-0 fw-bold">
                        הדף שחיפשתם אינו קיים במערכת
                    </p>
                </div>
            </div>
        </div>
    );
  }
  
  export default Page404;
  