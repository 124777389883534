
const LayoutSplitScreenContent = props => {
  return (
    <div className={`${props.className}`} id={props.id ? props.id : ''} style={{width:'40%'}}>
      {props.children}
    </div>
  );
}

export default LayoutSplitScreenContent;
