import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./VideoComponentMobile.sass";
import $ from "jquery";
import { sendMeetingEnd, setGetAgentStatus, setShowFindAgentFormModel } from "../../../Helpers/AppartmentReserveUtils";
import MeetingVideoMobile from "./MeetingVideoMobile";
import AgentFormPopup from "../../Modals/AgentFormPopup/AgentFormPopup";
import { searchMeetingAgain } from "../../../Helpers/RegistetUtils";
import { setRoomSpaceID } from "../../../Helpers/MeetingsUtils";
import { setMeetingStartAt, setRoomSpaceID as setRoomSpaceID_verification, setSlug } from "../../../Helpers/verificationUtils";

const VideoComponentMobile = memo((props) => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const findingAgentState = useSelector((state) => state.meeting.getAgentStatus);
	const timeToSearch = 60000;
	const projectSettings = useSelector((state) => state.project.projectSettings);
	const meetingStatus = useSelector((state) => state.meeting.meetingStatus);
	const hideAgentPanelState = useSelector((state) => state.project.projectSettings.hide_agent_panel)
	const isMobile = useSelector((state) => state.project.isMobile)
	const step = useSelector((state) => state.project.currentStep);
	const showFindAgentFormModel = useSelector((state) => state.project.showFindAgentFormModel);
	const userApprovedApt = useSelector((state) => state.project.userApprovedApt);
	const userVerified = useSelector((state) => state.project.userVerified);

	useEffect(() => { }, [currentLanguge]);

	const searchAgain = (e) => {
		$(e.target).prop('disabled', true);
		searchMeetingAgain(currentLanguge);
	}

	// seraching agent + timer
	window.searchTimeBar = window.searchTimeBar || [];

	const minimizeAgentPanel = () => {
		if ($('#hideMobile_FindAgent').hasClass('minimized')) {
			$('#hideMobile_FindAgent').removeClass('minimized');
			$('#findAgentMobile_trigger__container').css('bottom', `0px`)
			$('body').css('padding-bottom', `${$('#findAgentMobile_trigger__container')[0].clientHeight}px`)
		} else {
			$('#hideMobile_FindAgent').addClass('minimized');
			$('#findAgentMobile_trigger__container').css('bottom', `calc(-${$('#findAgentMobile_trigger__container')[0].clientHeight}px + 10px)`)
			$('body').css('padding-bottom', `0px`)
		}
	}
	
	const clearIntervals = () => {
		for (var i=0; i < window.searchTimeBar.length; i++) {
			clearInterval(window.searchTimeBar[i]);
		}

		window.searchTimeBar = [];
	}

	useEffect(() => {
		let searchingAgentRunning = localStorage.getItem('searchingAgentRunning');
		let searchingAgentTime = localStorage.getItem('searchingAgentTime');

		if (findingAgentState === "searching" && isMobile) {
			// search progress bar

			if (window.searchTimeBar.length > 0) return;
			if (searchingAgentRunning == 'false' || (searchingAgentRunning == 'true' && parseInt(searchingAgentTime) > 0)) {
				localStorage.setItem('searchingAgentRunning', true);

				let searchProg = timeToSearch / 1000;
				let progBarWidth = 0;

				if (searchingAgentTime > 0) {
					searchProg = searchingAgentTime;	
					progBarWidth = ((timeToSearch / 1000 - searchProg) / (timeToSearch / 1000)) * 100;
				}

				$('#progBar_m').css('width', `${progBarWidth}%`);

				window.searchTimeBar.push(setInterval(() => {
					if (searchProg >= 0) {
						let progBarWidth = ((timeToSearch / 1000 - searchProg) / (timeToSearch / 1000)) * 100;
						$('#progBar_m').css('width', `${progBarWidth}%`);

						localStorage.setItem('searchingAgentTime', searchProg);
						searchProg--;	
					} else {

						setGetAgentStatus("notFound");
						clearIntervals();

						$('#progBar_m').css('width', `0%`);
						$('#searchingBar').fadeOut();

						localStorage.removeItem('searchingAgentRunning');
						localStorage.removeItem('searchingAgentTime');

						// End Meeting 
						if (!userApprovedApt && !userVerified) {
							sendMeetingEnd();
							// meetingSlice
							setRoomSpaceID("");

							// verificationSlice
							setMeetingStartAt("");
							setRoomSpaceID_verification("");
							setSlug("");
						}
					}
				}, 1000))
			}
		}
		return () => { if (!searchingAgentRunning) clearIntervals() };
	}, [findingAgentState, isMobile]);

	useEffect(() => {
		if (props.showAgentForm == true) {
			setShowFindAgentFormModel(true);
			setGetAgentStatus("register");
		}
	},[props.showAgentForm])
	
	const FindAgentSearching = () => {
		return (
			<>
				<div id="findAgentMobile_trigger__container" className={`pfContainerShadow py-3 px-2`} style={{ background: 'var(--rf-white)' }}>
					<span id="progBar_m"></span>
					<a id="hideMobile_FindAgent" className='withProgBar'
						data-gt-category="agent component" data-gt-action="click" data-gt-label="close agent not found"
						onClick={minimizeAgentPanel}><i class="bi bi-chevron-double-down"></i></a>
					
					<div className="p-0">
						<h3 className="fw-bold">{currentLanguge.findAgentComp.searchingTitle}</h3>
						<p className="m-0">{currentLanguge.findAgentComp.searchingSubtitle}</p>
					</div>

					<div className="findAgent__welcome_actions mt-2">
						<a className="btn rf-btnSecondery rf-fullBtn btn-lg mt-2 f18 w600"
							href={`/${projectSettings.project_slug}/register/?infllow=1`}
							id="findAgentReschedule_btn"
							data-gt-category="agent component" data-gt-action="click" data-gt-label="schedule for later">
							{currentLanguge.findAgentComp.findAgentReschedule}
						</a>
					</div>
				</div>
			</>
		)
	}

	const NotFoundAgent = () => {
		return (
			<div id="findAgentMobile_trigger__container" className={`pfContainerShadow py-3 px-2`} style={{ background: 'var(--rf-white)' }}>
				<a id="hideMobile_FindAgent" className=''
					data-gt-category="agent component" data-gt-action="click" data-gt-label="close agent not found"
					onClick={minimizeAgentPanel}><i class="bi bi-chevron-double-down"></i></a>
				<p className="text-center">{currentLanguge.findAgentComp.agentNotFoundMsg}</p>

				<a className="btn rf-btnPrimary rf-fullBtn btn-lg f18 w600" href={`/${projectSettings.project_slug}/register/?infllow=1`}
					data-gt-category="agent component" data-gt-action="click" data-gt-label="schedule for later">
					{currentLanguge.findAgentComp.findAgentReschedule}
				</a>

				<p className="my-3 text-center">{currentLanguge.findAgentComp.ctaOr}</p>

				<button className="btn rf-btnSecondery rf-fullBtn btn-lg f18 w600"
					onClick={(e) => searchAgain(e)}
					data-gt-category="agent component" data-gt-action="click" data-gt-label="search again">
					{currentLanguge.findAgentComp.searchAgainCTA}
				</button>
			</div>
		)
	}

	const FindAgentMobile__container = () => {
		if (hideAgentPanelState != "1" && isMobile && step != '8' && step != '7') {
			if ((findingAgentState == false || findingAgentState == 'register') && meetingStatus != 'start') {
				// Register
				return (<></>);
			} else if (findingAgentState == 'searching' && meetingStatus != 'start') {
				// Searching
				return <FindAgentSearching/>
			} else if (findingAgentState == 'notFound' && meetingStatus != 'start') {
				// Not Found
				return <NotFoundAgent/>
			} else if (meetingStatus == 'start') {
				return (
					<div id="findAgentMobile_trigger__container" className={`pfContainerShadow`} style={{background: 'var(--rf-white)'}}>
						<MeetingVideoMobile />
					</div>
				)
			} else {
				return <></>
			}
		} else {
			return <></>
		}
		
	}
	
	const toggleFindAgentFormModel = () => {
		setShowFindAgentFormModel(showFindAgentFormModel ? false : true);
		setGetAgentStatus(showFindAgentFormModel ? false : "register");
	}

	useEffect(() => {
		if (!isMobile) return;
		if ((findingAgentState == 'searching' && meetingStatus != 'start')
			|| (findingAgentState == 'notFound' && meetingStatus != 'start')
			|| (meetingStatus == 'start')) {
			$('body').css('padding-bottom', `${$('#findAgentMobile_trigger__container')[0].clientHeight}px`)
		} 
	},[findingAgentState, meetingStatus, isMobile])

	useEffect(() => {
		if (findingAgentState == 'searching') {
			setShowFindAgentFormModel(false);
		}
	},[findingAgentState])
	
	return (
		<>	
			<FindAgentMobile__container/>
			<AgentFormPopup show={showFindAgentFormModel} toggleFindAgentFormModel={toggleFindAgentFormModel} />
		</>
	);
});

export default VideoComponentMobile;
