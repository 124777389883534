import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setFlowErrorObject } from "../../../Helpers/AppartmentReserveUtils";
import "./FlowErrorModal.sass";

const FlowErrorModal = () => {
	const [show, setShow] = useState(false);
	const errorObject = useSelector((state) => state.project.flowErrorObject);
	const sales_phone = useSelector((state) => state.project.projectSettings.project_sales_phone);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);
	
	const handleStay = () => {
		setShow(false);
	};
	useEffect(() => {
		if (errorObject.errorCode) {
			setShow(true);
		}
	}, [errorObject]);

	const onClose = () => {
		setShow(false);
		setFlowErrorObject({errorCode:"",errorMessage:"", errorTitle: ""})
	};

	const Phone = () => {
		if (sales_phone.includes('*') && currentLanguge.direction == 'rtl') {
			let newsales_phone = sales_phone.replace('*', '');
			return newsales_phone + '*';
		} else {
			return sales_phone;
		}
	};
	return (
		<Modal show={show} contentClassName="position-relative " centered>
			<div className="closepopupButton" onClick={onClose}>
				<img src='/assets/img/icons/ico-close.svg' alt='' height="20" width="20"/>
			</div>
			{/* <img src="/assets/img/icons/ico-closeSqaure.svg" className="closeButtonAlign pointer zindexon" alt="" height="25" width="25" onClick={onClose}/> */}
			<Modal.Body className="col-12 d-flex flex-column text-center">
				<h3 className={`f24 w600 ${errorObject.errorTitle == '' || !errorObject.errorTitle ? 'd-none' : ''}`}>{errorObject.errorTitle}</h3>
				<p className="mb-0">
					{errorObject.errorMessage}
				</p>
				<button className={`btn rf-btn-primary fw-bold f18 mt-3 mx-auto ${!errorObject.errorRedirectUrl || errorObject.errorRedirectUrl == '' ? '' : 'd-none'}`} onClick={onClose}>
					{currentLanguge.formsCTA.close}	
				</button>
				<a className={`btn rf-btn-primary fw-bold f18 mt-3 mx-auto ${!errorObject.errorRedirectUrl || errorObject.errorRedirectUrl == '' ? 'd-none' : ''}`} href={errorObject.errorRedirectUrl} onClick={onClose}>
					{errorObject.errorRedirectTitle}	
				</a>
			</Modal.Body>
			<Modal.Footer className={`${sales_phone ? 'd-flex': 'd-none'} justify-content-center py-1`}>
				<span className="text-center align-self-center">{currentLanguge.flowErrorModal.footerText} {sales_phone && Phone()}</span>
			</Modal.Footer>
		</Modal>
	);
};
export default FlowErrorModal;
