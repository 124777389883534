import { Modal } from "react-bootstrap";
import AgentForm from "../../MeetingComponents/FindAgent/AgentForm";

const AgentFormPopup = ((props) => {
	return (
        <Modal show={props.show} centered >
            <a className="closepopupButton" onClick={props.toggleFindAgentFormModel} data-gt-category="agent component" data-gt-action="click" data-gt-label="close lead form">
                <img src='/assets/img/icons/ico-close.svg' alt='' height="20" width="20"/>
            </a>
            <Modal.Body>
                <AgentForm showBack={props.showBack == true ? true : false} />
            </Modal.Body>
        </Modal>
	);
});

export default AgentFormPopup;
