import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	date: "",
	hour: "",
	hoursIndex: 0,
	currentWeek: 0,
	availableHours: [],
	weeksOfMonth: [],
	timeToMeeting: "1",
	meetingStatus: false,
	meetingStartAt: "",
	getAgentStatus: "",
	roomSpaceID: "",
	userID: "",
	agentID: "",
	meetingStep: "",
	requestSkipPay: false,
	requestSkipSign: false,
	isAgent: false,
	agentFirstname: "",
	agentEmail: "",
	agentLastname: "",
	clientType: "1",
	isSelfReserve: true,
	clientTypeName: "",
	meetingSetSuccesful: {
		meetingSuccess: false,
		responseText: false,
	},
	timeOffset: 0,
	timezone: "",
	agentControl: false,
	agentControlChanged: false
};

export const meetingSlice = createSlice({
	name: "meetings",

	initialState,

	reducers: {
		reset: () => initialState,
		setDate: (state, action) => {
			state.date = action.payload;
		},
		setMeetingSetSuccesful: (state, action) => {
			state.meetingSetSuccesful = action.payload;
		},
		setTimeOffset: (state, action) => {
			state.timeOffset = action.payload;
		},
		setTimezone: (state, action) => {
			state.timezone = action.payload;
		},
		setMeetingStartAt: (state, action) => {
			state.meetingStartAt = action.payload;
		},
		setIsSelfReserve: (state, action) => {
			state.isSelfReserve = action.payload;
		},
		setClientType: (state, action) => {
			state.clientType = action.payload;
		},
		setClientTypeName: (state, action) => {
			state.clientTypeName = action.payload;
		},
		setUserId: (state, action) => {
			state.userID = action.payload;
		},
		setAgentFirstname: (state, action) => {
			state.agentFirstname = action.payload;
		},
		setAgentLasttname: (state, action) => {
			state.agentLastname = action.payload;
		},
		setAgentEmail: (state, action) => {
			state.agentEmail = action.payload;
		},
		setAgentID: (state, action) => {
			state.agentID = action.payload;
		},
		setMeetingStep: (state, action) => {
			state.meetingStep = action.payload;
		},
		setRequestSkipPay: (state, action) => {
			state.requestSkipPay = action.payload;
		},
		setIsAgent: (state, action) => {
			state.isAgent = action.payload;
		},
		setAgentControl: (state, action) => {
			state.agentControl = action.payload;
		},
		setAgentControlChanged: (state, action) => {
			state.agentControlChanged = action.payload;
		},
		setRoomSpaceID: (state, action) => {
			state.roomSpaceID = action.payload;
		},
		setRequestSkipSign: (state, action) => {
			state.requestSkipSign = action.payload;
		},
		setHour: (state, action) => {
			state.hour = action.payload;
		},
		setHoursIndex: (state, action) => {
			state.hoursIndex = action.payload;
		},
		incrementWeekIndex: (state) => {
			state.currentWeek += 1;
		},
		decrementWeekIndex: (state) => {
			state.currentWeek -= 1;
		},
		setAvailableHours: (state, action) => {
			state.availableHours = action.payload;
		},
		setMonthDays: (state, action) => {
			state.weeksOfMonth = action.payload;
		},
		setTimeToMeeting: (state, action) => {
			state.timeToMeeting = action.payload;
		},
		setMeetingStatus: (state, action) => {
			state.meetingStatus = action.payload;
		},
		setGetAgentStatus: (state, action) => {
			state.getAgentStatus = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	reset,
	setHour,
	setDate,
	setHoursIndex,
	incrementWeekIndex,
	decrementWeekIndex,
	setAgentEmail,
	setAgentFirstname,
	setAgentLasttname,
	setAvailableHours,
	setMonthDays,
	setMeetingSetSuccesful,
	setRoomSpaceID,
	setRequestSkipSign,
	setClientType,
	setTimeToMeeting,
	setMeetingStatus,
	setGetAgentStatus,
	setTimeOffset,
	setTimezone,
	setMeetingStartAt,
	setAgentControl,
	setAgentControlChanged,
	setClientTypeName,
	setAgentID,
	setIsAgent,
	setMeetingStep,
	setRequestSkipPay,
	setIsSelfReserve,
	setUserId,
} = meetingSlice.actions;

export default meetingSlice.reducer;
