import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ThumbPlaceHolder } from "../../Layout/ImagePlacholders/ImagePlacholders";
import {  AsyncThumbImage } from "./image";
import { ConvertCurrency } from "../../../Helpers/ConvertCurrency";
import GTEvent from "../../../Helpers/GTEvent";

const BuildingIndicators = ({ buildingID, className }) => {
	const dynamicAppartmentsData = useSelector((state) => state.project.dynamicAppartmentsData);
	const [stats, setStats] = useState({ available: "", pending: "", reserved: "" });
	const isDataChange = useSelector((state) => state.project.isDataChange);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);
	useEffect(async () => {
		try {
			var available = 0;
			var pending = 0;
			var reserved = 0;
			for (let building of dynamicAppartmentsData) {
				if (building.building_id == buildingID) {
					for (let floor of building.floors) {
						for (let props of floor.properties) {
							if (props.status === 1) {
								available++;
							}
							if (props.status === 2) {
								pending++;
							}
							if (props.status === 3 || props.status === 4) {
								reserved++;
							}
						}
					}
				}
			}
			const data = { available, pending, reserved };
			setStats(data);
		} catch (err) {
			console.log(err);
		}
	}, [dynamicAppartmentsData, isDataChange]);

	useEffect(() => {
		let elems = document.querySelectorAll(".stat_circle");
		elems.forEach((el) => {
			if (el.classList.contains("heartbeat")) {
				el.classList.remove("heartbeat");
				el.classList.add("heartbeat");
			}
		});
	}, [stats]);


	return (
		<div className={`row flex-wrap m-0 ${className}`}>
			<div className="col-auto stat-item text-nowrap d-flex flex-row pde-2 pds-0 pfTextColor">
				<span className="stat_circle stat_circle__available align-self-center heartbeat"></span>
				<span className="f18 stat_value mds-1 fw-bold align-self-center">{stats.available}</span>
				<span className="w500 mds-1 pfAvailText align-self-end">{currentLanguge.projectStats.lableAvailable}</span>
			</div>

			<div className="col-auto stat-item text-nowrap d-flex flex-row pde-2 pds-0">
				<span className="stat_circle stat_circle__progress align-self-center heartbeat"></span>
				<span className="stat_value mds-1 fw-bold align-self-center f18">{stats.pending}</span>
				<span className="w500 mds-1 pfAvailText align-self-end">{currentLanguge.projectStats.lableInProgress}</span>
			</div>

			<div className="col-auto stat-item text-nowrap d-flex flex-row pde-2 pds-0">
				<span className="stat_circle stat_circle__reserved align-self-center heartbeat"></span>
				<span className="stat_value mds-1 fw-bold align-self-center f18">{stats.reserved}</span>
				<span className="w500 mds-1 pfAvailText align-self-end">{currentLanguge.projectStats.lableReserved}</span>
			</div>
		</div>
	);
};

const Building = ({ buildingName, floors, rooms, startingPrice, handleBuildingSelect, buildingID, idx, thumbImg, onContainerClick,isAgent,meetingSlug, history }) => {
	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != "undefined" ? ConvertCurrency(project_default_currency) : "";
	const currentLanguge = useSelector((state) => state.project.currentLanguge);

	useEffect(() => { }, [currentLanguge]);
	
	let priceStringed = (() => parseInt(startingPrice).toLocaleString())();
	
	useEffect(() => {
		AsyncThumbImage(thumbImg, "thumbParent", idx, ["buildings_list__item-img", "align-self-center"], buildingName);
		AsyncThumbImage(thumbImg, "thumbParentMobile", idx, ["buildings_list__item-img", "align-self-center"], buildingName);
	}, []);


	if (window.hasOwnProperty("$")) {
		window.$("#buildingImgCont").sticky({ topSpacing: 90, bottomSpacing: 140, wrapperClassName: "" });
	}

	const onContainerClickLocal = (e, meetingSlug, isAgent = null) => {
		onContainerClick(e, meetingSlug, isAgent);
		GTEvent(e);
	}

	return (
		<div
			id={buildingID}
			className="list-group-item d-flex buildings_list__item buildings_list__itemExtra pfFloorBborder position-relative d-flex flex-row justify-content-start p-0 pds-2 pt-2 pb-2"
			data-building={idx}
			onClick={(e) => onContainerClickLocal(e, thumbImg)}
			key={idx}
			data-gt-category='building selection' data-gt-action='click' data-gt-label='preview building'
		>
			<div className="w-100 h-100 position-absolute top-0 elemIndex" onClick={(e) => onContainerClickLocal(e, meetingSlug,isAgent)} data-blid={"a" + buildingID} data-gt-category='building selection' data-gt-action='click' data-gt-label='preview building'></div>
			{/* desktopView */}
			<div className="desktopView">
				<div className="col-auto pb-2 pt-2 d-flex justify-content-center">
					<div className={`col-12 d-flex justify-content-center align-self-center m-auto position-relative`} id={`thumbParent${idx}`}>
						<ThumbPlaceHolder height="153px" width="110px" />
					</div>
				</div>
				<div className="p-3 d-flex flex-column position-relative buildings_list__iteminfo">
					<h2 className="fw-bold f26 d-xl-fs-5 col-8 direction_text" style={{direction:'ltr'}}>{buildingName}</h2>
					<div className="building_stats mt-2">
						<div className="container stats_container flex-nowrap p-0 m-0">
							<BuildingIndicators buildingID={buildingID} />
						</div>
					</div>

					<div className="building_info mt-2">
						<div className="container info_container p-0">
							<div className="row m-0">
								<div className="col-auto info-item f18 fw-bold pds-0 pde-3 mb-2">
									<img src="/assets/img/icons/ico-floors.svg" className="mde-1 pfIcon" alt={currentLanguge.buildingsSelection_step.floors} height="23" width="24" />
									{`${floors} ${currentLanguge.buildingsSelection_step.floors}`}
								</div>
								<div className="col-auto info-item f18 fw-bold pds-0 pde-3 mb-2">
									<img src="/assets/img/icons/ico-rooms.svg" className="mde-1 pfIcon" alt={currentLanguge.buildingsSelection_step.rooms} height="23" width="24" />
									{`${rooms} ${currentLanguge.buildingsSelection_step.rooms} `}
								</div>
								<div className="col-auto info-item f18 fw-bold pds-0 pde-3 mb-2">
									<img src="/assets/img/icons/ico-price.svg" className="mde-1 pfIcon" alt={currentLanguge.general.priceStartFrom} height="23" width="24" />
									{`${currentLanguge.general.priceStartFrom} ${currencySymbol}${priceStringed}`}
								</div>
							</div>
						</div>
					</div>
				</div>
				<button
					id={buildingID}
					className="buildings_list__item-cta btn rf-btn-primary fw-bold f18 col-3 pfButton pfSubmitBtnColor pfContainerShadow disappear mt-1 mde-2"
					data-building="1"
					data-builname={buildingName}
					type="button"
					onClick={(e) => handleBuildingSelect(e, thumbImg, rooms, currentLanguge, history)}
					data-gt-category='building selection' data-gt-action='click' data-gt-label='view building'
				>
					{currentLanguge.buildingsSelection_step.selectionCTA}
				</button>
			</div>

			{/* mobileView */}
			<div className="mobileView">
				<div className="d-flex">
					<div className="col-auto pb-2 pt-2 d-flex justify-content-center">
						<div className="col-12 d-flex justify-content-center align-self-center m-auto position-relative" id={`thumbParentMobile${idx}`}>
							<ThumbPlaceHolder height="153px" width="110px" />
						</div>
					</div>
					<div className="building_stats">
						<h2 className="fw-bold f26 d-xl-fs-5 mds-2 direction_text" style={{ direction: 'ltr' }}>{buildingName}</h2>
						<div className="container stats_container flex-wrap p-0 mds-2  ">
							<BuildingIndicators buildingID={buildingID} className='flex-column' />
						</div>
					</div>
				</div>
				<div className="p-3 pt-0 pds-0 d-flex flex-column position-relative buildings_list__iteminfo">
					<div className="building_info mt-2">
						<div className="container info_container p-0">
							<div className="row m-0 flex-column">
								<div className="col-auto info-item f18 fw-bold pds-0 pde-3 mb-2">
									<img src="/assets/img/icons/ico-price.svg" className="mde-1 pfIcon" alt={currentLanguge.general.priceStartFrom} height="23" width="24" />
									{`${currentLanguge.general.priceStartFrom} ${currencySymbol}${priceStringed}`}
								</div>
								<div className="col-auto info-item f18 fw-bold pds-0 pde-3 mb-2">
									<img src="/assets/img/icons/ico-floors.svg" className="mde-1 pfIcon" alt={currentLanguge.buildingsSelection_step.floors} height="23" width="24" />
									{`${floors} ${currentLanguge.buildingsSelection_step.floors}`}
								</div>
								<div className="col-auto info-item f18 fw-bold pds-0 pde-3 mb-2">
									<img src="/assets/img/icons/ico-rooms.svg" className="mde-1 pfIcon" alt={currentLanguge.buildingsSelection_step.rooms} height="23" width="24" />
									{`${rooms} ${currentLanguge.buildingsSelection_step.rooms} `}
								</div>
							</div>
						</div>
					</div>
				</div>
				<button
					id={buildingID}
					className="buildings_list__item-cta mx-auto btn rf-btn-primary fw-bold f18 col-12 pfButton pfSubmitBtnColor pfContainerShadow disappear mt-1"
					data-building="1"
					type="button"
					data-builname={buildingName}
					onClick={(e) => handleBuildingSelect(e, thumbImg, rooms, currentLanguge, history)}
					data-gt-category='building selection' data-gt-action='click' data-gt-label='view building'
				>
					{currentLanguge.buildingsSelection_step.selectionCTA}
				</button>
			</div>

		</div>
	);
};

export default Building;
