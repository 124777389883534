const ApartmentAdditionalDetail = (props) => {
	return (
		<div key={props.id} className="featureItem apartment-additional-details__item my-3 mde-4 p-0">
			<img src={props.imgSrc} alt={props.title + " " + props.subTitle} />
			<div className="d-flex flex-column mds-2">
				<span className=" f18 lh18">{props.title}</span>
				<span className="f18 fw-bold">{props.subTitle}</span>
			</div>
		</div>
	);
};

export default ApartmentAdditionalDetail;


