import verificationSlice from "../Reducers/verificationSlice";
import meetingSlice from "../Reducers/meetingSlice";
import projectSlice from "../Reducers/projectSlice";
import registerSlice from "../Reducers/registerSlice";
import dataSlice from "../Reducers/dataReducer";

import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import storage from "redux-persist/lib/storage/session";

import { combineReducers } from "redux";
import { createStore } from "redux";

const reducers = combineReducers({
	verification: verificationSlice,
	project: projectSlice,
	meeting: meetingSlice,
	register: registerSlice,
	data: dataSlice,
});

const persistConfig = {
	key: "root",
	// version: 1,
	storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const makeStore = () => {
	const store = createStore(persistedReducer);
	const persistor = persistStore(store);
	return { store, persistor };
};
export default makeStore;
