import { memo } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import $ from "jquery";

const UserInfo = () => {
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const formSelector = useSelector((state) => state.register);
	const projectSelector = useSelector((state) => state.project);
	const meetingSelector = useSelector((state) => state.meeting);
	const agentID = useSelector((state) => state.meeting.agentID);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	useEffect(() => {
	}, [currentLanguge, projectSelector]);
	
	const Name = () => {
		if (isAgent) {
			return `${formSelector.firstName} ${formSelector.lastName}`;
		}
		if (meetingSelector.agentFirstname) {
			return `${meetingSelector.agentFirstname} ${meetingSelector.agentLastname}`;
		}
	};

	const Phone = () => {
		try {
			let returnPhone = '';
			if (isAgent) {
				if (formSelector.mobileNumber.includes('+')) {
					if (currentLanguge.direction == 'rtl') {
						let mobileNumber = formSelector.mobileNumber.replace('+', '');
						returnPhone = mobileNumber + '+';
						return <a target="_blank" href={`tel:${returnPhone}`}>{returnPhone}</a>;
					} else {
						return <a target="_blank" href={`tel:${formSelector.mobileNumber}`}>{formSelector.mobileNumber}</a>;
					}
				}
				return <a target="_blank" href={`tel:${formSelector.mobileNumber}`}>{formSelector.mobileNumber}</a>;
			}
			if (projectSelector.projectSettings && projectSelector.projectSettings.project_sales_phone && projectSelector.projectSettings.project_sales_phone.includes('*')) {
				if (currentLanguge.direction == 'rtl') {
					let newProject_sales_phone = projectSelector.projectSettings.project_sales_phone.replace('*', '');
					returnPhone = newProject_sales_phone + '*';
					return <a target="_blank" href={`tel:${returnPhone}`}>{returnPhone}</a>;
				} else {
				return <a target="_blank" href={`tel:${projectSelector.projectSettings.project_sales_phone}`}>{projectSelector.projectSettings.project_sales_phone}</a>;
			}
			}
			return <a target="_blank" href={`tel:${projectSelector.projectSettings.project_sales_phone}`}>{projectSelector.projectSettings.project_sales_phone}</a>;
		} catch (err) {
			console.log(err);
		}
	};

	const ID = () => {
		try {
			if (isAgent) {
				return `${formSelector.ID == '00000000' ? '' : formSelector.ID}`;
			}
			return agentID;
		} catch (err) {
			console.log(err);
		}
	};
	const Header = () => {
		if (isAgent && formSelector.ID != '00000000') {
			return currentLanguge.meetingVideoComp.userInfo_ID;
		} else if (isAgent && formSelector.ID == '00000000') {
			return null;
		}
		return currentLanguge.meetingVideoComp.userInfo_AgentNumber;
	};


	useEffect(() => {
		if (isAgent && formSelector.ID == '00000000') {
			$('.video_client_id').hide();
		} else {
			$('.video_client_id').show();

		}
	}, []);

	return (
		<div id="videoMeeting_userInfo" >
			<p className="mb-1 fs-3 align-self-start mds-1 ">
				{Name()}
			</p>
			<div className="d-flex" style={{opacity: '0.7'}}>
				<span className="mb-2">
					{/* <span className="mds-2 f18">{currentLanguge.meetingVideoComp.userInfo_Phone}</span> */}
					<span className="f18 pfColorGray w700">{Phone()}</span>
				</span>
				<span className="video_client_id">
					<span className="mds-2 f18">{Header()}</span>
					<span className="mds-1 f18 pfColorGray">{ID()}</span>
				</span>
				
			</div>
		</div>
	);
};

export default memo(UserInfo);
