import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeGetRequest } from "../../../Axios";
import Donut from "../../Stats/Donut/Donut";
import StepTitle from "../../UI/Titels/StepTitle";
import "./FloorSelection.sass";
import "../DetailsApproval/DetailsApproval.sass";
import { handleFloorSelect, setFloorsBack, setLoadingModalContent, setRemoteAction, setSelectedAptImg } from "../../../Helpers/AppartmentReserveUtils";
import Floor from "./Floor";
import $ from "jquery";
import RoomsFilter from "../../General/RoomsFilter/RoomsFilter";
import { handleExistingImage, handleLargeImagesDisplay } from "../BuildingsSelection/image";
import { SideImagePlaceholder } from "../../Layout/ImagePlacholders/ImagePlacholders";
import { setSideImage } from "../../../Helpers/AppartmentReserveUtils/pusherReg";

const FloorSelection = (props) => {
	const [floors, setFloors] = useState([]);
	const [roomsFilter, setRoomsFilter] = useState("0");
	const [externalFilter, setExternalFilter] = useState("0");
	const selectedFloorImg = useSelector((state) => state.project.selectedFloorImg);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const [mainFloors, setMainFloors] = useState(floors);
	const buildingID = useSelector((state) => state.project.buildingID);
	const meetingSlug = useSelector((state) => state.verification.slug);
	const [requestOk, setRequestOk] = useState(false);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	let buildingName = useSelector((state) => state.project.buildingName);

	let imgParentId = "floorImg";
	let imagesPropName = "floorImages";
	let firstImageProp = "floorsgMain";

	useEffect(() => { }, [currentLanguge]);
	useEffect(() => {
		try {
			setLoadingModalContent(false);
			const requestFloors = async () => {
				try {
					const req = await makeGetRequest("/buildingfloors", buildingID);
					if (req) {
						if (req.data.message) {
							setFloors(req.data.message);

							setRequestOk(true);
							setFloorsBack(req.data.message);
							if (!selectedFloorImg) {
								setSelectedAptImg(req.data.message[0].floor_main_image_wbuilding)
								handleExistingImage(selectedFloorImg, "floorImg");
							}
						}
					}
				} catch (err) {
					console.log(err);
				}
			};
			if(buildingID) requestFloors();
		} catch (err) {
			console.log(err);
		}
	}, [buildingID]);

	useEffect(() => {
		if (requestOk) {
			handleLargeImagesDisplay(floors, "floor_main_image_wbuilding", "floorsSide", "id", firstImageProp, imgParentId, imagesPropName, false, 'floor_title', currentLanguge.apartmentSelectionTable.headerFloor);
			setSideImage(buildingID,1)
		}
	}, [requestOk]);

	// get apt click listener
	useEffect(() => {
		document.addEventListener("floorClick", (e) => {
			try {
				onRemoteFloorClick(e);
			} catch (err) {
				console.log(err);
			}
		});
		document.addEventListener("filterFChange", (e) => {
			setExternalFilter(e.detail);
		});

		return () => {
			document.removeEventListener("floorClick", () => {});
			document.removeEventListener("filterFChange", () => {});
		};
	}, []);


	//handle side image stickiness
	useEffect(() => {
		// test();
		if (window.hasOwnProperty("$")) {
			window.$("#floorImg").sticky({ topSpacing: 90, bottomSpacing: 380, wrapperClassName: "" });
		}
	}, []);

	const setExtnernalFilter = (value) => {
		setExternalFilter(value);
	};

	//set the local filter according to the selection from the component
	useEffect(() => {
		setRoomsFilter(externalFilter);
	}, [externalFilter]);

	const onRemoteFloorClick = useCallback((e) => {
		try {
			e.stopPropagation();
			e.preventDefault();
			let selector = e.detail;
			let elem = document.querySelector(`[data-flid=${selector}]`);
			let parent = elem.parentElement;
			let id;
			let button;
			if (parent.classList.contains("floors_list__item")) {
				button = parent.querySelector("button");
				id = button.id;

				let imgParent = document.getElementById(imgParentId);
				let copy = [...window[imagesPropName]];
				let currentImage = copy.filter((el) => el.id === id);
				currentImage[0].img.style.opacity = 0;
				imgParent.replaceChild(currentImage[0].img, imgParent.firstElementChild);
				setTimeout(() => {
					$("#floorImg").children("img").fadeTo(400, 1);
				}, 0);

				button.classList.remove("disappear");
				parent.classList.add("blueBorder");
				parent.classList.remove("notSelectedGrey");
				let elems = document.querySelectorAll(".floors_list__item");
				elems.forEach((el) => {
					if (el.id !== id) {
						el.classList.remove("blueBorder");
						el.querySelector("button").classList.add("disappear");
						el.classList.add("notSelectedGrey");
					}
				});

				// scroll to element
				$('html, body').animate({
					scrollTop: parseInt($(`#${id}`).offset().top - 100)
				}, 200);
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	const onFloorClick = useCallback((e, src) => {
		e.stopPropagation();
		e.preventDefault();
		let elem = e.target;
		let parent = elem.parentElement;
		let id;
		let button;
		let target = e.target;

		

		if (parent.classList.contains("floors_list__item") && !target.classList.contains('floors_list__item-cta')) {
			button = parent.querySelector("button");
			id = button.id;

			let imgParent = document.getElementById(imgParentId);
			let copy = [...window[imagesPropName]];
			let currentImage = copy.filter((el) => el.id === id);
			currentImage[0].img.style.opacity = 0;
			imgParent.replaceChild(currentImage[0].img, imgParent.firstElementChild);
			setTimeout(() => {
				$("#floorImg").children("img").fadeTo(400, 1);
			}, 0);

			button.classList.remove("disappear");
			parent.classList.add("blueBorder");
			parent.classList.remove("notSelectedGrey");
			let elems = document.querySelectorAll(".floors_list__item");
			elems.forEach((el) => {
				if (el.id !== id) {
					el.classList.remove("blueBorder");
					el.querySelector("button").classList.add("disappear");
					el.classList.add("notSelectedGrey");
				}
			});

			// scroll to element
			// $('html, body').animate({
			// 	scrollTop: parseInt($(`#${id}`).offset().top - 100)
			// }, 200);
			
			if (meetingSlug) {
				setRemoteAction({ payload: e.target.dataset.flid, eventName: "floorClick", author: isAgent });
			}
		}
	}, []);


	const Floors = useCallback(({ list }) => {
		try {
			if (!list) {
				return null;
			}
			list = [].concat(list).sort((a,b)=> parseInt(a.floor_title) > parseInt(b.floor_title) ? 1 : -1).reverse();
			return list.map((el, idx) => {
				let roomsInfo = el.roomsinfo.replace(/\s+/g, "").split(",");

				let newArr = roomsInfo
					.sort(function (a, b) {
						return a - b;
					})
					.reverse()
					.join(",")
					.trim("");
				return (
					<Floor
						key={`${idx}${idx}`}
						title={el.floor_title}
						id={el.id}
						rooms={newArr}
						price={el.pricefrom}
						handleFloorSelect={handleFloorSelect}
						onFloorClick={onFloorClick}
						idx={idx}
						floorImg={el.floor_main_image_wbuilding}
						buildingID={el.building_id}
					></Floor>
				);
			});
		} catch (err) {
			console.log(err);
		}
	});
	//filter without meeting behaviour
	useEffect(async () => {
		let filteredResults = floors;

		if (roomsFilter > 0) {
			filteredResults = filteredResults.filter((floor) => {
				if (floor.roomsinfo.length == 1) {
					if (parseInt(floor.roomsinfo) == parseInt(roomsFilter)) {
						return floor;
					}
				}

				const parsed = floor.roomsinfo.split(",");
				let trimmed = parsed.map((el) => el.trim());
				if (trimmed.includes(`${roomsFilter}`)) {
					return floor;
				}
			});
		} else if (roomsFilter === 0) {
			filteredResults = floors;
		}

		handleExistingImage(selectedFloorImg, "floorImg");

		setMainFloors(filteredResults);
	}, [roomsFilter, externalFilter]);

	//set the display container anytime there is a change in parent obj
	useEffect(() => {
		setMainFloors(floors);
	}, [floors]);

	useEffect(() => {
		// test();
		if (window.hasOwnProperty("$")) {
			window.$("#floorImg").sticky({ topSpacing: 90, bottomSpacing: 140, wrapperClassName: "" });
		}
	}, []);
	
	return (
		<div className="d-flex m-0 flex-nowrap apartment_reserve__stepContainer" id="apartment_reserve__step-2">
			{/* Step Content  */}
			<div className="step_content px-3 py-4">
				<div className="row pfFloorBborder">
					<div className="col-12 col-lg-auto appear">
						<StepTitle className="pfHeaderShadow py-2 mb-3 fw-bold">{currentLanguge.floorSelection_step.stepTitle}</StepTitle>
						<RoomsFilter classes='desktopView' buildingID={buildingID} costumeOnChange={setExtnernalFilter} />
					</div>
					<div className="col m-2 flex-row justify-content-end desktopView">
						<div className="col-auto p-0 m-0 d-flex flex-column justify-content-start">
							<Donut />
							<h2 className="align-self-center fs-5 fw-bold pfTextColor" style={{direction:'ltr'}}>{buildingName}</h2>
						</div>
					</div>
					<div className="col-12 mobileView">
						<RoomsFilter buildingID={buildingID} costumeOnChange={setExtnernalFilter} />
					</div>
				</div>

				{/* Floors List  */}
				<div className="list-group floors_list" id="floorScroll" onClick={(e) => onFloorClick(e)}>
					<Floors list={mainFloors} />
				</div>
			</div>

			{/* Step Graphics  */}
			<div className="bg-image step_side_img" id="floorImg">
				<SideImagePlaceholder />
			</div>
		</div>
	);
};

export default memo(FloorSelection);
