import $ from 'jquery';


const GTEvent = (e, additionalToLabel = '') => {
	let target;
	let gtCategory, gtAction, gtLabel;

	if (e.type == 'click') {
		if (e.target.type == 'submit' || e.target.tagName == 'INPUT' || e.target.tagName == 'LABEL') return;
		target = $(e.target)[0];

		if (!target.dataset.gtCategory && !target.dataset.gtAction && !target.dataset.gtLabel) {
			target = $(e.target).parent()[0];
		}
	} else if (e.type == "submit") {
		target = $(e.target).find('button[type=submit]')[0];
	} else if (e.type == "change") {
		target = $(e.target)[0];
	} else if (e.type == "mousedown") {
		target = $(e.target)[0];
	} else  {
		return;
	}

	if (!target.dataset || (!target.dataset.gtCategory && !target.dataset.gtAction && !target.dataset.gtLabel)) return;

	gtCategory = target.dataset.gtCategory;
	gtAction = target.dataset.gtAction;
	gtLabel = target.dataset.gtLabel + " " +  additionalToLabel;
	pushGAvent(gtCategory, gtAction, gtLabel);
}
export default GTEvent;



export const pushGAvent = (gtCategory, gtAction, gtLabel) => {
	let projectID = sessionStorage.getItem('projectID');
	window.dataLayer.push({
		'event': `RF ${gtCategory}`,
		'action': gtAction,
		'label': gtLabel,
		'projectID' : projectID

	});
	console.log("GT event:", gtCategory, ":", gtAction, ":", gtLabel);
}
