const FormSectionTitle = props => {
    return (
        <div className="row mb-3">
            <div className="col">
                <h3 className={`${props.className}`}>{props.children}</h3>
            </div>
        </div>
    );
  }
  
  export default FormSectionTitle;
  