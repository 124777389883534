import { useEffect } from "react";
import "./FindAgent.sass";
import { useSelector } from "react-redux";

import AgentForm from "./AgentForm";
import { sendMeetingEnd, setGetAgentStatus } from "../../../Helpers/AppartmentReserveUtils";
// import MeetingTimerBar from "../MeetingTimerBar/MeetingTimerBar"; // TODO: check if can remove
import $ from "jquery";
import { searchMeetingAgain } from "../../../Helpers/RegistetUtils";
import { setRoomSpaceID } from "../../../Helpers/MeetingsUtils";
import { setMeetingStartAt, setRoomSpaceID as setRoomSpaceID_verification, setSlug } from "../../../Helpers/verificationUtils";

const FindAgent = () => {
	const findingAgentState = useSelector((state) => state.meeting.getAgentStatus);
	const projectSettings = useSelector((state) => state.project.projectSettings);
	const isMobile = useSelector((state) => state.project.isMobile)
	const userApprovedApt = useSelector((state) => state.project.userApprovedApt);
	const userVerified = useSelector((state) => state.project.userVerified);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const timeToSearch = 60000;

	useEffect(() => { }, [currentLanguge]);

	const requestAgent = async (e) => {
		setGetAgentStatus("register");
	};
	
	const searchAgain = (e) => {
		$(e.target).prop('disabled', true);
		searchMeetingAgain(currentLanguge);
	}


	// step - Welcome
	const FindAgentWelcome = () => {
		return (
			<div id="findAgent__welcome_container">
				<div className="p-0">
					<h3 className="fw-bold">{currentLanguge.findAgentComp.welcomeTitle}</h3>
					<p className="m-0">{currentLanguge.findAgentComp.welcomeSubtitle}</p>

					<ul className="mt-4 checkListStyle">
						{currentLanguge.findAgentComp.welcomeBolets && currentLanguge.findAgentComp.welcomeBolets.map((el, idx) => {
							return <li key={idx}>{el}</li>;
						})}
					</ul>
				</div>

				<div className="findAgent__welcome_actions mt-4">
					<a className="btn rf-btnPrimary rf-fullBtn btn-lg f18 w600"
						onClick={(e) => requestAgent(e)}
						id="findAgentButton"
						data-gt-category="agent component" data-gt-action="click" data-gt-label="open lead form"
						>
							{currentLanguge.findAgentComp.findAgentCTA}
					</a>
					<a className="btn rf-btnSecondery rf-fullBtn btn-lg mt-2 f18 w600"
						href={`/${projectSettings.project_slug}/register/?infllow=1`}
						id="findAgentReschedule_btn"
						data-gt-category="agent component" data-gt-action="click" data-gt-label="schedule for later">
						{currentLanguge.findAgentComp.findAgentReschedule}
					</a>
				</div>
			</div>
		);
	};

	// step - Searching
	const FindAgentSearching = () => {
		return (
			<div id="findAgent__searching_container">
				<div className="p-0">
					<h3 className="fw-bold">{currentLanguge.findAgentComp.searchingTitle}</h3>
					<p className="m-0">{currentLanguge.findAgentComp.searchingSubtitle}</p>
				</div>

				<div className="findAgent__welcome_actions mt-4">
					<a className="btn rf-btnSecondery rf-fullBtn btn-lg mt-2 f18 w600"
						href={`/${projectSettings.project_slug}/register/?infllow=1`}
						id="findAgentReschedule_btn"
						data-gt-category="agent component" data-gt-action="click" data-gt-label="schedule for later">
						{currentLanguge.findAgentComp.findAgentReschedule}
					</a>
				</div>
			</div>
		);
	}

	// step - Not Found
	const NotFoundAgent = () => {
		return (
			<div id="findAgent__notfound_container">
				<p className="text-center">{currentLanguge.findAgentComp.agentNotFoundMsg}</p>

				<a className="btn rf-btnPrimary rf-fullBtn btn-lg f18 w600" href={`/${projectSettings.project_slug}/register/?infllow=1`}
					data-gt-category="agent component" data-gt-action="click" data-gt-label="schedule for later">
					{currentLanguge.findAgentComp.findAgentReschedule}
				</a>

				<p className="my-3 text-center">{currentLanguge.findAgentComp.ctaOr}</p>

				<button className="btn rf-btnSecondery rf-fullBtn btn-lg f18 w600"
					onClick={(e) => searchAgain(e)}
					data-gt-category="agent component" data-gt-action="click" data-gt-label="search again">
					{currentLanguge.findAgentComp.searchAgainCTA}
				</button>
			</div>
		);
	};

	const AgentFound = () => {
		return <div id="findAgent__found_container" className="d-flex flex-column align-items-center">
			<img src="/assets/img/icons/ico-checked.svg" className="align-self-center pfIcon mb-4" alt="" height="30" width="30" />
			{currentLanguge.findAgentComp.agentFound}
		</div>;
	}


	const AgentComponent = ({ state, isMobile }) => {
		
		window.searchTimeBar = window.searchTimeBar || [];

		const clearIntervals = () => {
			for (var i=0; i < window.searchTimeBar.length; i++) {
				clearInterval(window.searchTimeBar[i]);
			}
			window.searchTimeBar = [];
		}

		useEffect(() => {
			let searchingAgentRunning = localStorage.getItem('searchingAgentRunning');
			let searchingAgentTime = localStorage.getItem('searchingAgentTime');

			if (state === "searching" && !isMobile) {
				// search progress bar
				if (window.searchTimeBar.length > 0) return;
				if (searchingAgentRunning == 'false' || (searchingAgentRunning == 'true' && parseInt(searchingAgentTime) > 0)) {
					localStorage.setItem('searchingAgentRunning', true);

					let searchProg = timeToSearch / 1000;
					let progBarWidth = 0;

					if (searchingAgentTime > 0) {
						searchProg = searchingAgentTime;	
						progBarWidth = ((timeToSearch / 1000 - searchProg) / (timeToSearch / 1000)) * 100;
					}
					$('#searchingBar').fadeIn();
					$('#progBar').css('width', `${progBarWidth}%`);

					window.searchTimeBar.push(setInterval(() => {
						if (searchProg >= 0) {
							let progBarWidth = ((timeToSearch / 1000 - searchProg) / (timeToSearch / 1000)) * 100;
							$('#progBar').css('width', `${progBarWidth}%`);
	
							localStorage.setItem('searchingAgentTime', searchProg);
							searchProg--;	
						} else {
							setGetAgentStatus("notFound");
							clearIntervals();
							
							$('#progBar').css('width', `0%`);
							$('#searchingBar').fadeOut();

							
							localStorage.removeItem('searchingAgentRunning');
							localStorage.removeItem('searchingAgentTime');

							// End Meeting 
							if (!userApprovedApt && !userVerified) {
								sendMeetingEnd();
								// meetingSlice
								setRoomSpaceID("");

								// verificationSlice
								setMeetingStartAt("");
								setRoomSpaceID_verification("");
								setSlug("");
							}
						}
					}, 1000))
				}
			}
			return () => { if (!searchingAgentRunning) clearIntervals() };
		}, [state]);

		if (!state) {
			$('.findAgentDemo_bg').slideDown();
			return <FindAgentWelcome/>;
		} else if (state === "register") {
			$('.findAgentDemo_bg').slideUp();
			return <AgentForm showBack={true} />;
		} else if (state === "notFound") {
			$('.findAgentDemo_bg').slideDown();
			return <NotFoundAgent />;
		} else if (state === "searching") {
			$('.findAgentDemo_bg').slideDown();
			return <FindAgentSearching/>;
		} else if (state == true) {
			$('.findAgentDemo_bg').slideDown();
			localStorage.removeItem('searchingAgentRunning');
			localStorage.removeItem('searchingAgentTime');
			return <AgentFound/>
		}
	};


	return (
		<>
			<div className="findAgent__main_container">
				<div className="findAgentDemo_bg">
					<img src="/assets/img/meating_bg2.png" />
					<div id="searchingBar">
						<span id="progBar"></span>
					</div>
				</div>

				<div className="findAgentDemo_content mt-4">
					<AgentComponent state={findingAgentState} isMobile={isMobile} />
				</div>
			</div>
		</>
	);
};

export default FindAgent;
