import { useSelector } from "react-redux";
import { useEffect } from "react";
import store from "../../Redux/Store/store";
import * as meetingActions from "../../Redux/Reducers/meetingSlice";
import * as registerActions from "../../Redux/Reducers/registerSlice";
import * as verificationActions from "../../Redux/Reducers/verificationSlice";
import { setLoadingModalContent } from "../../Helpers/AppartmentReserveUtils";

const SuccessPage = () => {
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const date = useSelector((state) => state.meeting.date);
	const isSelfReserve = useSelector((state) => state.meeting.isSelfReserve);

	const hour = useSelector((state) => state.meeting.hour);
	const redirectAdress = "https://realforest.com";

	const monthName = new Date(date).toLocaleString(currentLanguge.languageLocalCode, { month: 'long' }).trim();
	const fullYear = new Date(date).getFullYear();
	const day = new Date(date).getDate();
	
	useEffect(() => { }, [currentLanguge]);

	const onRedirectClick = () => {
		setLoadingModalContent(true);
		if (isSelfReserve) {
			store.dispatch(verificationActions.reset());
			store.dispatch(meetingActions.reset());
			store.dispatch(registerActions.reset());

			setTimeout(() => {
				window.location.href = redirectAdress;
			}, 500)
			return;
		}
		window.location.href = "/";
	};
	return (
		<div className="col-12 d-flex flex-column justify-content-center">
			<div className="col-12 d-flex flex-column justify-content-center">
				<img src="/assets/img/icons/ico-checked.svg" className="col-2 align-self-center" alt="" width="50px" />
				<h1 className="f36 w600 align-self-center text-center">{currentLanguge.register.registerSuccessful}</h1>
                <p className={`f20 align-self-center text-center`}>{`${currentLanguge.register.registerSuccessfulText} ${day} ${monthName}, ${fullYear} ${currentLanguge.general.inHour} ${hour}`}</p>
			</div>
			<button className="btn rf-btn-primary col-6 align-self-center f18 py-1 fw-bold" onClick={onRedirectClick}>
				{currentLanguge.formsCTA.formDone}
			</button>
		</div>
	);
};

export default SuccessPage;
