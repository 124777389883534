import { useSelector } from "react-redux";
import SignatureCanvas from "./SignatureCanvas";
import StepTitle from "../../UI/Titels/StepTitle";
import {
	setLoadingModalContent,
} from "../../../Helpers/AppartmentReserveUtils";
import { useEffect, useState } from "react";
import { makePostRequest } from "../../../Axios";
import "../DetailsApproval/DetailsApproval.sass";
import { handleExistingImage } from "../BuildingsSelection/image";
import $ from "jquery";
import "./ContractSignature.sass";

const ContractSignature = ({ handleSignatureSubmit, isAgent, meetingSlug }) => {
	const [signatureBase64, setSignatureBase64] = useState("");
	const [isSigEmpty, setIsSigEmpty] = useState(true);
	const thereIsNewContract = useSelector((state) => state.project.thereIsNewContract);
	const verificationSelector = useSelector((state) => state.verification);
	const isClientRegistered = useSelector((state) => state.verification.isClientRegistered);
	const selectedFloorImg = useSelector((state) => state.project.selectedAptImg);
	const currentLanguge = useSelector((state) => state.project.currentLanguge);

	useEffect(() => { }, [currentLanguge]);

	const setSignatureBase64Val = (value) => {
		setSignatureBase64(value);
	};

	const setSignatureConf = (value) => {
		setIsSigEmpty(value);
	};

	useEffect(() => {
		if (window.hasOwnProperty("$")) {
			window.$("#contractImg").sticky({ topSpacing: 90, bottomSpacing: 177, wrapperClassName: "" });
		}
	}, []);


	//get contract presented
	useEffect(async () => {
		try {
			setLoadingModalContent("");
			if (isClientRegistered) {
				let slug = verificationSelector.slug;
				
				let langCode = sessionStorage.getItem("langCode");
				if (!langCode || langCode == '') {
					langCode = '';
				} else {
					langCode = '/' + langCode;
				}

				const contractReq = await makePostRequest(`/meetingroomagreement/${slug}${langCode}`);
				if (!contractReq) {
					return;
				}

				if (contractReq.data.meeting) {
					let generated_agrement_html = (contractReq.data.meeting.generated_agrement_html).trim().replace('&lt;', '<').replace('&gt;', '>');
					$("#contractContent .d-none").removeClass('d-none').css('opacity', 0);
					$("#contractContent").html(generated_agrement_html);
					$("#contractContainer").fadeTo(400, 1);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}, [thereIsNewContract, currentLanguge]);

	useEffect(() => {
		handleExistingImage(selectedFloorImg, "contractImg");
	}, []);

	return (
		<div className="row m-0" id="apartment_reserve__stepCon">
			{/* Step Content  */} 
			<div className="px-3 py-4 position-relative flashit stepCon">

				<StepTitle className="pfHeaderShadow fw-bold mt-3">{currentLanguge.contract_step.stepTitle}</StepTitle>
				<div className="contract_preview__container mt-3" id="contractContainer">
					{/* <h2 className="fw-bold mb-3 pfHeaderColor">{currentLanguge.contract_step.contractTitle}</h2> */}
					<div id="contract_preview__contract" className="my-3 p-2 shadow ">
						<div id="contractContent" className="col" />
					</div>
				</div>
				{/* Digital Signature captur  */}
				<div className={`digital_signature_container my-5`}>
					<h2 className="fw-bold mb-2">{currentLanguge.contract_step.signContractTitle}</h2>
					<p className="mb-2">{currentLanguge.contract_step.signContractText}</p>
					<div
						id="contract_preview__contract"
						style={{overflow:'hidden'}}
						className=" d-flex justify-content-center my-3 p-3 rounded d-flex flex-column pfContainerShadow position-relative  pb-0 mb-0"
					>
						<div className="col d-flex flex-row justify-content-between">
							<h3 className="fw-bold pfItemFont pfHeaderColor mb-0">{currentLanguge.contract_step.signature}</h3>
							<button type="button" className="btn-close p-0" aria-label="Close" id="cleanSig">
								<img src="/assets/img/icons/icons8-erase.svg" alt="" height="20px" />
							</button>
						</div>
						{isAgent && <div className='SignatureCanvas__close'>{ currentLanguge.contract_step.agentSignatureNotAllowed }</div>}
						<SignatureCanvas setIsSigEmpty={setSignatureConf} setSignatureBase64={setSignatureBase64Val} />
						<div className="invalidFeedback m-0 mb-3 p-0 pfItemFont align-self-center" style={{ height: "10px" }} id="invalidSig" />
						{/* <hr className="w-100 pfHeaderColor p-0 m-0 align-self-center m-auto" /> */}
					</div>
				</div>
				<div className="col d-flex justify-content-center" onClick={(e) => handleSignatureSubmit(e, isSigEmpty, signatureBase64, currentLanguge)}>
					<input
						id="signatureButtonSelect"
						type="submit"
						value={currentLanguge.contract_step.stepCTA}
						data-gt-category='digital signature' data-gt-action='click' data-gt-label='send contract'
						className="btn rf-btn-primary col-xxl-6 col-lg-4 col-sm-12 col-12 fw-bold f18 py-2 align-self-center"
					/>
				</div>
			</div>

			{/* Step Graphics  */}
			<div className="bg-image rounded" style={{ maxHeight: "80vh" }} id='contractImg'>
				<div className="col-12 d-flex justify-content-center align-self-center m-auto position-relative">
					<img src={selectedFloorImg} className="rounded-bottom" alt="" />
				</div>
			</div>
		</div>
	);
};

export default ContractSignature;
