export const he = {
	language: 'heb',
	langAPICode: 'heb', 
	languageLocalCode: 'he-IL',
	direction: 'rtl',
	general: {
		timeSeconds: 'שניות',
		timeMinutes: 'דקות',
		timeDay: 'יום',
		inHour: 'בשעה',
		languageSelectorTitle: 'שפות',
		priceStartFrom: 'החל מ-',
		amount: 'סכום',
		previewMode: 'מצב תצוגה מקדימה',
		fewMoreMoments: 'עוד כמה רגעים...',
		pleaseWait: 'אנא המתן...',
		or: 'או',
		metric: {
			SQM: 'מ"ר',
			SQF: 'פ"ר',
		}
	},
	directions: {
		n: 'צפון',
		w: 'מערב',
		e: 'מזרח',
		s: 'דרום',
		ne: 'צפון מזרח',
		nw: 'צפון מערב',
		se: 'דרום מזרח',
		sw: 'דרום מערב',
		ns: 'צפון דרום',
		we: 'מערב מזרח'
	},
	footer: {
		homeLink: 'עמוד הבית',
		termsLink: 'תנאי שימוש'
	},
	formsFields: {
		firstName: 'שם פרטי',
		lastName: 'שם משפחה',
		ID: 'תעודת זהות',
		email: 'דואר אלקטרוני',
		phone: 'טלפון',
		mobilePhone: 'טלפון נייד',
		terms: 'אני מאשר/ת את',
		differentBuyer_details: 'פרטי הרוכש/ת שונים מהפרטים המוזנים בטופס',
		termsLink: 'תנאי שימוש',
		daySelectorTitle: 'בחירת יום',
		nextWeek: 'השבוע הבא',
		prevWeek: 'השבוע הקודם',
		hourSelectorTitle: 'בחירת שעה',
		hourSelectorSubitle: 'השעות המוצגות לפי אזור זמן: ',
		timeZone: 'איזור זמן',
		roomsFilter_all : 'כל חדרים',
		roomsFilter_suffix : 'חדרים'
	},
	formsCTA: {
		formSend: 'שלח',
		formDone: 'סיים',
		formContinue: 'המשך',
		formApprove: 'אשר',
		formaCancel: 'בטל',
		close: 'סגור',
		registerSubmitBtn: 'אישור',
		loginVerificationSubmitBtn: 'שלח',
		validationCodeSubmitBtn: 'אישור',
		reschedualMeetingSubmitBtn: 'אישור',
		skipSchedualMeeting: 'באפשרותכם ללחוץ על כפתור דילוג'
	},
	formsErrors: {
		invalidFirstName: 'יש להזין שם פרטי תקין',
		invalidLastName: 'יש להזין שם משפחה תקין',
		invalidID: 'יש להזין תעודת זהות תקינה',
		invalidPhone: 'יש להזין מספר טלפון תקין',
		invalidTerms: 'יש לאשר את תנאי השימוש',
		invalidEmail: 'יש להזין כתובת דואר אלקטרוני תיקנית',
		invalidValidationCode: 'הקוד שהוזן שגוי, נסו שנית',
		emptyValidationCode: 'קוד ריק. יש להזין את הקוד שהתקבל',
		invalidMeetingTime: 'יש לבחור זמן לפגישה',
		meetingTimeIsTaken: 'השעה שבחרתם תפוסה, אנא בחרו בשעה אחרת',
		notAvailableMeeting: 'אין פגישות זמינות',
		selectMeetingTime: 'נא לבחור תאריך ושעה לפגישה',
		invalidDate: 'נא לבחור תאריך לפגישה',
		invalidTime: 'נא לבחור שעה לפגישה',
		tryAgain: 'משהו השתבש. אנא נסו שוב',
		emailIsTaken: 'כתובת הדואר שהוזנה תפוסה',
		leadUpdateError: 'לא ניתן לעדכן פרטים',
		generalPaymentError: 'שגיאה בלתי צפויה',
		botError: 'האם אתה בוט?',
		noMeeting: 'הפגישה לא נמצא.',
		noOptionsFoound: 'לא נמצאו אפשרויות'
	},
	flowErrorModal: {
		footerText: 'למידע נוסף ניתן להתקשר לטלפון'
	},
	landing: {
		catalogBtn: "קטלוג הפרויקט",
	},
	userDetailsFormFields: {
		formTitle1: 'פרטי התקשרות',
		formTitle2: 'פרטי הרוכש/ת',
		formTitle2_buyer: 'פרטי המזנמינ/ה',
		formDescription1: 'האימות יתבצע באמצעות הפרטים המוזנים בטופס זה',
		isEditingLable: '(החוזה תחת עריכה)'
	},
	register: {
		title: "בחירת מועד לפגישה",
		titleIcon1: 'זמן פגישה',
		titleIcon2: 'פרטי שיחת הוידאו ישלחו לפני הפגישה במייל',
		formTitle: 'פרטי התקשרות',
		registerSuccessful: 'פגישתך נקבעה בהצלחה!',
		registerSuccessfulText: 'אנו מחכים לראותך בפגישה שנקבעה לתאריך'
	},
	login: {
		initialLoginGreeting: 'שלום',
		initialLoginGreetingText1: 'כדי להתחיל יש לבצע תהליך אימות',
		initialLoginGreetingText2: 'כחלק משמירה על פרטי חשבונך,  אנו מבקשים לאמת את זהותך גם באמצעות קוד אימות חד פעמי שישלח אליך',
	},
	loginLateArrival: {
		textPart1: 'לצערנו מועד הפגישה עם',
		textPart2: 'עבר ולא ניתן עוד להצטרף לפגישה זו',
		descriptionText1: 'היי',
		descriptionText2: 'אנו מקפידים על זמני הפגישות על מנת לא לעכב את הלקוחות הבאים בתור',
		descriptionText3: 'נשמח לתיאום מועד חדש',
		reschedualBtn: 'קביעת פגישה למועד אחר'
	},
	loginEarlyArrival: {
		titleTextClient: 'יש עוד זמן עד הפגישה עם',
		text1_1Client: 'שלום',
		text1_2Client: 'הפגישה נקבעה לתאריך',
		text1_3Client: 'בשעה',
		text2Client: 'מחכים לראותך',
		titleTextAgent: 'יש עוד זמן עד הפגישה עם',
		text1_1Agent: 'שלום',
		text1_2Agent: 'הפגישה נקבעה לתאריך',
		text1_3Agent: 'בשעה',
		text2Agent: 'מחכים לראותך',
	},
	reschedualMeeting: {
		title: 'בחירת מועד לפגישה',
		titleIcon1: 'זמן פגישה',
		titleIcon2: 'פרטי שיחת הוידאו ישלחו לפני הפגישה במייל',
		reschedualMeetingSuccessfulMsg: 'הפגישה נקבעה בהצלחה!'
	},
	checkVideoStep: {
		timerTitle: 'הפגישה תתחיל בעוד',
		title: 'בדיקת מצלמה ומיקרופון',
		subTitle: 'הפגישה תתקיים בוידאו, ועל כן לפני הפגישה מומלץ לוודא שהמצלמה והמיקרופון תקינים',
		checkCamera: 'בדיקת מצלמה',
		cameraListTitle: 'בחירת מצלמה',
		checkMic: 'בדיקת מיקרופון',
		micListTitle: 'בחירת מיקרופון',
		speakerListTitle: 'בחירת רמקול'
	},
	confirmValidationCode: {
		codePlaceholder: 'הזן את הקוד שהתקבל',
		emailText: 'שלחנו קוד חד פעמי לאימייל',
		phoneText: 'שלחנו קוד חד פעמי לטלפון',
		validationCodeLable1: 'מהו הקוד שקיבלת?',
		validationCodeLable2: 'הזינו את 6 הספרות שהתקבלו:',
		resandValidationCode: 'שליחת קוד פעם נוספת',
		useDifferentValidationMethod: 'השתמש בשיטת אימות אחרת',
		resandGeneralError: 'משהו השתבש. אנא נסו שוב',
		resandTimerDesc: 'ניתן לשלוח שוב בעוד',
		resandAttemtsPassed: 'ניתן לבצע עד 3 ניסיונות',
		validationCodeSent: 'קוד נשלח בהצלחה',
		emailNotFound: 'כתובת אימייל לא נמצאה',
		phoneNotFound: 'מספר הטלפון לא נמצא',
		phoneInput: 'מספר טלפון נייד',
		codeVerificationPopupTitle: 'אימות פרטים',
		codeVerificationPopupCTA: 'אימות',
		codeVerificationInputLable: 'הזן/י את הקוד',
		codeVerificationInputPlaceholder: 'קוד האימות',
		verificationPopupTitle1: 'אימות פרטים',
		verificationPopupTitle2: 'שליחת קוד באמצעות',
		tryAgain: 'אנא נסו שנית',
		enter6dig: 'יש להזין קוד בעל 6 ספרות'
	},
	verficationSuccessful: {
		title: 'האימות הושלם בהצלחה',
		text: 'הפגישה תחל בדקות הקרובות, מומלץ לוודא את תקינות המצלמה והמקרופון.  יש באפשרותכם לצפות בדירות הזמינות ולהתחיל בתהליך באופן עצמאי עד להגעת נציג המכירות',
		cta: 'כניסה'
	},
	detailsApprovalModalContainer: {
		title: 'אישור פרטי הדירה',
		clientDetailsTitle: 'פרטי הרוכש/ת',
		footerText: 'בלחיצתך על אישור הבחירה, הדירה תשוריין עבורך ולא תהיה זמינה לרכישה ע"י משתמשים אחרים.'
	},
	donutStats: {
		lableAvailable: 'זמינות',
		lableInProgress: 'בתהליך',
		lableReserved: 'משוריינות'

	},
	projectStats: {
		title: 'דירות בפרויקט:',
		lableAvailable: 'זמינות',
		lableInProgress: 'בתהליך',
		lableReserved: 'משוריינות'
	},
	stepsMenu: {
		step1:'בחירת\n בניין',
		step2:'בחירת\n קומה',
		step3:'בחירת\n דירה',
		step4:'פרטי\n דירה',
		step5:'חתימה\n דיגיטלית',
		step6:'תשלום',
		step7:'תיאום\n חוזה',
		step8:'סיום'
	},
	apartmentViewChangeCTA: {
		title: 'מצבי תצוגה נוספים',
		tableView: 'תצוגת טבלה',
		graphicView: 'תצוגה גרפית',
		view3D: 'הצג 3D',
		hide3D: 'הסתר 3D'

	},
	findAgentComp: {
		welcomeTitle: 'היפגשו עם נציג מכירות אונליין',
		welcomeSubtitle: 'מלאו את הפרטים ונאתר עבורכם נציג מכירות זמין לפגישה מיידית או תאמו פגישת עתידית לזמן שמתאים לכם.',
		welcomeBolets: ['נציג המכירות ישמח לעמוד לשירותכם ולענות על שאלות','ליווי מלא במציאת הדירה המתאימה עבורכם','סיוע בהשלמת תהליך שיריון הדירה באופן מקוון'],
		searchingTitle: 'מאתרים נציג פנוי...',
		searchingSubtitle: 'אנא המתינו, אנו מאתרים עבורכם נציג מכירות זמין לפגישה מיידית.',
		agentNotFoundMsg: 'לצערנו לא נמצא נציג מכירות זמין כעת,\nבאפשרותכם לתאם פגישה למועד מאוחר יותר או לנסות לאתר נציג זמין פעם נוספת.',
		timeLeft: 'זמן נותר',

		findAgentWelcomeTitle: 'הזמנת נציג לשיחת וידיאו',
		findAgentWelcomeSubtitle: 'בכל שלב תוכלו לשוחח עם נציג בשיחת וידיאו',
		findAgentCTA: 'הזמן נציג',
		findAgentReschedule: 'קבע פגישה עם נציג למועד מאוחר יותר',
		agentFound: 'נציג נמצא עבורך והוא יצטרף לפגישה ברגעים הקרובים',
		agentFormTitle: 'הזמנת נציג',
		agentNameLable: 'שם הנציג:',
		agentPhoneLable: 'טלפון:',
		agentFormSubtitle: 'בבקשה מלאו את הפרטים והמערכת תאתר עבורכם נציג פנוי לשיחת וידאו',
		searchingForAgentTitle: 'מחפש נציג פנוי...',
		searchingForAgentText: 'אנא המתן בזמן שהמערכת מאתרת נציג פנוי',
		toastTitle: 'שימו לב',
		toastMsg: 'הפגישה עם הנציג עומדת להסתיים בעוד 10 דקות. \n ניתן להמשיך בתהליך השריון באופן עצמאי',
		searchAgainCTA: 'חפש שוב',
		findAgentNavCTA: 'הזמן נציג',
		ctaOr: 'או'
	},
	onBoardingModal : {
		title: 'ברוכים הבאים לממשק שריון הדירה הדיגיטלי!',
		msg: 'בכל שלב תוכלו לשוחח עם נציג בשיחת וידיאו',
		inviteAgent_stepTitle: 'היי, בוא נדבר!',
		schedule_stepTitle: 'היי, בוא נדבר!',
		desktop_inviteAgent: 'קבע פגישה עם נציג למועד מאוחר יותר',
		desktop_schedule: 'הזמן נציג לשיחת וידיאו עכשיו!',
		mobile_inviteAgent: 'קבע פגישה עם נציג למועד מאוחר יותר',
		mobile_schedule: 'הזמן נציג לשיחת וידיאו עכשיו!',

		inviteAgent_stepTitle: 'הזמנת נציג',
		schedule_stepTitle: 'קבע פגישה',
		desktop_inviteAgent:  'בכל שלב תוכלו לשוחח עם נציג בשיחת וידיאו',
		mobile_inviteAgent:  'בכל שלב תוכלו לשוחח עם נציג בשיחת וידיאו',
		mobile_schedule: 'קבע פגישה עם נציג למועד מאוחר יותר',

		cta: 'הבנתי, בואו נתחיל',
		cta_finish: 'סיים',
		cta_next: 'הבא',
		cta_skip: 'דלג'
	},
	meetingVideoComp: {
		reqSent: 'הבקשה נשלחה',
		reqApproved: 'הבקשה אושרה',
		skipSigntBtn: 'דילוג על חתימה',
		skipPaymentBtn: 'דילוג על סליקה',
		waitingApprovel: 'ממתין לאישור',
		reqRejected: 'הבקשה נדחתה ע"י מנהל',
		cameraListTitle: 'בחירת מצלמה',
		micListTitle: 'בחירת מיקרופון',
		speakerListTitle: 'בחירת רמקול',
		agentControlBtn: 'שליטת סוכן',
		clientControlBtn: 'שליטת לקוח',
		prepingMsg: 'מכינים בשבילך הכל...',
		addingNewUserToMeeting: 'לקוח הצטרף לשיחה',
		addingNewAgentToMeeting: 'סוכן הצטרף לשיחה',
		userLeftMeeting: 'המשתמש עזב/ה את השיחה',
		agentLeftMeeting: 'הסוכן עזב/ה את השיחה',
		underAgentControl: 'מערכת בשליטת סוכן',
		userInfo_ID: 'ת.ז',
		userInfo_Phone: 'טלפון:',
		userInfo_AgentNumber: 'מספר סוכן:',
		alt_shareScreen: 'שיטוף מסך',
		alt_fullScreen: 'מסך מלא',
		alt_videoSource: 'הגדרות מצלמה',
		alt_soundSource: 'הגדרות קול',
		sectionTitle_controls: 'שליטה',
		sectionTitle_actions: 'פעולות',
		notesListTitle: 'רשימת הערות',
		notesError: 'יש להזין לפחות שני תווים',
	},
	messageBar: {
		apartment: 'דירה',
		selectApartment: 'בחירת דירה',
		floor: 'קומה',
		signature: 'חתימה',
		payment: 'תשלום',
		meeting: 'פגישה',
		finish: 'סיום',
		reservation: 'שריון דירה'
	},
	appartmentShort: {
		apartment: 'דירה',
		rooms: 'חדרים',
		building: 'בניין',
		floor: 'קומה',
		model: 'דגם',
		balconyTerrace: 'שטח המרפסת'
	},
	apartmentStats: {
		availableApt: 'דירה זמינה',
		notAvailableApt: 'דירה לא זמינה',
		reservedApt: 'דירה תפוסה',
		progressApt: 'דירה משוריינת',
		soldApt: 'דירה נמכרה'
	},
	buildingsSelection_step: {
		stepTitle: 'בחירת בניין',
		floors: 'קומות',
		rooms: 'חדרים',
		selectionCTA: 'בחירת בניין'
	},
	floorSelection_step: {
		stepTitle: 'בחירת קומה',
		rooms: 'חדרים',
		stepCTA: 'בחירת קומה'
	},
	apartmentSelectionList: {
		stepTitle: 'בחירת דירה',
		watchApt: 'צפייה בדירה',
	},
	apartmentSelectionTable: {
		watchApt: 'לצפייה בדירה',
		stepTitle: 'בחירת דירה',
		mobile_stepTitle: 'בחירת דירה (טבלה)',
		mobile_filterAptNum: 'סידור לפי: מספר דירה',
		mobile_filterPrice: 'סידור לפי: מחיר',
		mobile_filterRooms: 'סידור לפי: מספר חדרים',
		price: 'מחיר',
		clearFilters: 'נקה סינון',
		headerApartment: 'דירה',
		headerBuilding: 'בניין',
		headerFloor: 'קומה',
		headerRooms: 'חדרים',
		headerModel: 'דגם',
		headerDirection: 'כיוון',
		headerAptTerrace: 'שטח דירה',
		headerBalconyTerrace: 'שטח מרפסת',
		headerGradenTerrace: 'שטח גינה',
		headerServiceTerrace: 'שטח מרפסת שירות',
		mobile_filtersModel_trigger: 'סינון',
		mobile_filtersModel_title: 'סנן לפי',
		mobile_filtersModel_submit: 'ביצוע סינון',
		filter__show_available: 'הצג דירות זמינות בלבד'

	},
	apartmentSelectionGraphic: {
		watchApt: 'צפייה בדירה',
		selectApt: 'בחירת דירה'
	},
	apartmentDetails_step: {
		rooms: 'חדרים',
		apartment: 'דירה',
		aptTerrace: 'שטח דירה',
		balconyTerrace: 'שטח מרפסת',
		gradenTerrace: 'שטח גינה',
		serviceTerrace: 'שטח מרפסת שירות',
		tabsTrigger_general:'מידע כללי',
		tabsTrigger_gallery:'גלריית תמונות',
		tabsTrigger_tour3D:'סיור 3D',
		tabsTrigger_plans:'מפרט דירה',
		tabsTrigger_downloads: 'קבצים להורדה',
		aptDescriptionTitle: 'תאור הדירה',
		aptAdditionalDetailsTitle: 'מאפיינים נוספים',
		aptDownloadPlansLink: 'מפרט דירה להורדה',
		stepCTA: 'זו הדירה שאני רוצה',
		alt_gallery: 'תמונת גלרייה',
		alt_galleryThumb: 'תמונת גלרייה ממוזערת ',
		alt_videoThumb: 'תמונת וידיאו ממוזערת ',

	},
	detailsApproval_step: {
		stepTitle: 'בקשה לרכישה',
		sempleFilesTitle: 'מסמכים לדוגמא',
		stepSubtitle: 'יופי, בחרתם את הדירה שלכם! כדי לשריין את הדירה יש למלא את הפרטים, לחתום דיגיטלית ולשלם את דמי הרישום.',
		reserveContractExampleLink: 'הורדת דוגמת הסכם לשריון לדירה',
		sellContractExampleLink: 'הורדת דוגמת חוזה לרכישת דירה',
		contractLoader: 'מכין תצוגה מקדימה לחוזה...',
		stepCTA: 'המשך',
		agentWaitingModalTitle : 'הלקוח מבצע אימות, אנא המתן לסיום התהליך',
		agentWaitingModalTitle_customer : 'הסוכן מבצע אימות, אנא בדוק את תיבת הדואר / הטלפון שלך להעברת הקוד האימות לסוכן.'
	},
	contract_step: {
		stepTitle: 'הסכם בקשה לרכישה',
		contractTitle: 'חוזה שריון הדירה',
		signContractTitle: 'חתימה על הסכם שריון הדירה',
		signContractText: 'נא לחתום מטה',
		signature: 'חתימה',
		stepCTA: 'המשך',
		stepBack: 'חזור',
		updatingSignature: 'מעדכן את חתימתכם...',
		agentSignatureNotAllowed: 'העבר שליטה ללקוח לצורך חתימה החוזה'
	},
	payment_step: {
		stepTitle: 'תשלום דמי רישום',
		descriptionText1: 'כמעט סיימנו! כדי להשלים את תהליך השריון יש לשלם את דמי הרישום על סך',
		descriptionText2: 'לביצוע התשלום אנא מלאו את פרטי כרטיס האשראי',
		helpText: 'לעזרה ניתן להתקשר לטלפון',
		credit_card_number_placeholder: 'מספר כרטיס אשראי',
		expiry_placeholder: 'תוקף 12/21',
		cvv_placeholder: 'CVV',
		card_holder_id_number_placeholder: 'ת.ז של בעל/ת הכרטיס',
		invalidName: 'יש להזין את שם בעל הכרטיס',
		ccnum_lable: 'מספר כרטיס אשראי',
		myexp_lable: 'תוקף',
		mycvv_lable: 'CVV',
		myid_lable: 'ת.ז של בעל/ת הכרטיס',
		customerDetailsTitle: 'פרטי בעל הכרטיס',
		fullName_lable: 'שם מלא',
		fullName_placeholder: 'שם מלא של בעל הכרטיס',
		stepCTA: 'תשלום',
		paymentFooterText: 'אזור זה מאובטח במנגנון הצפנה מתקדם בטכנולוגיית SSL ועומד בתקן PCI העולמי',
		paymentSuccessfulTitle: 'התשלום בוצע בהצלחה',
		paymentUnsuccessfulMsg: 'התשלום לא עבר, אנא נסה שוב',
		paymentSuccessfulMsg: 'התשלום בוצע בהצלחה. <br/> נא להמתין... המערכת מעבירה אותך לעמוד הבא',
		paymenyPopupTitle: 'תשלום מקדמה',
		paymenyPopupClose: 'סגור',
		paymenyPopupContinu: 'המשך',
		paymenyProcessModalTitle: 'תשלום מקדמה',
		paymenyProcessModalText: 'נא להמתין בזמן שהמערכת מבצעת תשלום...',
		updatingPayment: 'מעדכן את התשלום...'
	},
	followUpMeeting_step: {
		stepTitle: 'תיאום פגישת המשך',
		subTitle: 'תהליך השריון הושלם בהצלחה!<br/> בעמוד זה יש באפשרותכם לתאם פגישת המשך עם נציג מטעם החברה להשלמת רכישת הדירה. <br/><br/>אנא בחרו תאריך ושעה מועדפים',
		stepCTA: 'תיאום פגישה',
		skipStepCTA: 'דילוג על תיאום פגישה'
	},
	finish_step: {
		text1: 'הידד',
		text2: 'שריון דירה',
		text3: 'הסתיים בהצלחה',
		description: 'אנחנו מודים לך שבחרת בנו על מנת שנוכל ביחד לבנות את בית חלומותיך ומבטיחים לעשות זאת בדרך הטובה ביותר',
		approvalNumTitle: 'אסמכתא:',
		infoSent: 'הסכם השריון ואישור תשלום נשלחו לכתובת:',
		nextStepButton: 'סיום'
	},
	flowErrors: {
		alreadyReservedApt: 'מצטערים אנו רואים שהדירה בה אתם צופים אינה זמינה יותר לרכישה. באפשרותכם לבחור דירה אחרת',
		generalError_retry: 'שגיאה כללית. נא לנסות שנית',
		generalError_reload: "שגיאה כללית. נא לרענן את הדף ולנסות שנית.",
		error713: 'הפגישה תחל בקרוב, יש להמתין לסוכן המכירות על מנת להמשיך בתהליך',
		error705: 'הפגישה הסתיימה',
		error711: 'הפגישה הסתיימה',
		errorNoticTitle: 'שימו לב',
		reschedualBtn: 'קביעת פגישה למועד אחר',
		registerRequired: 'יש לבצע הרשמה לפני שממשיכים',
		signatureRequired: 'יש לחתום על הטופס לפני שממשיכים',
		stripePaymentIntentError: 'לא ניתן לייצר מסוף תשלום'
	},
	app: {
		mobileUnavailable: 'המכשיר אינו נתמך',
		mobileUnavailable2: 'אנא התחברו דרך הדפדפן בהמחשב על מנת לקבל את החוויה המלאה'
	},
	accessibility: {
		resetTitle: 'איפוס הגדרות',
		closeTitle: 'סגור',
		menuTitle: 'אפשרויות נגישות',
		increaseText: 'הגדלת גופן',
		decreaseText: 'הקטנת גופן',
		increaseTextSpacing: 'הגדלת רווח בין תווים',
		decreaseTextSpacing: 'הקטנת רווח בין תווים',
		invertColors: 'היפוך צבעים',
		grayHues: 'מונוכרום',
		underlineLinks: 'הדגשת קישורים',
		bigCursor: 'סמן גדול',
		readingGuide: 'סרגל קריאה',
		textToSpeech: 'טקסט לדיבור',
		speechToText: 'דיבור לטקסט'
	}
};

export const en = {
	language: 'eng',
	langAPICode: 'eng',
	languageLocalCode: 'en-US',
	direction: 'ltr',
	general: {
		timeSeconds: 'Seconds',
		timeMinutes: 'Minutes',
		timeDay: 'Day',
		inHour: 'At',
		languageSelectorTitle: 'Languages',
		priceStartFrom: 'Starting Price',
		amount: 'Amount',
		previewMode: 'Preview Mode',
		fewMoreMoments: 'Few more moments...',
		pleaseWait: 'Please wait...',
		or: 'or',
		metric: {
			SQM: 'm²',
			SQF: 'ft²',
		}
	},
	directions: {
		n: 'North',
		w: 'West',
		e: 'East',
		s: 'South',
		ne: 'North East',
		nw: 'North West',
		se: 'South East',
		sw: 'South West',
		ns: 'North South',
		we: 'West East'
	},
	footer: {
		homeLink: 'Homepage', 
		termsLink: 'Terms of service'
	},
	formsFields: {
		firstName: 'First Name',
		lastName: 'Last Name',
		ID: 'ID',
		email: 'Email',
		phone: 'Phone Number',
		mobilePhone: 'Phone Number',
		terms: 'I agree to the',
		differentBuyer_details: 'Select if buyer details are different',
		termsLink: 'Terms of service',
		daySelectorTitle: 'Select Day',
		nextWeek: 'Next week',
		prevWeek: 'Previous week',
		hourSelectorTitle: 'Select time',
		hourSelectorSubitle: 'Time displayed according to: ',
		timeZone: 'Time Zone',
		roomsFilter_all : 'All',
		roomsFilter_suffix : 'Bedrooms'
	},
	formsCTA: {
		formSend: 'Send',
		formDone: 'Done',
		formContinue: 'Continue',
		formApprove: 'Accept',
		formaCancel: 'Cancel',
		close: 'Close',
		registerSubmitBtn: 'Submit',
		loginVerificationSubmitBtn: 'Send',
		validationCodeSubmitBtn: 'Submit',
		reschedualMeetingSubmitBtn: 'Submit',
		skipSchedualMeeting: 'You can skip this stage and schedule later'
	},
	formsErrors: {
		invalidFirstName: 'Please enter a valid First name',
		invalidLastName: 'Please enter a valid Last name',
		invalidID: 'Please enter a valid ID',
		invalidPhone: 'Please enter a valid Phone number',
		invalidTerms: 'Please accept the terms',
		invalidEmail: 'Please enter a valid Email adress',
		invalidValidationCode: 'Invalid Code. Try Again.',
		emptyValidationCode: 'Please enter the validation code',
		invalidMeetingTime: 'Please choose meeting time',
		notAvailableMeeting: 'No meetings avaiable',
		meetingTimeIsTaken: 'The time you selected is taken, please select another time',
		selectMeetingTime: 'Please select a date and time for the meeting',
		invalidDate: 'Please select a date for the meeting',
		invalidTime: 'Please select a time for the meeting',
		tryAgain: 'Something went wrong. Please try again',
		emailIsTaken: 'The e-mail address entered is already taken',
		leadUpdateError: "Couldn't update user information",
		generalPaymentError: 'An unexpected error occured',
		botError: 'Are you a bot?',
		noMeeting: 'Meeting not found',
		noOptionsFoound: 'No suggestions'
	},
	flowErrorModal: {
		footerText: 'For more information please call'
	},
	landing: {
		catalogBtn: "Project Catalog",
	},
	userDetailsFormFields: {
		formTitle1: 'Contact Details',
		formTitle2: 'Your Details',
		formTitle2_buyer: 'Buyer Details',
		formDescription1: 'The verification will be performed using the form details',
		isEditingLable: 'The Contract is being edited'
	},
	register: {
		title: "Meeting Schedule",
		titleIcon1: 'Meeting length',
		titleIcon2: 'Meeting details will be sent to your Email',
		formTitle: 'Contact Details',
		registerSuccessful: 'Your meeting has been scheduled successfully',
		registerSuccessfulText: 'We are waiting to see you in'
	},
	login: {
		initialLoginGreeting: 'Hello!',
		initialLoginGreetingText1: 'Please complete verificaton',
		initialLoginGreetingText2: 'As part of keeping your account safe, please verify your identity with a verification code that has been sent to you',
	},
	loginLateArrival: {
		textPart1: 'Meeting time with agent',
		textPart2: 'has passed and is unavailable',
		descriptionText1: 'Hello',
		descriptionText2: 'We set the meeting times so as not to delay the next customers in line',
		descriptionText3: 'Please schedule new meeting',
		reschedualBtn: 'Schedule'
	},
	loginEarlyArrival: {
		titleTextClient: 'There is time left till the meeting with',
		text1_1Client: 'Hello',
		text1_2Client: 'The meeting has been scheduled to',
		text1_3Client: 'At',
		text2Client: 'Waiting to see you!',
		titleTextAgent: 'There is time left till the meeting with',
		text1_1Agent: 'Hello',
		text1_2Agent: 'The meeting has been scheduled to',
		text1_3Agent: 'At',
		text2Agent: 'Waiting to see you!',
	},
	reschedualMeeting: {
		title: 'Meeting Schedule',
		titleIcon1: 'Meeting date',
		titleIcon2: 'Meeting details will be sent to your Email',
		reschedualMeetingSuccessfulMsg: 'Your meeting has been scheduled successfully'
	},
	checkVideoStep: {
		timerTitle: 'The meeting will start in',
		title: 'Camera and microphone check',
		subTitle: 'The meeting will be held through video, please make sure your camera and microphone are connected',
		checkCamera: 'Camera Check',
		cameraListTitle: 'Select Camera',
		checkMic: 'Microphone Check',
		micListTitle: 'Select Microphone',
		speakerListTitle: 'Select Speaker'
	},
	confirmValidationCode: {
		codePlaceholder: 'Please enter your code',
		emailText: 'Code has been sent to your Email',
		phoneText: 'Code has been sent to your Phone Number',
		validationCodeLable1: 'Validation code',
		validationCodeLable2: 'Please enter the six-digit code:',
		resandValidationCode: 'Resend',
		useDifferentValidationMethod: 'Use different validation method',
		resandGeneralError: 'Something went wrong. Please try again',
		resandTimerDesc: 'Resend in',
		resandAttemtsPassed: '3 verification attempts allowed',
		validationCodeSent: 'Code has been sent',
		emailNotFound: 'Email adress not found',
		phoneNotFound: 'Phone number not found',
		phoneInput: 'Phone number',
		codeVerificationPopupTitle: 'Verification',
		codeVerificationPopupCTA: 'Verify',
		codeVerificationInputLable: 'Please submit your code below',
		codeVerificationInputPlaceholder: 'Verification code',
		verificationPopupTitle1: 'Verification',
		verificationPopupTitle2: 'Send verification code with',
		tryAgain: 'Try again',
		enter6dig: 'Please enter 6 digits code'
	},
	verficationSuccessful: {
		title: 'Verification has been completed successfully',
		text: 'The meeting will start in the next few minutes, please make sure your camera and microphone are connected. You can view the available apartments and start the process independently until the sales representative arrives',
		cta: 'Start'
	},
	detailsApprovalModalContainer: {
		title: 'Verify your reservation',
		clientDetailsTitle: 'Buyer details',
		footerText: 'By clicking on the confirmation button, the apartment will be reserved for you and will not be available for purchase by other customers.'
	},
	donutStats: {
		lableAvailable: 'Available',
		lableInProgress: 'Pending',
		lableReserved: 'Reserved'

	},
	projectStats: {
		title: 'Project Availability:',
		lableAvailable: 'Available',
		lableInProgress: 'Pending',
		lableReserved: 'Reserved'
	},
	stepsMenu: {
		step1:'Building\n Selection',
		step2:'Floor\n Selection',
		step3:'Apartment\n Selection',
		step4:'Apartment\n Details',
		step5:'Digital\n Signature',
		step6:'Payment',
		step7:'Meeting\n Schedule',
		step8:'Complete'
	},
	apartmentViewChangeCTA: {
		title: 'Apartment view modes',
		tableView: 'Table View',
		graphicView: 'Graphic View',
		view3D: 'View 3D',
		hide3D: 'Hide 3D'

	},
	findAgentComp: {
		welcomeTitle: 'Meet with a sales agent online',
		welcomeSubtitle: 'Please fill your details and we will look for available agents or schedule a meeting for later.',
		welcomeBolets: ['Will provide you information and answer any questions','Help you find the right apartment for you','Will assist you in completing the reservation process online'],
		searchingTitle: 'Looking for an available agent...',
		searchingSubtitle: 'Please wait, we’re looking for an available agent to take your call',
		agentNotFoundMsg: 'Unfortunately we were not able to find an available agent at the moment, you can schedule a meeting for later or search again.',
		timeLeft: 'TIME LEFT',

		findAgentWelcomeTitle: "Hey, Let's Talk!",
		findAgentWelcomeSubtitle: 'At any stage you can invite an agent for a video chat',
		findAgentCTA: 'Invite an agent',
		findAgentReschedule: 'Schedule with an agent for later',
		agentFound: 'Sales agent has been found, please wait',
		agentFormTitle: 'Agent Request',
		agentNameLable: 'Agent Name:',
		agentPhoneLable: 'Phone Number:',
		agentFormSubtitle: 'Please fill in your details and the system will find an available agent for a video chat',
		searchingForAgentTitle: 'Looking for available agent...',
		searchingForAgentText: 'Please wait while the system locates an available agent',
		toastTitle: 'Pay attention',
		toastMsg: 'The meeting with the agent is about to end in 10 minutes. \n You can continue the process independently',
		searchAgainCTA: 'Search Again',
		findAgentNavCTA: 'Schedule meeting',
		ctaOr: 'Or'
	},
	onBoardingModal : {
		title: 'Welcome to the apartments reservation interface!',
		msg: 'At any stage you can speak with an agent, via video chat',
		inviteAgent_stepTitle: "Hey, let's talk!",
		schedule_stepTitle: "Hey, let's talk!",
		desktop_inviteAgent: 'Invite an agent for a video chat now!',
		desktop_schedule: 'Schedule with an agent for later',
		mobile_inviteAgent: 'Invite an agent for a video chat now!',
		mobile_schedule: 'Schedule with an agent for later',
		cta: "Got it, let's begin",
		cta_finish: 'Finish',
		cta_next: 'Next',
		cta_skip: 'Skip'
	},
	meetingVideoComp: {
		reqSent: 'Request Sent',
		reqApproved: 'Request Approved',
		skipSigntBtn: 'Skip Signature',
		skipPaymentBtn: 'Skip Payment',
		waitingApprovel: 'Wainting for approvel',
		reqRejected: 'Request rejected by manager',
		cameraListTitle: 'Select Camera',
		micListTitle: 'Select Microphone',
		speakerListTitle: 'Select Speaker',
		agentControlBtn: 'Agent Control',
		clientControlBtn: 'User Control',
		prepingMsg: 'Loading..',
		addingNewUserToMeeting: 'Client joined the meeting',
		addingNewAgentToMeeting: 'Agent joined the meeting',
		userLeftMeeting: 'The user has left the meeting',
		agentLeftMeeting: 'The agent has left the meeting',
		underAgentControl: 'Agent control',
		userInfo_ID: 'ID',
		userInfo_Phone: 'Phone Number:',
		userInfo_AgentNumber: 'Agent Number:',
		alt_shareScreen: 'Share screen',
		alt_fullScreen: 'Full screen',
		alt_videoSource: 'Camera settings',
		alt_soundSource: 'Sound settings',
		sectionTitle_controls: 'CONTROL',
		sectionTitle_actions: 'ACTIONS',
		notesListTitle: 'LEAD NOTES',
		notesError: 'Enter a valid note',
	},
	messageBar: {
		apartment: 'Apartment',
		selectApartment: 'Select Apartment',
		floor: 'Floor',
		signature: 'Signature',
		payment: 'Payment',
		meeting: 'Meeting',
		finish: 'Complete',
		reservation: 'Reservation'
	},
	appartmentShort: {
		apartment: 'Apartment',
		rooms: 'Bedrooms',
		building: 'Building',
		floor: 'Floor',
		model: 'Model',
		balconyTerrace: 'Balcony Area',
	},
	apartmentStats: {
		availableApt: 'Available',
		notAvailableApt: 'Reserved',
		reservedApt: 'Reserved',
		progressApt: 'Reserved',
		soldApt: 'Sold'
	},
	buildingsSelection_step: {
		stepTitle: 'Building Selection',
		floors: 'Floors',
		rooms: 'Bedrooms',
		selectionCTA: 'Select'
	},
	floorSelection_step: {
		stepTitle: 'Floor Selection',
		rooms: 'Bedrooms',
		stepCTA: 'Select'
	},
	apartmentSelectionList: {
		stepTitle: 'Apartment Selection',
		watchApt: 'Select',
	},
	apartmentSelectionTable: {
		watchApt: 'Select',
		stepTitle: 'Apartment Selection',
		mobile_stepTitle: 'Apartment Selection',
		mobile_filterAptNum: 'By apartment number',
		mobile_filterPrice: 'By pricce',
		mobile_filterRooms: 'By room number',
		price: 'Price',
		clearFilters: 'Clear Filters',
		headerApartment: 'Apartment',
		headerBuilding: 'Building',
		headerFloor: 'Floor',
		headerRooms: 'Bedrooms',
		headerModel: 'Model',
		headerDirection: 'Direction',
		headerAptTerrace: 'Apartment Area',
		headerBalconyTerrace: 'Balcony Area',
		headerGradenTerrace: 'Garden Area',
		headerServiceTerrace: 'Extras Area',
		mobile_filtersModel_trigger: 'Filters',
		mobile_filtersModel_title: 'Filter by',
		mobile_filtersModel_submit: 'Set filters',
		filter__show_available: 'Show only available units'
	},
	apartmentSelectionGraphic: {
		watchApt: 'View',
		selectApt: 'Select Apartment'
	},
	apartmentDetails_step: {
		rooms: 'Bedrooms',
		apartment: 'Apartment',
		aptTerrace: 'Apartment Area',
		balconyTerrace: 'Balcony Area',
		gradenTerrace: 'Garden Area',
		serviceTerrace: 'Extras Area',
		tabsTrigger_general:'General',
		tabsTrigger_gallery: 'Gallery',
		tabsTrigger_tour3D:'3D Tour',
		tabsTrigger_plans:'Specifications',
		tabsTrigger_downloads: 'Files',
		aptDescriptionTitle: 'Apartment Description',
		aptAdditionalDetailsTitle: 'Additional Details',
		aptDownloadPlansLink: 'Download Plans',
		stepCTA: 'Reserve now',
		alt_gallery: 'Gallery photos',
		alt_galleryThumb: 'Gallery thumbnail',
		alt_videoThumb: 'Video thumbnail',
	},
	detailsApproval_step: {
		stepTitle: 'Reservation Request',
		sempleFilesTitle: 'Sample documents',
		stepSubtitle: 'Great, you have selected your apartment!  In order to reserve the apartment, please fill in your details, sign and pay the reservation deposit.',
		reserveContractExampleLink: 'Agreement Sample',
		sellContractExampleLink: 'Contract Sample',
		contractLoader: 'Creating Contract',
		stepCTA: 'Continue',
		agentWaitingModalTitle : 'Client preforming verification, please wait',
		agentWaitingModalTitle_customer : 'Agent preforming verification, please check your mailbox / phone to transfer the verification code to the agent.'

	},
	contract_step: {
		stepTitle: 'Purchase Request Agreement',
		contractTitle: 'Appartment Reservation Agreement',
		signContractTitle: 'Approving reservation with signature',
		signContractText: 'Please sign in the box below',
		signature: 'Signature',
		stepCTA: 'Continue',
		stepBack: 'Back',
		updatingSignature: 'Updating your signature...',
		agentSignatureNotAllowed: 'Transfer control to the customer for signing the contract'
	},
	payment_step: {
		stepTitle: 'Reservation Deposit Payment',
		descriptionText1: 'You’re almost done! In order to complete the reservation process, please pay the deposit fee of',
		descriptionText2: 'To make a payment, please fill in your credit card details',
		helpText: 'For assistance please call',
		credit_card_number_placeholder: 'Card Number',
		expiry_placeholder: 'MM/YY',
		cvv_placeholder: 'CVV',
		card_holder_id_number_placeholder: 'Cardholder ID',
		invalidName: 'Please enter cardholder name',
		ccnum_lable: 'Card Number',
		myexp_lable: 'Best before',
		mycvv_lable: 'CVV',
		myid_lable: 'Cardholder ID number',
		customerDetailsTitle: 'Cardholder Details',
		fullName_lable: 'Full Name',
		fullName_placeholder: 'Cardholder full name',
		stepCTA: 'Reserve Apartment',
		paymentFooterText: 'This page is secured by an advanced encryption mechanism in SSL technology and meets the global PCI standard',
		paymentSuccessfulTitle: 'Payment Successful',
		paymentUnsuccessfulMsg: 'Your payment was not successful, please try again',
		paymentSuccessfulMsg: 'Processing, please wait..<br/>The Downpayment Transferred successfully',
		paymenyPopupTitle: 'Processing reservation payment',
		paymenyPopupClose: 'Close',
		paymenyPopupContinu: 'Continue',
		paymenyProcessModalTitle: 'Processing reservation payment',
		paymenyProcessModalText: 'Processing payment, please wait..',
		updatingPayment: 'Updating payment...'
	},
	followUpMeeting_step: {
		stepTitle: 'Follow up meeting',
		subTitle: 'You have successfully completed the reservation process! <br/> You may schedule a follow up meeting with a representative to complete your purchase. <br/><br/>Please select a convenient time and date from the options below',
		stepCTA: 'Schedule',
		skipStepCTA: 'Skip and schedule later'
	},
	finish_step: {
		text1: 'Congratulations',
		text2: 'Reservation of apartment',
		text3: 'has been successful.',
		description: 'We thank you for choosing the house of your dreams with us!',
		approvalNumTitle: 'Payment Reference N.',
		infoSent: 'The reservation agreement and payment confirmation sent to:',
		nextStepButton: 'Finish'
	},
	flowErrors: {
		alreadyReservedApt: 'Sorry, the apartment you are looking at is no longer available for purchase. Please choose another apartment',
		generalError_retry: 'General Error. Please try again',
		generalError_reload: "General Error. Please reload the page and try again",
		error713: 'The meeting will start soon, you have to wait for the sales agent to continue the process',
		error705: 'The meeting is over',
		error711: 'The meeting is over',
		errorNoticTitle: 'Pay attention',
		reschedualBtn: 'Schedule an appointment for another date',
		registerRequired: 'Registration must be done before proceeding',
		signatureRequired: 'The contract must be signed before proceeding',
		stripePaymentIntentError: 'Payment terminal cannot be produced'
	},
	app: {
		mobileUnavailable:'Device not supported',
		mobileUnavailable2:'Please use a browser on your computer to get the full experience',
	},
	accessibility: {
		resetTitle: 'Reset',
		closeTitle: 'Close',
		menuTitle: 'Accessibility Options',
		increaseText: 'Increase text size',
		decreaseText: 'Decrease text size',
		increaseTextSpacing: 'Increase text spacing',
		decreaseTextSpacing: 'Decrease text spacing',
		invertColors: 'Invert colors',
		grayHues: 'Gray hues',
		underlineLinks: 'Underline links',
		bigCursor: 'Big cursor',
		readingGuide: 'Reading guide',
		textToSpeech: 'Text to speech',
		speechToText: 'Speech to text'
	}
};
