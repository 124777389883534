import DateSelectionFormFields from "../../General/UserDetailsForm/DateSelectionFormFields";
import "../../../pages/RegisterPage/RegisterPage.sass";
import MainTitle from "../../../components/UI/Titels/MainTitle";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { handleSkipFollowup, setFollowMeeting, setLoadingModalContent } from "../../../Helpers/AppartmentReserveUtils";
import { handleExistingImage } from "../BuildingsSelection/image";
import GTEvent from "../../../Helpers/GTEvent";

import parse from 'html-react-parser'
import "./FollowUpMeeting.sass";

const FollowUpMeeting = (props) => {
	const projectID = useSelector((state) => state.project.projectID);
	const selectedFloorImg = useSelector((state) => state.project.selectedAptImg);
	const [thereAreAgent, setThereAreAgents] = useState(true);
	const currentLanguge = useSelector((state) => state.project.currentLanguge);
	const basicData = useSelector((state) => state.project.appartmentBasicData);

	useEffect(() => { }, [currentLanguge]);
	
	useEffect(() => {
		setLoadingModalContent("");
		handleExistingImage(selectedFloorImg,"meetingImg", `${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`)
	}, []);

	const propsFunc = (data) => {
		setThereAreAgents(data);
	};
	
	const setFollowMeetingLocal = (e, currentLanguge) => {
		setFollowMeeting(e, currentLanguge);
		GTEvent(e);
	}

	return (
		<div className="row m-0 flashit apartment_reserve__stepContainer" id="apartment_reserve__step-7">
			<div className="stepCon">
				<div className="col-xxl-12">
					<MainTitle className="mt-4 mb-0">{currentLanguge.followUpMeeting_step.stepTitle}</MainTitle>

					<p>{parse(currentLanguge.followUpMeeting_step.subTitle)}</p>

					{/* Registration Form */}
					<form id="register_form" className="my-5" onSubmit={(e) => setFollowMeetingLocal(e, currentLanguge)}>
						{/* Date Selection */}
						{thereAreAgent ? <DateSelectionFormFields isFollowMeeting={true} setThereAreAgents={propsFunc} projectID={projectID} /> : <div>No agent component</div>}

						<div className="row justify-content-center pt-4">
							{thereAreAgent ? (
								<div className="col-lg-6 col-sm-12 text-center" id="scheduleBtn_container">
									<div className="d-grid">
										<button type="submit" className="btn rf-btn-primary fw-bold fs-6 py-3 pfSubmitBtnColor pfShadow" id="scheduleBtn" data-gt-category='follow up meeting' data-gt-action='click' data-gt-label='submit form'>{currentLanguge.followUpMeeting_step.stepCTA}</button>
									</div>
									<div id="successReg" className="invalidField"></div>
								</div>
							) : (
								null
							)}

							<div className="row d-flex justify-content-center mb-2">
								<a href="#" className=" zindexon text-center text-decoration-none fw-bold mt-3  rf-darkText rf-no-hover" onClick={(e) => handleSkipFollowup(currentLanguge)}  id="skipBtn"  data-gt-category='follow up meeting' data-gt-action='click' data-gt-label='skip follow up meeting'>
									{currentLanguge.followUpMeeting_step.skipStepCTA}
								</a>
							</div>
						</div>
					</form>
				</div>
			</div>
			{/* Step Graphics  */}
			<div className="bg-image" id='meetingImg'>
				<img src={selectedFloorImg} alt="" />
			</div>
		</div>
	);
};

export default FollowUpMeeting;
