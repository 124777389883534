import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	apiEmail: "frontapi@re-jet.online",
	apiPassword: "DNAK43023NVPOAS236NBAPOREJ",
	verificationMethod: "phone",
	verificationAttempts: 0,
	currentVerificationStep: 1,
	email: "",
	mobileNumber: "",
	areaCode: "050",
	countryCode: "+972",
	numberWithPrefix: "",
	code: "",
	slug: "",
	agentKey: "",
	isAgent: false,
	isAgentControl: false,
	projectLanding: "",
	isClientRegistered: false,
	pusherKey: "04d7dd7701ae0821ca40",
	salesAgentID: "",
	agentName: "",
	agentNumber: "",
	roomSpaceID: "",
	twillioName: "realforest",
	twillioSID: "ACcc54c71075b731385d6073165ae9386c",
	twillioSecret: "SK0d717194bf89fdf23bf71fab81a67a3a",
	twillioToken: "b76Sdr1429ZNgJyrLNyOLj3de2k67F0e",
	workingToken: "",
	isClient: false,
	isSignatureRequired: true,
	isPaymentRequired: true,
	reservationAmount: 0,
	tranzillaAccount: "",
	tranzillaApiKey: "",
	tranzillaTrandmode: "",
	meetingStartAt: "",
	showTimerAfter: "",
	setClientType: "",
	continueToApp:false,
};

export const verificationSlice = createSlice({
	name: "verification",
	initialState,
	reducers: {
		reset: () => initialState,
		incrementStep: (state) => {
			state.currentVerificationStep += 1;
		},
		setContinueToApp: (state, action) => {
			state.continueToApp = action.payload
		},
		decrementStep: (state) => {
			state.currentVerificationStep -= 1;
		},
		setAgentName: (state, action) => {
			state.agentName = action.payload;
		},
		setAgentNumber: (state, action) => {
			state.agentNumber = action.payload;
		},
		setVerificationStep: (state, action) => {
			state.currentVerificationStep = action.payload;
		},
		setVerificationAttempts: (state, action) => {
			state.verificationAttempts = action.payload;
		},
		setVerificationMethod: (state, action) => {
			state.verificationMethod = action.payload;
		},
		setEmail: (state, action) => {
			state.email = action.payload.toLowerCase();
		},
		setMobileNumber: (state, action) => {
			state.mobileNumber = action.payload;
		},
		setNumberWithPrefix: (state, action) => {
			state.numberWithPrefix = action.payload;
		},
		setAreaCode: (state, action) => {
			state.areaCode = action.payload;
		},
		setCode: (state, action) => {
			state.code = action.payload;
		},
		setSlug: (state, action) => {
			state.slug = action.payload;
		},
		setAgentKey: (state, action) => {
			state.agentKey = action.payload;
		},
		setProjectLanding: (state, action) => {
			state.projectLanding = action.payload;
		},
		setIsClientRegistered: (state, action) => {
			state.isClientRegistered = action.payload;
		},
		setSalesAgentID: (state, action) => {
			state.salesAgentID = action.payload;
		},
		setRoomSpaceID: (state, action) => {
			state.roomSpaceID = action.payload;
		},
		setWorkingToken: (state, action) => {
			state.workingToken = action.payload;
		},
		setIsClient: (state, action) => {
			state.isClient = action.payload;
		},
		setIsAgent: (state, action) => {
			state.isAgent = action.payload;
		},
		setIsAgentControl: (state, action) => {
			state.isAgentControl = action.payload;
		},
		setIsSignatureRequired: (state, action) => {
			state.isSignatureRequired = action.payload;
		},
		setIsPaymentRequired: (state, action) => {
			state.isPaymentRequired = action.payload;
		},
		setTranzillaAccouont: (state, action) => {
			state.tranzillaAccount = action.payload;
		},
		setTranzillaApiKey: (state, action) => {
			state.tranzillaApiKey = action.payload;
		},
		setTranzillaTrandmode: (state, action) => {
			state.tranzillaTrandmode = action.payload;
		},
		setMeetingStartAt: (state, action) => {
			state.meetingStartAt = action.payload;
		},
		setReservationAmount: (state, action) => {
			state.reservationAmount = action.payload;
		},
		setShowTimerAfter: (state, action) => {
			state.showTimerAfter = action.payload;
		},
		setClientType: (state, action) => {
			state.setClientType = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	reset,
	setShowTimerAfter,
	setTranzillaAccouont,
	setTranzillaTrandmode,
	setMeetingStartAt,
	setReservationAmount,
	setTranzillaApiKey,
	incrementStep,
	decrementStep,
	setContinueToApp,
	setEmail,
	setAreaCode,
	setMobileNumber,
	setVerificationMethod,
	setCode,
	setSlug,
	setAgentKey,
	setProjectLanding,
	setVerificationStep,
	setVerificationAttempts,
	setNumberWithPrefix,
	setIsClientRegistered,
	setSalesAgentID,
	setRoomSpaceID,
	setWorkingToken,
	setIsClient,
	setIsAgent,
	setIsAgentControl,
	setIsPaymentRequired,
	setIsSignatureRequired,
	setClientType,
	setAgentName,
	setAgentNumber,
} = verificationSlice.actions;

export default verificationSlice.reducer;
