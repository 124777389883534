import { useParams } from "react-router-dom";
import { makePostRequest } from "../../Axios";
import { setIsSelfReserve } from "../../Helpers/AppartmentReserveUtils";
import { setSlug } from "../../Helpers/verificationUtils";
import LoginPage from "../../pages/LoginPage/LoginPage";
import store from "../../Redux/Store/store";
import moment from "moment";
import { useEffect, useState } from "react";
import {
	handleSetToken,
} from "../../Helpers/verificationUtils";
import * as projectActions from "../../Redux/Reducers/projectSlice";
import LoadingModal from "../Modals/LoadingModal/LoadingModal";
import { setProjectSettingsObject } from "../../Helpers/AppartmentReserveUtils";
import { setMeetingStatus, setRoomSpaceID } from "../../Helpers/MeetingsUtils";

const MainRedirect = () => {
	const getUrlParams = new URLSearchParams(window.location.search);
	const agentKey = getUrlParams.get("agentkey");
	const { meetingSlug } = useParams();
	const { projectSlug } = useParams();
	
	const token = sessionStorage.getItem("forestToken");
	const [isLoading, setIsLoading] = useState(true);
	const timeBeforeMeeting = 10;

	const isUserVerified = store.getState().project.userVerified;
	const [continueToApp, setContinueToApp] = useState(store.getState().verification.continueToApp);


	// set meeting time
	const verifyMeetingTime = async (meetingSlug, setIsLoading) => {
		const timeFormat = "YYYY-MM-DD HH:mm:ss";
		try {
			let data = new FormData();
			let langCode = sessionStorage.getItem("langCode");
			if (!langCode || langCode == '') {
				langCode = '';
			} else {
				langCode = '/' + langCode;
			}

			//get meeting data
			const res = await makePostRequest(`/meetingrooms/${meetingSlug}${langCode}`, data);
			if (!res) {
				return;
			}

			const meetingLength = res.data.project.meeting_duration;
			const meetingUtc = res.data.meeting.meeting_start_datetime;

			const meetingStartTime = moment.utc(meetingUtc).tz(res.data.clientDetails.client_timezone).format(timeFormat);
			const meetingStartTime2 = moment(meetingStartTime)

			const meetingEndtTime = moment(meetingStartTime2).add(meetingLength, "minutes");
			const now = moment();
			store.dispatch(projectActions.setProjectDefaultLangauge(res.data.project.project_default_language));
			store.dispatch(projectActions.setCurrentLangauge(res.data.project.project_default_language));
			setProjectSettingsObject(res.data.project);

			//early check for date match
			store.dispatch(projectActions.setFlowType('scheduled-meeting'));

			

			if (now.isAfter(meetingStartTime2) && agentKey) {
			// On Time
				setContinueToApp(true);
				setIsLoading(false);
				return;
			} else if (now.isBefore(meetingStartTime2)) {
			// Early
				let duration = moment.duration(meetingStartTime2.diff(now))
				let asMinuets = duration.asMinutes();

				if (asMinuets > timeBeforeMeeting) {
					setIsLoading(false);
					return;
				}
				if (asMinuets < timeBeforeMeeting) {
					setIsLoading(false);
					return;
				}
			} else if (now.isAfter(meetingEndtTime)) {
			// Late 
				setIsLoading(false);
				return;
			}
		} catch (err) {
			console.log(err);
		}
	};


	// set meeting slug
	useEffect(() => {
		setSlug(meetingSlug);
		setRoomSpaceID(meetingSlug)
		if (meetingSlug) {
			setIsSelfReserve(false);
		}
	},[])

	// set meeting
	useEffect(async () => {
		if(meetingSlug) handleSetToken().then(()=>verifyMeetingTime(meetingSlug, setIsLoading))
	}, [token]);

	

	//Agent goes directly into the app
	if ((agentKey || isUserVerified) && continueToApp) {
		setMeetingStatus('start')
		window.location.href = `/${projectSlug}/building_selection/${window.location.search}&meetingSlug=${meetingSlug}`;
	}
	//verification page
	if (meetingSlug) return <LoginPage  setContinueToApp={setContinueToApp} />;
	
	return <>
		<LoadingModal/>
	</>;
};

export default MainRedirect;
