import { validateEmail } from "../verificationUtils/";
import * as actions from "../../Redux/Reducers/registerSlice";
import * as projectActions from "../../Redux/Reducers/projectSlice";
import { showErrorMessage, setShowPopup } from "../verificationUtils/";
import { makePostRequest } from "../../Axios";
import store, { persistor } from "../../Redux/Store/store";
import {
	setRemoteAction,
	setUserApprovedApt,
	setShowApprovePopup,
	setGetAgentStatus,
	setLoadingModalContent,
	setMeetingSetSuccesful,
	setNowMeeting,
	setProjectSettingsObject,
	setFlowErrorObject,
} from "../AppartmentReserveUtils";
import moment from "moment";
import { setUserId } from "../MeetingsUtils";
import { setMeetingSettings } from "../ProjectLanding";
import $ from "jquery";
import { pushGAvent } from "../GTEvent";


export const handleRegisterInputs = () => {
	// Leed
	const setFirstName = (value) => {
		store.dispatch(actions.setFirstName(value));

		const isDifferentBuyer = store.getState().register.isDifferentBuyer;
		if (!isDifferentBuyer) {
			store.dispatch(actions.setBuyerFirstName(value));
		}
	};
	const setLastName = (value) => {
		store.dispatch(actions.setLastName(value));

		const isDifferentBuyer = store.getState().register.isDifferentBuyer;
		if (!isDifferentBuyer) {
			store.dispatch(actions.setBuyerLastName(value));
		}
	};
	const setEmail = (value) => {
		store.dispatch(actions.setEmail(value));

		const isDifferentBuyer = store.getState().register.isDifferentBuyer;
		if (!isDifferentBuyer) {
			store.dispatch(actions.setBuyerEmail(value));
		}
	};
	const setMobileNumber = (value) => {
		store.dispatch(actions.setMobileNumber(value));

		const isDifferentBuyer = store.getState().register.isDifferentBuyer;
		if (!isDifferentBuyer) {
			store.dispatch(actions.setBuyerMobileNumber(value));
		}
	};
	const setAreaCode = (value) => {
		store.dispatch(actions.setAreaCode(value));

		const isDifferentBuyer = store.getState().register.isDifferentBuyer;
		if (!isDifferentBuyer) {
			store.dispatch(actions.setBuyerAreaCode(value));
		}
	};
	
	const setID = (value) => {
		store.dispatch(actions.setID(value));

		const isDifferentBuyer = store.getState().register.isDifferentBuyer;
		if (!isDifferentBuyer) {
			store.dispatch(actions.setBuyerID(value));
		}
	};

	// Buyer
	const setBuyerEmail = (value) => {
		store.dispatch(actions.setBuyerEmail(value));
	};
	const setBuyerMobileNumber = (value) => {
		store.dispatch(actions.setBuyerMobileNumber(value));
	};
	const setBuyerMobileNumberWithoutPrefix = (value) => {
		store.dispatch(actions.setBuyerMobileNumberWithoutPrefix(value));
	};
	
	const setBuyerFirstName = (value) => {
		store.dispatch(actions.setBuyerFirstName(value));
	};
	const setBuyerLastName = (value) => {
		store.dispatch(actions.setBuyerLastName(value));
	};
	const setBuyerID = (value) => {
		store.dispatch(actions.setBuyerID(value));
	};
	const setBuyerAreaCode = (value) => {
		store.dispatch(actions.setBuyerAreaCode(value));
	};


	const setIsTermsAgreed = (value) => {
		store.dispatch(actions.setTerms(value));
	};

	const setIsDifferentBuyer = (value) => {
		store.dispatch(actions.setIsDifferentBuyer(value));
	};

	return {
		setID,
		setLastName,
		setMobileNumber,
		setFirstName,
		setEmail,
		setAreaCode,
		setIsTermsAgreed,

		setBuyerEmail,
		setBuyerMobileNumber,
		setBuyerFirstName,
		setBuyerLastName,
		setBuyerID,
		setBuyerAreaCode,
		setIsDifferentBuyer,
		setBuyerMobileNumberWithoutPrefix,
	};
};

export const setTimezone = (value) => {
	store.dispatch(actions.setTimezone(value));
};

export const validateRegisterForm = (e, currentLanguge) => {
	const { ID, email, firstName, lastName, mobileNumber, termsAgreed,
		buyerFirstName, buyerLastName, buyerEmail, buyerID, buyerMobileNumber} = store.getState().register;
	const isDifferentBuyer = store.getState().register.isDifferentBuyer;

	let showID = true;
	if (window.location.pathname.includes('/register')) {
		showID = false;
	}
	try {
		let isValid = true;

		if (firstName.length < 2) {
			showErrorMessage("invalidFirstName", currentLanguge.formsErrors.invalidFirstName);
			isValid = false;
		}
		if (lastName.length < 2) {
			showErrorMessage("invalidLastName", currentLanguge.formsErrors.invalidLastName);
			isValid = false;
		}
		if (ID.length < 8 && !isDifferentBuyer && showID) {
			showErrorMessage("invalidID", currentLanguge.formsErrors.invalidID);
			isValid = false;
		}
		//handle email validation
		const isEmailValid = validateEmail(email);
		if (!isEmailValid) {
			showErrorMessage("invalidEmail", currentLanguge.formsErrors.invalidEmail);
			isValid = false;
		}
		if (mobileNumber.length < 12) {
			showErrorMessage("invalidPhone", currentLanguge.formsErrors.invalidPhone);
			isValid = false;
		}
		if (termsAgreed === false) {
			showErrorMessage("invalidTerms", currentLanguge.formsErrors.invalidTerms);
			isValid = false;
		}

		// If coming from register page
		var pathname = window.location.href;
		if (pathname.includes('/register')) { 
			// check for dates
			const meetingHour = store.getState().meeting.hour;
			const meetingDate = store.getState().meeting.date;

			if (!meetingDate || meetingDate == '') {
				showErrorMessage("invalidDate", currentLanguge.formsErrors.invalidDate);
				isValid = false;
			}

			if (!meetingHour || meetingHour == '') {
				showErrorMessage("invalidTime", currentLanguge.formsErrors.invalidTime);
				isValid = false;
			}
		}

		// check buyer detailes
		if (isDifferentBuyer) {
			if (buyerFirstName.length < 2) {
				showErrorMessage("invalidFirstName_buyer", currentLanguge.formsErrors.invalidFirstName);
				isValid = false;
			}
			if (buyerLastName.length < 2) {
				showErrorMessage("invalidLastName_buyer", currentLanguge.formsErrors.invalidLastName);
				isValid = false;
			}
			if (buyerID.length < 8) {
				showErrorMessage("invalidID_buyer", currentLanguge.formsErrors.invalidID);
				isValid = false;
			}
			//handle email validation
			const isBuyerEmailValid = validateEmail(buyerEmail);
			if (!isBuyerEmailValid) {
				showErrorMessage("invalidEmail_buyer", currentLanguge.formsErrors.invalidEmail);
				isValid = false;
			}
			if (buyerMobileNumber.length < 12) {
				showErrorMessage("invalidPhone_buyer", currentLanguge.formsErrors.invalidPhone);
				isValid = false;
			}
		}

		if (!isValid) {
			if (document.getElementById("register_page")) {
				document.getElementById("register_page").scrollIntoView();
			} else if (document.getElementById("customer_details_from")) { 
				document.getElementById("customer_details_from").scrollIntoView();
			}
			return false;
		}

		
		return true;
	} catch (err) {
		console.log(err);
	}
};

const validateAgentRegisterForm = (firstName, lastName, email, mobileNumber, termsAgreed, currentLanguge) => {
	try {
		if (firstName.length < 2) {
			showErrorMessage("invalidFirstNameAgent", currentLanguge.formsErrors.invalidFirstName);
			return false;
		}
		if (lastName.length < 2) {
			showErrorMessage("invalidLastNameAgent", currentLanguge.formsErrors.invalidLastName);
			return false;
		}
		//handle email validation
		const isEmailValid = validateEmail(email);
		if (!isEmailValid) {
			showErrorMessage("invalidEmailAgent", currentLanguge.formsErrors.invalidEmail);
			return false;
		}
		if (mobileNumber.length < 10) {
			showErrorMessage("invalidPhoneAgent", currentLanguge.formsErrors.invalidPhone);
			return false;
		}
		if (!termsAgreed) {
			showErrorMessage("invalidTermsAgent", currentLanguge.formsErrors.invalidTerms);
		}
		return true;
	} catch (err) {
		console.log(err);
	}
};

export const setUserID = (id) => {
	store.dispatch(projectActions.setUserID(id));
};


/* @return A timezone offset in hours */
const getOffsetstring = (timeZone = 'UTC', date = new Date()) => {
	const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
	const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
	let offset = (tzDate.getTime() - utcDate.getTime()) / 3600000;
	
	let offsetStr = '';
	let offsetInt = parseInt(offset);
	let offsetEnd = offset % 1 == 0 ? '00' : '30';
	if (offset >= 10) {
		offsetStr = `+${offsetInt}${offsetEnd}`
	} else if (offset >= 0) {
		offsetStr = `+0${offsetInt}${offsetEnd}`
	} else if (offset <= -10) {
		offsetStr = `-${offsetInt}${offsetEnd}`
	} else if (offset < 0) {
		offsetStr = `-0${offsetInt}${offsetEnd}`
	}
	return offsetStr;
}


export const requestMeeting = async (e, meetingType, currentLanguge) => {
	if (e) {
		e.preventDefault();
		$(`#${e.target.id}`).find('input[type=submit]').prop('disabled', true);
	}


	const userID = store.getState().meeting.userID;
	const meetingHour = store.getState().meeting.hour;
	const meetingDate = store.getState().meeting.date;
	const timeZone = store.getState().project.timezone;
	let timeOffsetStr = getOffsetstring(timeZone);
	
	if (!meetingHour || !meetingDate) {
		$("#scheduleBtn").prop("disabled", false);
		$("#scheduleBtn").prop("value", currentLanguge.followUpMeeting_step.stepCTA);
		showStaticMessage("successReg", currentLanguge.formsErrors.invalidMeetingTime, false);
		return;
	}
	let projectSlug = store.getState().project.projectID;
	let sessionProjectSlug = sessionStorage.getItem('projectID');
	if (!projectSlug && sessionProjectSlug != '') projectSlug = sessionProjectSlug;


	const meetingTime = `${meetingDate}T${meetingHour}${timeOffsetStr}`;

	const toUtchs = moment.utc(meetingTime).format("YYYY-MM-DD HH:mm");
	const meetingData = new FormData();

	meetingData.append("projectslug", projectSlug);
	meetingData.append("userclient_id", userID);
	meetingData.append("datetime", toUtchs);
	meetingData.append("meetingtype", meetingType);

	const meetingReq = await makePostRequest("/schedule_meeting", meetingData).catch((err) => console.log(err));

	if (meetingReq) {
		if (meetingReq.data.statusCode === 400) {
			setMeetingSetSuccesful({ meetingSuccesful: false, responseText: currentLanguge.formsErrors.meetingTimeIsTaken });
			
			if(e) $(`#${e.target.id}`).find('input[type=submit]').prop('disabled', false);

			return;
		}
		if (meetingReq.data.statusCode == 200) {
			showStaticMessage("successReg", currentLanguge.reschedualMeeting.reschedualMeetingSuccessfulMsg, true);
			setMeetingSetSuccesful({ meetingSuccesful: true, responseText: currentLanguge.reschedualMeeting.reschedualMeetingSuccessfulMsg });
		}
		if (meetingReq.data.statusCode !== 200) {
			setMeetingSetSuccesful({ meetingSuccesful: false, responseText: currentLanguge.formsErrors.tryAgain });

			showStaticMessage("successReg", currentLanguge.formsErrors.tryAgain, false);
			if(e) $(`#${e.target.id}`).find('input[type=submit]').prop('disabled', false);
			return;
		}

		if(e) $(`#${e.target.id}`).find('input[type=submit]').prop('disabled', false);
		return meetingReq;
	}
};
export const setTimeZone = (value) => {
	store.dispatch(projectActions.setTimeZone(value));
};
export const handleRegisterForm = async (e, currentLanguge) => {
	e.preventDefault();

	const { termsAgreed, lastName, ID, email, firstName, ipAddress } = store.getState().register;
	let projectSlug = store.getState().project.projectID;
	let sessionProjectSlug = sessionStorage.getItem('projectID');
	if (!projectSlug && sessionProjectSlug != '') projectSlug = sessionProjectSlug;

	const userID = store.getState().meeting.userID;
	const timeZone = store.getState().project.timezone;
	const numberParsed = window.registerPhoneInput.getNumber();
	const meetingHour = store.getState().meeting.hour;
	const meetingDate = store.getState().meeting.date;
	const country = store.getState().register.country;

	let langCode = sessionStorage.getItem("langCode");
	if (!langCode || langCode == '') {
		langCode = '';
	}
	
	//check form validity
	const isFormValid = validateRegisterForm(e, currentLanguge);


	if (isFormValid && termsAgreed) {
		if (!meetingHour || !meetingDate) {
			showErrorMessage("successReg", currentLanguge.formsErrors.selectMeetingTime);
			return;
		}

		const formData = new FormData();
		formData.append("projectslug", projectSlug);
		formData.append("firstname", firstName);
		formData.append("lastname", lastName);
		formData.append("email", email);
		formData.append("mobile", numberParsed);

		if (window.location.pathname.includes('/register')) {
			formData.append("identity_id", '000000000');
		} else {
			formData.append("identity_id", ID);
		}

		formData.append("client_type", 1);
		formData.append("leadsource", "WEB");
		formData.append("client_timezone", timeZone);
		formData.append("client_country", country);
		formData.append("client_ip", ipAddress);
		formData.append("client_language", langCode);

		
		$("#projectRegisterButton").prop("disabled", true);
		$("#projectRegisterButton").prop("value", currentLanguge.general.fewMoreMoments);

		const req = await makePostRequest("/newclientlead", formData).catch((err) => {
			console.log(err);
		});
		if (req) {
			if (req.data.statusCode) {
				showErrorMessage("invalidEmail", currentLanguge.formsErrors.emailIsTaken);
				return;
			}
			if (req.data.client) {
				const id = req.data.client.id;
				setUserId(id);
			}
			const requestMeetingReq = await requestMeeting(e, "agent", currentLanguge);
			if (requestMeetingReq) {
				$("#projectRegisterButton").prop("disabled", false);
				$("#projectRegisterButton").prop("value", currentLanguge.formsCTA.registerSubmitBtn);
				if (requestMeetingReq.data.statusCode === 200) {
					pushGAvent('registration', 'click', 'register form submited');
					store.dispatch(actions.setRegisterSuccess(true));
				}
			}

			return;
		}
		return;
	}
};
export const setIsClientVerified = (value) => {
	store.dispatch(actions.setIsClientVerified(value));
};



export const updateLeadData = async (currentLanguge) => {
	try {
		const { firstName, lastName, email, mobileNumber, ID,
			buyerFirstName, buyerLastName, buyerEmail, buyerID, buyerMobileNumber } = store.getState().register;
		const meetingSlug = store.getState().verification.slug;
		const timeZone = store.getState().project.timezone;
		let langCode = sessionStorage.getItem("langCode");
		if (!langCode || langCode == '') {
			langCode = '';
		}

		const formData = new FormData();
		formData.append("firstname", firstName);
		formData.append("lastname", lastName);
		formData.append("email", email);
		formData.append("mobile", mobileNumber);
		formData.append("identity_id", ID);

		formData.append("leadsource", "WEB");
		formData.append("client_timezone", timeZone);
		formData.append("client_language", langCode);

		formData.append("ca_firstname", buyerFirstName);
		formData.append("ca_lastname", buyerLastName);
		formData.append("ca_email", buyerEmail);
		formData.append("ca_mobile", buyerMobileNumber);
		formData.append("ca_identity_id", buyerID);

		setLoadingModalContent(currentLanguge.general.pleaseWait);
		const req = await makePostRequest("/update_lead/" + meetingSlug, formData).catch((err) => {
			setShowApprovePopup(false);
			setLoadingModalContent("");
			setFlowErrorObject({errorCode:"100",errorMessage:currentLanguge.formsErrors.leadUpdateError, errorTitle: ""})

			persistor.flush();
			return;
		});
		
		if (!req) {
			setShowApprovePopup(false);
			setLoadingModalContent("");
			setFlowErrorObject({errorCode:"100",errorMessage:currentLanguge.formsErrors.leadUpdateError, errorTitle: ""})

			persistor.flush();
			return;
		} 

		setRemoteAction({ eventName: "setApprovePop", payload: true, author: true });
		setUserApprovedApt(true);


		setRemoteAction({ eventName: "setShowAgentModal", payload: true, author: false });
		setShowPopup(true);
		persistor.flush();
		return;

	} catch (err) {
		console.log(err);
	}

};


export const handleNewClientShort = async (currentLanguge) => {
	try {
		const { lastName, ID, email, firstName, mobileNumber, ipAddress } = store.getState().register;
		let projectSlug = store.getState().project.projectID;
		let sessionProjectSlug = sessionStorage.getItem('projectID');
		if (!projectSlug && sessionProjectSlug != '') projectSlug = sessionProjectSlug;
		
		const timeZone = store.getState().project.timezone;
		const country = store.getState().register.country;
		let langCode = sessionStorage.getItem("langCode");
		if (!langCode || langCode == '') {
			langCode = '';
		}
		
		const formData = new FormData();
		formData.append("projectslug", projectSlug);
		formData.append("firstname", firstName);
		formData.append("lastname", lastName);
		formData.append("email", email);
		formData.append("mobile", mobileNumber);
		formData.append("identity_id", ID);
		formData.append("client_type", 1);
		formData.append("leadsource", "WEB");
		formData.append("client_timezone", timeZone);
		formData.append("client_country", country);
		formData.append("client_language", langCode);

		formData.append("client_ip", ipAddress);
		setLoadingModalContent(currentLanguge.general.pleaseWait);
		const req = await makePostRequest("/newclientlead", formData).catch((err) => {
			console.log(err);
		});
		
		if (!req) {
			return;
		}

		if (req.data.statusCode) {
			showErrorMessage("invalidEmail", currentLanguge.formsErrors.emailIsTaken, false);
			setLoadingModalContent("");
			return;
		}
	
		if (req.data.client) {
			const id = req.data.client.id;
			setUserId(id);
			const meetingData = await setNowMeeting(currentLanguge);
			
			setMeetingSettings(meetingData.data.message);
			setRemoteAction({ eventName: "setShowAgentModal", payload: true, author: false });

			setShowPopup(true);
		}
	} catch (err) {
		console.log(err);
	}
};

export const handleNewClient = async (e, currentLanguge) => {
	try {
		e.preventDefault();
		const isClientRegistered = store.getState().verification.isClientRegistered;
		if (!isClientRegistered) {
			const { termsAgreed, lastName, ID, email, firstName, mobileNumber, ipAddress } = store.getState().register;
			let projectSlug = store.getState().project.projectID;
			let sessionProjectSlug = sessionStorage.getItem('projectID');
			if (!projectSlug && sessionProjectSlug != '') projectSlug = sessionProjectSlug;
			
			const timeZone = store.getState().project.timezone;
			const country = store.getState().register.country;
			let langCode = sessionStorage.getItem("langCode");
			if (!langCode || langCode == '') {
				langCode = '';
			}

			//check form validity
			const isFormValid = validateRegisterForm(e, currentLanguge);
			
			if (isFormValid && termsAgreed) {
				const formData = new FormData();
				formData.append("projectslug", projectSlug);
				formData.append("firstname", firstName);
				formData.append("lastname", lastName);
				formData.append("email", email);
				formData.append("mobile", mobileNumber);
				formData.append("identity_id", ID);
				formData.append("client_type", 1);
				formData.append("leadsource", "WEB");
				formData.append("client_timezone", timeZone);
				formData.append("client_country", country);
				formData.append("client_language", langCode);

				formData.append("client_ip", ipAddress);

				setLoadingModalContent(currentLanguge.general.pleaseWait);
				const req = await makePostRequest("/newclientlead", formData).catch((err) => {
					console.log(err);
				});
				if (!req) {
					return;
				}

				if (req.data.statusCode) {
					showErrorMessage("invalidEmail", currentLanguge.formsErrors.emailIsTaken, false);
					setLoadingModalContent("");

					return;
				}
				if (req.data.client) {
					const id = req.data.client.id;
					setUserId(id);
					const meetingData = await setNowMeeting(currentLanguge);
					setMeetingSettings(meetingData.data.message);
					setRemoteAction({ eventName: "setShowAgentModal", payload: true, author: false });

					setShowPopup(true);
				}
			}
		} else {
			document.getElementById("register_page").scrollIntoView();
		}
	} catch (err) {
		console.log(err);
	}
};


export const setVerificationRegStep = (value) => {
	store.dispatch(actions.setCodeRegisterStep(value));
};


export const showStaticMessage = (id, message, success) => {
	const errorElem = document.getElementById(id);
	if (errorElem?.style) {
		errorElem.style.display = "block";
		errorElem.textContent = message;
		if (success) {
			errorElem.style.color = "green";
		}
		if (!success) {
			errorElem.style.color = "red";
		}
		setTimeout(() => {
			$(`#${id}`).fadeOut();
		}, 3000)
	}
};

export const lockRegisterFields = () => {
	let elem = document.querySelector("#digitalForm");
	const inputs = elem.querySelectorAll("input");

	inputs.forEach((el) => {
		if (el.type === "radio" || el.type === "submit") {
			return;
		}
		el.disabled = true;
		el.readOnly = true;
	});
};

export const setShowCodeVerificationPopup = (value) => {
	store.dispatch(projectActions.setShowVerificationPopup(value));
};

export const searchMeetingAgain = async (currentLanguge) => {
	try {
		
		let langCode = sessionStorage.getItem("langCode");
		if (!langCode || langCode == '') {
			langCode = '';
		} 

		localStorage.setItem('searchingAgentTime', 0);
		localStorage.setItem('searchingAgentRunning', false);

		const meetingReq = await setNowMeeting(currentLanguge);
		if (!meetingReq) {
			console.warn(meetingReq);
			return;
		}
		
		// Request a meeting
		let clientdata = new FormData();
		clientdata.append("meetingslug", meetingReq.data.message.roomspaceid);
		const requestinviteagentReq = await makePostRequest("/requestinviteagent", clientdata);

		if (!requestinviteagentReq) {
			console.warn(requestinviteagentReq);
			return;
		}

		let data = new FormData();
		data.append("agent_key", "");
		const meetingroomsReq = await makePostRequest(`/meetingrooms/${meetingReq.data.message.roomspaceid}/${langCode}`, data);

		if (meetingroomsReq) {
			setGetAgentStatus("searching");
			setMeetingSettings(meetingroomsReq.data.meeting, meetingroomsReq.data.agentDetails);
			setProjectSettingsObject(meetingroomsReq.data.project);
		} else {
			console.warn(meetingroomsReq);
			return;
		}
	} catch (err) {
		console.log(err);
	}
}

export const registerAgentAssign = async (e, firstName, lastName, email, mobileNumber, termsAgreed, loadingButtonHtml, currentLanguge) => {
	try {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		
		$(".registerAgentBtn").prop("disabled", true);
		$(".registerAgentBtn").prop("textContent", currentLanguge.general.fewMoreMoments);
		
		let projectSlug = store.getState().project.projectID;
		let sessionProjectSlug = sessionStorage.getItem('projectID');
		if (!projectSlug && sessionProjectSlug != '') projectSlug = sessionProjectSlug;
		
		const ipAddress = store.getState().register.ipAddress;
		const timeZone = store.getState().project.timezone;
		const country = store.getState().register.country;
		let langCode = sessionStorage.getItem("langCode");
		if (!langCode || langCode == '') {
			langCode = '';
		} else {
			langCode = langCode;
		}

		const meetingslug = store.getState().meeting.roomSpaceID;
		const userID = store.getState().meeting.userID;

		// if client is registerd and have a meeting
		if (meetingslug != '' && userID != '') {
			setGetAgentStatus("searching");

			// Request a meeting
			let clientdata = new FormData();
			clientdata.append("meetingslug", meetingslug);
			const sababa = await makePostRequest("/requestinviteagent", clientdata);
			if (!sababa) {
				return;
			}

			pushGAvent('agent component', 'click', 'submit lead form');
			let data = new FormData();
			data.append("agent_key", "");
			const res = await makePostRequest(`/meetingrooms/${meetingslug}/${langCode}`, data);
			if (res) {
				setMeetingSettings(res.data.meeting, res.data.agentDetails);
				setProjectSettingsObject(res.data.project);
			}
			return;
		} else {
			//check form validity
			const isFormValid = validateAgentRegisterForm(firstName, lastName, email, mobileNumber, termsAgreed, currentLanguge);

			if (isFormValid && termsAgreed) {
				
				// button.disabled = true;
				const formData = new FormData();
				formData.append("projectslug", projectSlug);
				formData.append("firstname", firstName);
				formData.append("lastname", lastName);
				formData.append("email", email);
				formData.append("mobile", mobileNumber);
				formData.append("identity_id", "00000000");
				formData.append("client_type", 1);
				formData.append("leadsource", "WEB");
				formData.append("client_timezone", timeZone);
				formData.append("client_country", country);
				formData.append("client_ip", ipAddress);
				formData.append("client_language", langCode);

				const req = await makePostRequest("/newclientlead", formData).catch((err) => {
					console.log(err);
					$(".registerAgentBtn").prop("disabled", false);
					$(".registerAgentBtn").prop("textContent", currentLanguge.formsCTA.registerSubmitBtn);
				});
				

				if (!req) {
					$(".registerAgentBtn").prop("disabled", false);
					$(".registerAgentBtn").prop("textContent", currentLanguge.formsCTA.registerSubmitBtn);
					return;
				}
				if (req.data.statusCode) {
					showErrorMessage("invalidEmail", currentLanguge.formsErrors.emailIsTaken, false);
					// store.dispatch(store.dispatch(actions.resetForm()));
					$(".registerAgentBtn").prop("disabled", false);
					$(".registerAgentBtn").prop("textContent", currentLanguge.formsCTA.registerSubmitBtn);
					return;
				}
				if (req.data.client) {
					const id = req.data.client.id;
					setUserId(id);
					
					let langCode = sessionStorage.getItem("langCode");
					if (!langCode || langCode == '') {
						langCode = '';
					} else {
						langCode = '/' + langCode;
					}

					const meetingReq = await setNowMeeting(currentLanguge);
					if (!meetingReq) {
						$(".registerAgentBtn").prop("disabled", false);
						$(".registerAgentBtn").prop("textContent", currentLanguge.formsCTA.registerSubmitBtn);
						return;
					}

					store.dispatch(actions.setFirstName(firstName));
					store.dispatch(actions.setLastName(lastName));
					store.dispatch(actions.setEmail(email));
					store.dispatch(actions.setMobileNumber(mobileNumber));
					store.dispatch(actions.setMobileNumberWithoutPrefix(mobileNumber));
					setGetAgentStatus("searching");

					// Request a meeting
					let clientdata = new FormData();
					clientdata.append("meetingslug", meetingReq.data.message.roomspaceid);
					const sababa = await makePostRequest("/requestinviteagent", clientdata);
					if (!sababa) {
						$(".registerAgentBtn").prop("disabled", false);
						$(".registerAgentBtn").prop("textContent", currentLanguge.formsCTA.registerSubmitBtn);
						return;
					}
					pushGAvent('agent component', 'click', 'submit lead form');
					
					let data = new FormData();
					data.append("agent_key", "");
					const res = await makePostRequest(`/meetingrooms/${meetingReq.data.message.roomspaceid}${langCode}`, data);
					if (res) {
						setMeetingSettings(res.data.meeting, res.data.agentDetails);
						setProjectSettingsObject(res.data.project);
					}
				}
			} else {
				$(".registerAgentBtn").prop("disabled", false);
				$(".registerAgentBtn").prop("textContent", currentLanguge.formsCTA.registerSubmitBtn);
			}
		}

		
	} catch (err) {
		console.log(err);
	}
};

export const setRegisterSuccess = (value) => {
	store.dispatch(actions.setRegisterSuccess(value));
};


export const setEditClientDetails = (value) => {
	store.dispatch(actions.setEditClientDetails(value));
};

export const setSelectedMic = (value) => {
	store.dispatch(actions.setSelectedMic(value));
};

export const setSelectedCamera = (value) => {
	store.dispatch(actions.setSelectedCamera(value));
};

export const setSelectedSpeaker = (value) => {
	store.dispatch(actions.setSelectedSpeaker(value));
};
