import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { makePostRequest } from "../../Axios";
import HomeDescription from "../../components/HomePage/HomeDescription/HomeDescription";
import HomeHero from "../../components/HomePage/HomeHero/HomeHero";
import HomeIcons from "../../components/HomePage/HomeIcons/HomeIcons";
import HomeMap from "../../components/HomePage/HomeMap/HomeMap";
import HomeSlider from "../../components/HomePage/HomeSlider/HomeSlider";
import { setPreviewMode, setShowNav, setShowFooterLinks, setLoadingModalContent, setLoadingIsDone } from "../../Helpers/AppartmentReserveUtils";
import ProjectLandingInit, { getWithExpiry } from "../../Helpers/ProjectLanding";
import { handleSetToken } from "../../Helpers/verificationUtils";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import store from "../../Redux/Store/store";
import * as meetingActions from "../../Redux/Reducers/meetingSlice";
import * as registerActions from "../../Redux/Reducers/registerSlice";
import * as verificationActions from "../../Redux/Reducers/verificationSlice";
import * as projectActions from "../../Redux/Reducers/projectSlice";
import $ from 'jquery';
import GTPageView from "../../Helpers/GTPageView";
import SiteAuthorizationPage from "../SiteAuthorizationPage/SiteAuthorizationPage";

const handlePreviewKey = (slug, key) => {
	const validatePreviewKey = async () => {
		if (!key) {
			return;
		}
		let data = new FormData();
		data.append("previewkey", key);
		const req = await makePostRequest(`/projectpreview/${slug}`, data);
		if (!req) {
			return false;
		}
		if (req.status == 500) {
			return;
			// popup
		}
		if (req.data.statusCode == 404) {
			console.log("bad preview key");
			return false;
		}
		if (req.data.statusCode == 400) {
			console.log("invalid request");
			return false;
		}
		if (req.data.statusCode == 200) {
			console.log("good preview key");
			setPreviewMode(true);
			return true;
		}
	};
	let res = validatePreviewKey();
	return res;
};

const HomePage = (props) => {
	const isLoaded = useSelector((state) => state.project.isLoading);
	const isChangingLang = useSelector((state) => state.project.isChangingLang);

	const projectSettings = useSelector((state) => state.project.projectSettings);
	const [isPreview, setIsPreview] = useState(false);
	const project_published = useSelector((state) => state.project.projectSettings.project_published) == 0 ? false : true;

	const [istoken, setistoken] = useState(false);
	const getUrlParams = new URLSearchParams(window.location.search);
	const previewkey = getUrlParams.get("previewkey");
	let { projectSlug } = useParams();
	const [isOk, setIsOk] = useState(true);
    const currentLanguge = useSelector((state) => state.project.currentLanguge);
	const siteAuthorization = useSelector((state) => state.project.siteAuthorization);

	useEffect(() => {
		// sessionStorage.removeItem('langCode');
		store.dispatch(verificationActions.reset());
		store.dispatch(meetingActions.reset());
		store.dispatch(registerActions.reset());
		store.dispatch(projectActions.setAppartmentTitle(''));
		store.dispatch(projectActions.setAppartmentBasicaData({}));
		store.dispatch(projectActions.setAppartmentID(''));
		store.dispatch(projectActions.setAppartment_id(''));
		store.dispatch(projectActions.setStep(1));
		store.dispatch(projectActions.setStepsProgress(1));
		
		store.dispatch(projectActions.setOnBoardingViewed(false));

		setShowNav(false);
		setShowFooterLinks(true);

		$('#steps_menu__mobile_container').hide();
		$('#steps_menu__mobile_container').next('hr').hide();
		$('#apartment_view_change__mobile').hide();
		$('#apartment_view_change__mobile').next('hr').hide();

		GTPageView('home page')
	},[])

	useEffect(() => {
		const asyncCall = async () => {
			handleSetToken().then(async () => {
				setistoken(true);

				const res = await ProjectLandingInit(projectSlug);
				if (!res) {
					setIsOk(false);
				}

				if (res.data && res.data.colors_theme.length == 0 && res.data.projectinfo.length == 0) {
					store.dispatch(verificationActions.reset());
					store.dispatch(meetingActions.reset());
					store.dispatch(registerActions.reset());

					setShowNav(false);
					setShowFooterLinks(true);

					window.location.href = '/404';
				}
				handlePreviewKey(projectSlug, previewkey).then((isPreviewValid) => {
					if (isPreviewValid) {
						setIsPreview(isPreviewValid);
					}
				});
				setLoadingIsDone(true);
				setLoadingModalContent(false);
			});
		};

		
		if ((!projectSettings || projectSettings.length == 0 || projectSettings.project_slug == '' || projectSettings.project_slug != projectSlug) && projectSlug != '404') {
			setLoadingModalContent(true);
			asyncCall();
		} else {
			if (!isChangingLang) {
				setLoadingModalContent(false);
			}
			
			if (projectSettings.skip_landing_page == 1) {
				window.location.href = `/${projectSlug}/building_selection`;
			}
		}
	}, [projectSettings, currentLanguge, isChangingLang]);
	
	
	if (!siteAuthorization || !getWithExpiry('siteAuthorization_expiry')) {
		store.dispatch(projectActions.setSiteAuthorization(false));
		return <SiteAuthorizationPage />;
	}

	
	if (!isOk) {
		window.location.href = `/404`;
	}

	if (isLoaded && !project_published) {
		window.location.href = `/404`;
	}

	return (
		<div className="container-fluid" id="page_home">
			<LoadingModal/>
			<HomeHero />
			<HomeIcons />
			<HomeSlider />
			<HomeDescription projectSettings={projectSettings} />
			<HomeMap projectSettings={projectSettings} />
		</div>
	);
};

export default HomePage;
