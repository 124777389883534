import { useSelector } from "react-redux";
import { SideImagePlaceholder } from "../ImagePlacholders/ImagePlacholders";
import classes from "./LayoutSplitScreenImage.module.sass";
import { useEffect } from "react";
import { handleExistingImage } from "../../ApartmentReserveSteps/BuildingsSelection/image";

const LayoutSplitScreenImage = (props) => {
	const mainImg = useSelector((state) => state.project.projectSettings.project_image_desktop);
	const project_title = useSelector((state) => state.project.projectSettings.project_title);

	useEffect(() => {
		if (mainImg && !props.projectImageDesktop) {
			handleExistingImage(mainImg, "sideImageLogin", project_title);
		} else if (props.projectImageDesktop && props.projectImageDesktop != '') {
			handleExistingImage(props.projectImageDesktop, "sideImageLogin", project_title);
		} else if (props.backgroundImage != '') {
			handleExistingImage(props.backgroundImage, "sideImageLogin", project_title);
		}
	}, [mainImg]);

	useEffect(() => {
		if (window.hasOwnProperty("$")) {
			window.$("#sideImageLogin").sticky({ topSpacing: 78, bottomSpacing: 92, wrapperClassName: "" });
		}
	}, []);
	return (
		<div className={`login_side_img d-lg-block d-sm-none appear desktopView ${classes.wrapper}`} id="sideImageLogin">
			<div className="col-12 d-flex justify-content-center align-self-center m-auto position-relative">
				<SideImagePlaceholder/>
			</div>
		</div>
	);
};

export default LayoutSplitScreenImage;
