import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import Amount from "../Payment/Amount";
import $ from "jquery";
import ProcessModal from "../Payment/ProcessModal";
import PaymentModal from "./PaymentModal";
import { setRemoteAction } from "../../../Helpers/AppartmentReserveUtils";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
	const [isCharging, setIsCharging] = useState(false);
	const [result, setResult] = useState("");
	const [resultForAgent, setResultForAgent] = useState("");
	const meetingSlug = useSelector((state) => state.verification.slug);
	const isAgent = useSelector((state) => state.meeting.isAgent);

  const currentLanguge = useSelector((state) => state.project.currentLanguge)
  
  useEffect(() => { }, [currentLanguge]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setSuccessMessage(currentLanguge.payment_step.paymentSuccessfulTitle);
          break;
        case "processing":
          setErrorMessage(currentLanguge.payment_step.paymenyProcessModalText);
          break;
        case "requires_payment_method":
          setErrorMessage(currentLanguge.payment_step.paymentUnsuccessfulMsg);
          break;
        default:
          setErrorMessage(currentLanguge.formsErrors.tryAgain);
          break;
      }
    });
  }, [stripe]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
		setIsCharging(true);
    /* TODO:
    *  Send via pusher - paymentInProgress_start
    */
    if (meetingSlug) {
      setRemoteAction({ eventName: "paymentInProgress_start", payload: true, author: isAgent });
    }

    stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    }).then((result) => {
        setResult(result);
      if (result.error) {
        if (result.error.type === "card_error" || result.error.type === "validation_error"|| result.error.type === "invalid_request_error") {
          setErrorMessage(result.error.message);
        } else {
          setErrorMessage(currentLanguge.formsErrors.generalPaymentError);
        }
      } else if (result.paymentIntent) {
        switch (result.paymentIntent.status) {
          case "succeeded":
            setSuccessMessage(currentLanguge.payment_step.paymentSuccessfulTitle);
            break;
          case "processing":
            setErrorMessage(currentLanguge.payment_step.paymenyProcessModalText);
            break;
          case "requires_payment_method":
            setErrorMessage(currentLanguge.payment_step.paymentUnsuccessfulMsg);
            break;
          default:
            setErrorMessage(currentLanguge.formsErrors.tryAgain);
            break;
        }
      }
      setIsLoading(false);
      setIsCharging(false);
      
      /* TODO:
      *  Send via pusher - paymentInProgress_end + result
      */
      if (meetingSlug) {
        setRemoteAction({ eventName: "paymentInProgress_end", payload: result, author: isAgent });
      }

    }).catch((error) => {
      console.error('Stripe Error', error);
      if (error.type === "card_error" || error.type === "validation_error"|| error.type === "invalid_request_error") {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(currentLanguge.formsErrors.generalPaymentError);
      }
    });
  };

  
  /* TODO:
  *  add eventListner for event - paymentInProgress_start
  *  if user is agent - show popup
  */
  useEffect(() => {
		document.addEventListener("paymentInProgress_start", (e) => {
      const getUrlParams = new URLSearchParams(window.location.search);
      const agentKey = getUrlParams.get("agentkey");
      const isAgent = agentKey != '' && agentKey != null ? true : false;
      if (meetingSlug && isAgent) {
        setIsCharging(true);
        setResultForAgent(e.detail)
      }
		});

    document.addEventListener("paymentInProgress_end", (e) => {
      const getUrlParams = new URLSearchParams(window.location.search);
      const agentKey = getUrlParams.get("agentkey");
      const isAgent = agentKey != '' && agentKey != null ? true : false;
      if (meetingSlug && isAgent) {
        setResultForAgent(e.detail)
        setTimeout(() => {
          setIsCharging(false);
        },8000)
      }
    });
    
		return () => {
			document.removeEventListener("paymentInProgress_start", () => {});
			document.removeEventListener("paymentInProgress_end", () => {});
		};
	}, []);

  useEffect(() => {
    $('#payment-message__error').fadeIn();
    setTimeout(() => {
      $('#payment-message__error').fadeOut();
      setErrorMessage('');
    }, 5000);
  }, [errorMessage])

  useEffect(() => {
    $('#payment-message__success').fadeIn();
    setTimeout(() => {
      $('#payment-message__success').fadeOut();
    }, 5000);
  }, [successMessage])

  return (
    <>
      <form id="payment-form" className="mt-3" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <Amount />

        <button disabled={isLoading || !stripe || !elements} id="submit" className="d-block btn rf-btn-primary col-12 pfInput pfShadow mt-4 mb-4 f18 pfSubmitBtnColor">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : currentLanguge.payment_step.stepCTA}
          </span>
        </button>
        {/* Show any error or success messages */}
        <div id="payment-message__error">{errorMessage}</div>
        <div id="payment-message__success">{successMessage}</div>
      </form>
      <ProcessModal isCharging={isCharging} data={resultForAgent} />
      <PaymentModal success={result} />
    </>
  );
}