import { handlePaymentSkip } from "../Utils";
import store from "../../../Redux/Store/store";
import { useEffect } from "react";
import $ from 'jquery';
import { useSelector } from "react-redux";

const SkipPayBtn = ({ isAgent }) => {
	const requestSkipPay = store.getState().meeting.requestSkipPay;
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	useEffect(() => { }, [currentLanguge]);
	
	const onPayClick = (e) => {
		e.target.textContent = `${currentLanguge.meetingVideoComp.reqSent} `;
		handlePaymentSkip();
		return;
	};

	useEffect(() => {
		
		const requestSkipPay = store.getState().meeting.requestSkipPay;

		if (requestSkipPay) {
			$('#skipPaymentBtn').html(`${currentLanguge.meetingVideoComp.reqApproved} `).prop('disabled', true).css('opacity','1');
		} 
	},[requestSkipPay])
	

    return (
        <button
            id="skipPaymentBtn"
            className="btn rf-btnSecondery f16 w600 mds-1"
            onClick={(e) => onPayClick(e)}
        >
            {currentLanguge.meetingVideoComp.skipPaymentBtn}
        </button>
    );
};

export default SkipPayBtn;
