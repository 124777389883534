
import Pusher from "pusher-js";

// const pusherID = "04d7dd7701ae0821ca40"; // production
const pusherID = "fa90692a251480989e09";

const pusher = new Pusher(pusherID, {
    // cluster: "eu", // production
    cluster: "ap2",
});

export default pusher