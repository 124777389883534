import StepTitle from "../../UI/Titels/StepTitle";
import AppartmentStatus from "../../Stats/AppartmentStatus/AppartmentStatus";
import { useSelector } from "react-redux";
import Donut from "../../Stats/Donut/Donut";
import { useEffect } from "react";
import { AppartmentLoader } from "../../Layout/ImagePlacholders/ImagePlacholders";
import { ConvertCurrency } from "../../../Helpers/ConvertCurrency";
import { getPriceForClientType } from "../../../Helpers/AppartmentReserveUtils";

const AptPrice = ({ clientType, price, currencySymbol, specialPrice, alt }) => {
	if (!clientType) {
		return (
			<div className="col-auto mds-3 d-flex align-items-center mde-2 fs-3 pds-0 apartment_prices f24 " id="previewPrice">
				<img src="/assets/img/icons/ico-price.svg" className="mde-2 pfIcon" alt={alt} />
				<span className="f24">{`${currencySymbol}${price}`}</span>
				{/* {`${currencySymbol}${newP}`} */}
			</div>
		);
	}
	
	if (clientType == 1) {
		return (
			<div className="col-auto mds-3 d-flex align-items-center mde-2 fs-3 pds-0 apartment_prices f24 " id="previewPrice">
				<img src="/assets/img/icons/ico-price.svg" className="mde-2 pfIcon" alt={alt} />
				<span className="f24">{`${currencySymbol}${price}`}</span>
				{/* {`${currencySymbol}${newP}`} */}
			</div>
		);
	}
	if (clientType > 1) {
		return (
			<div className="col-auto mds-3 d-flex align-items-center mde-2 fs-3 pds-0 apartment_prices f24 " id="previewPrice">
				<img src="/assets/img/icons/ico-price.svg" className="mde-2 pfIcon" alt={alt} />
				<span className="old_price f24">{`${currencySymbol}${price}`}</span>
				{`${currencySymbol}${specialPrice}`}
			</div>
		);
	}
};

export const AppartmentInfo = ({ setIsAvailable }) => {
	const basicData = useSelector((state) => state.project.appartmentBasicData);
	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != "undefined" ? ConvertCurrency(project_default_currency) : "";
	const clientType = useSelector((state) => state.meeting.clientType);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const defaultMetric = useSelector((state) => state.project.projectSettings.project_default_metric)

	useEffect(() => { }, [currentLanguge]);
	const priceProp = getPriceForClientType(clientType);

	let specialPrice = (() => parseInt(basicData[priceProp]).toLocaleString())();
	let old = (() => parseInt(basicData.price1).toLocaleString())();
	if (!basicData["id"]) {
		return <AppartmentLoader />;
	}

	
	return (
		<div className="row d-flex flex-row flex-wrap">
			<div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 m-0 p-0 d-flex flex-column flex-wrap">
				<div className="col-auto d-flex flex-row m-0 p-0 flex-wrap pfcol" id="previewInfo">
					<StepTitle className="mds-3 fw-bold">{`${!isNaN(basicData.title) ? currentLanguge.apartmentDetails_step.apartment : ''} ${basicData.title}`}</StepTitle>
					<AppartmentStatus id={basicData.id} floorID={basicData.floorID} buildingID={basicData.buildingID} setFlag={setIsAvailable} />
				</div>
				{/* <div className="col-auto ms-3 d-flex align-items-center me-2 fs-3 pds-0 apartment_prices f24 " id="previewPrice">
					<img src="/assets/img/icons/ico-price.svg" className="me-2 pfIcon" alt="" />
					<span className="old_price f24 d-none">{`${currencySymbol}${old}`}</span>
					{`${currencySymbol}${newP}`}
				</div> */}
				<AptPrice clientType={clientType} currencySymbol={currencySymbol} specialPrice={specialPrice} price={old} alt={currentLanguge.apartmentSelectionTable.price} />
			</div>

			{/* Apartment info  */}
			<div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 m-0 flex-wrap justify-content-end px-0 pt-4" id="previewSet">
				{basicData.rooms &&
					<div className="col-auto mde-5 info-item d-flex flex-column justify-content-center f18 fw-bold pds-0 align-self-center">
						<img src="/assets/img/icons/ico-rooms.svg" className="mb-2 pfIcon mx-auto mde-2" alt={`${basicData.rooms} ${currentLanguge.apartmentDetails_step.rooms}`} height="43" width="46" />
						<span className="align-self-center text-nowrap">{`${basicData.rooms} ${currentLanguge.apartmentDetails_step.rooms}`}</span>
					</div>
				}
				{basicData.sqrMeter && 
					<div className="col-auto mde-5 info-item d-flex flex-column justify-content-center f18 fw-bold pds-0 align-self-center ">
						<img src="/assets/img/icons/ico-measuring-tape.svg" className="mb-2 pfIcon mx-auto mde-2" alt={`${basicData.sqrMeter} ${currentLanguge.general.metric[defaultMetric]}`} height="43" width="46" />
						<span className="align-self-center text-nowrap">{`${basicData.sqrMeter} ${currentLanguge.general.metric[defaultMetric]}`}</span>
					</div>
				}
				{basicData.direction && 
					<div className={`col-auto mde-5 info-item flex-column justify-content-center f18 fw-bold pds-0 align-self-center ${basicData.direction && currentLanguge.directions[basicData.direction.toLowerCase()] ? 'd-flex' : 'd-none'}`}>
						<img src="/assets/img/icons/ico-sign.svg" className="mb-2 pfIcon mx-auto mde-2" alt={basicData.direction ? currentLanguge.directions[basicData.direction.toLowerCase()] : ''} height="43" width="46" />
						<span className="align-self-center">{basicData.direction ? currentLanguge.directions[basicData.direction.toLowerCase()] : ''}</span>
					</div>
				}
				<div className="col-auto mde-5 info-item flex-column justify-content-center f18 fw-bold pds-0 align-self-center desktopView">
					<div className="d-flex align-self-center">
						<Donut className="previewDonut" size="50"/>
					</div>
					<h2 className="p-0 m-0 f18 fw-bold align-self-center">{basicData.buildingName}</h2>
				</div>
			</div>
		</div>
	);
};
