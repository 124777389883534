import { useCallback, useEffect, useState } from "react";
import { setRemoteAction } from "../../../../Helpers/AppartmentReserveUtils";
import DesktopTableBody from "../DesktopTable/DesktopTableBody";
import { useSelector } from "react-redux";
import {
	sortAptNum,
	sortAptNumV2,
	sortbuilding, 
	sortbuildingV2, 
	sortDirection, 
	sortDirectionV2, 
	sortFloorNum, 
	sortFloorNumV2, 
	sortModel, 
	sortModelV2, 
	sortPrice, 
	sortPriceV2, 
	sortRooms, 
	sortRoomsV2, 
	sortSize, 
	sortSizeV2, 
	sortSpecialPrice, 
	sortSpecialPriceV2, 
	sortTerrace, 
	sortTerraceV2,
	sortGardenTerraceV2,
	sortServiceTerraceV2
} from "../Utils";
import $ from 'jquery';
import GTEvent ,{pushGAvent} from "../../../../Helpers/GTEvent";

const DesktopTable = (props) => {
    const allAppartmentsData = useSelector((state) => state.project.allAppartmentsData);
	const meetingSlug = useSelector((state) => state.verification.slug);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const clientType = useSelector((state) => state.meeting.clientType);
	const clientTypeName = useSelector((state) => state.meeting.clientTypeName);

	const [mainDisplay, setMainDisplay] = useState(allAppartmentsData);
	const [aptNumFilter, setAptNumFilter] = useState(0);
	const [buildingfilter, setBuildingFilter] = useState(0);
	const [floorfilter, setFloorFilter] = useState(0);
	const [roomsFilter, setRoomsFilter] = useState(0);
	const [modelFilter, setModelFilter] = useState(0);
	const [showAvailable, setShowAvailable] = useState(false);

	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [directionFilter, setDirectionFilter] = useState('');
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	useEffect(() => { }, [currentLanguge]);

	useEffect(() => {
		let filteredResults = [...allAppartmentsData];
		if (aptNumFilter > 0) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.aptNum === aptNumFilter || apt.aptNum.includes(`${aptNumFilter}`)) {
					return apt;
				}
			});
		}
		if (buildingfilter > 0) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.buildingNum === buildingfilter || apt.buildingNum.includes(`${buildingfilter}`)) {
					return apt;
				}
			});
		}
		if (floorfilter > 0) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.floorNum === floorfilter || apt.floorNum.includes(`${floorfilter}`)) {
					return apt;
				}
			});
		}
		if (roomsFilter > 0) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.roomsNum.includes(`${roomsFilter}`)) {
					return apt;
				}
			});
		}
		if (modelFilter !== 0) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.model.includes(modelFilter)) {
					return apt;
				}
			});
		}
		if (directionFilter !== 0 || directionFilter !== '') {
			filteredResults = filteredResults.filter((apt) => {
				return currentLanguge.directions[apt.direction.toLowerCase()] ? currentLanguge.directions[apt.direction.toLowerCase()].includes(directionFilter) : apt;
			});
		}
		if (showAvailable == true) {
			filteredResults = filteredResults.filter((apt) => {
				if (apt.property_status == "1") {
					return apt;
				}
			});
		}

		setMainDisplay(filteredResults);
	}, [roomsFilter, aptNumFilter, floorfilter, buildingfilter, directionFilter, modelFilter, showAvailable]);


	const resetFilters = () => {
		let payload = [...allAppartmentsData];
		setMainDisplay(payload);
		setAptNumFilter('');
		setBuildingFilter('');
		setFloorFilter('');
		setRoomsFilter('');
		setModelFilter('');
		setShowAvailable(false);
		setDirectionFilter('');

		$('.sortHighlight').removeClass('sortHighlight');
	};

	useEffect(() => {
		document.addEventListener("sort", (e) => {
			const splitted = e.detail.split(" ");
			const direction = splitted[0];
			const sortType = splitted[1];

			if (sortType === "aptNumSort") {
				sortAptNum(setMainDisplay,direction, mainDisplay);
				return;
			}
			if (sortType === "buildingNumSort") {
				sortbuilding(setMainDisplay,direction, mainDisplay);
				return;
			}
			if (sortType === "floorNumSort") {
				sortFloorNum(setMainDisplay,direction, mainDisplay);
				return;
			}
			if (sortType === "roomsNumSort") {
				sortRooms(setMainDisplay,direction, mainDisplay);
				return;
			}
			if (sortType === "appartmentTerraceSort") {
				sortSize(setMainDisplay,direction, mainDisplay);
				return;
			}
			if (sortType === "balconyTerraceSort") {
				sortTerrace(setMainDisplay,direction, mainDisplay);
				return;
			}
			if (sortType === "priceSort") {
				sortPrice(setMainDisplay,direction, mainDisplay);
				return;
			}
			if (sortType === "priceHeverSort") {
				sortSpecialPrice(setMainDisplay,direction, mainDisplay);
				return;
			}
			if (sortType === "modelSort") {
				sortModel(setMainDisplay,direction, mainDisplay);
				return;
			}
			if (sortType === "directionSort") {
				sortDirection(setMainDisplay,direction, mainDisplay);
				return;
			}

			return;
		});

		return () => {
			document.removeEventListener("sort", () => {});
		};
	}, []);


	useEffect(() => {
		document.addEventListener("tableFilter", (e) => {
			const splitted = e.detail.split(" ");
			const payload = splitted[0];
			const sortType = splitted[1];

			if (sortType === "appartmentsFilter") {
				setAptNumFilterV1(payload);
				return;
			}
			if (sortType === "buildingsFilter") {
				setBuildingFilterV1(payload);
				return;
			}
			if (sortType === "floorsFilter") {
				setFloorFilterV1(payload);
				return;
			}
			if (sortType === "roomsNumSort") {
				setRoomsFilterV1(payload);
				return;
			}
			if (sortType === "roomsFilter") {
				setRoomsFilterV1(payload);
				return;
			}
			if (sortType === "modelFilter") {
				setModelFilterV1(payload);
				return;
			}
			if (sortType === "directionFilter") {
				setDirectionFilterV1(payload);
				return;
			}

			return;
		});

		return () => {
			document.removeEventListener("tableFilter", () => {});
		};
	}, []);



	useEffect(() => {
		if (allAppartmentsData.length > 0 && mainDisplay.length == 0 && !isDataLoaded) {
			setIsDataLoaded(true)
			setMainDisplay(allAppartmentsData);
			$('#table_loader').hide().removeClass('d-flex');
		} else if (mainDisplay.length > 0 && !isDataLoaded) {
			setIsDataLoaded(true)
			$('#table_loader').hide().removeClass('d-flex');
		}
	 }, [mainDisplay, allAppartmentsData]);


	const setAptNumFilterV1 = (value) => {
		setAptNumFilter(value);
	};
	const setBuildingFilterV1 = (value) => {
		setBuildingFilter(value);
	};
	const setFloorFilterV1 = (value) => {
		setFloorFilter(value);
	};
	const setRoomsFilterV1 = (value) => {
		setRoomsFilter(value);
	};
	const setModelFilterV1 = (value) => {
		setModelFilter(value);
	};
	const setDirectionFilterV1 = (value) => {
		setDirectionFilter(value);
	};


	const setAptNumFilterV2 = (e) => {
		let value = e.target.value;
		setAptNumFilter(value);
		GTEvent(e);
	};

	const setBuildingFilterV2 = useCallback((e) => {
		let value = e.target.value;
		setBuildingFilter(value);
		GTEvent(e);
	}, []);

	const setFloorFilterV2 = useCallback((e) => {
		let value = e.target.value;
		setFloorFilter(value);
		GTEvent(e);
	}, []);
	const setRoomsFilterV2 = useCallback((e) => {
		let value = e.target.value;
		setRoomsFilter(value);
		GTEvent(e);
	}, []);
	const setModelFilterV2 = useCallback((e) => {
		let value = e.target.value;
		setModelFilter(value);
		GTEvent(e);
	}, []);
	const setDirectionFilterV2 = useCallback((e) => {
		let value = e.target.value;
		setDirectionFilter(value);
		GTEvent(e);
	}, []);
	const setShowAvailableV2 = useCallback((e) => {
		let value = e.target.checked;
		setShowAvailable(value);
		pushGAvent('table view','click','filter available');
	}, []);

	const setFilter_showAvailable = (checked) => {
		setShowAvailable(checked);
	}

	const PriceTableHead = ({ clientType, clientTypeName }) => {

		if (!clientType) {
			return (
				<div className="text-nowrap d-flex align-items-center">
					<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='priceHever'>
						<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortSpecialPriceV2(setMainDisplay,"up",mainDisplay,meetingSlug,setRemoteAction,isAgent)}></i>
						<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() =>sortSpecialPriceV2(setMainDisplay,"down",mainDisplay,meetingSlug,setRemoteAction,isAgent)}></i>
					</span>
				</div>
			);
		}
		if (clientType == 1) {
			return (
				<div className="text-nowrap d-flex align-items-center">
					{currentLanguge.apartmentSelectionTable.price} {clientTypeName}
					<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='priceHever'>
						<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortSpecialPriceV2(setMainDisplay,"up",mainDisplay,meetingSlug,setRemoteAction,isAgent)}></i>
						<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortSpecialPriceV2(setMainDisplay,"down",mainDisplay,meetingSlug,setRemoteAction,isAgent)}></i>
					</span>
				</div>
			);
		}
		if (clientType > 1) {
			return (
				<div className="text-nowrap d-flex align-items-center">
					{currentLanguge.apartmentSelectionTable.price} {clientTypeName}
					<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='priceHever'>
						<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortSpecialPriceV2(setMainDisplay,"up",mainDisplay,meetingSlug,setRemoteAction,isAgent)}></i>
						<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortSpecialPriceV2(setMainDisplay,"down",mainDisplay,meetingSlug,setRemoteAction,isAgent)}></i>
					</span>
				</div>
			);
		}
    };
    
	return (
		<div id="desktopTable">
			<div className="d-flex  align-items-center">
				<span className="ms-auto resetFilters" onClick={resetFilters} data-gt-category='table view' data-gt-action='click' data-gt-label='reset filters'>
					{currentLanguge.apartmentSelectionTable.clearFilters}
					<img src="/assets/img/icons/icons8-erase.svg" className="mds-2" height="20px" />
				</span>

				<div className="form-check mds-2 my-0">
					<input
						className="form-check-input"
						type="checkbox"
						value={showAvailable}
						checked={showAvailable}
						id="mobileTable__filter__showAvailable"
						onChange={setShowAvailableV2}
					/>
					<label className="form-check-label pfItemFont" htmlFor="mobileTable__filter__showAvailable">
						{currentLanguge.apartmentSelectionTable.filter__show_available}
					</label>
				</div>
			</div>
			{/* Apartments Table  */}
			<div className="row mt-3" style={{overflowX: 'scroll'}}>
					
				<table className="table table-striped table-bordered" id="apartments_table">
					<thead>
						<tr>
							<th></th>
							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.headerApartment}{" "}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='aptNum'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortAptNumV2(setMainDisplay,"up", mainDisplay,meetingSlug,setRemoteAction,isAgent)} 
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc apartment number'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortAptNumV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc apartment number'></i>
									</span>
								</div>
							</th>
							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.headerBuilding}{" "}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='buildingNum'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortbuildingV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc building number'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortbuildingV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc building number'></i>
									</span>
								</div>
							</th>
							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.headerFloor}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='floorNum'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortFloorNumV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc floor number'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortFloorNumV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc floor number'></i>
									</span>
								</div>
							</th>
							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.headerRooms}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='roomsNum'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortRoomsV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc rooms number'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortRoomsV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc rooms number'></i>
									</span>
								</div>
							</th>
							<th>
								<div className="text-nowrap d-flex align-items-center">
								{currentLanguge.apartmentSelectionTable.headerModel}{" "}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='model'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortModelV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc model'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortModelV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc model'></i>
									</span>
								</div>
							</th>
							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.headerDirection}{" "}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='direction'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortDirectionV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc direction'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortDirectionV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc direction'></i>
									</span>
								</div>
							</th>
							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.headerAptTerrace}{" "}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='appartmentTerrace'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortSizeV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc apartment terrace'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortSizeV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc apartment terrace'></i>
									</span>
								</div>
							</th>
							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.headerBalconyTerrace}{" "}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='balconyTerrace'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortTerraceV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc apartment balcony terrace'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortTerraceV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc apartment balcony terrace'></i>
									</span>
								</div>
							</th>

							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.headerGradenTerrace}{" "}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='gardenTerrace'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortGardenTerraceV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc apartment garden terrace'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortGardenTerraceV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc apartment garden terrace'></i>
									</span>
								</div>
							</th>

							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.headerServiceTerrace}{" "}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='serviceTerrace'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortServiceTerraceV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc apartment service terrace'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortServiceTerraceV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc apartment service terrace'></i>
									</span>
								</div>
							</th>

							<th>
								<div className="text-nowrap d-flex align-items-center">
									{currentLanguge.apartmentSelectionTable.price}{" "}
									<span className=" w-50 d-flex flex-column m-0 p-0" data-sortaction='price'>
										<i className="fa pointer fa-fw fa-sort-up" style={{ lineHeight: "10px" }} onClick={() => sortPriceV2(setMainDisplay, "up", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort desc apartment price'></i>
										<i className="fa pointer fa-fw fa-sort-down" style={{ lineHeight: "10px" }} onClick={() => sortPriceV2(setMainDisplay, "down", mainDisplay, meetingSlug, setRemoteAction, isAgent)}
											data-gt-category='table view' data-gt-action='click' data-gt-label='sort asc apartment price'></i>
									</span>
								</div>
							</th>
							{clientType > 1 && (
								<th>
									<PriceTableHead clientType={clientType} clientTypeName={clientTypeName} key={clientTypeName} />
								</th>
							)}
							<th></th>
						</tr>
						<tr>
							<th />
							<th className="pfTableInput">
								<input
									type="text"
									className="form-control pfTableFontItem aptTableFilter"
									placeholder={currentLanguge.apartmentSelectionTable.headerApartment}
									aria-label={currentLanguge.apartmentSelectionTable.headerApartment}
									aria-describedby="basic-addon1"
									data-gt-category='table view' data-gt-action='click' data-gt-label='filter apartment number'
									value={aptNumFilter == 0 ? "" : aptNumFilter}
									onChange={setAptNumFilterV2}
								/>
							</th>
							<th className="pfTableInput">
								<input
									type="text"
									className="form-control pfTableFontItem aptTableFilter"
									placeholder={currentLanguge.apartmentSelectionTable.headerBuilding}
									aria-label={currentLanguge.apartmentSelectionTable.headerBuilding}
									aria-describedby="basic-addon1"
									data-gt-category='table view' data-gt-action='click' data-gt-label='filter building number'
									value={buildingfilter == 0 ? "" : buildingfilter}
									onChange={setBuildingFilterV2}
								/>
							</th>
							<th className="pfTableInput">
								<input
									type="text"
									className="form-control pfTableFontItem aptTableFilter"
									placeholder={currentLanguge.apartmentSelectionTable.headerFloor}
									aria-label={currentLanguge.apartmentSelectionTable.headerFloor}
									aria-describedby="basic-addon1"
									data-gt-category='table view' data-gt-action='click' data-gt-label='filter floor number'
									value={floorfilter == 0 ? "" : floorfilter}
									onChange={setFloorFilterV2}
								/>
							</th>
							<th className="pfTableInput">
								<input
									type="text"
									className="form-control pfTableFontItem aptTableFilter"
									placeholder={currentLanguge.apartmentSelectionTable.headerRooms}
									aria-label={currentLanguge.apartmentSelectionTable.headerRooms}
									aria-describedby="basic-addon1"
									data-gt-category='table view' data-gt-action='click' data-gt-label='filter rooms number'
									value={roomsFilter == 0 ? "" : roomsFilter}
									onChange={setRoomsFilterV2}
								/>
							</th>
							<th className="pfTableInput">
								<input
									type="text"
									className="form-control pfTableFontItem aptTableFilter"
									placeholder={currentLanguge.apartmentSelectionTable.headerModel}
									aria-label={currentLanguge.apartmentSelectionTable.headerModel}
									aria-describedby="basic-addon1"
									data-gt-category='table view' data-gt-action='click' data-gt-label='filter model'
									value={modelFilter == 0 ? "" : modelFilter}
									onChange={setModelFilterV2}
								/>
							</th>
							<th className="pfTableInput">
								<input
									type="text"
									className="form-control pfTableFontItem aptTableFilter"
									placeholder={currentLanguge.apartmentSelectionTable.headerDirection}
									aria-label={currentLanguge.apartmentSelectionTable.headerDirection}
									aria-describedby="basic-addon1"
									data-gt-category='table view' data-gt-action='click' data-gt-label='filter direction'
									value={directionFilter == 0 ? "" : directionFilter}
									onChange={setDirectionFilterV2}
								/>
							</th>
							<th />
							<th />
							<th />
							<th />
							<th />
							<th />
						</tr>
					</thead>
					<tbody>
						<DesktopTableBody list={mainDisplay} clientType={clientType} clientTypeName={clientTypeName} />
					</tbody>
				</table>
				{/* table loader */}
				<div className="sideImageLG d-flex justify-content-center align-self-center w-100 my-4" style={{width: '100% !important'}} id="table_loader">
					<div className="bounce1"></div>
					<div className="bounce2"></div>
					<div className="bounce3"></div>
				</div>
			</div>
		</div>
	);
};
export default DesktopTable;
