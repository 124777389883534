import { setAgentControl, setMeetingStatus, setRequestSkipPay, setRequestSkipSign } from "../MeetingsUtils";
import {
	setSuccessStep,
	setGetAgentStatus,
	setBuildingName,
	convertIdToSlug,
	setAppartment_id,
	setSelectedFloorImg,
	disptachEvent,
	setBuildingNameAsync,
	setFloorName,
	setFloorNameAsync,
	setSelectedAptImg,
	setPropertyNamesAsync,
	getAptParents,
	setAppartmentTitle,
	addURLHistory
} from "./index";
import store, { persistor } from "../../Redux/Store/store";
import * as actions from "../../Redux/Reducers/projectSlice";
import { unfreezAgentControls } from "../../components/MeetingComponents/Utils";
import { setMeetingSettings } from "../ProjectLanding";
import { makePostRequest } from "../../Axios";
import $ from 'jquery';

export const setSideImage = (currentValue, step) => {
	// setSelectedFloorImg;
	if (step == 1) {
		const buildings = store.getState().data.buildings;
		for (let item of buildings) {
			if (item.id == currentValue) {
				setSelectedFloorImg(item.first_picture);
				persistor.flush();
				return;
			}
		}
		return;
	}
	if (step == 2) {
		const floors = store.getState().data.floors;
		for (let item of floors) {
			if (item.id == currentValue) {
				setSelectedFloorImg(item.floor_main_image_wbuilding);
				persistor.flush();

				return;
			}
		}
		//currentvalue floor id, and building id
	}
	if (step == 3) {
		const appartments = store.getState().data.appartments;
		for (let item of appartments) {
			if (item.property_id == currentValue) {
				setSelectedAptImg(item.pv_apartment_image_desktop);
				persistor.flush();

				return;
			}
		}
	}
};

const pusherSubscribe = (pusher, meetingslug, allAppartmentsData, isClient, isAgent, agentKey, buildingID, floorID, appartment_id, isSelfReserve, currentLanguge) => {
	if (!meetingslug) {
		return;
	}
	let list = store.getState().project.allAppartmentsData;

	var channel = pusher.subscribe("meeting-" + meetingslug);

	channel.bind("request_of_skip_sign", function (data) {
		if (isAgent) {
			if (data == 1) {
				$('#skipSigntBtn').html(currentLanguge.meetingVideoComp.waitingApprovel).prop('disabled', true).css('opacity','1');
				setRequestSkipSign(false);
			} else if (data == 2) {
				$('#skipSigntBtn').html(currentLanguge.meetingVideoComp.reqRejected).prop('disabled', false).css('opacity','1');
				setRequestSkipSign(false);
			} else if (data == 10) {
				$('#skipSigntBtn').html(currentLanguge.meetingVideoComp.reqApproved).prop('disabled', true).css('opacity','1');
				setRequestSkipSign(true);
			}
		}
		if (isClient) {
			if (data == 1) {
				setRequestSkipSign(false);
			}
			if (data == 2) {
				setRequestSkipSign(false);
			}
			if (data == 10) {
				setRequestSkipSign(true);
			}
		}
	});

	channel.bind("uiEvent", function (data) {
		disptachEvent(data);
	});

	channel.bind("request_of_invite_agent", function (data) {
		setGetAgentStatus(true);

		for (var i=0; i < window.searchTimeBar.length; i++) {
			clearInterval(window.searchTimeBar[i]);
		}

		let content = new FormData();
		content.append("agentkey", "");
		//get meeting data
		const fireAsync = async () => {
			let langCode = sessionStorage.getItem("langCode");
			if (!langCode || langCode == '') {
				langCode = '';
			} else {
				langCode = '/' + langCode;
			}

			const res = await makePostRequest(`/meetingrooms/${meetingslug}${langCode}`, content);
			if (!res) {
				return;
			}
			if (res.data) {
				setMeetingSettings(res.data.meeting, res.data.agentDetails);
				setMeetingStatus("start");
				persistor.flush();
			}
		};

		fireAsync();
	});

	channel.bind("request_of_skip_pay", function (data) {
		if (isAgent) {
			if (data == 1) {
				$('#skipPaymentBtn').html(currentLanguge.meetingVideoComp.waitingApprovel).prop('disabled', true).css('opacity','1');
				setRequestSkipPay(false);
			}
			if (data == 2) {
				$('#skipPaymentBtn').html(currentLanguge.meetingVideoComp.reqRejected).prop('disabled', false).css('opacity','1');
				setRequestSkipPay(false);
			}
			if (data == 10) {
				$('#skipPaymentBtn').html(currentLanguge.meetingVideoComp.reqApproved).prop('disabled', true).css('opacity','1');
				setRequestSkipPay(true);
			}
		}
		if (isClient) {
			if (data == 1) {
				setRequestSkipPay(false);
			}
			if (data == 2) {
				setRequestSkipPay(false);
			}
			if (data == 10) {
				setRequestSkipPay(true);
			}
		}
	});

	channel.bind("agentControl", function (data) {
		if (data == 0) {
			setAgentControl(false);
		}
		if (data == 1) {
			setAgentControl(true);
		}
		unfreezAgentControls();
	});

	channel.bind("flowdata", function (data) {
		const remoteStepKeys = Object.keys(data);
		const remoteStepValues = Object.values(data);
		const requestSkipPay = store.getState().meeting.requestSkipPay;

		const stepsParsed = remoteStepKeys.map((el, idx) => {
			return { step: el, data: remoteStepValues[idx] };
		});
		const currentRemoteStep = stepsParsed[remoteStepKeys.length - 1];

		if (currentRemoteStep.step == "1") {
			store.dispatch(actions.setBuldingID(currentRemoteStep.data));
			setSideImage(currentRemoteStep.data, 1);

			addURLHistory('floor_selection', false)

			store.dispatch(actions.setStep("2"));
			store.dispatch(actions.setStepsProgress("2"));
			persistor.flush();
			setBuildingNameAsync(list, currentRemoteStep.data);
			setSuccessStep(0);
		}
		//set floor in building
		if (currentRemoteStep.step === "2") {
			const floorImg = store.getState().project.selectedFloorImg;
			store.dispatch(actions.setFloorID(currentRemoteStep.data));
			if (floorImg) {
				setSideImage(currentRemoteStep.data, 2);
			}
			if (!floorImg) {
				setSideImage(currentRemoteStep.data, 1);
			}
			persistor.flush();
			addURLHistory('apartment_selection', false)
			store.dispatch(actions.setStep("3"));
			store.dispatch(actions.setStepsProgress("3"));
			setFloorNameAsync(list, currentRemoteStep.data);
			setSuccessStep(1);
		}

		//set appartment preview
		if (currentRemoteStep.step == "3") {
			const currentData = getAptParents(currentRemoteStep.data);
			setAppartment_id(currentRemoteStep.data);
			store.dispatch(actions.setBuldingID(currentData.buildingID));
			store.dispatch(actions.setFloorID(currentData.floorID));
			let slug = convertIdToSlug(currentRemoteStep.data);
			store.dispatch(actions.setAppartmentID(slug));

			setBuildingName(currentData.buildingNum);
			setFloorName(currentData.floorNum);
			setSideImage(currentData.floorID, 2);
			setAppartmentTitle(currentData.aptNum);
			persistor.flush();

			addURLHistory('apartment_details', false)
			store.dispatch(actions.setStep("4"));
			store.dispatch(actions.setStepsProgress("4"));
			// setSideImage(currentRemoteStep.data, 3);
			setSuccessStep(0);
			setSuccessStep(1);
			setSuccessStep(2);
		}
		//this is my apt
		if (currentRemoteStep.step == "4") {
			setSuccessStep(3);
			setPropertyNamesAsync(list, currentRemoteStep.data);
			persistor.flush();

			addURLHistory('approve_details', false)
			store.dispatch(actions.setStep("5"));
			store.dispatch(actions.setStepsProgress("5"));
		}
		if (currentRemoteStep.step == "5") {
			setSuccessStep(4);

			if (requestSkipPay) {
				setSuccessStep(5);
				addURLHistory('meeting_schedule', false)
				store.dispatch(actions.setStep("7"));
				store.dispatch(actions.setStepsProgress("7"));
			} else {
				addURLHistory('payment', false)
				store.dispatch(actions.setStep("6"));
				store.dispatch(actions.setStepsProgress("6"));
			}
		}
		if (currentRemoteStep.step == "6") {
			addURLHistory('meeting_schedule', false)
			store.dispatch(actions.setStep("7"));
			store.dispatch(actions.setStepsProgress("7"));
			setSuccessStep(5);
		}
		if (currentRemoteStep.step === "7") {
			addURLHistory('finish', false)
			store.dispatch(actions.setStep("8"));
			store.dispatch(actions.setStepsProgress("8"));
			setSuccessStep(6);
		}
	});
};

export default pusherSubscribe;
