import { handleCodeInput } from "../../../Helpers/verificationUtils";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "./ConfirmValidationCode.sass";

const ConfirmValidationCode = (props) => {
	const currentLanguge = useSelector((state) => state.project.currentLanguge);
	const verificationMethod = useSelector((state) => state.verification.verificationMethod);

	const emailText = `${currentLanguge.confirmValidationCode.emailText} ${props.email}`;
	const phoneText = `${currentLanguge.confirmValidationCode.phoneText} ${props.numberWithPrefix}`;
	let validationMethodText = verificationMethod === "email" ? emailText : phoneText;

	useEffect(() => { }, [currentLanguge]);
	useEffect(() => { 
		validationMethodText = verificationMethod === "email" ? emailText : phoneText;
	}, [verificationMethod]);
	
	return (
		<div id="login-form__step2" className="px-2">
			{/* Login Form - Step 2: enter verification code */}

			<div className="alert alert-red shadow-sm alert-warning alert-dismissible fade show d-none" role="alert" id="codeIndicator">
				{/* {currentLanguge.formErrors.invalidValidationCode} */}
				<button type="button" className="btn-close" aria-label="Close" onClick={() => props.handleCodeIndicator()}></button>
			</div>

			<div className="row g-0">
				<div className="col-12 text-center py-2 px-">
					<img src="/assets/img/icons/ico-phone.svg" className="col-2 mt-5" alt="" width="127" height="93" />
					<h1 className="fw-bold mt-4 f36">{currentLanguge.confirmValidationCode.validationCodeLable1}</h1>
					<p className="fs-5 f16">
						<span className="d-left f16">{validationMethodText}</span> ,
						<br />
						{currentLanguge.confirmValidationCode.validationCodeLable2}
					</p>
				</div>
			</div>

			{/* Login Form - Step 1: use phone or email to get verification code */}
			<div className="row">
				<div className="col-12">
					<form
						className="row  flex-column align-items-center needs-validation"
						noValidate
						id="login_form__verification"
						onSubmit={(e) => props.validateCode(e, props.verificationMethod, currentLanguge)}
					>
						<div className="col-xxl-7 col-xl-10 col-lg-10 col-md-10 col-sm-12 mb-3">
							<div className="row  mb-3" id="verificationInputs">
								<div className="col input-group-lg">
									<input
										type="text"
										className="form-control inputSingle px-0"
										id="login_form__verification-code1"
										maxLength="1"
										onInput={(e) => handleCodeInput(e)}
									/>
								</div>
								<div className="col input-group-lg">
									<input
										type="text"
										className="form-control inputSingle px-0"
										id="login_form__verification-code2"
										maxLength="1"
										onInput={(e) => handleCodeInput(e)}
									/>
								</div>
								<div className="col input-group-lg">
									<input
										type="text"
										className="form-control inputSingle px-0"
										id="login_form__verification-code3"
										maxLength="1"
										onInput={(e) => handleCodeInput(e)}
									/>
								</div>
								<div className="col input-group-lg">
									<input
										type="text"
										className="form-control inputSingle px-0"
										id="login_form__verification-code4"
										maxLength="1"
										onInput={(e) => handleCodeInput(e)}
									/>
								</div>
								<div className="col input-group-lg">
									<input
										type="text"
										className="form-control inputSingle px-0"
										id="login_form__verification-code5"
										maxLength="1"
										onInput={(e) => handleCodeInput(e)}
									/>
								</div>
								<div className="col input-group-lg">
									<input
										type="text"
										className="form-control inputSingle px-0"
										id="login_form__verification-code6"
										maxLength="1"
										onInput={(e) => handleCodeInput(e)}
									/>
								</div>
							</div>
							<div className="invalidField m-auto text-center" id="invalidCode"></div>
						</div>
						<div className="col-xxl-7 col-xl-7 col-lg-10 col-md-10 col-sm-12 mb-2">
							<button className="btn rf-btn-primary w-100 f16 fw-bold py-3" type="submit">
								{currentLanguge.formsCTA.validationCodeSubmitBtn}
							</button>
						</div>
					</form>
				</div>

				<div className="col-4 d-flex mx-auto flex-column text-center">
					<a className="fw-bold f16 text-decoration-none cursor-pointer"  onClick={(e) => props.resendCode(e, props.verificationMethod, currentLanguge)}>
						{currentLanguge.confirmValidationCode.resandValidationCode}
					</a>
					{currentLanguge.general.or}
					<a className="fw-bold f16 text-decoration-none cursor-pointer" onClick={(e) => { props.setStep(1); }}>
						{currentLanguge.confirmValidationCode.useDifferentValidationMethod}
					</a>
					<span className="resend_counter f15"></span>
				</div>
				<div className="invalidField text-center" id="smsSent" />
			</div>
		</div>
	);
};

export default ConfirmValidationCode;
