import "./App.sass";

import Footer from "./components/General/Footer/Footer";
import AppRouter from "./AppRouter";
// redux provider
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/Store/store";
import { useEffect } from "react";
import { handleSetToken, setWorkingToken } from "./Helpers/verificationUtils";
import { PersistGate } from "redux-persist/lib/integration/react";
import { setTimeZone } from "./Helpers/RegistetUtils";
import { VideoGrant } from "twilio/lib/jwt/AccessToken";
import faker from "faker/locale/en";
import { setCountry, setIpAddress, setIsMobile } from "./Helpers/AppartmentReserveUtils";
import MainNav from "./components/Navigation/MainNav/MainNav";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import GTEvent from './Helpers/GTEvent';
import BackToTop from "./components/General/BackToTop/BackToTop";
import { withRouter } from "react-router-dom";

console.warn = function(){};

const App = (props) => {
	const reCaptchaKey = '6LdUXhMdAAAAALqB6CI23w2otqwkRAruO9CvWe4X';

	useEffect(() => {
		const asyncCall = async () => {
			await handleSetToken();
			sessionStorage.setItem("activeWindow", Date.now());
		};
		asyncCall();

		window.stepHistory = props.history;
	}, []);



	useEffect(async () => {
		const TWILIO_ACCOUNT_SID = "ACcc54c71075b731385d6073165ae9386c";
		const TWILIO_API_KEY_SID = "SK0d717194bf89fdf23bf71fab81a67a3a";
		const TWILIO_API_KEY_SECRET = "b76Sdr1429ZNgJyrLNyOLj3de2k67F0e";

		const AccessToken = require("twilio").jwt.AccessToken;

		const ChatGrant = AccessToken.ChatGrant;

		let identity = faker.name.firstName("male");
		// Create a "grant" which enables a client to use Chat as a given user,
		// on a given device
		const chatGrant = new VideoGrant();

		// Create an access token which we will sign and return to the client,
		// containing the grant we just created
		const token = new AccessToken(TWILIO_ACCOUNT_SID, TWILIO_API_KEY_SID, TWILIO_API_KEY_SECRET, { identity: identity });

		token.addGrant(chatGrant);

		// Serialize the token to a JWT string
		setWorkingToken(token.toJwt());
	}, []);

	useEffect(() => {
		//get user timezone
		function getIp() {
			fetch("https://ipinfo.io/json?token=567893b3937a56", { headers: { Accept: "application/json" } })
				.then((resp) => resp.json())
				.catch(() => {
					return {
						country: "us",
					};
				})
				.then((resp) => {
					setIpAddress(resp.ip);
					setCountry(resp.country);
					setTimeZone(resp.timezone);
				});
		}
		getIp();
	}, []);
	
	useEffect(() => {
		document.addEventListener('click', GTEvent);
		return () => {
			window.removeEventListener('click', GTEvent);
		};
	}, []);

	useEffect(() => {
		handleWindowSizeChange();
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);


	const handleWindowSizeChange = () => {
		let mobile = window.innerWidth <= 1150 ? true : false;
		setIsMobile(mobile);
	}

	return (
		<>
			<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}> {/* Captcha */}
							<div id="top_ancher"></div>
							<MainNav location="main" />
							<BackToTop/>
							<AppRouter />
							<Footer />
						</GoogleReCaptchaProvider> {/* Captcha */}

					</PersistGate>
			</Provider>
		</>
	);
};

export default withRouter(App);


