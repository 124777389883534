import { useEffect } from "react";
import "./BackToTop.sass";
import $ from 'jquery'

const BackToTop = () => {

    useEffect(() => {
        document.addEventListener('scroll', (e) => {
            var doc = document.documentElement;
            var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

            let viewHeight = window.screen.availHeight;


            if (top >= 500) {
                $('#backToTop_button').fadeIn();
            } else {
                $('#backToTop_button').fadeOut();
            }
        })
        return () => {
			document.removeEventListener("scroll", () => {});
		};
	},[]);

    const handleClick = () => {
        window.scrollTo(0, 0);
	};

    return (
        <button id='backToTop_button' style={{display: 'none'}} onClick={handleClick}>
            <i className="bi bi-caret-up-fill"></i>
        </button>
    );
};

export default BackToTop;
