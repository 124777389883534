import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./StripePayment.sass";
import AppartmentShort from "../../General/AppartmentShort/AppartmentShort";
import { ConvertCurrency } from "../../../Helpers/ConvertCurrency";
import { NumberWithCommas } from "../../../Helpers/NumberFormat";
import { setLoadingModalContent, getPaymentIntent } from "../../../Helpers/AppartmentReserveUtils";
import { handleExistingImage } from "../BuildingsSelection/image";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const StripePayment = (props) => {
	const basicData = useSelector((state) => state.project.appartmentBasicData);

	const projectReservationPrice = useSelector((state) => state.project.projectSettings.project_reservation_price);

	const projectStripePublishkey = useSelector((state) => state.project.projectSettings.stripe_publishkey);

	const project_sales_phone = useSelector((state) => state.project.projectSettings.project_sales_phone);

	const selectedFloorImg = useSelector((state) => state.project.selectedAptImg);

	const project_default_currency = useSelector((state) => state.project.projectSettings.project_default_currency);
	const currencySymbol = typeof project_default_currency != "undefined" ? ConvertCurrency(project_default_currency) : "";
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

    const [clientSecret, setClientSecret] = useState("");

	const stripePromise = loadStripe(projectStripePublishkey);

    useEffect(() => { }, [currentLanguge]);
    
	useEffect(() => {
		if (window.hasOwnProperty("$")) {
			window.$("#paymentImg").sticky({ topSpacing: 90, bottomSpacing: 140, wrapperClassName: "" });
		}
	}, []);

	const Phone = () => {
		if (project_sales_phone.includes('*') && currentLanguge.direction == 'rtl') {
			let newProject_sales_phone = project_sales_phone.replace('*', '');
			return newProject_sales_phone + '*';
		} else {
			return project_sales_phone;
		}
	};

	useEffect(() => {
		setLoadingModalContent("");
        handleExistingImage(selectedFloorImg, "paymentImg", `${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`);
	}, []);
	
	const fakeSubmit = () => {
		
	}

	// Get PaymentIntent as soon as the page loads
	useEffect(() => {
		// setClientSecret(paymentIntent.client_secret);
		getPaymentIntent(projectReservationPrice, currentLanguge).then(clientSecretReq => {
			if(clientSecretReq) setClientSecret(clientSecretReq);
		});

	},[projectStripePublishkey, projectReservationPrice])
    

    const appearance = {
		theme: 'stripe',
		variables: {
			colorPrimary: 'var(--rf-main-color)',
			colorBackground: 'var(--rf-white)',
			colorText: 'var(--rf-dark-gray-trans)',
			colorDanger: 'var(--rf-text-error)',
		},
		rules: {
			'.Label': {
				fontWeight: '500',
				fontSize: '18px'
			}
		}
    };
    const options = {
        clientSecret,
        appearance,
    };
    
	return (
		<div className="row m-0 apartment_reserve__stepContainer" id="apartment_reserve__step-6">
			<div className="stepCon">
				<h1 className="fw-bold mt-4 f48">{currentLanguge.payment_step.stepTitle}</h1>
				<span className="f20 w500 mb-3">
					{currentLanguge.payment_step.descriptionText1} <b>{`${currencySymbol}${NumberWithCommas(projectReservationPrice)}`}</b>. 
					<br />
					<span>
						{" "}
						{currentLanguge.payment_step.helpText} <span style={{ direction: "rtl" }}>{Phone()}</span>
					</span>
				</span>
				{/* Apartment Details again for review before signing  */}
				<AppartmentShort id={"paymentModal"} className='mobileFlexView' />
				<br/>
				<br/>
				<p className="f20 w500 mb-3">{currentLanguge.payment_step.descriptionText2}</p>
                <div className="col-xxl-12 d-flex flex-column justify-content-center flashit">
					{clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    )}
					<div className="col-12 d-flex flex-column">
						<div className="d-flex flex-row justify-content-between align-self-center align-items-center flex-wrap mobileTextCenter">
							<span className="pfItemFontSm mb-3">{currentLanguge.payment_step.paymentFooterText}</span>
							<div className="d-flex mobileContentCenter mobileFuulWidth mb-3">
								<span className="col-auto mds-1">
									<img src="/assets/img/icons/ico-amex.png" alt="amex"/>
								</span>
								<span className="col-auto mds-1">
									<img src="/assets/img/icons/ico-master.png" alt="master"/>
								</span>
								<span className="col-auto mds-1">
									<img src="/assets/img/icons/ico-visa.png" alt="visa"/>
								</span>
							</div>
						</div>
						<div className="col d-flex flex-row align-self-center pb-5 flex-wrap mobileContentCenter">
							<span className="col-auto mds-1">
								<img src="/assets/img/icons/ico-auth.png" alt="auth"/>
							</span>
							<span className="col-auto mds-1">
								<img src="/assets/img/icons/ico-digital.png" alt="digital"/>
							</span>
							<span className="col-auto mds-1">
								<img src="/assets/img/icons/ico-secure.png" alt="secure"/>
							</span>
							<span className="col-auto mds-1">
								<img src="/assets/img/icons/ico-pci.png" alt="pci"/>
							</span>
						</div>
					</div>
				</div>
			</div>
			{/* Step Graphics  */}
			<div className="bg-image rounded" id="paymentImg">
				<div className="col-12 d-flex justify-content-center align-self-center m-auto position-relative">
					<img src={selectedFloorImg} alt={`${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`} />
				</div>
			</div>
		</div>
	);
};

export default StripePayment;
