import { useEffect } from "react";
import CheckVideo from "../../MeetingComponents/CheckVideo/CheckVideo";
import "./CheckVideoStep.sass";
import { useSelector } from "react-redux";
import CountDownTimer from "react-component-countdown-timer";
import { setMeetingStatus } from "../../../Helpers/MeetingsUtils";
import { initMeetingFlag } from "../../../Helpers/AppartmentReserveUtils";
import { setContinueToApp } from "../../../Helpers/verificationUtils";

const CheckVideoStep = (props) => {
	const timeToMeeting = useSelector((state) => state.meeting.timeToMeeting);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	useEffect(() => { }, [currentLanguge]);
	
	let secondsToMeeting = timeToMeeting;

	const onEndTimer = () => {
		if (isAgent) {
			setMeetingStatus("start");
			return;
		}
		initMeetingFlag().then((res) => {
			setContinueToApp(true);
			setMeetingStatus("start");
			window.location.reload();
		});
	};
	return (
		<div className="col-auto d-flex flex-column">
			<div className="col-12 d-flex flex-row justify-content-start bg-white" style={{ height: "50px" }}>
				<img src="/assets/img/icons/ico-timer.svg" className="align-self-center mds-3" alt="" heigh="25" width="25" />
				<span className="align-self-center mds-2 w500 d-flex flex-row justify-content-center">
					{currentLanguge.checkVideoStep.timerTitle}
					<CountDownTimer
						count={secondsToMeeting}
						className="checkVideoCounter align-self-center mds-1 mde-1 rf-darkText rf-transparentBg"
						size={16}
						onEnd={onEndTimer}
						hideHours={true}
						hideDay={true}
					/>
					{currentLanguge.general.timeMinutes}
				</span>
			</div>
			<h1 className="text-center mt-5 mb-2 f48 w700">{currentLanguge.checkVideoStep.title}</h1>
			<h2 className="fs-5 text-center mt-2 pfItemFont">{currentLanguge.checkVideoStep.subTitle}</h2>
			<CheckVideo />
		</div>
	);
};

export default CheckVideoStep;
