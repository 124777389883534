import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { makePostRequest } from "../../../Axios";
import {
	getNewContract,
	setUserVerified,
	setStepShort,
	setThereIsNewContract,
	updateMeetingFlow,
	setLoadingModalContent,
	resetFlow,
	setRemoteAction,
	setShowApprovePopup,
	setFlowErrorObject,
	setUserApprovedApt,
} from "../../../Helpers/AppartmentReserveUtils";
import {
	handleRegisterInputs,
	setShowCodeVerificationPopup,
	showStaticMessage,
	validateRegisterForm,
} from "../../../Helpers/RegistetUtils";
import { setIsClientRegistered, HandleVerificationInputs, requestCode, setShowPopup } from "../../../Helpers/verificationUtils";
import { setUserId } from "../../../Redux/Reducers/meetingSlice";
import { setClientType } from "../../../Redux/Reducers/verificationSlice";
import store, { persistor } from "../../../Redux/Store/store";
import AppartmentShort from "../../General/AppartmentShort/AppartmentShort";
import UserDetailsFormFields from "../../General/UserDetailsForm/UserDetailsFormFields";
import VerificationPopup from "../../General/VerificationPopup/VerificationPopup";
import CodeVerificationPopup from "../../General/VerificationPopup/CodeVerifyPopup";

import StepTitle from "../../UI/Titels/StepTitle";
import { handleExistingImage } from "../BuildingsSelection/image";
import "./DetailsApproval.sass";
import $ from 'jquery';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import GTEvent from "../../../Helpers/GTEvent";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";

const DetailsApproval = (props) => {
	const [isdifferentBuyerDetails, setIsdifferentBuyerDetails] = useState(false);

	const { setVerificationMethod } = HandleVerificationInputs();
	const { setAreaCode, setEmail, setFirstName, setID, setLastName, setMobileNumber, setIsTermsAgreed, 
		setBuyerEmail, setBuyerMobileNumber, setBuyerFirstName, setBuyerLastName, setBuyerID, setBuyerAreaCode, setBuyerMobileNumberWithoutPrefix,
		setIsDifferentBuyer} = handleRegisterInputs();
	const verificationSelector = useSelector((state) => state.verification);
	const isClientRegistered = useSelector((state) => state.verification.isClientRegistered);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const userApprovedApt = useSelector((state) => state.project.userApprovedApt);
	const terms = useSelector((state) => state.project.projectSettings.project_agreement_termspdf);

	const meetingSlug = useSelector((state) => state.verification.slug);

	const contractExampleHref = useSelector((state) => state.project.projectSettings.project_contract_pdf);
	const AgreementExampleHref = useSelector((state) => state.project.projectSettings.project_reservationsample_pdf);
	const isUserVerified = useSelector((state) => state.project.userVerified);
	const registerSelector = useSelector((state) => state.register);
	const termsAgreed = useSelector((state) => state.register.termsAgreed);

	const [codeVerification, setCodeVerification] = useState("");
	const enableEditAgreement = useSelector((state) => state.project.projectSettings.enable_edit_onagreement);
	const selectedFloorImg = useSelector((state) => state.project.selectedAptImg);
	const phonePropName = "phoneDigital";
	const currentLanguge = useSelector((state) => state.project.currentLanguge);

	const meeting = useSelector((state) => state.meeting);
	const { projectSlug } = useParams();

	const [buyerFormSelectors, setBuyerFormSelectors] = useState({...registerSelector});
	const [verificationAttempts, setVerificationAttempts] = useState(0);
	let resendInterval;

	// captch start
	const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');
	const [actionToChange, setActionToChange] = useState('');
	
	// Block fields if customer in a meeting and not an agent
	// useEffect(() => {
	// 	const getUrlParams = new URLSearchParams(window.location.search);
	// 	const agentKey = getUrlParams.get("agentkey");
	// 	const isAgent = agentKey != '' && agentKey != null ? true : false;
		
	// 	if ((!enableEditAgreement || enableEditAgreement == 0) && !isAgent && meeting.isSelfReserve != true) {
	// 		$('#digitalForm').find('input').prop('disabled', true);
	// 	} else {
	// 		$('#digitalForm').find('input').prop('disabled', false);
	// 	}
	// },[meeting])

	useEffect(() => { }, [currentLanguge]);


	useEffect(async () => {
		try {
			setLoadingModalContent("");
			handleExistingImage(selectedFloorImg, "aptSideImg");
			if (!meetingSlug) {
				return;
			}
			const slug = store.getState().verification.slug;
			const formData = new FormData();
			formData.append("agentkey", "");

			
			let langCode = sessionStorage.getItem("langCode");
			if (!langCode || langCode == '') {
				langCode = '';
			} else {
				langCode = '/' + langCode;
			}
			
			const res = await makePostRequest(`/meetingrooms/${slug}${langCode}`, formData);
			if (!res) {
				return;
			}
			if (res.data.clientDetails) {
				setIsClientRegistered(true);
				setUserId(res.data.clientDetails.id);
				setClientType(res.data.clientDetails.client_type);

				const data = res.data.clientDetails;
				const phone = registerSelector.mobileNumberWithoutPrefix;
				const email = registerSelector.email;
				const firstName = registerSelector.firstname;
				const lastName = registerSelector.lastname;
				const id = registerSelector.identity_id;

				if (id == "00000000") {
					setID("");
				} else {
					setID(id);
				}

				setEmail(email);
				setFirstName(firstName);
				setLastName(lastName);
				setMobileNumber(phone);
				setIsTermsAgreed(true);
				let elem = document.querySelector("#digitalForm");
				let checkbox = document.querySelector("#customer_details_from__terms");
				let button = document.querySelector("#registerShort");
				
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		if (termsAgreed) {
			$('#apartment_reserve__step-5').find('#customer_details_from__terms').prop("checked", true);
		} else {
			$('#apartment_reserve__step-5').find('#customer_details_from__terms').prop("checked", false);
		}
	}, [termsAgreed]);

	const formSubmit = useCallback(async (e) => {
		e.preventDefault();
        if (!executeRecaptcha) {
            return;
		}
		
        const result = await executeRecaptcha('register');
		setToken(result);
		$(e.target).find('button[type=submit]').prop('disabled', true);
		
		let data = new FormData();
		data.append("token", result);
		data.append("action", 'register');

		const req = await makePostRequest("/validate_recaptcha", data);
		if (!req) {
			return;
		}
		if (req.data.message == 'OK') {
			handleSubmitLocal(e);
			$(e.target).find('button[type=submit]').prop('disabled', false);

		} else {
			setFlowErrorObject({errorCode:"100",errorMessage:currentLanguge.formsErrors.botError, errorTitle: ""})
		}
		
	}, [executeRecaptcha]);
	
	useEffect(() => {
        if (!executeRecaptcha) {
            return;
        }
        const handleReCaptchaVerify = async () => {
            const token = await executeRecaptcha('register');
			setToken(token);
        };
        handleReCaptchaVerify();
    }, [executeRecaptcha]);
	// captch end

	// If buyer detailes are different
	useEffect(() => {
		let buyerFormSelectorsTemp = { ...buyerFormSelectors };
		if (isdifferentBuyerDetails == true) {
			buyerFormSelectorsTemp.email = '';
			buyerFormSelectorsTemp.firstName = '';
			buyerFormSelectorsTemp.lastName = '';
			buyerFormSelectorsTemp.mobileNumber = '';
			buyerFormSelectorsTemp.mobileNumberWithoutPrefix = '';
			buyerFormSelectorsTemp.ID = '';
			setBuyerEmail('');
			setBuyerMobileNumber('');
			setBuyerFirstName('');
			setBuyerLastName('');
			setBuyerID('');
			setBuyerAreaCode('');
			setBuyerMobileNumberWithoutPrefix('');

			setIsDifferentBuyer(true);
			$('#register_form__id_element').hide();
		} else {
			buyerFormSelectorsTemp.email = registerSelector.email;
			buyerFormSelectorsTemp.firstName = registerSelector.firstName;
			buyerFormSelectorsTemp.lastName = registerSelector.lastName;
			buyerFormSelectorsTemp.mobileNumber = registerSelector.mobileNumber;
			buyerFormSelectorsTemp.mobileNumberWithoutPrefix = registerSelector.mobileNumberWithoutPrefix;
			buyerFormSelectorsTemp.ID = registerSelector.ID;
			setBuyerEmail(registerSelector.email);
			setBuyerFirstName(registerSelector.firstName);
			setBuyerLastName(registerSelector.lastName);
			setBuyerID(registerSelector.ID);
			setBuyerAreaCode(registerSelector.areaCode);
			setBuyerMobileNumber(registerSelector.mobileNumber);
			setBuyerMobileNumberWithoutPrefix(registerSelector.mobileNumberWithoutPrefix);

			setIsDifferentBuyer(false);
			$('#register_form__id_element').show();
		}
		setBuyerFormSelectors(buyerFormSelectorsTemp);
	}, [isdifferentBuyerDetails]);

	const newContractSubmit = (e) => {
		e.preventDefault();

		const isFormValid = validateRegisterForm(e, currentLanguge);
		if (isUserVerified && isFormValid && termsAgreed) {
			const { ID } = store.getState().register;
			setID(ID);
			getNewContract(e, currentLanguge);
			if (meetingSlug) {
				setRemoteAction({ eventName: "stepChange", payload: "5.5", author: isAgent });
			}

			props.history.push(`/${projectSlug}/contract_signature`);
			setStepShort("5.5");
		}
	};

	const verifyCode = async (e, method, code) => {
		setLoadingModalContent(true);

		$(e.target).prop('disabled', true);

		if (code == '' || code.length == 0) {
			showStaticMessage("invalidRegCode", currentLanguge.formsErrors.emptyValidationCode, false);
			$(e.target).prop('disabled', false);
			setLoadingModalContent("");
			return;
		}

		let thereIsNewContract = store.getState().project.thereIsNewContract;
		e.stopPropagation();
		const data = new FormData();
		const slug = store.getState().verification.slug;
		data.append("meetingslug", slug);
		data.append("code", code);
		const res = method === "email" ? await makePostRequest("/userclientmailcodecheck", data) : await makePostRequest("/userclientsmscodecheck", data);
		if (!res) {
			$(e.target).prop('disabled', false);
			setLoadingModalContent("");
			return;
		}

		if (res) {
			if (res.data.statusCode == 400) {
			} else if (res.data.statusCode == 200) {
				const meetingSlug = store.getState().verification.slug;
				const meetingStatus = store.getState().meeting.meetingStatus;
				setLoadingModalContent(`${currentLanguge.detailsApproval_step.contractLoader}`);
				const id = store.getState().project.appartmentID;
				const agentKey = store.getState().verification.agentKey;
				const appartment_id = store.getState().project.appartmentRealID;
				const buildingID = store.getState().project.buildingID;
				const floorID = store.getState().project.floorID;
				let data = {
					1: buildingID,
					2: floorID,
					3: appartment_id,
					4: appartment_id,
				};
				let strngified = JSON.stringify(data);

				let langCode = sessionStorage.getItem("langCode");
				if (!langCode || langCode == '') {
					langCode = '';
				} else {
					langCode = '/' + langCode;
				}

				if (!isUserVerified) {
					const req = await updateMeetingFlow(strngified, 4, meetingSlug, agentKey);

					if (!req) {
						$(e.target).prop('disabled', false);
						setLoadingModalContent("");
						return;
					}

					if (req.data.statusCode == 404 || req.data.statusCode == 722) {
						$(e.target).prop('disabled', false);

						setFlowErrorObject({
							errorCode: "code 404",
							errorMessage: currentLanguge.flowErrors.alreadyReservedApt,
						});
						setUserApprovedApt(false);
						setLoadingModalContent("");
						setShowCodeVerificationPopup(false);

						resetFlow(false);
						persistor.flush();
						return;
					}

					if (req.data.meeting) {
						$(e.target).prop('disabled', false);

						setShowCodeVerificationPopup(false);
						setIsClientRegistered(true);
						setUserVerified(true);
						setThereIsNewContract(!thereIsNewContract);
						let data = new FormData();
						data.append("agent_key", "");
						const res = await makePostRequest(`/meetingrooms/${meetingSlug}${langCode}`, data);
						if (!res) {
							setLoadingModalContent("");
							return;
						}
						
						getNewContract(e, currentLanguge);
						
						props.history.push(`/${projectSlug}/contract_signature`);
						setStepShort("5.5");

						if (meetingSlug) {
							setRemoteAction({ eventName: "stepChange", payload: "5.5", author: isAgent });
						}
						setLoadingModalContent("");
						return;
					}

				}
				if (isUserVerified) {
					const req = await updateMeetingFlow(strngified, 4, meetingSlug, agentKey);
					if (!req) {
						$(e.target).prop('disabled', false);
						setLoadingModalContent("");
						return;
					}

					if (req.data.statusCode == 404) {
						$(e.target).prop('disabled', false);

						setFlowErrorObject({
							errorCode: "code 404",
							errorMessage: currentLanguge.flowErrors.alreadyReservedApt,
						});
						setUserApprovedApt(false);
						setLoadingModalContent("");

						persistor.flush();
					}

					if (req.data.meeting) {
						$(e.target).prop('disabled', false);

						setShowCodeVerificationPopup(false);
						setIsClientRegistered(true);
						setThereIsNewContract(!thereIsNewContract);
						let data = new FormData();
						data.append("agent_key", "");
						const res = await makePostRequest(`/meetingrooms/${meetingSlug}${langCode}`, data);
						if (!res) {
							setLoadingModalContent("");
							return;
						}
						
						getNewContract(e, currentLanguge);

						props.history.push(`/${projectSlug}/contract_signature`);
						setStepShort("5.5");
						
						if (meetingSlug) {
							setRemoteAction({ eventName: "stepChange", payload: "5.5", author: isAgent });
						}
						setLoadingModalContent("");
						return;
					}
				}
			} else {
				$(e.target).prop('disabled', false);
				showStaticMessage("invalidRegCode", currentLanguge.flowErrors.invalidValidationCode, false);
			}
		}
		setLoadingModalContent("");

		return res;
	};

	const resendCodeShort = async (e, method, elem) => {
		e.preventDefault();
		e.stopPropagation();

		$(e.target).css({ 'pointer-events': 'none', 'opacity' : '0.4'});


		// check how many attempts are done
		if (verificationAttempts >= 3) {
			// if more then 3 - set timer for 5min
			reOpenResendCTA(300, e.target, true);
			$(`#${elem}`).css({ 'color': 'var(--rf-text-error)' }).html(currentLanguge.confirmValidationCode.resandAttemtsPassed).fadeIn();
			return;
		}
		let newVerificationAttempts = verificationAttempts + 1;
		setVerificationAttempts(newVerificationAttempts);

		const slug = store.getState().verification.slug;
		const data = new FormData();
		data.append("meetingslug", slug);

		if (method === "email") {
			const email = store.getState().register.email;
			data.append("email", email);
			const emailReq = await requestCode("/userclient_emailcheck", data);
			if (emailReq.data.statusCode == 200) {
				showStaticMessage(elem, currentLanguge.confirmValidationCode.validationCodeSent, true);
			}else {
				showStaticMessage(elem, currentLanguge.confirmValidationCode.resandGeneralError, false);
				$(e.target).css({ 'pointer-events': 'auto', 'opacity': '1' });
				return;
			}
		} else if (method === "phone") {
			const phoneNumber = window[phonePropName].getNumber();
			data.append("mobile", phoneNumber);
			const codeReq = await requestCode("/userclient_mobilecheck", data);

			if (codeReq.data.statusCode == 200) {
				showStaticMessage(elem, currentLanguge.confirmValidationCode.validationCodeSent, true);
			} else {
				showStaticMessage(elem, currentLanguge.confirmValidationCode.resandGeneralError, false);
				$(e.target).css({ 'pointer-events': 'auto', 'opacity': '1' });
				return;
			}
		}

		// re-open the option to send again
		reOpenResendCTA(30, e.target);
		return;
	};

	const reOpenResendCTA = (time, ctaID, resetVerificationAttempts = false) => {
		clearInterval(resendInterval);
		$('.resend_counter').html(`${currentLanguge.confirmValidationCode.resandTimerDesc} ${getTimeStr(time)}`).fadeIn();

		resendInterval = setInterval(() => {
			if (time > 0) {
				time--;
				$('.resend_counter').html(`${currentLanguge.confirmValidationCode.resandTimerDesc} ${getTimeStr(time)}`)
			} else {
				$(ctaID).css({ 'pointer-events': 'auto', 'opacity': '1' });
				$('.resend_counter').html('').fadeOut();

				if (resetVerificationAttempts) setVerificationAttempts(0);
				
				clearInterval(resendInterval);
			}
		}, 1000)
	}

	const getTimeStr = (time) => {
		return new Date(time * 1000).toISOString().substring(14, 19)
	}

	const requestVerificationCode = async (e, method) => {
		e.preventDefault();
		e.stopPropagation();
		const slug = store.getState().verification.slug;

		const data = new FormData();
		data.append("meetingslug", slug);

		$(e.target).find('button[type=submit]').prop('disabled', true);

		if (method === "email") {
			setVerificationMethod("email");
			const email = store.getState().register.email;
			data.append("email", email);
			const emailReq = await requestCode("/userclient_emailcheck", data);
			if (emailReq) {
				if (emailReq.data.statusCode == 400) {
					showStaticMessage("invalidEmailPopover", currentLanguge.confirmValidationCode.emailNotFound, false);
					$(e.target).find('button[type=submit]').prop('disabled', false);

				}
				if (emailReq.data.statusCode == 200) {
					setShowPopup(false);
					setShowCodeVerificationPopup(true);
					setVerificationAttempts(1);
					return;
				}
			}
		}
		if (method === "phone") {
			setVerificationMethod("phone");
			const phoneNumber = window[phonePropName].getNumber();
			data.append("mobile", phoneNumber);
			const codeRed = await requestCode("/userclient_mobilecheck", data);
			if (codeRed) {
				if (codeRed.data.statusCode == 400) {
					showStaticMessage("invalidPhonePopover", currentLanguge.confirmValidationCode.phoneNotFound, false);
					$(e.target).find('button[type=submit]').prop('disabled', false);

				}
				if (codeRed.data.statusCode == 200) {
					setShowPopup(false);
					setShowCodeVerificationPopup(true);
					setVerificationAttempts(1);
					return;
				}
			}
		}

		return;
	};

	const verifyFields = (method) => {
		return (
			<div className="col-xxl-12 col-xl-10 col-lg-12 col-md-12 col-sm-12 mb-3 input-group-lg d-flex flex-column">
				<label htmlFor="validationCustomMobile" className="form-label align-self-center pfItemFontSm">
					{currentLanguge.confirmValidationCode.phoneInput}
				</label>

				<div className="input-group input-group-md input-group-phone">
					<input
						type="text"
						className="form-control pfInput pfContainerShadow d-left"
						id="validationCustomMobile"
						placeholder={currentLanguge.confirmValidationCode.codePlaceholder}
						onChange={(e) => props.onChangeCode(e.currentTarget.value, method)}
					/>
				</div>
			</div>
		);
	};

	const setCode = (value) => setCodeVerification(value);

	const handleTextChange = useCallback(event => {
        setActionToChange(event.target.value);
	}, []);

	const handleSubmitLocal = (e) => {
		e.stopPropagation();
		e.preventDefault();
		const isFormValid = validateRegisterForm(e, currentLanguge);
		
		if (isFormValid) GTEvent(e);
		if (!userApprovedApt && isFormValid) {
			if (meetingSlug) {
				const registerS = store.getState().register;
				setRemoteAction({ eventName: "openApprovePop", payload: registerS, author: isAgent });
				setShowApprovePopup(true);
				return;
			}
			setShowApprovePopup(true);
			return;
		}

		if (!isUserVerified && isClientRegistered) {
			if (isFormValid) {
				setShowPopup(true);
				return;
			}
			return;
		}
		if (isClientRegistered) {
			if (isFormValid) {
				setLoadingModalContent(currentLanguge.detailsApproval_step.contractLoader);
				newContractSubmit(e);
				return;
			}
		}
		if (!isClientRegistered) {
			if (isFormValid) {
				props.handleSubmit(e, currentLanguge);
				return;
			}
		}
	};

	const triggerIsdifferentBuyerDetails = (e) => {
		setIsdifferentBuyerDetails(e.target.checked)
		GTEvent(e, e.target.checked ? 'true': 'false');
	}

	return (
		<div className="row m-0 apartment_reserve__stepContainer" id="apartment_reserve__step-5">
			{/* Step Content  */}
			<div className="px-3 py-4 step5_content">
				<StepTitle className="pfHeaderShadow m-0 fw-bold">{currentLanguge.detailsApproval_step.stepTitle}</StepTitle>

				<p className="mb-4 pb-3 f20 w400">{currentLanguge.detailsApproval_step.stepSubtitle}</p>

				<AppartmentShort id={"sigModal"} className="mobileFlexView" />

				{/* Validate Customer Details Form  */}
				<form id="customer_details_from" className="my-4" onSubmit={(e) => formSubmit(e)}>
					{/* Client Details  */}
					<UserDetailsFormFields
						setAreaCode={setAreaCode}
						setEmail={setEmail}
						setFirstName={setFirstName}
						setLastName={setLastName}
						setID={setID}
						setMobileNumber={setMobileNumber}
						formSelector={props.formSelector}
						detailField={currentLanguge.userDetailsFormFields.formTitle2}
						phonePropName={phonePropName}
						isClientRegistered={isClientRegistered}
						isdifferentBuyerDetails= {isdifferentBuyerDetails}
						handleTextChange={handleTextChange}
					/>
					<VerificationPopup
						setVerificationMethod={setVerificationMethod}
						make={requestVerificationCode}
						slug={verificationSelector.slug}
						phone={registerSelector.mobileNumber}
						email={registerSelector.email}
						step={registerSelector.codeRegisterStep}
						getCodeShort={requestVerificationCode}
						verifyFields={verifyFields}
					/>
					<CodeVerificationPopup
						onChangeCode={setCode}
						verifyCode={verifyCode}
						code={codeVerification}
						verificationMethod={verificationSelector.verificationMethod}
						resendCode={resendCodeShort}
					/>

					{/* Different Client details */}
					<div className="row mt-3 mb-3 slide-in-left flashit">
						<div className="col">
							<div className="form-check">
								<input
									className="form-check-input align-self-center"
									type="checkbox"
									value="0"
									data-gt-category='buyer verification' data-gt-action='click' data-gt-label='different buyer details'
									id="customer_details_from__differentBuyer_details"
									onChange={(e) => triggerIsdifferentBuyerDetails(e)}
								/>
								<label className="form-check-label w500 f18 align-self-center mds-2" htmlFor="customer_details_from__differentBuyer_details">
									{currentLanguge.formsFields.differentBuyer_details}
								</label>
							</div>
						</div>
					</div>
					{isdifferentBuyerDetails == true ? 
						<UserDetailsFormFields
							buyerDetailes={true}
							setAreaCode={setBuyerAreaCode}
							setEmail={setBuyerEmail}
							setFirstName={setBuyerFirstName}
							setLastName={setBuyerLastName}
							setID={setBuyerID}
							setMobileNumber={setBuyerMobileNumber}
							formSelector={buyerFormSelectors}
							detailField={currentLanguge.userDetailsFormFields.formTitle2_buyer}
							phonePropName={`${phonePropName}_buyer`}
							isClientRegistered={isClientRegistered}
							isdifferentBuyerDetails={isdifferentBuyerDetails}
							handleTextChange={handleTextChange}
						/> : "" }

					{/* Terms  */}
					<div className="row mt-3 mb-3 slide-in-left flashit">
						<div className="col">
							<div className="form-check">
								<input
									className="form-check-input align-self-center"
									type="checkbox"
									value={termsAgreed}
									id="customer_details_from__terms"
									onChange={(e) => setIsTermsAgreed(e.target.checked)}
								/>
								<label className="form-check-label w500 f18 align-self-center mds-2" htmlFor="customer_details_from__terms">
									{currentLanguge.formsFields.terms}{" "}
									<a href={`${terms}`} target="_blank" rel="noreferrer">
										{currentLanguge.formsFields.termsLink}
									</a>
								</label>
							</div>
							<div id="invalidTerms" className="invalidField"></div>
						</div>
					</div>

					{/* CTA */}
					<div className="row pt-1 slide-in-left">
						<div className=" col-xxl-5 col-lg-5 col-sm-12 text-center">
							<div className="d-grid">
								<button id="registerShort" type="submit" className="btn rf-btn-primary fw-bolder f18 sigButtonPadding pfShadow"
									data-gt-category='buyer verification' data-gt-action='submit' data-gt-label='submit buyer details form'>
									{currentLanguge.detailsApproval_step.stepCTA}
								</button>
							</div>
						</div>
					</div>
					<div id="registerSuccess" className="invalid-feedback"></div>
				</form>

				{/* Contract Semples download files  */}
				<div className="files_download_container slide-in-left">
					<h3 className={`fs-4 fw-bold`}>{currentLanguge.detailsApproval_step.sempleFilesTitle}</h3>
					{AgreementExampleHref &&
						<a href={AgreementExampleHref} className="file_item f16" target="_blank" rel="noreferrer"
							data-gt-category='buyer verification' data-gt-action='click' data-gt-label='download agreement example'>
							<img className="mde-2" src="/assets/img/icons/ico-file-download.svg" alt={currentLanguge.detailsApproval_step.reserveContractExampleLink} />
							{currentLanguge.detailsApproval_step.reserveContractExampleLink}
						</a>
					}
					{contractExampleHref &&
						<a href={contractExampleHref} className="file_item f16" target="_blank" rel="noreferrer"
							data-gt-category='buyer verification' data-gt-action='click' data-gt-label='download contract example'>
							<img className="mde-2 " src="/assets/img/icons/ico-file-download.svg" alt={currentLanguge.detailsApproval_step.sellContractExampleLink} />
							{currentLanguge.detailsApproval_step.sellContractExampleLink}
						</a>
					}
				</div>
			</div>

			{/* Step Graphics  */}
			<div className="" style={{maxWidth: '40%'}} id="sigSideContainer">
				<div className="col-12 d-flex justify-content-center align-self-center m-auto position-relative">
					<AppartmentShort className='desktopView' id={"sigModal2"} verticalView={true} />
				</div>
			</div>

		</div>
	);
};

export default withRouter(DetailsApproval);
