

const GTPageView = (gtLabel) => {
	pushGAvent('Page View', 'view', gtLabel);
}
export default GTPageView;

const pushGAvent = (gtCategory, gtAction, gtLabel) => {
	let projectID = sessionStorage.getItem('projectID');
	window.dataLayer.push({
		'event': `${gtCategory}`,
		'action': gtAction,
		'label': gtLabel,
		'projectID' : projectID

	});
	console.log("GT event:", gtCategory, ":", gtAction, ":", gtLabel);
}
