import HomeIconItem from "./HomeIconItem";
import SectionTitle from "../../UI/Titels/SectionTitle";
import "./HomeIcons.sass";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const HomeIcons = (props) => {
	const [icons, setIcons] = useState([]);
	const [sectionTitle, setSectionTitle] = useState([]);
	const projectSettings = useSelector((state) => state.project.projectSettings);
	const featuresListJson = useSelector((state) => state.project.projectSettings.featuresListJson);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	
	useEffect(() => { }, [currentLanguge]);
	
	
	useEffect(() => {
		if (projectSettings.hasOwnProperty("featuresListJson") && projectSettings.featuresListJson) {
			let values;
			if (typeof projectSettings.featuresListJson == 'string') {
				values = Object.values(JSON.parse(projectSettings.featuresListJson));
			} else {
				values = Object.values(projectSettings.featuresListJson);
			}
			setIcons(values);
		}

		if (projectSettings.hasOwnProperty("project_seo_title")) {
			setSectionTitle(projectSettings.project_seo_title);
		}
	}, [projectSettings, featuresListJson]);

	const Icons = ({ list }) => {
		let content = list.map((el, idx) => {
			let vals = el.val.split("|");
			let splitted = el.icon.split("/");
			return <HomeIconItem icon_img={`/assets/img/icons/${splitted[3]}`} icon_title={vals[0]} val={vals[1]} itemkey={idx}  key={`homeIcons${idx}`} />;
		});
		return content;
	};

	if (featuresListJson == '' || !featuresListJson) return ("");
	return (
		<div className="row my-5" id="project_icons">
			<div className="container">
				{/* Sectiion Title */}
				<div className="row justify-content-center">
					<div className="col-12 text-center">
						<SectionTitle className="my-2">{sectionTitle}</SectionTitle>
					</div>
				</div>

				{/* Project icons */}
				<div className="row g-2 justify-content-center pt-4 text-center project_icons__list">
					{Icons({ list: icons })}
				</div>

				{/* CTA */}
				<div className="row justify-content-center pt-4">
					<div className={`col-lg-3 col-md-6 col-sm-12  text-center ${projectSettings.project_catalog_pdf == '' ? 'd-none' : ''}`}>
						<div className="d-grid">
							<a href={projectSettings.project_catalog_pdf} target="_blank" download className="rf-btn-primary fw-bold py-3 f18" style={{'textDecoration': 'none'}}  data-gt-category='home page' data-gt-action='click' data-gt-label='project catalog download'>
								{currentLanguge.landing.catalogBtn}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeIcons;
