import { useSelector } from "react-redux";
import moment from 'moment'
import { useEffect } from "react";

const EarlyArrival = ({ meetingTime }) => {
	const register = useSelector((state) => state.register);
	const meeting = useSelector((state) => state.meeting);
    const splitted = meetingTime.split(" ");
	const date = moment(splitted[0]).format("DD-MM-YYYY");
	const time = splitted[1];
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	useEffect(() => { }, [currentLanguge]);
	
	let monthName = new Date(splitted[0]).toLocaleString(currentLanguge.languageLocalCode, { month: 'long' }).trim();
	let fullYear = new Date(splitted[0]).getFullYear();
	let day = new Date(splitted[0]).getDay();
	const splittedDate = date.split("-")

	let splittedTime = time.split(":");
	let timeParsed = `${splittedTime[0]}:${splittedTime[1]}`;


	const getUrlParams = new URLSearchParams(window.location.search);
	const agentKey = getUrlParams.get("agentkey");
	const isAgent = agentKey != '' && agentKey != null ? true : false;

	let titleText, text2, text1 = '';
	if (!isAgent) {
		titleText = `${currentLanguge.loginEarlyArrival.titleTextClient} ${meeting.agentFirstname} ${meeting.agentLastname}`;
		text1 = `${currentLanguge.loginEarlyArrival.text1_1Client} ${register.firstName} ${register.lastName}, ${currentLanguge.loginEarlyArrival.text1_2Client} ${splittedDate[0]} ${monthName}, ${fullYear} ${currentLanguge.loginEarlyArrival.text1_3Client} ${timeParsed} `;
		text2 = `${currentLanguge.loginEarlyArrival.text2Client}`;
	} else {
		titleText = `${currentLanguge.loginEarlyArrival.titleTextAgent} ${register.firstName} ${register.lastName}`;
		text1 = `${currentLanguge.loginEarlyArrival.text1_1Agent} ${meeting.agentFirstname} ${meeting.agentLastname}, ${currentLanguge.loginEarlyArrival.text1_2Agent} ${splittedDate[0]} ${monthName}, ${fullYear} ${currentLanguge.loginEarlyArrival.text1_3Agent} ${timeParsed} `;
		text2 = `${currentLanguge.loginEarlyArrival.text2Agent}`;
	}
	

	return (
		<div className="earlyArrivalContainer row g-0 mt-5">
			<div className="col-12 text-center py-2 px-5 mt-4" id="earlyArrivalContent">
				<img src="/assets/img/icons/ico-info.svg" alt="" className="loginLayout_img mx-auto" />
				<h1 className="fw-bold my-4 f36">
					{titleText}
				</h1>

				<span className="m-0 p-0 align-self-center mt-2 mb-1 f20">{text1}</span>
				<span className="m-0 p-0 align-self-center mt-2 mb-2 f18">{text2}</span>

			</div>
		</div>
	);
};

export default EarlyArrival;
