import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

const GoogleMap = ({ children, ...props }) => (
    <div className='row' >
      <div id="map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyAGecYBtwfXbyJ_7rpU0vaXcWhqi7O2Yts',
        }}
        {...props}
      >
        {children}
      </GoogleMapReact>
      </div>
    </div>
);

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;