import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	buildings: [],
	floors: [],
	appartments: [],
	images: [],
	
};

export const dataSlice = createSlice({
	name: "data",

	initialState,

	reducers: {
		setBuildingLarge: (state, action) => {
			state.buildings = action.payload;
		},
		setFloorLarge: (state, action) => {
			state.floors = action.payload;
		},
		setApartmentsLarge: (state, action) => {
			state.appartments = action.payload;
		},
		setImages: (state, action) => {
			state.images = action.payload
		}
	},
});

// Action creators are generated for each case reducer function
export const { setFloorLarge, setApartmentsLarge, setBuildingLarge,setImages } = dataSlice.actions;

export default dataSlice.reducer;
