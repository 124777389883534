import { Suspense, useEffect, useState } from "react";
import StepTitle from "../../UI/Titels/StepTitle";
import "./BuildingsSelection.sass";
import { makeGetRequest } from "../../../Axios";
import { useSelector } from "react-redux";
import "./BuildingSelection.sass";
import "../DetailsApproval/DetailsApproval.sass";
import BuildingsView from "./BuildingView";
import {
	addDelays,
	setBuildings,
	setLoadingModalContent,
	setRoomsCount,
} from "../../../Helpers/AppartmentReserveUtils";
import $ from "jquery";
import { handleLargeImagesDisplay } from "./image";
import { SideImagePlaceholder } from "../../Layout/ImagePlacholders/ImagePlacholders";
import { onContainerClick, onRemoteContainerClick } from "./BuildingUtils";

const BuildingsSelection = (props) => {
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const [requestOk, setRequestOk] = useState(false);
	const projectID = useSelector((state) => state.project.projectID);
	const meetingSlug = useSelector((state) => state.verification.slug);

	const buildings = useSelector((state) => state.data.buildings);
	const currentLanguge = useSelector((state) => state.project.currentLanguge);

	let imgParentId = "buildingImgCont";
	let imagesPropName = "buildingImages"; 
	let firstImageProp = "buildingMain";

	useEffect(() => { }, [currentLanguge]);

	useEffect(() => {
		if (window.hasOwnProperty("$")) {
			window.$("#buildingImgCont").sticky({ topSpacing: 90, bottomSpacing: 140, wrapperClassName: "" });
		}
	}, []);

	useEffect(() => {
		const abortController = new AbortController();

		try {
			setLoadingModalContent("");
			if (projectID) {
				const requestBuildings = async () => {
					
					let langCode = sessionStorage.getItem("langCode");
					if (!langCode || langCode == '') {
						langCode = '';
					} else {
						langCode = '/' + langCode;
					}

					const req = await makeGetRequest("/projectbuildings",  `${projectID}${langCode}`).catch((err) => console.log(err));
					if (!req) {
						return;
					}
					if (req) {
						if (req.data.message) {
							const mapped = req.data.message.map((el, idx) => el);
							setBuildings(mapped);
							setRequestOk(true);
							$('.buildings_list__item').addClass('showElement');
						}
					}
				};
				requestBuildings();
			}
		} catch (err) {
			console.log(err);
		}
		return () => {
			abortController.abort(); // cancel pending fetch request on component unmount
		  } 
	}, [projectID, currentLanguge]);

	//if data is returned then handle images and content dealy animation
	useEffect(() => {
		if (requestOk) {
			handleLargeImagesDisplay(buildings, "first_picture", "buildingImg", "id", firstImageProp, imgParentId, imagesPropName, true, 'building_title');
			addDelays(".buildings_list__itemExtra");
		}
	}, [requestOk, currentLanguge]);

	// //set rooms count globally
	useEffect(() => {
		try {
			let roomsObj = buildings.map((el) => {
				return {
					id: el.id,
					rooms: el.roomsinfo,
				};
			});
			setRoomsCount(roomsObj);
		} catch (err) {
			console.log(err);
		}
	}, [buildings]);

	// get apt click listener
	useEffect(() => {
		document.addEventListener("buildingClick", (e) => {
			onRemoteContainerClick(e);
		});

		return () => {
			document.removeEventListener("buildingClick", () => {});
		};
	}, [buildings]);


	return (
		<div className="m-0 flex-nowrap apartment_reserve__stepContainer" id="apartment_reserve__step-1">
			<div className=" py-4 step_content buildingList">
				<StepTitle className="pfHeaderShadow py-2 mb-3 fw-bold">{currentLanguge.buildingsSelection_step.stepTitle}</StepTitle>
				<hr className="w-90 pfLine m-2" />
				<div className="list-group">
					<Suspense fallback={<div>laoding</div>}>
						{/* <OneRenderBuildings /> */}
						<BuildingsView list={buildings} handleBuildingSelect={props.handleBuildingSelect} onContainerClick={onContainerClick} isAgent={isAgent} meetingSlug={meetingSlug} />

					</Suspense>
				</div>
			</div>
			<div className="bg-image step_side_img" id="buildingImgCont">
				<div className="col-12 d-flex justify-content-center align-self-center m-auto position-relative">
					<SideImagePlaceholder />
				</div>
			</div>
		</div>
	);
};

export default BuildingsSelection;
