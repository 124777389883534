import { useEffect, useState } from "react";
import LayoutSplitScreenContent from "../../components/Layout/LayoutSplitScreen/LayoutSplitScreenContent";
import LayoutSplitScreenImage from "../../components/Layout/LayoutSplitScreen/LayoutSplitScreenImage";
import PageContentWrapper from "../../components/Layout/PageContentWrapper";
import CheckVideoStep from "../../components/LoginPage/CheckVideoStep/CheckVideoStep";
import ConfirmValidationCode from "../../components/LoginPage/ConfirmValidationCode/ConfirmValidationCode";
import InitialLoginForm from "../../components/LoginPage/InitialLoginForm/InitialLoginForm";
import LateArrival from "../../components/LoginPage/LateArrival/LateArrival";
import ReschedualMeeting from "../../components/LoginPage/ReschedualMeeting/ReschedualMeeting";
import VerficationSuccessfull from "../../components/LoginPage/VerficationSuccessfull/VerficationSuccessfull";
import * as registerActions from "../../Redux/Reducers/registerSlice";
import { requestMeeting } from "../../Helpers/RegistetUtils";
import "./LoginPage.sass";
import {
	HandleVerificationInputs,
	handleCodeInput,
	resendCode,
	validateCode,
	incrementStep,
	handleCodeIndicator,
	setVerificationStep,
	setProjectID,
	handleSetToken,
} from "../../Helpers/verificationUtils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import $ from "jquery";
import { makePostRequest } from "../../Axios";
import { setProjectSettingsObject } from "../../Helpers/AppartmentReserveUtils";
import { setAgentFirstname, setAgentLasttname, setMeetingStatus, setUserId, setRequestSkipPay, setRequestSkipSign, setTimeToMeeting } from "../../Helpers/MeetingsUtils";
import moment from "moment";
import { MainLoader } from "../../components/Layout/ImagePlacholders/ImagePlacholders";
import EarlyArrival from "../../components/LoginPage/EarlyArrival/EarlyArrival";
import store from "../../Redux/Store/store";
import * as projectActions from "../../Redux/Reducers/projectSlice";
import GTPageView from "../../Helpers/GTPageView";


const LoginPage = ({setContinueToApp}) => {
	const verificationSelector = useSelector((state) => state.verification);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const currentStep = useSelector((state) => state.verification.currentVerificationStep);
	const isUserVerified = useSelector((state) => state.project.userVerified);

	const [isLoading, setIsLoading] = useState(true);
	const [projectImageDesktop, setProjectImageDesktop] = useState('');
	const [meetingTime, setMeetingTime] = useState(true);
	const [meetingTempStatus, setMeetingTempStatus] = useState('before');
	const [step, setStep] = useState(1);
	
	const { meetingSlug } = useParams();
	const getUrlParams = new URLSearchParams(window.location.search);
	const agentKey = getUrlParams.get("agentkey");
	const isAgent = agentKey != '' && agentKey != null ? true : false;

	const token = sessionStorage.getItem("forestToken");

	const timeBeforeMeeting = 20;

	useEffect(() => { }, [currentLanguge]);

	const { setVerificationMethod, setEmail, setMobileNumber, handlePhoneSubmit, handleEmailSubmit } = HandleVerificationInputs();

	const verifyMeetingTime = async (meetingSlug, setIsLoading) => {
		const timeFormat = "YYYY-MM-DD HH:mm:ss";
		try {
			let data = new FormData();
			data.append("agentkey", "");

			let langCode = sessionStorage.getItem("langCode");
			if (!langCode || langCode == '') {
				langCode = '';
			} else {
				langCode = '/' + langCode;
			}

			//get meeting data
			const res = await makePostRequest(`/meetingrooms/${meetingSlug}${langCode}`, data);
			if (!res) {
				return;
			}

			if (res.data.statusCode == 404) {
					setIsLoading(false)
					setStep(999)
				return;
			}
			if (res.data.meeting.request_of_skip_sign == '10') {
				setRequestSkipSign(true);
			} else {
				setRequestSkipSign(false);
			}
			if (res.data.meeting.request_of_skip_pay == '10') {
				setRequestSkipPay(true);
			} else {
				setRequestSkipPay(false);
			}
			

			store.dispatch(projectActions.setProjectDefaultLangauge(res.data.project.project_default_language));
			store.dispatch(projectActions.setCurrentLangauge(res.data.project.project_default_language));

			setUserId(res.data.clientDetails.id);
			setProjectID(res.data.project.project_slug);
			store.dispatch(registerActions.setFirstName(res.data.clientDetails.firstname));
			store.dispatch(registerActions.setLastName(res.data.clientDetails.lastname));
			store.dispatch(registerActions.setID(res.data.clientDetails.identity_id));
			store.dispatch(registerActions.setMobileNumber(res.data.clientDetails.mobile));
			setAgentFirstname(res.data.agentDetails.first_name);
			setAgentLasttname(res.data.agentDetails.last_name);
			setProjectSettingsObject(res.data.project);
			setProjectImageDesktop(res.data.project.project_image_desktop);

			const meetingLength = res.data.project.meeting_duration;
			const meetingUtc = res.data.meeting.meeting_start_datetime;
			const client_timezone = res.data.clientDetails.client_timezone;

			const meetingStartTime = moment.utc(meetingUtc).tz(client_timezone).format(timeFormat);
			const meetingStartTime2 = moment(meetingStartTime)

			setMeetingTime(meetingStartTime);

			const meetingEndtTime = moment(meetingStartTime2).add(meetingLength, "minutes");

			//early check for date match

			let timeLoop = setInterval(() => {
				const now = moment();
				const verificationStep = sessionStorage.getItem('verificationStep');
				const loginStep = sessionStorage.getItem('loginStep');
				let duration = moment.duration(meetingStartTime2.diff(now))
				let asMinuets = duration.asMinutes();
				let secondsToMeeting = duration.asSeconds();

				setTimeToMeeting(secondsToMeeting);

				// On Time
				if (now.isAfter(meetingStartTime2) && !isAgent && now.isBefore(meetingEndtTime)) {
					setIsLoading(false)
					if (loginStep === 7 && verificationStep != 2) {
						setVerificationStep(1);
						setStep(1);
					} 
					setMeetingTempStatus('ontime');
					return;
				} else if (now.isAfter(meetingStartTime2) && isAgent && now.isBefore(meetingEndtTime)) {
					setMeetingTempStatus('ontime');
					setContinueToApp(true);
					clearInterval(timeLoop)
					return;
				}

				// Early
				if (now.isBefore(meetingStartTime2)) {
					setIsLoading(false);
					if (asMinuets > timeBeforeMeeting) {
						setMeetingStatus("waiting");
						setMeetingTempStatus('vary_eraly');

						// setVerificationStep(7);
						setStep(7);
						return;
					} else if (asMinuets < timeBeforeMeeting) {
						if (!isAgent) {
							if (loginStep == 7 && verificationStep != 2) {
								setVerificationStep(1);
								setStep(1);
							} 
						} else {
							// setVerificationStep(7);
							setStep(7);
						}
						setMeetingTempStatus('eraly');
						return;
					}
				}

				// Late 
				if (now.isAfter(meetingEndtTime)) {
					setIsLoading(false);
					// setVerificationStep(4);
					setStep(4);
					setMeetingTempStatus('late');
					clearInterval(timeLoop)
					return;
				}
			}, 2000);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if(step == 1) setVerificationStep(1);
		sessionStorage.setItem('loginStep', step)
		GTPageView('login page');
	}, [step])
	
	useEffect(() => {
		sessionStorage.setItem('verificationStep', currentStep)
		if(currentStep === 2) setStep(2);
	}, [currentStep]);

	useEffect(() => {
		if(isUserVerified && (meetingTempStatus == 'eraly')) setStep(6);
	}, [isUserVerified, meetingTempStatus]);


	useEffect(async () => {
		handleSetToken().then(()=>verifyMeetingTime(meetingSlug, setIsLoading))
	}, [token]);

	useEffect(() => {
		$("#apartment_view_change__desktop").hide();
		$("#apartment_view_change__mobile").hide();
		$(".stats_container").hide();
	}, []);

	const Phone = (phone) => {
		if (phone.includes('*')) {
			let newphone = phone.replace('*', '');
			return newphone + '*';
		} else if (phone.includes('+')) {
			let newphone = phone.replace('+', '');
			return newphone + '+';
		} else {
			return phone;
		}
	};


	if (isLoading) {
		return <MainLoader />;
	}

	if (step === 999) {
		return (
			<div id="login_page">
				<div className="col-6 mt-5 mx-auto text-center" style={{fontSize:'40px'}}>Meeting not found</div>
			</div>
		);
	} else {
		return (
			<PageContentWrapper id="login_page">
				{/* Content Col  */}
				<LayoutSplitScreenContent className="pb-5 px-0 mobileFuulWidthi">
					{/* Meeting time - on time/over due  */}
					{/* Step 1: Welcome message and Phone/Email Verefication  */}
					{step === 1 && (
						<InitialLoginForm
							setVerificationMethod={setVerificationMethod}
							setEmail={setEmail}
							setMobileNumber={setMobileNumber}
							handlePhoneSubmit={handlePhoneSubmit}
							handleEmailSubmit={handleEmailSubmit}
							slug={verificationSelector.slug}
							mobileNumber={verificationSelector.mobileNumber}
							verificationSelector={verificationSelector}
						/>
					)}
					{/* Step 2: Validate Verfication Code  */}
					{step === 2 && (
						<ConfirmValidationCode
							handleCodeInput={handleCodeInput}
							email={verificationSelector.email}
							phone={Phone(verificationSelector.mobileNumber)}
							areaCode={verificationSelector.areaCode}
							numberWithPrefix={Phone(verificationSelector.numberWithPrefix)}
							verificationMethod={verificationSelector.verificationMethod}
							resendCode={resendCode}
							validateCode={validateCode}
							handleCodeIndicator={handleCodeIndicator}
							setStep={setStep}
						/>
					)}
					{/* Step 3: Verfication Code Validated Successfully. Proceed In   */}
					{step === 3 && <VerficationSuccessfull />}
					{/* Step 4.1: Meeting is over. Proceed without agent, or reschedual meeting  */}
					{step === 4 && <LateArrival incrementStep={incrementStep} />}
					{/* Step 4.1.1: Meeting is over. Reschedual meeting  */}
					{step === 5 && <ReschedualMeeting requestMeeting={requestMeeting} />}
					{/* Step 4.2: Came on time, Chack video and mic setting  */}
					{step === 6 && <CheckVideoStep checkVideo="checkVideo"  />}
					{step === 7 && <EarlyArrival meetingTime={meetingTime} />}
				</LayoutSplitScreenContent>

				{/* Background img Col */}
				<LayoutSplitScreenImage  projectImageDesktop={projectImageDesktop } />
			</PageContentWrapper>
		);
	}
};

export default LoginPage;
