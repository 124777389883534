import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import './HomeSlider.sass';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const HomeSlider = () => {
	const project_gallery = useSelector((state) => state.project.projectSettings.project_gallery);
    useEffect(() => {
    },[project_gallery])


    const homeSlides = project_gallery.map((el) => {
		return (
			<div key={el.filepath}>
				<img src={el.filepath} alt={el.filetitle}/>
			</div>
		);
	})
    if (!project_gallery || project_gallery.length == 0) return <></>;

    return (
        <div className="row mt-5" id="project_home_slider">
            <div className="container p-0">
                <Carousel useKeyboardArrows={true}
                    animationHandler='slide'
                    autoPlay={true}
                    swipeable={true}
                    emulateTouch={true}
                    interval={3000}
                    infiniteLoop={true}
                    showThumbs={false}
                    showIndicators={false}
                    dynamicHeight={false}
                    showStatus={false}>
                    {homeSlides}
                </Carousel>
            </div>
        </div>
      );
}

export default HomeSlider;
