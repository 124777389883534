import './ProjectStats.sass';

const ProjectStatItem = props => {
  return (
    <div className="col text-nowrap d-flex align-items-center statItem" key={props.indx}>
        <span className={`stat_circle align-self-center heartbeat ${props.stat_color}`}></span>
        <span className="stat_value align-self-center w700">{isNaN(props.stat_number) ? '' : props.stat_number}</span>
        <span className="stat_title w500">{props.stat_title}</span>
    </div>
  );
}

export default ProjectStatItem;
