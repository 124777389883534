import { useEffect, useState } from "react";
import { createLocalVideoTrack } from "twilio-video";
import "./CheckVideo.sass";
import { createInitialTracks, getAudioDevices, getAudioOutputDevice, getVideoDevices } from "./LocalVideoUtils";
import { useSelector } from "react-redux";

const CheckVideo = (props) => {
	const [testCamera, setTestCamera] = useState(false);
	const currentLanguge = useSelector((state) => state.project.currentLanguge);
	const selectedCamera = useSelector((state) => state.register.selectedCamera);
	const selectedMic = useSelector((state) => state.register.selectedMic);
	const selectedSpeaker = useSelector((state) => state.register.selectedSpeaker);

	useEffect(() => { }, [currentLanguge]);
	
	//set initial video element
	useEffect(async () => {
		const video = document.querySelector("video");
		if (!testCamera && !video) {
			createLocalVideoTrack({ deviceId: { exact: selectedCamera } }).then((track) => {
				const localMediaContainer = document.getElementById("local-cam-container");
				localMediaContainer.appendChild(track.attach());
			});
		}
		//set micrphone permission
		const micPermission = await navigator.mediaDevices.getUserMedia({ video: true, audio: true }).catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		const asyncDevicesCall = async () => {
			const devices = await navigator.mediaDevices.enumerateDevices();
			getVideoDevices(devices, selectedCamera);
			getAudioDevices(devices, selectedMic);
			getAudioOutputDevice(devices, selectedSpeaker);
		};
		asyncDevicesCall();
		createInitialTracks(selectedCamera, selectedMic);
	}, []);

	return (
		<div className="col-12 d-flex flex-column">
			<div className="col-11 align-self-center mt-3" id="local-cam-container">
				<video src="" />
			</div>
			<div className="col-11 d-flex flex-row align-self-center rf-darkBg" style={{ height: "38px", borderTop: "1px solid white" }}>
				<div className="col-12 align-self-center d-flex flex-row justify-content-center h-100" aria-label="video-control">
					<div className="dropdown col-6  p-0 d-flex justify-content-center text-center dropup">
						<button
							className="bg-dark dropdown-toggle w600 col-12 checkBtns h-100 text-white align-self-center"
							type="button"
							id="checkCamBtn"
							data-bs-toggle="dropdown"
							aria-expanded="false"
							style={{ letterSpacing: "0.5px" }}
						>
							{currentLanguge.checkVideoStep.checkCamera}
						</button>
						<ul className="dropdown-menu col-12 pt-0" id="videoCheckDropdownMenu" style={{ minHeight: "100px" }} aria-labelledby="checkCamBtn">
							<span className="f14 w600 text-center bg-secondary d-block text-light py-2">{currentLanguge.checkVideoStep.cameraListTitle}</span>
						</ul>
					</div>

					<div className="dropdown col-6  p-0 d-flex justify-content-center text-center dropup" style={{ borderRight: "1px solid white" }}>
						<button
							className="bg-dark dropdown-toggle w600 col-12 checkBtns h-100 text-white align-self-center"
							type="button"
							id="checkMicBtn"
							data-bs-toggle="dropdown"
							aria-expanded="false"
							style={{ letterSpacing: "0.5px" }}
						>
							{currentLanguge.checkVideoStep.checkMic}
						</button>
						<div className="dropdown-menu col-12 pt-0" id="audioCheckDropdownMenu" style={{width:"300px", zIndex:"99999"}} aria-labelledby="checkMicBtn" role="menu">
							<div className="col-12 border-bottom" id="outPutDeviceMenu" role="menu">
								<span className="f14 w600 text-center bg-secondary d-block text-light py-2">{currentLanguge.checkVideoStep.speakerListTitle}</span>
							</div>
							<span className="f14 w600 text-center bg-secondary d-block text-light py-2">{currentLanguge.checkVideoStep.micListTitle}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckVideo;
