import { AgentFormPhone } from "../../General/PhoneValidator/PhoneValidator";
import FormSectionTitle from "../../General/UserDetailsForm/FormSectionTitle";
import { memo, useCallback, useEffect, useState } from "react";
import { registerAgentAssign } from "../../../Helpers/RegistetUtils";
import { useSelector } from "react-redux";
import { setGetAgentStatus, setFlowErrorObject } from "../../../Helpers/AppartmentReserveUtils";
import store from "../../../Redux/Store/store";
import $ from "jquery";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { makePostRequest } from "../../../Axios";

const AgentForm = (props) => {
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastName] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [mobileNumberWithPrefix, setMobileNumberWithPrefix] = useState("");

	const [email, setEmail] = useState("");
	const [termsAgreed, setTermsAgreed] = useState(false);

	const terms = useSelector((state) => state.project.projectSettings.project_agreement_termspdf);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)

	const registerFirstname = useSelector((state) => state.register.firstName);
	const registerLastName = useSelector((state) => state.register.lastName);
	const registerEmail = useSelector((state) => state.register.email);
	const registerMobileNumber = useSelector((state) => state.register.mobileNumberWithPrefix);
	const registerTermsAgreed = useSelector((state) => state.register.termsAgreed);

	const meetingslug = store.getState().meeting.roomSpaceID;
	const userID = store.getState().meeting.userID;

	// captch start
	const { executeRecaptcha } = useGoogleReCaptcha();
	const [token, setToken] = useState('');
	const [actionToChange, setActionToChange] = useState('');

	const findAgentSubmit = useCallback(async (e, firstname, lastname, email, mobileNumberWithPrefix, termsAgreed, loadingButton, currentLanguge) => {
		e.preventDefault();
        if (!executeRecaptcha) {
            return;
		}

        const result = await executeRecaptcha('register');
		setToken(result);
		
		let data = new FormData();
		data.append("token", result);
		data.append("action", 'register');

		const req = await makePostRequest("/validate_recaptcha", data);
		if (!req) {
			return;
		}
		
		// if (req.data.message == 'OK') {
			registerAgentAssign(e, firstname, lastname, email, mobileNumberWithPrefix, termsAgreed, loadingButton, currentLanguge);
		// } else {
			// setFlowErrorObject({errorCode:"100",errorMessage:currentLanguge.formsErrors.botError, errorTitle: ""})
		// }
		
	}, [executeRecaptcha]);
	
	useEffect(() => { }, [currentLanguge]);

	useEffect(() => {
        if (!executeRecaptcha) {
            return;
        }
        const handleReCaptchaVerify = async () => {
            const token = await executeRecaptcha('register');
			setToken(token);
        };
        handleReCaptchaVerify();
    }, [executeRecaptcha]);
	// captch end
	
	const handleTextChange = useCallback(event => {
        setActionToChange(event.target.value);
	}, []);

	useEffect(() => {
		if (registerFirstname != '') setFirstname(registerFirstname);
		if (registerLastName != '') setLastName(registerLastName);
		if (registerEmail != '') setEmail(registerEmail);
		if (registerMobileNumber != '') setMobileNumber(registerMobileNumber);
		setTermsAgreed(registerTermsAgreed);
	 }, [registerFirstname, registerLastName, registerEmail, registerMobileNumber, registerTermsAgreed]);

	useEffect(() => {
		if (meetingslug != '' && userID != '') {
			$('#agentForm_firstName').prop('disabled', true);
			$('#agentForm_lastName').prop('disabled', true);
			$('#agentForm_email').prop('disabled', true);
			$('#agentValidator').prop('disabled', true);
			$('#customer_details_from__terms').prop('disabled', true);
		}
	}, [meetingslug, userID]);
	
	const handleBack = () => {
		setGetAgentStatus(false);
	};
	

	const loadingButton = `<div className="lds-spinner">
				<div></div><div></div>
				<div></div><div></div>
				<div></div><div></div>
				<div></div><div></div>
				<div></div><div></div>
				<div></div><div></div>
			</div>`;
	
	return (
		<div className="position-relative">
			<img
				src="/assets/img/icons/cross.png"
				id="agentFormBack"
				className={`position-absolute pointer ${props.showBack ? "": 'd-none'}`}
				data-gt-category="agent component" data-gt-action="click" data-gt-label="close lead form"
				alt=""
				height="30"
				width="30"
				onClick={handleBack}
			/>

			<div className="col-12 d-flex flex-column justify-content-center align-self-center text-center pfItemFont mb-3 pt-4 ">
				<FormSectionTitle className="fs-5 fw-bold">{currentLanguge.findAgentComp.agentFormTitle}</FormSectionTitle>
				<span>{currentLanguge.findAgentComp.agentFormSubtitle}</span>
			</div>

			<div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 d-flex flex-column justify-content-center mb-4">
				<div className="col-xxl-10 col-xl-10 col-lg-10 mt-0 col-md-10 col-sm-10 mt-1 align-self-center">
					<div className="input-group-md">
						<label className="form-label">{currentLanguge.formsFields.firstName}</label>
						<input className="form-control pfShadow pfInput formItemFont"
							placeholder={currentLanguge.formsFields.firstName}
							value={firstname}
							id='agentForm_firstName'
							onChange={(e) => { setFirstname(e.target.value); handleTextChange(e);}} />
					</div>
					<div className="invalidField" id="invalidFirstNameAgent" />
				</div>
				<div className="col-xxl-10 col-xl-10 col-lg-10 mt-1 col-md-10 col-sm-10 mt-1 align-self-center">
					<div className="input-group-md">
						<label className="form-label">{currentLanguge.formsFields.lastName}</label>
						<input className="form-control pfShadow pfInput formItemFont"
							placeholder={currentLanguge.formsFields.lastName}
							value={lastname}
							id='agentForm_lastName'
							onChange={(e) => { setLastName(e.target.value); handleTextChange(e);}} />
					</div>
					<div className="invalidField" id="invalidLastNameAgent" />
				</div>

				<div className="col-xxl-10 col-xl-10 col-lg-10 mt-1 col-md-10 col-sm-10 mt-1 align-self-center">
					<div className="input-group-md">
						<label className="form-label">{currentLanguge.formsFields.email}</label>
						<input className="form-control pfShadow pfInput formItemFont" type="email"
							placeholder={currentLanguge.formsFields.email}
							value={email}
							id='agentForm_email'
							onChange={(e) => { setEmail(e.target.value); handleTextChange(e);}} />
					</div>
					<div className="invalidField" id="invalidEmailAgent" />
				</div>

				<div className="col-xxl-10 col-xl-10 col-lg-10 mt-1 col-md-10 col-sm-10 mt-1 align-self-center">
					<div className="input-group-md d-flex flex-column">
						<label className="form-label">{currentLanguge.formsFields.phone}</label>
						<AgentFormPhone
							onChangeMethod={setMobileNumberWithPrefix}
							valueSelector={mobileNumber}
							id="agentValidator"
							className="pde-5 pfInput pfShadow"
							propName={"agentPhone"}
							key={"agentValidator"}
							placeHolderFunc={setMobileNumber}

						/>
					</div>
					<div className="invalidField" id="invalidPhoneAgent" />
				</div>
				<div className="col-xxl-10 col-xl-10 col-lg-10 mt-1 col-md-10 col-sm-10 mt-1 align-self-center">
					<div className="form-check">
						<input
							className="form-check-input"
							type="checkbox"
							value={termsAgreed}
							checked={termsAgreed}
							id="customer_details_from__terms"
							onChange={(e) => setTermsAgreed(e.target.checked)}
						/>
						<label className="form-check-label pfItemFont" htmlFor="customer_details_from__terms">
							{currentLanguge.formsFields.terms}{" "}
							<a href={`${terms}`} target="_blank" rel="noreferrer">
								{currentLanguge.formsFields.termsLink}
							</a>
						</label>
					</div>
					<div id="invalidTermsAgent" className="invalidField"></div>
				</div>

				<div className="col-xxl-10 col-xl-10 col-lg-10 mt-0 col-md-10 col-sm-10 align-self-center  text-center mt-3">
					<div className="d-grid">
						<button
							key={lastname + firstname}
							type="submit"
							className="btn rf-btn-primary fw-bold fs-6 sigButtonPadding pfSubmitBtnColor registerAgentBtn"
							onClick={(e) => findAgentSubmit(e, firstname, lastname, email, mobileNumberWithPrefix, termsAgreed, loadingButton, currentLanguge)}
						>{currentLanguge.formsCTA.formContinue}</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(AgentForm);
