import store from "../../Redux/Store/store";
import * as actions from "../../Redux/Reducers/meetingSlice";
import { AppartmentLoader } from "../../components/Layout/ImagePlacholders/ImagePlacholders";
export const setHour = (hour) => {
	store.dispatch(actions.setHour(hour));
};
export const setDate = (date) => {
	store.dispatch(actions.setDate(date));
};

const handleDaySelection = (setMatchingHours, idx, value) => {
	setDate(value);
	setMatchingHours(idx);
};

export const Days = ({ daysList, setMatchingHours }) => {
	try {
		if (daysList) {

			return daysList.map((day, idx) => {

				return (
					<div className="input-group-day-select" key={idx}>
						<input
							onChange={(e) => console.log}
							type="radio"
							className="btn-check"
							name="schedule_meeting__day_select"
							id={`selected_day__${idx}`}
							autoComplete="off"
							value={day}
							onClick={(e) => handleDaySelection(setMatchingHours, idx, day.fulldate)}
						/>
						<label className="btn pfShadow fs-6" htmlFor={`selected_day__${idx}`}>
							<span className="d-block fw-bold">{day.weekday}</span>
							{day.dayToDisplay}
						</label>
					</div>
				);
			});
		} else {
			return (
				<div className="col-12 d-flex justify-content-center align-self-center m-auto position-relative">
					<AppartmentLoader key="presentationLoader" />
				</div>
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const HoursAvailable = ({ meetingHours, isHoursAvailableLoaded, setIsHoursAvailableLoaded }) => {
	try {
		if (meetingHours.time && isHoursAvailableLoaded) {
			return meetingHours.time.map((hour, idx) => {
				return (
					<div key={idx} className="input-group-day-hour">
						<input
							type="radio"
							className="btn-check "
							name="schedule_meeting__hour_select"
							id={`selected_hour${idx}`}
							autoComplete="off"
							value={hour}
							onClick={(e) => setHour(e.currentTarget.value)}
						/>
						<label
							className="btn pfShadow f16 w600 rounded-2 text-center center"
							htmlFor={`selected_hour${idx}`}
							style={{ height: "35px", width: "70px", lineHeight: "20px" }}
						>
							{hour}
						</label>
					</div>
				);
			});
		} else {
			return (
				<div className="col-12 d-flex justify-content-center align-self-center m-auto ">
					<AppartmentLoader key="presentationLoader2" />
				</div>
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const sliceIntoChunks = (arr, chunkSize) => {
	try {
		const res = [];
		for (let i = 0; i < arr.length; i += chunkSize) {
			const chunk = arr.slice(i, i + chunkSize);
			res.push(chunk);
		}


		return res;
	} catch (err) {
		console.log(err);
	}
};
export const setMeetingStatus = (value) => {
	store.dispatch(actions.setMeetingStatus(value));
};

export const setRequestSkipPay = (value) => {
	store.dispatch(actions.setRequestSkipPay(value));
};

export const setTimeToMeeting = (value) => {
	store.dispatch(actions.setTimeToMeeting(value));
};
export const setIsAgent = (value) => {
	store.dispatch(actions.setIsAgent(value));
};
export const setUserId = (value) => {
	store.dispatch(actions.setUserId(value));
};
export const setMeetingStep = (value) => {
	store.dispatch(actions.setMeetingStep(value));
};

export const setAgentControl = (value) => {
	store.dispatch(actions.setAgentControl(value));
};
export const setAgentControlChanged = (value) => {
	store.dispatch(actions.setAgentControlChanged(value));
};
export const setAgentID = (value) => {
	store.dispatch(actions.setAgentID(value));
};
export const setRequestSkipSign = (value) => {
	store.dispatch(actions.setRequestSkipSign(value));
};
export const setRoomSpaceID = (value) => {
	store.dispatch(actions.setRoomSpaceID(value));
};

export const setAgentFirstname = (value) => {
	store.dispatch(actions.setAgentFirstname(value));
};
export const setAgentLasttname = (value) => {
	store.dispatch(actions.setAgentLasttname(value));
};
export const setAgentEmail = (value) => {
	store.dispatch(actions.setAgentEmail(value));
};

export const setClientType = (value) => {
	store.dispatch(actions.setClientType(value));
};

export const setClientTypeName = (value) => {
	store.dispatch(actions.setClientTypeName(value));
};

export const setMeetingObj = (meetingObj) => {
	store.dispatch(actions.setAgentEmail(meetingObj.agentEmail));
	store.dispatch(actions.setAgentFirstname(meetingObj.agentFirstname));
	store.dispatch(actions.setAgentID(meetingObj.agentID));
	store.dispatch(actions.setAgentLasttname(meetingObj.agentLastname));
	store.dispatch(actions.setClientType(meetingObj.clientType));
	store.dispatch(actions.setClientTypeName(meetingObj.clientTypeName));
	store.dispatch(actions.setDate(meetingObj.date));
	store.dispatch(actions.setHour(meetingObj.hour));
	
	store.dispatch(actions.setIsSelfReserve(meetingObj.isSelfReserve));
	store.dispatch(actions.setTimezone(meetingObj.timezone));
	store.dispatch(actions.setAgentControlChanged(meetingObj.agentControlChanged));
}