import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./LoadingModal.sass";

const LoadingModal = () => {
	const loadingModalContent = useSelector((state) => state.project.loadingModalContent);

	const [show, setShow] = useState(false);

	useEffect(() => {
		if (loadingModalContent) {
			setShow(true);
			return;
		}
		if (!loadingModalContent) {
			setShow(false);
		}
	}, [loadingModalContent]);

	return (
		<Modal contentClassName="costumeModalBG" dialogClassName='onTopModal' backdropClassName='onTopModal' show={show} centered>
			<Modal.Body className="" id="loadingModal">
				<div className="row">
					<div className="sideImageLG d-flex justify-content-center align-self-center">
						<div className="bounce1"></div>
						<div className="bounce2"></div>
						<div className="bounce3"></div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default LoadingModal;
