import { useEffect, useState } from "react";
import FormSectionTitle from "./FormSectionTitle";
import { useSelector } from "react-redux";
import "./UserDetailsFormFields.sass";
import PhoneValidator from "../PhoneValidator/PhoneValidator";
import { lockRegisterFields } from "../../../Helpers/RegistetUtils";

const UserDetailsFormFields = (props) => {
	const registerSelector = useSelector((state) => state.register);
	const mobileWithoutPrefix = useSelector((state) => state.register.mobileNumberWithoutPrefix);
	const editClientDetails = useSelector((state) => state.register.editClientDetails);
	const [mobileNumber, setMobileNumber] = useState("");
	const currentLanguge = useSelector((state) => state.project.currentLanguge);

	const elementsIdExtra = props.buyerDetailes == true ? "_buyer" : "";
	
	useEffect(() => { }, [currentLanguge]);
	
	function onlyNum(e, maxLength) {
		const re = /^[0-9\b]+$/;
		e.preventDefault();

		if (re.test(e.target.value) && e.target.value.length > 0 && e.target.value.length <= maxLength) {
			document.getElementById(e.target.id).value = e.target.value;
		} else if ((!re.test(e.target.value) && e.target.value.length > 0) || e.target.value.length > maxLength) {
			document.getElementById(e.target.id).value = e.target.value.slice(0, -1);
		}
	}

	var place = "";
	if (props.isClientRegistered && !props.buyerDetailes) {	
		place = registerSelector.mobileNumber;
	} else if (!props.isClientRegistered && !mobileWithoutPrefix && !props.buyerDetailes) {
		place = registerSelector.mobileNumber;
	}else if (!props.isClientRegistered && mobileWithoutPrefix && !props.buyerDetailes) {
		place = registerSelector.mobileNumber;
	}else if (!props.isClientRegistered && props.buyerDetailes) {
		place = registerSelector.buyerMobileNumber;
	}

	useEffect(() => {
		if(editClientDetails) lockRegisterFields();
		if(props.isClientRegistered && editClientDetails) lockRegisterFields();
	},[editClientDetails, props.isClientRegistered])

	let fn = props.buyerDetailes == true ? props.formSelector.firstName : registerSelector.firstName;

	let showID = true;
	if (window.location.pathname.includes('/register')) {
		showID = false;
	 }
	return (
		<>
			<div className={!props.buyerDetailes ? 'costumeCol2' : 'costumeCol'}>
				<FormSectionTitle className="fs-4 fw-bold">{props.detailField ? `${props.detailField}` : `${currentLanguge.userDetailsFormFields.formTitle1}`}</FormSectionTitle>
				
				<p style={{ display: !props.buyerDetailes ? 'block' : 'none' }} className="mb-4">{currentLanguge.userDetailsFormFields.formDescription1}</p>
			</div>

			<div className="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-2" id={ `digitalForm${elementsIdExtra}`}>
				{/* First Name */}
				<div className="col col-md-5 col-sm-6 mt-0">
					<label htmlFor="register_form__firstname" className="form-label f18 mds-2 mb-1 w500">
						{currentLanguge.formsFields.firstName}
					</label>
					<div className="input-group-lg">
						<input
							type="text"
							className="form-control pfShadow pfInput w500 f18"
							id={ `register_form__firstname${elementsIdExtra}`}
							placeholder={currentLanguge.formsFields.firstName}
							onChange={(e) => { props.setFirstName(e.currentTarget.value); props.handleTextChange(e); }}
							value={props.buyerDetailes == true ? props.formSelector.firstName == '' ? registerSelector.buyerFirstName  : props.formSelector.firstName : registerSelector.firstName}
						/>
					</div>
					<div id={ `invalidFirstName${elementsIdExtra}`} className="invalidField appear"></div>
				</div>

				{/* Last Name */}
				<div className="col mt-0 col-md-5 col-sm-6">
					<label htmlFor="register_form__lastname" className="form-label f18 mds-2 mb-1 w500">
						{currentLanguge.formsFields.lastName}
					</label>
					<div className="input-group-lg">
						<input
							type="text"
							className="form-control pfShadow pfInput w500 f18 "
							id={ `register_form__lastname${elementsIdExtra}`}
							placeholder={currentLanguge.formsFields.lastName}
							onChange={(e) => { props.setLastName(e.currentTarget.value); props.handleTextChange(e); }}
							value={props.buyerDetailes == true ? props.formSelector.lastName == '' ? registerSelector.buyerLastName  : props.formSelector.lastName : registerSelector.lastName}
							/>
					</div>
					<div id={ `invalidLastName${elementsIdExtra}`} className="invalidField"></div>
				</div>

				{/* ID */}
				<div className={`col mt-0 col-md-5 col-sm-6 ${!showID ? 'd-none' : ''}`} id={ `register_form__id_element${elementsIdExtra}`}>
					<label htmlFor="register_form__id" className="form-label f18 mb-1 mds-2 w500">
						{currentLanguge.formsFields.ID}
					</label>
					<div className="input-group-lg">
						<input
							type="text"
							onInput={(e) => onlyNum(e, 10)}
							className="form-control pfShadow pfInput w500 f18 d-left"
							id={ `register_form__id${elementsIdExtra}`}
							placeholder={currentLanguge.formsFields.ID}
							onChange={(e) => { props.setID(e.currentTarget.value); props.handleTextChange(e);}}
							value={props.buyerDetailes == true ? props.formSelector.ID == '' ? registerSelector.buyerID  : props.formSelector.ID : registerSelector.ID}
							/>
					</div>
					<div id={ `invalidID${elementsIdExtra}`} className="invalidField"></div>
				</div>

				{/* Email */}
				<div className="col mt-0 col-md-5 col-sm-6">
					<label htmlFor="register_form__email" className="form-label f18 mb-1 mds-2 w500">
						{currentLanguge.formsFields.email}
					</label>
					<div className="input-group-lg">
						<input
							type="email"
							className="form-control pfShadow pfInput w500 d-left f18"
							id={ `register_form__email${elementsIdExtra}`}
							placeholder={currentLanguge.formsFields.email}
							onChange={(e) => { props.setEmail(e.currentTarget.value); props.handleTextChange(e);}}
							value={props.buyerDetailes == true ? props.formSelector.email == '' ? registerSelector.buyerEmail  : props.formSelector.email : registerSelector.email}
							/>
					</div>
					<div id={ `invalidEmail${elementsIdExtra}`} className="invalidField"></div>
				</div>

				{/* Phone */}
				<div className="col mt-0 col-md-5 col-sm-6 d-flex flex-column f18 ">
					<label className="form-label f18 mb-1 mds-2 w500" htmlFor={props.phonePropName}>
						{currentLanguge.formsFields.phone}
					</label>
					<PhoneValidator
						onChangeMethod={props.setMobileNumber}
						valueSelector={place}
						id={props.phonePropName}
						propName={props.phonePropName}
						className="align-self-end pde-5 pfInput w500 pfShadow"
						key={props.phonePropName}
						placeHolderFunc={setMobileNumber}
					/>
					<div id={ `invalidPhone${elementsIdExtra}`} className="invalidField"></div>
				</div>
			</div>
		</>
	);
};

export default UserDetailsFormFields;
