import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../../Redux/Store/store";
import { MainLoader } from "../../Layout/ImagePlacholders/ImagePlacholders";
import HeroTitle from "../../UI/Titels/HeroTitle";
import $ from "jquery";
import "./HomeHero.sass";
import {
	resetFlow,
} from "../../../Helpers/AppartmentReserveUtils";
import parse from 'html-react-parser'
import { useParams } from "react-router-dom";

const HomeHero = (props) => {
	const [isHeroLoaded, setIsHeroLoaded] = useState(false);
	const projectSettings = useSelector((state) => state.project.projectSettings);
	const isUserVerified = useSelector((state) => state.project.userVerified);
	const isProjectPublic = useSelector((state) => state.project.projectSettings.project_public) == 0 ? false : true;

	const cta_schedulemeeting = useSelector((state) => state.project.projectSettings.txt_schedulemeeting);
	const cta_startreservation = useSelector((state) => state.project.projectSettings.txt_startreservation);
	let { projectSlug } = useParams();

	const handleEnterProject = () => {
		const slug = store.getState().verification.slug;
		const isSelfReserve = store.getState().meeting.isSelfReserve;

		if (isUserVerified) {
			resetFlow(false);
			window.location.pathname = `/${projectSlug}/building_selection`;
			return;
		}
		if (isSelfReserve) {
			resetFlow(false);
			window.location.pathname = `/${projectSlug}/building_selection`;
			return;
		}
		if (slug) {
			window.location.pathname = "/login";
			return;
		}
		window.location.pathname = `/${projectSlug}/building_selection`;
	};

	useEffect(() => {
		try {
			if (!isHeroLoaded) {
				let hero = document.querySelector("#home_hero");

				let img = new Image();
				img.src = projectSettings.project_image_desktop;
				img.alt = projectSettings.project_title;
				img.classList.add("appear", "mainImage", "m-0", "p-0");
				img.onload = function () {
					hero.replaceChild(img, hero.firstChild);
					$("#innerHero").fadeTo(10, 1);
					setIsHeroLoaded(true);
				};
			}
		} catch (err) {
			console.log(err);
		}
	}, [projectSettings]);

	const GoToProjectButton = () => {
		if (isProjectPublic) {
			return (
				<button type="button" onClick={handleEnterProject} className={`btn btn-light fw-bold py-3`} id="enterProjectBtn" data-gt-category='home page'  data-gt-action='click'  data-gt-label='go to project'>
					{cta_startreservation}
				</button>
			);
		} else {
			return null;
		}
	};
	
	return (
		<div className="row justify-content-end mb-5 appear" id="home_hero">
			<MainLoader />
			<div className="col-lg-6 col-md-12 col-sm-12 " id="innerHero" style={{ opacity: 0 }}>
				<div className="container" style={{ height: "100%" }}>
					<div className="row justify-content-center hero_content" style={{ height: "100%" }}>
						<div className="col-lg-7 col-md-10 py-5 px-4 hero_content__box" style={{ zIndex: "1" }}>
							{/* Hero main title */}
							<HeroTitle className="mb-4">{projectSettings && projectSettings.project_title && projectSettings.project_title != '' ? parse(projectSettings.project_title) : ''}</HeroTitle>


							{/* Hero description */}
							<p className="mb-5">{projectSettings && projectSettings.project_text && projectSettings.project_text != '' ? parse(projectSettings.project_text) : ''}</p>

							{/* Hero CTA */}
							<div className="d-grid gap-4">
								<GoToProjectButton />
								<a href={`/${projectSettings.project_slug}/register`} className="btn btn-light fw-bold py-3" data-gt-category='home page'  data-gt-action='click'  data-gt-label='schedual meeting'>
									{cta_schedulemeeting}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeHero;
